import Ellipse22 from '@/assets/messageDemo/Ellipse22.jpg';
import Ellipse27 from '@/assets/messageDemo/Ellipse27.jpg';
import Ellipse28 from '@/assets/messageDemo/Ellipse28.jpg';
import Ellipse30 from '@/assets/messageDemo/Ellipse30.jpg';
import Ellipse34 from '@/assets/messageDemo/Ellipse34.jpg';
import Ellipse1173 from '@/assets/messageDemo/Ellipse1173.jpg';
import Ellipse1178 from '@/assets/messageDemo/Ellipse1178.jpg';
import Ellipse1182 from '@/assets/messageDemo/Ellipse1182.jpg';
import Ellipse1177 from '@/assets/messageDemo/Ellipse1177.jpg';
import Group3466340 from '@/assets/messageDemo/Group3466340.jpg';
import Group3466401 from '@/assets/messageDemo/Group3466401.jpg';
import callsparent from '@/components/homePage/img/callsparent.svg';
import messages from '@/components/homePage/img/messages.svg';
import contacts from '@/components/homePage/img/contacts.svg';
import photos from '@/components/homePage/img/photos.svg';
import application from '@/components/homePage/img/application.svg';
import homePageScreen1 from '@/assets/messageDemo/homePageScreen1.png';
import homePageScreen2 from '@/assets/messageDemo/homePageScreen2.png';
import homePageScreen3 from '@/assets/messageDemo/homePageScreen3.png';
import homePageScreen4 from '@/assets/messageDemo/homePageScreen4.png';
import homePageScreen5 from '@/assets/messageDemo/homePageScreen5.png';

export const jsonDataUserInfo = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      user: {
        id: 'RWlDo6nP89zNWMJQkJyoKjrLBOk4MVpZ',
        email: 'demo@parentalguard.com',
        name: 'demo@parentalguard.com',
        profile_picture_url: Ellipse34,
      },
      devices: [
        {
          id: 'demo-device-dddd0001',
          name: "Claire's Samsung",
          model: 'SM-S901U',
          brand: 'Samsung',
          battery: '98',
          wifi: 'on',
          android_version: '10',
          apk_version: '2.2.0',
          last_update_at: 1700124834,
          expire_at: 2524579199,
          is_expired: false,
          is_active: false,
        },
        {
          id: 'demo-device-dddd0002',
          name: "Axel's Samsung",
          model: 'SM-A5360',
          brand: 'Samsung',
          battery: '82',
          wifi: 'on',
          android_version: '12',
          apk_version: '2.2.0',
          last_update_at: 1700124835,
          expire_at: 2524579199,
          is_expired: false,
          is_active: false,
        },
      ],
      icloud_accounts: [
        {
          apple_id: 'demo@parentalguard.com',
          begin_at: 946656000,
          expired_at: 2524579199,
          storage_used: '30.22GB/50.00GB',
        },
      ],
      subscripts: {
        begins_at: 946656000,
        expires_at: 2524579199,
        effective_device_num: 0,
        effective_icloud_num: 0,
        total_effective_pit: 0,
      },
      is_auto_renew: false,
    },
  },
};

export const jsonDataDevices = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      devices: [
        {
          id: 'demo-device-dddd0001',
          name: "Claire's Samsung",
          model: 'SM-S901U',
          brand: 'Samsung',
          battery: '98',
          wifi: 'on',
          android_version: '10',
          apk_version: '2.2.0',
          last_update_at: 1700124834,
          expire_at: 2524579199,
          is_expired: false,
          is_active: false,
        },
        {
          id: 'demo-device-dddd0002',
          name: "Axel's Samsung",
          model: 'SM-A5360',
          brand: 'Samsung',
          battery: '82',
          wifi: 'on',
          android_version: '12',
          apk_version: '2.2.0',
          last_update_at: 1700124835,
          expire_at: 2524579199,
          is_expired: false,
          is_active: false,
        },
      ],
      icloud_accounts: [
        {
          apple_id: 'demo@parentalguard.com',
          expired_at: 2524579199,
          is_expired: false,
          devices: [
            {
              id: 'apple-demo-device-0002',
              name: "David's iPhone 13",
              model: 'iPhone13',
              last_update_at: 1697597216,
              expire_at: 2524579199,
              is_expired: false,
              is_active: true,
              apple_id: 'demo@parentalguard.com',
            },
            {
              id: 'apple-demo-device-0001',
              name: "Emily's iPhone 15",
              model: 'iPhone15',
              last_update_at: 1697597216,
              expire_at: 2524579199,
              is_expired: false,
              is_active: true,
              apple_id: 'demo@parentalguard.com',
            },
          ],
        },
      ],
    },
  },
};

export const jsonDataActive = {
  code: 200,
  message: 'OK',
  data: {
    whatsapp_active_session: [
      {
        chat_session_title: 'Pre holidayers',
        last_message: 'Got it.',
        count: 16,
        percentage: 100,
        avatar_url: Group3466340,
        contact_name: 'Pre holidayers',
      },
      {
        chat_session_title: 'Hailey Levine',
        last_message: 'Ok. Nighty night.',
        count: 14,
        percentage: 87,
        avatar_url: Ellipse28,
        contact_name: 'Hailey Levine',
      },
      {
        chat_session_title: 'Luke Adler',
        last_message: 'hahaha. 100% agreed.',
        count: 12,
        percentage: 75,
        avatar_url: Ellipse30,
        contact_name: 'Luke Adler',
      },
      {
        chat_session_title: 'Mike',
        last_message:
          "No problem. I'm always happy to share beautiful things with my friends.",
        count: 10,
        percentage: 62,
        avatar_url: Ellipse1178,
        contact_name: 'Mike',
      },
      {
        chat_session_title: 'Bonnie',
        last_message: 'Yeah, it sounds like a dream. ',
        count: 7,
        percentage: 43,
        avatar_url: Ellipse1182,
        contact_name: 'Bonnie',
      },
    ],
    instagram_active_session: [
      {
        chat_session_title: 'Luna',
        last_message: 'You too! ',
        count: 10,
        percentage: 100,
        avatar_url: Ellipse1173,
        contact_name: 'Luna',
      },
      {
        chat_session_title: 'Nelia Justo',
        last_message: 'Yeah, who said no. hahahaha😄',
        count: 8,
        percentage: 80,
        avatar_url: Ellipse22,
        contact_name: 'Nelia Justo',
      },
      {
        chat_session_title: 'talk& gossip',
        last_message: 'see ya then.',
        count: 7,
        percentage: 70,
        avatar_url: Group3466401,
        contact_name: 'talk& gossip',
      },
      {
        chat_session_title: 'Peter',
        last_message: 'Thanks! You too. Talk to you soon.',
        count: 6,
        percentage: 60,
        avatar_url: Ellipse1177,
        contact_name: 'Peter',
      },
      {
        chat_session_title: 'Cameron',
        last_message: 'i am honestly sorry',
        count: 5,
        percentage: 50,
        avatar_url: Ellipse27,
        contact_name: 'Cameron',
      },
    ],
  },
};

export const jsonDataDeviceDDDD = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      device: {
        id: 'demo-device-dddd0001',
        name: "Claire's Samsung",
        model: 'SM-S901U',
        brand: 'Samsung',
        battery: '98',
        wifi: 'on',
        android_version: '10',
        apk_version: '2.2.0',
        last_update_at: 1700124834,
        expire_at: 2524579199,
        is_expired: false,
        is_active: false,
      },
    },
  },
};

export const jsonDataDeviceDDDD2 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      device: {
        id: 'demo-device-dddd0002',
        name: "Axel's Samsung",
        model: 'SM-A5360',
        brand: 'Samsung',
        battery: '82',
        wifi: 'on',
        android_version: '12',
        apk_version: '2.2.0',
        last_update_at: 1700124835,
        expire_at: 2524579199,
        is_expired: false,
        is_active: false,
      },
    },
  },
};

export const jsonDataGeneral = {
  code: 200,
  message: 'OK',
  data: [
    {
      name: 'Calls',
      src: callsparent,
      path: 'calllogs',
      total: 15,
      inc: 0,
    },
    {
      name: 'Messages',
      src: messages,
      path: 'messages',
      total: 35,
      inc: 0,
    },
    {
      name: 'Contacts',
      src: contacts,
      path: 'contacts',
      total: 42,
      inc: 0,
    },
    {
      name: 'Photos',
      src: photos,
      path: 'photos',
      total: 21,
      inc: 0,
    },
    {
      name: 'Applications',
      src: application,
      path: 'installedApps',
      total: 15,
      inc: 0,
    },
  ],
};

export const jsonDataScreen = {
  code: 200,
  message: 'OK',
  data: {
    usage_proportion: 0,
    weekly_usage: [27000, 26100, 20100, 29700, 21600, 18600, 25800],
    daily_usage: 24128,
    apps: [
      {
        logo_url: homePageScreen1,
        name: 'Spotify',
        usage: 21600,
        perc: 100,
      },
      {
        logo_url: homePageScreen2,
        usage: 18900,
        perc: 87.5,
      },
      {
        logo_url: homePageScreen3,
        name: 'Netflix',
        usage: 16200,
        perc: 75,
      },
      {
        logo_url: homePageScreen4,
        name: 'X',
        usage: 15600,
        perc: 72.22222222222221,
      },
      {
        logo_url: homePageScreen5,
        name: 'Pokémon GO',
        usage: 15600,
        perc: 72.22222222222221,
      },
    ],
  },
};

export const jsonDataDemoSessions = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjM4NTI5NDQzMzQsImlhdCI6MTcwNTQ2MDY4Nywic2Vzc2lvbl9pZCI6IjY1YTc0M2NmMWQ0NDdlMThlYTU2ZTM3OSJ9.XfXtJKLET00UZ-gJQ9Ap-XA7D6ADlwJ0FvmtO2GmiiA',
      user_id: 'RWlDo6nP89zNWMJQkJyoKjrLBOk4MVpZ',
      email: 'demo@parentalguard.com',
    },
  },
};

export const jsonDataDeviceIos = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      apple_id: 'demo@parentalguard.com',
      storage_usage: '30.22GB / 50.00GB',
    },
  },
};
