import axios from 'axios';
import store from '@/store';
import { service } from '@/utils/request';

// 获取设备列表
export const getDeviceApi = (data: any) => {
  return service({
    url: '/v1/devices',
    method: 'get',
    params: data,
  });
};

// 获取首页展示的通话与信息列表
export const getDashBoard = (params: any) => {
  return service({
    url: '/v2/dashboard/active-sessions',
    method: 'get',
    params: params,
  });
};

// 获取首页新增数据
export const getDashNew = (params: any) => {
  return service({
    url: '/v1/dashboard/statistics/general-apps',
    method: 'get',
    params: params,
  });
};

// 获取屏幕数据统计
export const getDashCount = (params: any) => {
  return service({
    url: '/v1/dashboard/statistics/screen-usages',
    method: 'get',
    params: params,
  });
};

// 获取当前设备信息
export const getDeviceInfo = (data: any, id: any) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  // console.log(source, 'source');
  store.commit('setDeviceInfoSource', source);
  return service({
    url: `/v1/devices/${id}`,
    method: 'get',
    params: data,
    cancelToken: source.token,
  });
};

// 解绑设备并删除该设备的所有数据
export const unbindAndEmptyDataApi = (id: any) => {
  return service({
    url: `/v1/devices/${id}`,
    method: 'delete',
  });
};

// 删除设备的所有数据
export const emptyDataApi = (id: any) => {
  return service({
    url: `/v1/devices/${id}/data`,
    method: 'delete',
  });
};

// 换绑
export const reboundDevice = (data: any) => {
  return service({
    url: '/v1/devices/rebound',
    method: 'post',
    data,
  });
};

// 获取日历事件列表
export const getCalender = (data: any) => {
  const CancelToken = axios.CancelToken;
  const calenderSource = CancelToken.source();
  store.commit('setClenderSource', calenderSource);
  return service({
    url: '/v1/calendars',
    method: 'get',
    params: data,
    cancelToken: calenderSource.token,
  });
};

// 获取通话记录
export const getCallLogs = (data: any) => {
  const CancelToken = axios.CancelToken;
  const callLogsSource = CancelToken.source();
  store.commit('setCallLogsSource', callLogsSource);
  return service({
    url: '/v1/calls',
    method: 'get',
    params: data,
    cancelToken: callLogsSource.token,
  });
};

/// 获取应用列表
export const getAppList = (id: any) => {
  const CancelToken = axios.CancelToken;
  const appListSource = CancelToken.source();
  store.commit('setAppListSource', appListSource);
  return service({
    url: `/v1/devices/${id}/apps`,
    method: 'get',
    cancelToken: appListSource.token,
  });
};

// 获取相册信息
export function photoInfo(data: any) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  store.commit('setPhotoInfoSource', source);
  return service({
    url: '/v1/photos',
    method: 'get',
    params: data,
    cancelToken: source.token,
  });
}

// 获取相册信息
export function deletePhoto(data: any) {
  return service({
    url: '/v1/photos',
    method: 'delete',
    data,
  });
}
// 获取禁用规则列表
export const getAppBlockList = (id: any) => {
  return service({
    url: `/v1/devices/${id}/block-rules`,
    method: 'get',
  });
};

// 创建禁用规则
export const createBlockRule = (id: any, data: any) => {
  return service({
    headers: {
      Authorization: localStorage.getItem('authorization'),
      // ContentType:'application/json',
    },
    url: `/v1/devices/${id}/app-block-rule`,
    method: 'post',
    data,
  });
};

// 修改禁用规则
export const editBlockRule = (id: any, data: any) => {
  return service({
    headers: {
      Authorization: localStorage.getItem('authorization'),
      // ContentType:'application/json',
    },
    url: `/v1/devices/${id}/app-block-rule`,
    method: 'patch',
    data,
  });
};

// 移除禁用规则
export const removeBlockRule = (id: any, data: any) => {
  return service({
    url: `/v1/devices/${id}/app-block-rules`,
    method: 'delete',
    data,
  });
};

// 获取未禁用应用列表
export const getUnblockApp = (id: any) => {
  return service({
    url: `/v1/devices/${id}/unblock-apps`,
    method: 'get',
  });
};

// 获取屏幕使用信息
export const getScreen = (data: any) => {
  return service({
    url: `/v1/screen-usage`,
    method: 'get',
    params: data,
  });
};

// 设置屏幕禁用规则
export const createScreenkRule = (id: any, data: any) => {
  return service({
    url: `/v1/devices/${id}/screen-block`,
    method: 'post',
    data,
  });
};

// 获取messages
export const getMessagesApi = (data: any) => {
  return service({
    url: '/v1/messages',
    method: 'get',
    params: data,
  });
};
//获取聊天会话
export const getChatSessionsApi = (data: any) => {
  return service({
    url: '/v1/chat-sessions',
    method: 'get',
    params: data,
  });
};
//获取地图信息列表
export const getLocationApi = (data: any) => {
  return service({
    url: '/v1/locations',
    method: 'get',
    params: data,
  });
};
//获取地理围栏列表
export const getGeofencApi = (data: any) => {
  return service({
    url: '/v1/geo-fencings',
    method: 'get',
    params: data,
  });
};
//修改创建地理围栏
export const getGeofencsApi = (data: any) => {
  return service({
    url: '/v1/geo-fencing',
    method: 'post',
    data,
  });
};
//删除围栏
export const deleteGeofencsApi = (data: any, id: string) => {
  return service({
    url: `/v1/geo-fencings/${id}`,
    method: 'delete',
    data,
  });
};

//获取围栏事件
export const crossingEventsApi = (data: any) => {
  return service({
    url: `/v1/geo-fencing/crossing-events`,
    method: 'get',
    params: data,
  });
};

//切换国家
export const changeCountry = (data: any, id: any) => {
  return service({
    url: `/v1/users/${id}`,
    method: 'patch',
    data,
  });
};

//ios同步图片接口
export const syncIOSPhotosApi = (data: any) => {
  return service({
    url: '/v1/icloud/photos',
    method: 'post',
    data: data,
  });
};

//ios获取照片接口
export const iosPhotosApi = (data: any, id: any) => {
  return service({
    url: `/v1/icloud/${id}/photos`,
    method: 'get',
    params: data,
  });
};
