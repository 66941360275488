export const FR = {
  chat: {
    pictureText: '[Image]',
    videoText: '[Vidéo]',
    audioText: '[Audio]',
    contactCard: '[Carte de Contact]',
    location: '[Emplacement]',
    other: '[Autre]',
    all: 'Tout',
    today: "Aujourd'hui",
    yesterday: 'Hier',
    last7Days: 'Les 7 Derniers Jours',
    last30Days: 'Les 30 Derniers Jours',
    customRange: 'Sélectionner la Plage Personnalisée',
    cancel: 'Annuler',
    apply: 'Appliquer',
    pushNotify: 'Notifications Push',
    numbers: 'Numéros',
    emails: 'E-mails',
    outgoingCalls: 'Appels Sortants',
    incomingCalls: 'Appels Entrants',
    missedCalls: 'Appels Manqués',
    answeredCalls: 'Appels Répondus',
    noAnswer: 'Pas de réponse',
    canceledCalls: 'Appels Annulés',
    loadingText: 'Chargement, merci de patienter...',
    noMoreText: "Il n'y a plus de contenu",
    noInfoText:
      "Aucune information sur l'événement, veuillez vérifier plus tard.",
    noAddressText:
      "Aucune information sur le carnet d'adresses, veuillez vérifier plus tard.",
    noCallText: "Pas de journal d'appels, veuillez revérifier plus tard.",
    dateSelect: 'Sélectionner une date',
    sentFile: 'Envoyé un fichier',
    sentAudio: 'Envoyé un audio',
    unGetFile: "Impossible d'obtenir le fichier",
    unGetAudio: "Impossible d'obtenir de l'audio",

    syncFailed: 'Synchronisation des données a échoué !',
    syncSuccessed: 'Synchronisation des données a réussi !',
    unknownContact: 'Contact inconnu',
    noSyncNotice1: "Les données n'ont pas été synchronisées depuis plus de ",
    noSyncNotice2: '24 heures',
    noSyncNotice3:
      '. Veuillez vous assurer que le téléphone cible est allumé et connecté à Internet, et que l\'application "System Service" fonctionne normalement.',
    sync: 'Synchronisation',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    noSupportToast: "Le filtrage par date n'est pas pris en charge dans Demo.",

    unknowVideo:
      "Vidéo. Les détails ne sont pas disponibles en raison de la politique de confidentialité d'Instagram.",
    unknowImage:
      "Image. Les détails ne sont pas disponibles en raison de la politique de confidentialité d'Instagram.",
    unknowAudio:
      "Message vocal. Les détails ne sont pas disponibles en raison de la politique de confidentialité d'Instagram.",
    outgoingVoice: 'Appel audio sortant',
    incomingVoice: 'Appel audio entrant',
    outgoingVideo: 'Appel vidéo sortant',
    incomingVideo: 'Appel vidéo entrant',
  },
  user: {
    userProfile: "Profil de l'Utilisateur",
    usesrname: "Nom d'utilisateur :",
    edit: 'Éditer',
    email: 'E-mail :',
    permission: 'Informations sur la permission',
    bounded: 'Appareil(s) Lié(s) :',
    restDevice: 'Appareil(s) restant(s) disponible(s) :',
    expire: "Date d'expiration :",
    expired: '((Expiré)',
    logout: 'Se déconnecter',
    changename: "Changer le nom d'utilisateur",
    save: 'Enregistrer',
    inputname: "Veuillez entrer un nom d'utilisateur",
    nameNull:
      "Le format du nom d'utilisateur est anormal, veuillez le saisir à nouveau",
    networkError:
      "Impossible de changer le nom d'utilisateur, veuillez vérifier votre réseau",
    nameSuccess: "Le nom d'utilisateur a été modifié avec succès",
    changeavatar: "Changer d'avatar",
    loading: 'Chargement…',
    changeAvatar: 'Changer',
    supported:
      'Prend en charge les formats jpg, jpeg, png et la taille ne peut pas dépasser 2M.',
    maxsize: "La taille de l'image ne peut pas dépasser 2M",
    avatarError:
      "Impossible de changer l'avatar, veuillez vérifier votre réseau",
    avatarSuccess: 'Avatar modifié avec succès',
    imgError: "Erreur d'image",
    imageType: "Type d'image non compatible",
    nameEmpty: "Le nom d'utilisateur ne peut pas rester vide",
  },
  leftTab: {
    device: 'Appareil',
    unbound: 'Appareil(s) Non Lié(s)',
    addDevice: 'Ajouter un Appareil',
    dashboard: 'Tableau de bord',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: "Veuillez d'abord lier un appareil",
    payRemind: "Veuillez d'abord acheter",
    purchase: 'Acheter',
    unboundName: "Associer l'Appareil Maintenant",
    toBuy: "Acheter l'Appareil",
  },
  guide: {
    title: 'Veuillez lier votre appareil',
    oneT: "1. Obtenir l'Appareil Cible",
    oneContent:
      "Pour surveiller l'appareil, vous devez obtenir l'appareil surveillé cible pendant environ 10 minutes.",
    twoT: "2. Télécharger & Installer l'App",
    twoContent1: 'Entrer',
    twoContent2:
      "dans le navigateur de l'appareil cible, puis télécharger et installer l'app.",
    threeT: "3. Configurer les Autorisations de l'Appareil",
    threeContent:
      "Suivre les instructions de l'app pour configurer les autorisations de l'appareil. Après le réglage, vous pouvez surveiller l'appareil.",
    tips1:
      "Pour assurer la furtivité, l'app sera déguisée en logiciel système nommé 'System Service'.",
    tips2:
      "Pour assurer la navigation privée, vous pouvez supprimer les enregistrements du navigateur et les packages d'installation de l'app après avoir installé l'app avec succès",
    tips3:
      "l'app sera affichée en tant que 'System Service' sur le bureau. Vous pouvez choisir de le cacher dans un dossier pour un meilleur effet furtif.",
    tips41:
      "Pour assurer que vous pouvez installer l'app et configurer ses autorisations plus rapidement, vous pouvez d'abord consulter",
    tips42: ' pour apprendre à le faire.',
    detail: 'les tutoriels détaillés',
    tips5:
      "Si l'autorisation de l'appareil lié a expiré, vous pouvez modifier la liaison de l'appareil et lier l'appareil à cette autorisation de continuer à collecter des données.",
    rebound: "Cliquez pour changer la liaison de l'appareil",
  },
  dashboard: {
    contact: 'Top des contacts dans WhatsApp (dans les 7 jours)',
    call: 'Top des Appels sur WhatsApp',
    view: 'Voir Tout',
    account: 'Compte',
    id: 'ID de Compte :',
    expire: "Date d'Expiration :",
    bound: 'Appareil Lié :',
    restDevice: 'Appareil(s) Restant(s) :',
    target: "À Propos de l'Appareil Cible",
    brand: 'Marque :',
    model: 'Modèle :',
    version: 'Version Android :',
    battery: 'Batterie :',
    wifi: 'Wi-Fi :',
    appversion: "Version de l'App :",
    update: 'Dernière Mise à Jour :',
    blank: 'Aucune Activité Récente',
    unbindSuc: 'Dissociation a réussi !',
    unbindFail: 'Dissociation a échoué !',
    ReboundSuc: "Modification de la liaison de l'appareil a réussi !",
    ReboundFail: "Modification de la liaison de l'appareil a échoué !",
    noPlace: 'Pas de dispositif pour changer la liaison !',
    beExpOne: "L'autorisation de votre appareil expirera dans",
    beExpTwo: 'jours ',
    beExpThree:
      "et les données de l'appareil surveillé ne seront pas synchronisées après l'expiration.",
    expiredOne:
      "L'autorisation de votre appareil a expiré. Les données précédemment collectées seront effacées dans",
    expiredTwo:
      ". Après le renouvellement, les données de l'appareil surveillé continueront d'être synchronisées.",
    renew: 'Renouveler maintenant',

    insContact: 'Top Contacts sur Instagram (dans les 7 jours)',
    insCall: 'Top Contacts sur Instagram',
  },
  payPage: {
    title: 'Tarifs & Plans de ParentalGuard',
    dec: 'Surveiller WhatsApp avec Falikeep est plus simple que vous ne le pensez !',
    bigSave: 'Recommandé',
    one: 'Plan de 1 Mois',
    three: 'Plan de 3 Mois',
    year: 'Plan de 1 An',
    toBuy: 'Acheter',
    auto: 'Renouvellement Automatique',
    cancelAny: 'Annuler à tout moment',
    update: 'Mettre à jour automatiquement les données toutes les cinq minutes',
    collect: 'Collecter toutes les conversations',
    interaction: 'Meilleure expérience interactive',
    hide: 'Mode furtif',
  },
  pop: {
    changeBound: "Changer la liaison de l'appareil",
    reboundText1: "Êtes-vous sûr de lier l'appareil ",
    reboundText2:
      "à l'autorisation qui n'est liée à aucun appareil pour continuer à collecter des données (Date d'expiration :",
    reboundText3: ') ?',
    reboundText4:
      "à l'autorisation qui n'est liée à aucun appareil pour continuer à collecter des données",
    noRebound:
      "Aucune autorisation d'appareil disponible. Veuillez d'abord l'acheter.",
    goPay: 'Acheter',
    reboundList: 'Appareil Lié',
    reboundDec:
      "L'autorisation d'appareil suivante a expiré. Sélectionnez et modifier l'appareil lié à l'autorisation qui n'est liée à aucun appareil pour continuer à collecter des données.",
    rebounding: "Modification de la liaison de l'appareil...",
    reboundFail1: 'Impossible de modifier la liaison du périphérique',
    reboundFail2: '. Voulez-vous essayer à nouveau?',
    tryAgain: 'Réessayer',
    unbindTitle: "Dissocier l'appareil",
    unbindText1: 'Êtes-vous sûr de dissocier le périphérique',
    unbindText2:
      '? Toutes les informations de données collectées seront effacées après la libération.',
    remind: "Rappel d'Expiration",
    expiredPay: 'Renouveler',
    cancel: 'Annuler',
    yes: 'Oui',
    no: 'Non',
  },
  navTop: {
    langTitle: 'Changer de Langue',
  },
  famikeep200: {
    Calls: 'Appels',
    Messages: 'Messages',
    Contacts: 'Contacts',
    Photos: 'Photos',
    Applications: 'Applications',
    'Compared to yesterday': 'Comparé à hier',
    'All locations': 'Toutes les localisations',
    'Weekly average usage time': "Temps d'utilisation moyen hebdomadaire",
    'Floating comparison with last week':
      'Comparaison flottante avec la semaine dernière',
    Average: 'Moyenne',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mer',
    Thu: 'Jeu',
    Fri: 'Ven',
    Sat: 'Sam',
    Sun: 'Dim',
    'Most used applications': 'Applications les plus utilisées',
    Geolocation: 'Géolocalisation',
    'Last updated': 'Dernière mise à jour',
    'Geolocation permission':
      "L'autorisation de géolocalisation pour l'application 'Service Système' est désactivée, impossible d'obtenir la position",
    Geofence: 'Géofence',
    'Create a geofence':
      'Créez un géofencing pour recevoir des alertes par e-mail lorsque votre enfant entre/sort de la zone spécifiée',
    'enters/exits': 'entre/sort de la zone spécifiée',
    'Create a new geofence': 'Créer un nouveau géofencing',
    'Geofence name': 'Nom du géofencing',
    'Set geofence location': "Définir l'emplacement du géofencing",
    'Set radius': 'Définir le rayon',
    'Set monitoring time': 'Définir les heures de surveillance',
    'please choose': "Choisissez s'il vous plaît",
    Daily: 'Tous les jours',
    'Every Monday': 'Tous les lundis',
    'Every Tuesday': 'Tous les mardis',
    'Every Wednesday': 'Tous les mercredis',
    'Every Thursday': 'Tous les jeudis',
    'Every Friday': 'Tous les vendredis',
    'Every Saturday': 'Tous les samedis',
    'Every Sunday': 'Tous les dimanches',
    'Send alerts via email': 'Envoyer des alertes par e-mail',
    'We will send alert emails to the registered email':
      "Nous enverrons des e-mails d'alerte à l'adresse e-mail enregistrée",
    Save: 'Enregistrer',
    'Please enter a geofence name': 'Veuillez entrer un nom de géofencing',
    'Please select a geofence location':
      'Veuillez sélectionner un emplacement de géofencing',
    Entered: 'Entré',
    Exited: 'Sorti',
    Edit: 'Modifier',
    Delete: 'Supprimer',
    'Are you sure you want to delete':
      'Êtes-vous sûr de vouloir supprimer le géofencing xxx ? Les données de surveillance correspondantes seront également supprimées.',
    'Are you sure you want to close':
      "Etes-vous sûr de vouloir fermer ? Vous ne recevrez pas d'alertes par e-mail lorsque vous entrez/sortez de la barrière géographique.",
    'Call history': 'Historique des appels',
    Status: 'Statut',
    Number: 'Numéro',
    Duration: 'Durée',
    Date: 'Date',
    incoming: 'Appel entrant',
    outgoing: 'Appel sortant',
    missed: 'Appel manqué',
    'no answered': 'Pas de réponse',
    Address: 'Adresse',
    'Added time': "Heure d'ajout",
    'Sort by name': 'Trier par nom',
    'Sort by time': 'Trier par heure',
    'Please note that photos':
      "Veuillez noter que les photos seront téléchargées uniquement lorsque l'appareil sera connecté au Wi-Fi",
    Album: 'Album',
    'Select all photos on the current page':
      'Sélectionner toutes les photos de la page actuelle',
    'Previous page': 'Page précédente',
    'Next page': 'Page suivante',
    Back: 'Retour',
    'confirm to delete':
      'confirmer pour supprimer ? Les données ne peuvent pas être récupérées après la suppression',
    Download: 'télécharger',
    Calendar: 'Calendrier',
    Title: 'Titre',
    Description: 'Description',
    Location: 'Emplacement',
    'Start time': 'Heure de début',
    'End time': 'Heure de fin',
    'Installed applications': 'Applications installées',
    'All applications': 'Toutes les applications',
    Name: 'Nom',
    Version: 'Version',
    Size: 'Taille',
    'Installation date': "Date d'installation",
    'Disable applications': 'Désactiver les applications',
    'Seted disable rules': 'Définir les règles de désactivation',
    'Undisabled applications': 'Applications non désactivées',
    'Click the button below':
      "Cliquez sur le bouton ci-dessous pour créer une règle de désactivation d'application et choisissez les applications que vous souhaitez désactiver.",
    'Set disable rules': 'Définir les règles de désactivation',
    'Disable permanently': 'Désactiver en permanence',
    'Disable ': 'Désactiver',
    'Custom disable time': 'Personnaliser la durée de désactivation',
    'Disable period': 'Période de désactivation',
    From: 'De',
    To: 'À',
    Total: 'Total',
    Weekly: 'hebdomadaire',
    Time: 'temps',
    'Select the applications you want to disable':
      'Sélectionnez les applications que vous souhaitez désactiver',
    'Please select an application below':
      'Veuillez sélectionner une candidature ci-dessous',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Êtes-vous sûr de vouloir supprimer ? Les applications désactivées sous cette règle ne seront plus affectées.',
    'Disable options:': 'Disable options:',
    'one Disable permanently':
      "Désactivation permanente : Désactive complètement l'application. Votre enfant ne pourra pas utiliser les applications désactivées tant que vous n'aurez pas levé la désactivation.",
    'two Custom disable time':
      'Durée de désactivation personnalisée : Active uniquement la règle de désactivation pendant des plages horaires spécifiques, et votre enfant pourra utiliser les applications normalement en dehors de ces heures.',
    'You can only choose one of the two setting options':
      "Vous ne pouvez choisir qu'une des deux options de paramétrage.",
    complete: 'complet',
    'Screen usage time': "Temps d'utilisation de l'écran",
    'Screen Usage Time for xxx': "Temps d'utilisation de l'écran pour xxx",
    'Frequently used applications': 'Applications fréquemment utilisées',
    Frequency: 'Fréquence',
    'Usage time': "Temps d'utilisation",
    'Screen disable': "Désactivation de l'écran",
    'Turn on the switch and set the screen lock duration.':
      "Allumez l'interrupteur et définissez la durée de verrouillage de l'écran.",
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      "L'écran de l'appareil de votre enfant sera désactivé et se réactivera après que le temps défini aura été consommé.",
    'Disable all screen activities':
      "Désactiver toutes les activités de l'écran",
    'Set screen disable duration':
      "Définir la durée de désactivation de l'écran",
    'You can lift the disablement at any time':
      'Vous pouvez lever la désactivation à tout moment',
    'Remaining screen disable time on the target device':
      "Temps de désactivation restant sur l'écran de l'appareil cible",
    'Are you sure you want to lift the screen disablement now?':
      "Êtes-vous sûr de vouloir lever la désactivation de l'écran maintenant ?",
    Yes: 'Oui',
    'This account does not exist, please register first':
      "Ce compte n'existe pas, veuillez vous inscrire d'abord",
    'Synchronization successful, no new data':
      'Synchronisation réussie, pas de nouvelles données',
    'Location alert': 'Alerte de localisation',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Le dispositif xxx que vous surveillez est entré dans xx le xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Le dispositif xxx que vous surveillez est sorti de xx le xxxx-xx-xx',
    'View more location records:':
      "Voir plus d'enregistrements de localisation :",
    'Thank you!': 'Merci !',
    'xxx Team': 'Équipe xxx',
    'When obtaining this location':
      "Lors de l'obtention de cet emplacement, l'appareil a activé le positionnement virtuel, ce qui peut entraîner des imprécisions.",
    'The demo mode does not support this feature, please bind the device first.':
      "Le mode démo ne prend pas en charge cette fonctionnalité, veuillez d'abord lier l'appareil.",
    'Location Tracking': 'Suivi de localisation',
    'General Application': 'Application générale',
    'App Activites Tracking': 'Suivi des activités des applications',
    'Screen Time Tracking': "Suivi du temps d'écran",
    'Social Application': 'Application sociale',
    'Subject:': 'Sujet:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'Stockage iCloud utilisé',
    Online: 'En ligne',
    Offline: 'Hors ligne',
    'Last known location': 'Dernière position connue',
    'Please bind your device': 'Veuillez lier votre appareil',
    'Log in iCloud to binding.':
      "Connectez-vous au compte iCloud de l'appareil cible pour finaliser la liaison.",
    'Please enter your Apple ID': 'Veuillez entrer votre identifiant Apple',
    'Please enter your password': 'Veuillez entrer votre mot de passe',
    'Your entered account or password is incorrect.':
      'Le compte ou le mot de passe que vous avez saisi est incorrect.',
    'Ensure Apple ID and password':
      "Assurez-vous d'entrer l'identifiant Apple et le mot de passe de l'appareil cible, de vous authentifier et de finaliser la liaison.",
    'Forgot Apple ID or password?':
      'Vous avez oublié votre identifiant Apple ou votre mot de passe ?',
    'Devices using the same iCloud account will be bound':
      'Les appareils utilisant le même compte iCloud seront liés.',
    'Two-Factor Authentication': 'Authentification à deux facteurs',
    'A message has been sent':
      'Un message contenant un code de vérification a été envoyé à votre appareil. Veuillez entrer le code de vérification pour continuer.',
    'A message containing a verification code has been sent to xxx.':
      'Un message contenant un code de vérification a été envoyé à xxx. Veuillez entrer le code de vérification pour continuer.',
    'Incorrect verification code': 'Code de vérification incorrect',
    'Resend Verification Code': 'Renvoyer le code de vérification',
    'Get SMS Verification Code': 'Obtenir un code de vérification par SMS',
    'Please select a phone number to receive the verification code.':
      'Veuillez sélectionner un numéro de téléphone pour recevoir le code de vérification.',
    'Please select': 'Veuillez choisir',
    'The current iCloud has expired':
      'La vérification du compte iCloud actuel a expiré. Veuillez vous reconnecter à votre compte iCloud pour synchroniser les données. ',
    'current iCloud has expired':
      'La vérification du compte iCloud actuel a expiré. Veuillez vous reconnecter à votre compte iCloud pour synchroniser les données. Accéder à la vérification >>',
    'Go to verification >>': 'Aller à la vérification >>',
    'Data synchronization failed, iCloud account expired':
      "Échec de la synchronisation des données, compte iCloud expiré, veuillez revenir à la page d'accueil et revalider.",
    'Created geofences': 'Géofences créées',
    'No geofences created': 'Aucune géofence créée',
    'ParentalGuard - Parental Control App Pricing & Plans':
      "ParentalGuard - Tarification et plans de l'application de contrôle parental",
    '30-Day Money Back Guarantee': 'Garantie de remboursement de 30 jours',
    'Professional Customer Support': 'Support client professionnel',
    'All Prices Exclusive of VAT': 'Tous les prix hors TVA',
    '100% Secure': '100% Sécurisé',
    'More Features to Keep Your Kids Safe Online':
      'Plus de fonctionnalités pour garder vos enfants en sécurité en ligne',
    Features: 'Caractéristiques',
    'Monitor App': 'Application de surveillance',
    'Type of Data': 'Type de Données',
    'WhatsApp Chat': 'Чат WhatsApp',
    'App Activity': 'Активность приложения',
    'App Blocker': 'Блокировщик приложений',
    'Monitor Social Apps': 'Surveillance des applications sociales',
    'Location History': 'Historique de Localisation',
    'Location Reminder': 'Rappel de Localisation',
    'Live Location': 'Recherche sécurisée',
    'Safe Search': 'Recherche sécurisée',
    'Web Filter': 'Filtre Web',
    'Browser History': 'Historique du navigateur',
    'Content Detection': 'Détection de contenu',
    'All SMS': 'Tous les SMS',
    Photos: 'Photos',
    Video: 'Vidéo',
    Calendar: 'Calendrier',
    Documents: 'Documents',
    'Record Screen': 'Écran',
    'Call History': 'Historique des appels',
    'Call Recording': "Enregistrement d'appels",
    Contacts: 'Contacts',
    Messages: 'Messages',
    'Call Logs': 'Journal des appels',
    'Chat History': 'Historique de chat',
    'The device has been bound to other account':
      'デバイスは別のアカウントにバインドされています。まずバインドを解除するか、サポートセンターに連絡してバインドを解除してください。',
    Gallery: 'Galerie',
    'Hidden or Deleted Albums': 'Albums cachés ou supprimés',
    'Recent Projects': 'Projets récents',
    'Personal Collection': 'Personnel',
    'Recently Deleted': 'Récemment supprimé',
    'Live Photos': 'Photo en direct',
    'Exposure Photos': "Photo d'exposition",
    'Panoramic Photos': 'Photo panoramique',
    'Time-Lapse Photography': 'Photographie en accéléré',
    'Slow Motion': 'Ralenti',
    Portrait: 'Portrait',
    'Burst Mode': 'Prise de vue en rafale',
    Screenshot: "Capture d'écran",
    Motion: 'Dynamique',
    Hide: 'Cacher',
    'This iCloud account':
      "Ce compte iCloud n'est pas lié à un appareil. Veuillez vous connecter sur un appareil.",
    'Data synchronization':
      'La synchronisation des données peut prendre un certain temps, veuillez patienter.',
    'Location History1': 'Historique de lieu',
    'Auto Renewal': 'Renouvellement automatique et annulation à tout moment',
    Month: 'Mois',
    Quarter: 'Trimestre',
    Year: 'Année',
    Day: 'Jour',
    'See Demo': 'Voir la démo',
    'We Accept': 'Nous acceptons',
  },
  famikeep220: {
    'Browser Monitoring': 'Surveillance du navigateur',
    'Browser History': 'Historique du navigateur',
    'Browser Bookmarks': 'Signets du navigateur',
    'Serial Number': 'Numéro de série',
    'Website Restrictions': 'Restrictions de site Web',
    'Restriction Rules': 'Règles de restriction',
    'Prohibited Access': 'Accès interdit',
    'Access Warning': "Avertissement d'accès",
    'Restricted Website Log': 'Journal de site restreint',
    'Click to Add Restricted Website':
      'Cliquez pour ajouter un site Web restreint',
    'Restriction Method:': 'Mode de restriction :',
    'Prohibit Access':
      "1. Interdire l'accès : Désactivez complètement l'accès aux sites Web. Votre enfant ne pourra pas accéder aux sites Web restreints dans le navigateur tant que vous n'aurez pas supprimé la règle de blocage du site Web.",
    'Access Alerts':
      "2. Alertes d'accès : Lorsque votre enfant visite des sites Web conformes aux règles, vous recevrez des alertes par e-mail et générerez des enregistrements d'alerte (un seul e-mail d'alerte sera envoyé dans les 24 heures pour le même site Web).",
    'the rule disabling access takes priority.':
      "Si le même site Web est soumis à deux règles différentes, la règle de désactivation de l'accès a la priorité.",
    'Set Restriction Rules': 'Définir les règles de restriction',
    'This option will block':
      "Cette option bloquera l'accès aux sites Web saisis",
    'This option will allow':
      "Cette option permettra l'accès au site web saisi.",
    'For example':
      'Par exemple, si vous saisissez example.com, toutes les pages web associées (par exemple, www.example.com/page1.html) seront également bloquées.',
    'If you enter the specific website page':
      'Si vous entrez la page web spécifique www.example.com/page1.html, seule cette page sera bloquée.',
    'This option allows':
      "Cette option permet l'accès au site Web saisi mais générera des enregistrements de navigation correspondants.",
    'Send records by email': 'Envoyer des enregistrements par e-mail',
    'When enabled':
      "Lorsqu'il est activé, nous enverrons les enregistrements à l'adresse e-mail enregistrée lors de l'accès au site Web saisi.",
    'enter example':
      'Par exemple, si vous saisissez example.com, toutes les pages web associées (telles que www.example.com/page1.html) seront également alertées.',
    'enter the specific webpage':
      'Si vous entrez la page web spécifique www.example.com/page1.html, seule cette page sera alertée.',
    'Enter the website to be restricted': 'Entrez le site Web à restreindre',
    Bookmark: 'Signet',
    'Bookmark list': 'Liste des signets',
    'Total xx bookmarks': 'Total de xx signets',
    'Enter the URL': "Entrez l'URL (par ex., youtube.com)",
    'Added URLs': 'URLs ajoutées',
    Add: 'Ajouter',
    'Please add the restricted URL': "Veuillez ajouter l'URL restreinte",
    'Are you sure':
      'Êtes-vous sûr de vouloir supprimer ? La restriction sera supprimée après la suppression.',
    Video: 'Vidéo',
    'Please note that videos':
      "Veuillez noter que les vidéos ne seront téléchargées que lorsque l'appareil sera connecté au WIFI.",
    'Website access alert': "Alerte d'accès au site web",
    'monitoring accessed':
      "L'appareil xxx que vous surveillez a accédé à xx le xxxx-xx-xx.",
    'View more records:': "Voir plus d'enregistrements :",
    'Download and install the app.': "Téléchargez et installez l'application.",
    'After installation':
      "Après l'installation, veuillez ouvrir l'application en visitant xxx.com.",
    'Open immediately': 'Ouvrir immédiatement',
    'Unable to open System Service:': "Impossible d'ouvrir System Service :",
    'Please ensure this device has installed':
      '1. Veuillez vous assurer que cet appareil a installé System Service (cliquez pour télécharger System Service)',
    'click download': '(cliquez pour télécharger System Service)',
    'Copy the link':
      "2. Copiez l'adresse du lien de la page web actuelle et ouvrez-la dans le navigateur intégré",
    'Sure close':
      "Êtes-vous sûr de vouloir fermer ? Vous ne recevrez plus d'alertes par e-mail après la fermeture.",
    Loading: 'Chargement',
    'Loading failed':
      'Échec du chargement. Veuillez télécharger pour afficher localement.',
    'Select All Videos': 'Sélectionner toutes les vidéos de la page en cours',
    tips3:
      "L'application sera cachée sur l'appareil sous le nom de 'System Service'.",
    'If the video cannot be loaded':
      'Si la vidéo ne peut pas être chargée pendant une longue période, veuillez la télécharger et la regarder.',
  },
  famikeep222: {
    'Continue Google': 'Continuer avec Google',
    or: 'ou',
    Continue: 'Continuer',
    'legal guardian':
      'Je suis le tuteur légal de tout enfant mineur lié à ce compte',
    'take around 10 mins':
      'Veuillez lier un appareil Android (prendra environ 10 minutes)',
    'Get the Android device': "1. Obtenez l'appareil Android à surveiller",
    'Install Companion App': "2.Installez l'application compagnon",
    'Login to set permissions':
      '3. Connectez-vous pour définir les autorisations',
    twoContent1:
      "Veuillez accéder à ** depuis votre appareil Android surveillé pour télécharger et installer l'application",
    'set up permissions':
      "Veuillez vous connecter à votre compte dans l'application d'assistance, définir les autorisations, puis vous pourrez commencer la surveillance. (Veuillez noter que l'application ne peut être connectée qu'avec le mot de passe du compte, veuillez ** si vous n'avez pas défini de mot de passe).",
    'Click here': 'Cliquez ici',
    'continue collecting data':
      "5. Si les autorisations pour l'appareil lié ont expiré, vous pouvez continuer à collecter des données en le reliant.",
  },
  // 用户模块
  language: 'Langue',
  signIn: 'Se Connecter',
  email: 'E-mail',
  password: 'Mot de Passe',
  sendCode: 'Envoyer le Code',

  // 登录
  signingIn: 'En cours de connexion',
  noAccount: 'Pas de compte?',
  signUpNow: "S'inscrire maintenant.",
  forgetPassword: 'Mot de passe oublié?',

  // 注册
  signUp: "S'inscrire",
  createAccount: 'Créer un Compte',
  emVerificationCode: 'Code de Vérification',
  creatingAccount: "Création d'un Compte…",
  alreadyHaveAccount: 'Vous avez déjà un compte ?',
  SignInUp: 'Se Connecter.',
  send: 'Envoyé',
  sing: 's',
  byCreatingAnAccount: 'En créant un compte, vous acceptez les ',
  termsOfService: 'Termes de Service',
  and: ' et la ',
  privacyPolicy: 'Politique de Confidentialité.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Retrouver Le Mot De Passe',
  backTo: 'Retour à Se connecter',
  newPassword: 'Nouveau Mot de Passe',
  resetPassword: 'Réinitialiser le Mot de Passe',
  loginSucceeded: 'Connexion réussie !',
  logoutSucceeded: 'Déconnexion réussie !',

  // 用户模块异常提示
  passwordMmustBe8:
    "Le mot de passe doit être composé de 8 à 20 caractères et d'une combinaison de lettres et de chiffres",
  pleaseEnterPassword: 'Veuillez entrer le mot de passe de votre compte',
  pleaseEnterEmailAddress: 'Veuillez entrer votre adresse e-mail',
  invalidEmailFormat: "Format d'email invalide",
  pleaseEnterVerifiCode: 'Veuillez entrer le code de vérification',
  incorrectVerifiCode: 'Code de vérification incorrect',

  // Toast文案
  verificationCodeHasBeen:
    'Le code de vérification a été envoyé, veuillez vérifier votre e-mail',
  pleaseReadAndAgree:
    'Veuillez lire et accepter les Termes de Service et la Politique de Confidentialité.',
  networkAbnormal: 'Réseau anormal',
  emailRegistered:
    "L'e-mail n'a pas été enregistré, veuillez d'abord vous inscrire",
  passwordNotold:
    "Votre nouveau mot de passe ne peut pas être le même que l'ancien mot de passe",
  invalidPasswordFormat: 'Format de mot de passe invalide',
  theEmailHasRegistered: "L'e-mail a été enregistré",
  verificationCodeGetAgain:
    "Le code de vérification a expiré, veuillez l'obtenir à nouveau",
  erificationCodeMatch:
    "L'e-mail et le code de vérification ne correspondent pas",
  incorrectPasswordOrEmail:
    'Mot de passe ou e-mail incorrect, veuillez saisir à nouveau',
  requestError: 'Erreur de demande',
  pleaseTryAgainLater: 'Trop de demandes, veuillez réessayer plus tard',
  accessDenied: 'Accès refusé',
  requestErrorResourceNotFound:
    'Erreur de demande, la ressource est introuvable.',
  serverError: 'Erreur de serveur',
  connectionServerFailed: 'La connexion au serveur a échoué',
  requestTimedOut: 'La demande a expiré',
  networkAbnormaled: 'Réseau anormal',
  serviceUnavailable: 'Service indisponible',
  httpVersioSupportTheRequest:
    'La version http ne prend pas en charge la requête',
  wrongPasswordTime:
    'Vous avez saisi 5 fois un mauvais mot de passe. Votre compte est temporairement verrouillé et ne peut pas être connecté. Veuillez réessayer dans 10 minutes.',
};
