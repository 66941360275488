export const ID = {
  chat: {
    pictureText: '[Gambar]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Kartu Kontak]',
    location: '[Lokasi]',
    other: '[Lainnya]',
    all: 'Semua',
    today: 'Hari ini',
    yesterday: 'Kemarin',
    last7Days: '7 Hari Terakhir',
    last30Days: '30 Hari Terakhir',
    customRange: 'Pilih Jangka Waktu',
    cancel: 'Batal',
    apply: 'Terapkan',
    pushNotify: 'Notifikasi Push',
    numbers: 'Angka',
    emails: 'Email',
    outgoingCalls: 'Panggilan Keluar',
    incomingCalls: 'Panggilan Masuk',
    missedCalls: 'Panggilan Tidak Terjawab',
    answeredCalls: 'Panggilan Terjawab',
    noAnswer: 'Tidak ada jawaban',
    canceledCalls: 'Panggilan Dibatalkan',
    loadingText: 'Memuat, silakan tunggu…',
    noMoreText: 'Tidak ada konten lainnya',
    noInfoText: 'Tidak ada informasi event, silakan periksa kembali nanti.',
    noAddressText: 'Tidak ada informasi alamat, silakan periksa kembali nanti.',
    noCallText: 'Tidak ada catatan panggilan, silakan periksa kembali nanti.',
    dateSelect: 'Pilih Tanggal',
    sentFile: 'Kirim file',
    sentAudio: 'Kirim audio',
    unGetFile: 'Tidak bisa mendapat file',
    unGetAudio: 'Tidak bisa mendapat audio',

    syncFailed: 'Sinkronisasi data gagal!',
    syncSuccessed: 'Sinkronisasi data berhasil!',
    unknownContact: 'Kontak tidak dikenal',
    noSyncNotice1: 'Data belum disinkronkan selama lebih dari ',
    noSyncNotice2: '24 jam',
    noSyncNotice3:
      '. Pastikan ponsel target dihidupkan dan terhubung ke Internet, dan APP "System Service" berjalan normal.',
    sync: 'Sync',
    startDate: 'Mulai tanggal',
    endDate: 'Tanggal akhir',
    noSupportToast: 'Pemfilteran tanggal tidak didukung di Demo.',

    unknowVideo:
      'Video. Detail tidak tersedia karena kebijakan privasi Instagram.',
    unknowImage:
      'Gambar. Detail tidak tersedia karena kebijakan privasi Instagram.',
    unknowAudio:
      'Pesan suara. Detail tidak tersedia karena kebijakan privasi Instagram.',
    outgoingVoice: 'Panggilan audio keluar',
    incomingVoice: 'Panggilan audio masuk',
    outgoingVideo: 'Panggilan video keluar',
    incomingVideo: 'Panggilan video masuk',
  },
  user: {
    userProfile: 'Profil Pengguna',
    usesrname: 'Username:',
    edit: 'Sunting',
    emails: 'Email:',
    permission: 'Informasi perizinan',
    bounded: 'Perangkat terhubung:',
    restDevice: 'Perangkat Tersisa:',
    expire: 'Tanggal Kedaluwarsa:',
    expired: 'xxxx-xx-xx (Kedaluwarsa)',
    logout: 'Keluar',
    changename: 'Ubah username',
    save: 'Simpan',
    inputname: 'Silakan masukkan username',
    nameNull: 'Format username abnormal, silakan masukkan kembali',
    networkError: 'Gagal mengganti username, silakan periksa jaringan Anda',
    nameSuccess: 'Username berhasil diubah',
    changeavatar: 'Ubah avatar',
    loading: 'Memuat…',
    changeAvatar: 'Ubah',
    supported:
      'Mendukung format jpg, jpeg, png, dan ukuran tidak boleh melebihi 2M.',
    maxsize: 'Ukuran gambar tidak boleh melebihi 2M',
    avatarError: 'Gagal merubah avatar, silakan periksa jaringan Anda',
    avatarSuccess: 'Avatar berhasil dirubah',
    imgError: 'Galat gambar',
    imageType: 'Gambar tidak terdukung',
    nameEmpty: 'Username tidak dapat kosong',
  },
  leftTab: {
    device: 'Perangkat',
    unbound: 'Perangkat Tidak Terhubung:',
    addDevice: 'Tambah Perangkat',
    dashboard: 'Dasbor',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Silakan hubungkan perangkat dahulu',
    payRemind: 'Silakan beli terlebih dahulu',
    purchase: 'Beli Sekarang',
    unboundName: 'Ikat Perangkat Sekarang',
    toBuy: 'Beli Perangkat',
  },
  guide: {
    title: 'Silakan hubungkan perangkat Anda',
    oneT: '1. Mendapatkan Perangkat Tujuan',
    oneContent:
      'Untuk memantau perangkat, Anda perlu mendapat perangkat pemantauan tujuan selama sekitar 10 menit.',
    twoT: '2. Unduh dan Pasang Aplikasi',
    twoContent1: 'Masukkan',
    twoContent2:
      'di peramban perangkat tujuan, lalu unduh dan pasang aplikasi.',
    threeT: '3. Atur Perizinan Perangkat',
    threeContent:
      'Ikuti instruksi pada aplikasi untuk mengatur perizinan perangkat. Setelah mengaturnya, Anda dapat memantau perangkat.',
    tips1:
      "Untuk memastikan penyembunyian, aplikasi akan dikenali sebagai perangkat lunak sistem dengan nama 'System Service'.",
    tips2:
      'Untuk memastikan penyamaran, Anda dapat menghapus catatan peramban dan paket pemasangan aplikasi setelah berhasil memasang aplikasi',
    tips3:
      "aplikasi akan ditampilkan sebagai 'System Service' pada desktop. Anda dapat memilih untuk menyembunyikannya di folder untuk efek sembunyi lebih baik.",
    tips41:
      'Untuk memastikan bahwa Anda dapat memasang aplikasi dan mengatur perizinan dengan cepat, Anda dapat melihat',
    tips42: 'dahulu untuk mempelajari cara kerjanya.',
    detail: 'panduan rinci',
    tips5:
      'Jika izin perangkat terikat telah kedaluwarsa, Anda dapat mengubah pengikatan perangkat dan mengikatnya ke izin untuk melanjutkan pengumpulan data.',
    rebound: 'Klik untuk mengubah pengikatan perangkat',
  },
  dashboard: {
    contact: 'Kontak teratas di WhatsApp (dalam 7 hari)',
    call: 'Panggilan Teratas di WhatsApp',
    view: 'Tampilkan Semua',
    account: 'Akun',
    id: 'ID Akun:',
    expire: 'Tanggal Kedaluwarsa:',
    bound: 'Perangkat Terhubung:',
    restDevice: 'Perangkat Tersisa:',
    target: 'Tentang Perangkat Tujuan',
    brand: 'Merek:',
    model: 'Model:',
    version: 'Versi Android:',
    battery: 'Daya:',
    wifi: 'Wi-FI:',
    appversion: 'Versi Aplikasi:',
    update: 'Terakhir Diperbarui:',
    blank: 'Tidak Ada Aktivitas Terkini',
    unbindSuc: 'Lepas ikatan berhasil!',
    unbindFail: 'Gagal melepas ikatan!',
    ReboundSuc: 'Ubah pengikatan perangkat berhasil!',
    ReboundFail: 'Gagal mengubah pengikatan perangkat!',
    noPlace: 'Tidak ada perangkat untuk mengubah pengikatan!',
    beExpOne: 'Izin perangkat Anda akan kedaluwarsa dalam ',
    beExpTwo: 'hari',
    beExpThree:
      ', dan data perangkat yang dipantau tidak akan disinkronkan setelah kedaluwarsa.',
    expiredOne:
      'Izin perangkat Anda telah kedaluwarsa. Data yang dikumpulkan sebelumnya akan dihapus dalam',
    expiredTwo:
      '. Setelah pembaruan, data perangkat yang dipantau akan terus disinkronkan.',
    renew: 'Perbarui sekarang',

    insContact: 'Kontak Teratas di Instagram (dalam 7 hari)',
    insCall: 'Panggilan Teratas di Instagram',
  },
  payPage: {
    title: 'Perencanaan & Harga ParentalGuard',
    dec: 'Monitor WhatsApp dengan ParentalGuard lebih mudah dari yang Anda kira!',
    bigSave: 'LEBIH HEMAT',
    one: 'Perencanaan 1 Bulan',
    three: 'Perencanaan 3 Bulan',
    year: 'Perencanaan 1 Tahun',
    toBuy: 'Beli Sekarang',
    auto: 'Beli Pembaharuan',
    cancelAny: 'Batalkan Kapan Saja',
    update: 'Data diperbarui secara otomatis setiap lima menit',
    collect: 'Ambil semua percakapan',
    interaction: 'Pengalaman interaktif lebih baik',
    hide: 'Mode sembunyi',
  },
  pop: {
    changeBound: 'Ubah Pengikatan Perangkat',
    reboundText1: 'Apakah Anda yakin untuk mengubah pengikatan perangkat',
    reboundText2:
      'dan mengikatnya ke izin perangkat tidak terikat untuk melanjutkan pengumpulan data (Tanggal kedaluwarsa:',
    reboundText3: ')?',
    reboundText4:
      'dan mengikatnya ke izin perangkat tidak terikat untuk melanjutkan pengumpulan data',
    noRebound: 'Tidak ada izin perangkat yang tersedia. Silahkan beli dulu.',
    goPay: 'Beli sekarang',
    reboundList: 'Perangkat Terikat',
    reboundDec:
      'Izin perangkat berikut telah kedaluwarsa. Pilih perangkat dan ikat ke izin perangkat yang saat ini tidak terikat untuk melanjutkan pengumpulan data.',
    rebounding: 'Mengubah pengikatan perangkat...',
    reboundFail1: 'Gagal mengubah pengikatan perangkat ',
    reboundFail2: '. Apakah Anda ingin mencoba lagi?',
    tryAgain: 'Coba kembali',
    unbindTitle: 'Lepaskan Perangkat',
    unbindText1: 'Apakah Anda yakin untuk melepaskan ikatan perangkat',
    unbindText2:
      '? Semua informasi data yang dikumpulkan akan dihapus setelah tidak mengikat.',
    remind: 'Memperbarui',
    expiredPay: 'Batal',
    cancel: 'Mulai tanggal',
    yes: 'Ya',
    no: 'Tidak',
  },
  navTop: {
    langTitle: 'Ganti Bahasa',
  },
  famikeep200: {
    Calls: 'Telefoongesprek',
    Messages: 'Informasi',
    Contacts: 'Kontak person',
    Photos: 'Foto',
    Applications: 'Aplikasi',
    'Compared to yesterday': 'dibandingkan kemarin',
    'All locations': 'semua Lokasi',
    'Weekly average usage time': 'Rata-rata waktu yang dihabiskan minggu ini',
    'Floating comparison with last week': 'Mengambang dibandingkan minggu lalu',
    Average: 'rata-rata',
    Mon: 'Sen',
    Tue: 'Sel',
    Wed: 'Rab',
    Thu: 'Kam',
    Fri: 'Jum',
    Sat: 'Sab',
    Sun: 'Min',
    'Most used applications': 'aplikasi yang paling sering digunakan',
    Geolocation: 'lokasi geografis',
    'Last updated': 'waktu pembaruan',
    'Geolocation permission':
      "Izin lokasi APP 'System Service' tidak diaktifkan, dan akuisisi lokasi gagal",
    Geofence: 'Geofence',
    'Create a geofence':
      'Buat pembatasan wilayah dan Anda akan diberi tahu melalui email saat anak Anda masuk/keluar dari pembatasan wilayah.',
    'enters/exits': 'masuk/keluar dari pembatasan wilayah.',
    'Create a new geofence': 'Buat geofence baru',
    'Geofence name': 'nama pembatasan wilayah',
    'Set geofence location': 'Atur lokasi pagar',
    'Set radius': 'atur radius',
    'Set monitoring time': 'Tetapkan waktu pemantauan',
    'please choose': 'tolong pilih',
    Daily: 'Setiap hari',
    'Every Monday': 'Setiap Senin',
    'Every Tuesday': 'Setiap Selasa',
    'Every Wednesday': 'Setiap Rabu',
    'Every Thursday': 'Setiap Kamis',
    'Every Friday': 'Setiap Jumat',
    'Every Saturday': 'Setiap Sabtu',
    'Every Sunday': 'Setiap Minggu',
    'Send alerts via email': 'Email lansiran',
    'We will send alert emails to the registered email':
      'Kami akan mengirimkan email peringatan ke kotak surat terdaftar',
    Save: 'menyimpan',
    'Please enter a geofence name': 'Harap masukkan nama pembatasan wilayah',
    'Please select a geofence location': 'Silakan pilih lokasi pagar',
    Entered: 'Masuk',
    Exited: 'Keluar',
    Edit: 'Edit',
    Delete: 'Hapus',
    'Are you sure you want to delete':
      'Apakah Anda yakin ingin menghapus pagar xxx? Data pemantauan yang sesuai juga akan dihapus.',
    'Are you sure you want to close':
      'Yakin ingin menutup? Anda tidak akan menerima pemberitahuan email saat masuk/keluar dari pembatasan wilayah',
    'Call history': 'Catatan Panggilan',
    Status: 'Status',
    Number: 'Nomor',
    Duration: 'Durasi',
    Date: 'Tanggal',
    incoming: 'Panggilan Masuk',
    outgoing: 'Panggilan Keluar',
    missed: 'Panggilan Tidak Terjawab',
    'no answered': 'Tidak ada jawaban',
    Address: 'Alamat',
    'Added time': 'Waktu Tambah',
    'Sort by name': 'Urutkan Berdasarkan Nama',
    'Sort by time': 'Urutkan Berdasarkan Waktu',
    'Please note that photos':
      'Harap dicatat, foto hanya akan diunggah saat perangkat terhubung ke WIFI',
    Album: 'Album',
    'Select all photos on the current page': 'Pilih Semua Foto di Halaman Ini',
    'Previous page': 'Halaman Sebelumnya',
    'Next page': 'Halaman Berikutnya',
    Back: 'Kembali',
    'confirm to delete':
      'konfirmasi untuk menghapus? Data tidak dapat dipulihkan setelah dihapus',
    Download: 'unduh',
    Calendar: 'Kalender',
    Title: 'Judul',
    Description: 'Deskripsi',
    Location: 'Lokasi',
    'Start time': 'Waktu Mulai',
    'End time': 'Waktu Selesai',
    'Installed applications': 'Aplikasi yang Telah Terpasang',
    'All applications': 'Semua Aplikasi',
    Name: 'Nama',
    Version: 'Versi',
    Size: 'Ukuran',
    'Installation date': 'Tanggal Instalasi',
    'Disable applications': 'Menonaktifkan Aplikasi',
    'Seted disable rules': 'Aturan Nonaktif yang Telah Ditentukan',
    'Undisabled applications': 'Aplikasi yang Belum Dinonaktifkan',
    'Click the button below':
      'Klik tombol di bawah untuk membuat aturan nonaktif untuk aplikasi dan pilih aplikasi yang ingin Anda nonaktifkan',
    'Set disable rules': 'Mengatur Aturan Nonaktif',
    'Disable permanently': 'Selalu Nonaktifkan',
    'Disable ': 'Nonaktifkan',
    'Custom disable time': 'Nonaktifkan dalam Waktu Tersendiri',
    'Disable period': 'Periode Nonaktif',
    From: 'Dari',
    To: 'Hingga',
    Total: 'Total',
    Weekly: 'mingguan',
    Time: 'waktu',
    'Select the applications you want to disable':
      'Pilih aplikasi yang ingin Anda nonaktifkan',
    'Please select an application below': 'Silakan pilih aplikasi di bawah ini',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Apakah Anda yakin ingin menghapusnya? Aplikasi di bawah aturan nonaktif ini akan berfungsi lagi',
    'Disable options:': 'Cara Nonaktifkan:',
    'one Disable permanently':
      '1. Selalu Nonaktifkan: Aplikasi akan dinonaktifkan sepenuhnya. Anak Anda tidak dapat menggunakan aplikasi yang telah dinonaktifkan hingga Anda mencabut aturan nonaktifnya.',
    'two Custom disable time':
      '2. Waktu Nonaktif Kustom: Aturan akan diaktifkan hanya dalam jangka waktu tertentu. Di luar jangka waktu tersebut, anak Anda dapat menggunakan aplikasi seperti biasa.',
    'You can only choose one of the two setting options':
      'Hanya bisa memilih salah satu pengaturan',
    complete: 'menyelesaikan',
    'Screen usage time': 'Waktu Penggunaan Layar',
    'Screen Usage Time for xxx': 'Waktu Penggunaan Layar untuk xxx',
    'Frequently used applications': 'Aplikasi yang Sering Digunakan',
    Frequency: 'Frekuensi',
    'Usage time': 'Waktu Penggunaan',
    'Screen disable': 'Nonaktifkan Layar',
    'Turn on the switch and set the screen lock duration.':
      'Aktifkan sakelar, dan atur durasi layar terkunci.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Layar perangkat anak Anda akan dinonaktifkan dan akan kembali berfungsi setelah waktu berakhir.',
    'Disable all screen activities': 'Nonaktifkan semua aktivitas layar',
    'Set screen disable duration': 'Atur durasi nonaktif layar',
    'You can lift the disablement at any time':
      'Anda dapat menghapus nonaktif kapan saja',
    'Remaining screen disable time on the target device':
      'Waktu layar yang tersisa di perangkat target yang dinonaktifkan',
    'Are you sure you want to lift the screen disablement now?':
      'Apakah Anda yakin ingin menghapus nonaktif layar sekarang?',
    Yes: 'Konfirmasi',
    'This account does not exist, please register first':
      'Akun ini tidak ada, silakan daftar terlebih dahulu',
    'Synchronization successful, no new data':
      'Sinkronisasi berhasil, tidak ada data baru',
    'Location alert': 'Peringatan Lokasi',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Perangkat xxx yang Anda pantau masuk pada tanggal xxxx-xx-xx pukul xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Perangkat xxx yang Anda pantau telah meninggalkan xx pada xxxx-xx-xx.',
    'View more location records:': 'Lihat lebih banyak catatan lokasi:',
    'Thank you!': 'Terima kasih!',
    'xxx Team': 'Tim xxx',
    'When obtaining this location':
      'Saat mendapatkan lokasi ini, perangkat mengaktifkan pemosisian virtual, yang dapat menyebabkan ketidakakuratan.',
    'The demo mode does not support this feature, please bind the device first.':
      'Mode demo tidak mendukung fitur ini, harap ikat perangkat terlebih dahulu.',
    'Location Tracking': 'Pelacakan Lokasi',
    'General Application': 'Aplikasi Umum',
    'App Activites Tracking': 'Pelacakan Aktivitas Aplikasi',
    'Screen Time Tracking': 'Pelacakan Waktu Layar',
    'Social Application': 'Aplikasi Sosial',
    'Subject:': 'Subjek:',
  },
  famikeep210: {
    'iCloud used storage': 'Penyimpanan iCloud yang digunakan',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Lokasi terakhir yang diketahui',
    'Please bind your device': 'Silakan mengikat perangkat Anda',
    'Log in iCloud to binding.':
      'Masuk ke akun iCloud perangkat target untuk menyelesaikan pengikatan.',
    'Please enter your Apple ID': 'Silakan masukkan Apple ID Anda',
    'Please enter your password': 'Silakan masukkan kata sandi Anda',
    'Your entered account or password is incorrect.':
      'Akun atau kata sandi yang Anda masukkan salah.',
    'Ensure Apple ID and password':
      'Pastikan Anda memasukkan Apple ID dan kata sandi perangkat sasaran, melakukan autentikasi, dan menyelesaikan pengikatan.',
    'Forgot Apple ID or password?': 'Lupa Apple ID atau kata sandi?',
    'Devices using the same iCloud account will be bound':
      'Perangkat yang menggunakan akun iCloud yang sama akan diikatkan.',
    'Two-Factor Authentication': 'Otentikasi Dua Faktor',
    'A message has been sent':
      'Pesan yang berisi kode verifikasi telah dikirim ke perangkat Anda. Silakan masukkan kode verifikasi untuk melanjutkan.',
    'A message containing a verification code has been sent to xxx.':
      'Pesan yang mengandungi kode verifikasi telah dikirim ke xxx. Silakan masukkan kode verifikasi untuk melanjutkan.',
    'Incorrect verification code': 'Kode verifikasi salah',
    'Resend Verification Code': 'Kirim Ulang Kode Verifikasi',
    'Get SMS Verification Code': 'Dapatkan Kode Verifikasi SMS',
    'Please select a phone number to receive the verification code.':
      'Silakan pilih nomor telepon untuk menerima kode verifikasi.',
    'Please select': 'Silakan pilih',
    'The current iCloud has expired':
      'Verifikasi akun iCloud saat ini telah kedaluwarsa. Silakan masuk ke akun iCloud Anda lagi untuk menyinkronkan data. ',
    'current iCloud has expired':
      'Verifikasi akun iCloud saat ini telah kedaluwarsa. Silakan masuk ke akun iCloud Anda lagi untuk menyinkronkan data. Beralih ke verifikasi >>',
    'Go to verification >>': 'Pergi ke verifikasi >>',
    'Data synchronization failed, iCloud account expired':
      'Sinkronisasi data gagal, akun iCloud kadaluarsa, kembali ke beranda dan verifikasi ulang.',
    'Created geofences': 'Geofence yang Dibuat',
    'No geofences created': 'Tidak ada geofence yang dibuat',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Harga & Rencana Aplikasi Kontrol Orang Tua',
    '30-Day Money Back Guarantee': 'Jaminan Uang Kembali 30 Hari',
    'Professional Customer Support': 'Dukungan Pelanggan Profesional',
    'All Prices Exclusive of VAT': 'Semua Harga Tidak Termasuk PPN',
    '100% Secure': '100% Aman',
    'More Features to Keep Your Kids Safe Online':
      'Lebih Banyak Fitur untuk Menjaga Anak-anak Anda Tetap Aman Online',
    Features: 'Fitur',
    'Monitor App': 'Aplikasi Pemantauan',
    'Type of Data': 'Jenis Data',
    'WhatsApp Chat': 'แชท WhatsApp',
    'App Activity': 'กิจกรรมแอป',
    'App Blocker': 'บล็อกแอป',
    'Monitor Social Apps': 'Pemantauan Aplikasi Sosial',
    'Location History': 'Riwayat Lokasi',
    'Location Reminder': 'Pengingat Lokasi',
    'Live Location': 'Pencarian Aman',
    'Safe Search': 'Pencarian Aman',
    'Web Filter': 'Filter Web',
    'Browser History': 'Riwayat Browser',
    'Content Detection': 'Deteksi Konten',
    'All SMS': 'Semua SMS',
    Photos: 'Foto',
    Video: 'Video',
    Calendar: 'Kalender',
    Documents: 'Dokumen',
    'Record Screen': 'Layar',
    'Call History': 'Riwayat Panggilan',
    'Call Recording': 'Perekaman Panggilan',
    Contacts: 'Kontak',
    Messages: 'Pesan',
    'Call Logs': 'Log Panggilan',
    'Chat History': 'Riwayat Obrolan',
    'The device has been bound to other account':
      'Perangkat telah tersambung ke akun lain, silakan lepaskan terlebih dahulu atau hubungi layanan pelanggan untuk melepaskan.',
    Gallery: 'Galeri',
    'Hidden or Deleted Albums': 'Album Tersembunyi atau Dihapus',
    'Recent Projects': 'Proyek Terbaru',
    'Personal Collection': 'Pribadi',
    'Recently Deleted': 'Terakhir dihapus',
    'Live Photos': 'Foto Langsung',
    'Exposure Photos': 'Foto Paparan',
    'Panoramic Photos': 'Foto Pano',
    'Time-Lapse Photography': 'Fotografi Lapsus Waktu',
    'Slow Motion': 'Perlahan',
    Portrait: 'Potret',
    'Burst Mode': 'Pengambilan gambar berkelanjutan',
    Screenshot: 'Tangkapan Layar',
    Motion: 'Dinamis',
    Hide: 'Sembunyikan',
    'This iCloud account':
      'Akun iCloud ini tidak terhubung ke perangkat apa pun. Silakan masuk di perangkat.',
    'Data synchronization':
      'Sinkronisasi data mungkin memerlukan waktu, harap bersabar.',
    'Location History1': 'Riwayat Lokasi',
    'Auto Renewal': 'Perpanjangan otomatis & Batalkan Kapan Saja',
    Month: 'Bulan',
    Quarter: 'Kuartal',
    Year: 'Tahun',
    Day: 'Hari',
    'See Demo': 'Lihat Demo',
    'We Accept': 'Kami menerima',
  },
  famikeep220: {
    'Browser Monitoring': 'Pemantauan Browser',
    'Browser History': 'Riwayat Browser',
    'Browser Bookmarks': 'Tandai Browser',
    'Serial Number': 'Nomor Seri',
    'Website Restrictions': 'Pembatasan Situs Web',
    'Restriction Rules': 'Aturan Pembatasan',
    'Prohibited Access': 'Akses Dilarang',
    'Access Warning': 'Peringatan Akses',
    'Restricted Website Log': 'Pencatatan Situs Terbatas',
    'Click to Add Restricted Website': 'Klik untuk Menambahkan Situs Terbatas',
    'Restriction Method:': 'Metode Pembatasan:',
    'Prohibit Access':
      '1. Batasi Akses: Menonaktifkan akses ke situs web sepenuhnya. Anak Anda tidak akan bisa mengakses situs web yang dibatasi di dalam peramban hingga Anda menghapus aturan pemblokiran situs web.',
    'Access Alerts':
      '2. Akses Alert: Ketika anak anda mengunjungi situs web sesuai dengan aturan, anda akan menerima peringatan melalui email dan menghasilkan catatan peringatan (hanya satu email peringatan akan dikirimkan dalam waktu 24 jam untuk situs web yang sama).',
    'the rule disabling access takes priority.':
      'Jika situs web yang sama tunduk pada dua aturan yang berbeda, aturan penonaktifan akses memiliki prioritas.',
    'Set Restriction Rules': 'Tetapkan Aturan Pembatasan',
    'This option will block':
      'Opsi ini akan memblokir akses ke situs web yang dimasukkan',
    'This option will allow':
      'Opsi ini akan memungkinkan akses ke situs web yang dimasukkan.',
    'For example':
      'Sebagai contoh, jika Anda memasukkan example.com, semua halaman web terkait (misalnya, www.example.com/page1.html) juga akan diblokir.',
    'If you enter the specific website page':
      'Jika Anda memasukkan halaman situs web tertentu www.example.com/page1.html, hanya halaman itu yang akan diblokir.',
    'This option allows':
      'Opsi ini memungkinkan akses ke situs web yang dimasukkan tetapi akan menghasilkan catatan penjelajahan yang sesuai.',
    'Send records by email': 'Kirimkan catatan melalui email',
    'When enabled':
      'Ketika diaktifkan, kami akan mengirimkan catatan ke alamat email yang terdaftar saat mengakses situs web yang dimasukkan.',
    'enter example':
      'Misalnya, jika Anda memasukkan example.com, semua halaman web terkait (seperti www.example.com/page1.html) juga akan diingatkan.',
    'enter the specific webpage':
      'Jika Anda memasukkan halaman web tertentu www.example.com/page1.html, hanya halaman ini yang akan diingatkan.',
    'Enter the website to be restricted':
      'Masukkan situs web yang akan dibatasi',
    Bookmark: 'Tandai',
    'Bookmark list': 'Senarai Tanda',
    'Total xx bookmarks': 'Total xx bookmark',
    'Enter the URL': 'Masukkan URL (misalnya, youtube.com)',
    'Added URLs': 'URL yang ditambahkan',
    Add: 'Tambahkan',
    'Please add the restricted URL': 'Silakan tambahkan URL terbatas',
    'Are you sure':
      'Anda yakin ingin menghapus? Pembatasan akan dihapus setelah dihapus.',
    Video: 'Video',
    'Please note that videos':
      'Harap dicatat bahwa video hanya akan diunggah saat perangkat terhubung ke WIFI.',
    'Website access alert': 'Peringatan akses situs web',
    'monitoring accessed':
      'Perangkat xxx yang Anda pantau mengakses xx pada xxxx-xx-xx.',
    'View more records:': 'Lihat lebih banyak catatan:',
    'Download and install the app.': 'Unduh dan pasang aplikasinya.',
    'After installation':
      'Setelah instalasi, buka aplikasi dengan mengunjungi xxx.com.',
    'Open immediately': 'Buka segera',
    'Unable to open System Service:': 'Tidak dapat membuka System Service:',
    'Please ensure this device has installed':
      '1. Pastikan bahwa perangkat ini telah menginstal System Service (klik untuk mengunduh System Service)',
    'click download': '(klik untuk mengunduh System Service)',
    'Copy the link':
      '2. Salin alamat tautan halaman web saat ini dan buka di browser bawaan',
    'Sure close':
      'Apakah Anda yakin ingin menutup? Anda tidak akan menerima peringatan email setelah menutup.',
    Loading: 'Pemuatan',
    'Loading failed': 'Gagal memuat. Silakan unduh untuk melihat secara lokal.',
    'Select All Videos': 'Pilih Semua Video di Halaman Ini',
    tips3:
      "Aplikasi akan tersembunyi di perangkat dengan nama 'System Service'.",
    'If the video cannot be loaded':
      'Jika video tidak dapat dimuat untuk waktu yang lama, silakan unduh dan tonton.',
  },
  famikeep222: {
    'Continue Google': 'Lanjutkan dengan Google',
    or: 'atau',
    Continue: 'Lanjutkan',
    'legal guardian':
      'Saya adalah wali yang sah dari anak di bawah umur mana pun yang terhubung ke akun ini',
    'take around 10 mins':
      'Silakan mengikat perangkat Android (akan memakan waktu sekitar 10 menit)',
    'Get the Android device':
      '1. Dapatkan perangkat Android yang akan dipantau',
    'Install Companion App': '2. Pasang Aplikasi Pendamping',
    'Login to set permissions': '3. Login untuk mengatur izin',
    twoContent1:
      'Silakan mengakses ** dari perangkat Android yang dipantau untuk mengunduh dan memasang aplikasi',
    'set up permissions':
      'Silakan login ke akun Anda di Aplikasi Pendamping dan atur izin, lalu Anda dapat mulai memantau. (Harap dicatat bahwa aplikasi hanya dapat login melalui sandi akun, harap ** jika Anda belum mengatur sandi).',
    'Click here': 'Klik di sini',
    'continue collecting data':
      '5. Jika izin perangkat terikat sudah kedaluwarsa, Anda dapat melanjutkan mengumpulkan data dengan mengikat ulang.',
  },
  // 用户模块
  language: 'Bahasa',
  signIn: 'Masuk',
  email: 'Email',
  password: 'Kata Sandi',
  sendCode: 'Kirim Kode',

  // 登录
  signingIn: 'Gambar tidak terdukung',
  noAccount: 'Tidak punya akun?',
  signUpNow: 'Daftar sekarang',
  forgetPassword: 'Lupa kata sandi?',

  // 注册
  signUp: 'Daftar',
  createAccount: 'Buat Akun Baru',
  emVerificationCode: 'Kode Verifikasi Email',
  creatingAccount: 'Membuat Akun …',
  alreadyHaveAccount: 'Sudah punya akun?',
  SignInUp: 'Masuk',
  send: 'Mengirimkan',
  sing: 'detik',
  byCreatingAnAccount: 'Dengan membuat akun, Anda setuju dengan ',
  termsOfService: 'Ketentuan Layanan',
  and: ' dan ',
  privacyPolicy: 'Kebijakan Privasi.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Mendapatkan kata sandi',
  backTo: 'Kembali untuk Masuk',
  newPassword: 'Kata Sandi Baru',
  resetPassword: 'Atur Ulang Kata Sandi',
  loginSucceeded: 'Masuk berhasil!',
  logoutSucceeded: 'Logout berhasil!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Kata sandi harus 8-20 karakter dan kombinasi angka dan huruf.',
  pleaseEnterPassword: 'Silakan masukkan kata sandi akun',
  pleaseEnterEmailAddress: 'Silakan masukkan alamat email',
  invalidEmailFormat: 'Format email tidak valid',
  pleaseEnterVerifiCode: 'Silakan masukkan kode verifikasi',
  incorrectVerifiCode: 'Kode verifikasi tidak tepat',

  // Toast文案
  verificationCodeHasBeen:
    'Kode verifikasi telah terkirim, silakan periksa email Anda.',
  pleaseReadAndAgree:
    'Silakan baca dan setujui Ketentuan Layanan dan Kebijakan Privasi.',
  networkAbnormal: 'Jaringan abnormal',
  emailRegistered: 'Email belum terdaftar, silakan registrasi terlebih dahulu',
  passwordNotold: 'Kata sandi yang baru tidak bisa sama dengan yang lama',
  invalidPasswordFormat: 'Format kata sandi tidak valid',
  theEmailHasRegistered: 'Email telah terdaftar',
  verificationCodeGetAgain:
    'Kode verifikasi telah kedaluwarsa, silakan coba lagi',
  erificationCodeMatch: 'Kode verifikasi dan email tidak sesuai',
  incorrectPasswordOrEmail: 'Kata sandi atau email salah, silakan coba lagi',
  requestError: 'Permintaan gagal',
  pleaseTryAgainLater: 'Terlalu banyak permintaan, silakan coba lagi nanti',
  accessDenied: 'Akses gagal',
  requestErrorResourceNotFound: 'Permintaan galat, sumber tidak ditemukan',
  serverError: 'Galat server',
  connectionServerFailed: 'Koneksi server gagal',
  requestTimedOut: 'Permintaan waktu habis',
  networkAbnormaled: 'Jaringan abnormal',
  serviceUnavailable: 'Layanan tidak tersedia',
  httpVersioSupportTheRequest: 'Versi http tidak mendukung permintaan',
  wrongPasswordTime:
    'Anda telah memasukkan kata sandi yang salah sebanyak 5 kali. Akun Anda terkunci sementara dan tidak dapat masuk. Harap coba lagi dalam 10 menit.',
};
