import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  createWebHistory,
} from 'vue-router';
import store from '@/store/index';
//进度条(该进度条出现在顶部)
// import NProgress from "nprogress";

import 'nprogress/nprogress.css';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      requireAuth: false, // false表示不需要登录
    },
    component: () => import('@/components/login/login.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      title: '注册',
      requireAuth: false, // false表示不需要登录
    },
    component: () => import('@/components/login/signup.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    meta: {
      title: '忘记密码',
      requireAuth: false, // false表示不需要登录
    },
    component: () => import('@/components/login/forgotpassword.vue'),
  },
  // 打开app页面
  {
    path: '/open',
    name: 'open',
    meta: {
      title: '',
      requireAuth: false, // false表示不需要登录
    },
    component: () => import('@/views/openApp.vue'),
  },

  {
    path: '/',
    component: () => import('@/components/nav/nav.vue'),
    name: 'container',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    children: [
      {
        path: '',
        name: 'device',
        meta: {
          name: 'Device',
          isDevice: true,
        },
        // redirect: "home",
        children: [],
      },
      {
        path: 'addDevice',
        component: () => import('@/components/homePage/payPage.vue'),
        name: 'addDevice',
        meta: {
          name: 'Add Device',
          icon: 'icon-jiahao',
        },
      },
      {
        path: 'home',
        component: () => import('@/views/home/home.vue'),
        name: 'home',
        meta: {
          name: '首页',
          icon: 'icon-shouye',
        },
      },
      {
        path: 'whatsapp',
        component: () => import('@/views/whatsapp/whatsapp.vue'),
        name: 'whatsapp',
        meta: {
          name: '聊天',
          icon: 'icon-liaotian',
        },
      },
      {
        path: 'instagram',
        component: () => import('@/views/instagram/instagram.vue'),
        name: 'instagram',
        meta: {
          // name: "ins",
          // icon: "icon-ins",
        },
      },
      {
        path: 'guide',
        component: () => import('@/components/homePage/guidePage.vue'),
        name: 'guide',
        // import component from './../shims-vue.d';
        meta: {
          // name: "联系我们",
          // icon: "icon-lianxiwomen",
        },
      },
      {
        path: 'iosLogin',
        component: () => import('@/views/ios/icloudLogin.vue'),
        name: 'iosLogin',
        meta: {
          name: 'iosLogin',
          // icon: "icon-lianxiwomen",
        },
        beforeEnter: (to, from, next) => {
          // 在进入路由之前执行逻辑
          console.log('from', from);
          store.commit('setFrontPath', from.name);
          next();
        },
      },
      // ===========2.0.0新加
      {
        path: 'locations',
        component: () => import('@/views/location/locations.vue'),
        name: 'locations',
        meta: {
          name: 'locations',
          icon: 'icon-jiahao',
        },
      },
      {
        path: 'geoFencing',
        component: () => import('@/views/location/geoFencing.vue'),
        name: 'geoFencing',
        meta: {
          name: 'geoFencing',
          icon: 'icon-shouye',
        },
      },
      {
        path: 'messages',
        component: () => import('@/views/generalApp/messages.vue'),
        name: 'messages',
        // meta: {
        //   name: "Messages",
        //   icon: "icon-liaotian",
        // },
      },
      {
        path: 'calllogs',
        component: () => import('@/views/generalApp/callLogs.vue'),
        name: 'calllogs',
      },
      {
        path: 'contacts',
        component: () => import('@/views/generalApp/contacts.vue'),
        name: 'contacts',
        meta: {
          // name: "联系我们",
          // icon: "icon-lianxiwomen",
        },
      },
      {
        path: 'photos',
        name: 'photos',
        component: () => import('@/views/generalApp/photos.vue'),
        // meta: {
        //   hide: true,
        // },
      },
      {
        path: 'videos',
        name: 'videos',
        component: () => import('@/views/generalApp/video.vue'),
        // meta: {
        //   hide: true,
        // },
      },
      {
        path: 'calender',
        component: () => import('@/views/generalApp/calender.vue'),
        name: 'calender',
      },
      {
        path: 'installedApps',
        component: () => import('@/views/appActivites/installedApps.vue'),
        name: 'installedApps',
        meta: {
          // name: "联系我们",
          // icon: "icon-lianxiwomen",
        },
      },
      {
        path: 'appBlock',
        name: 'appBlock',
        component: () => import('@/views/appActivites/appBlock.vue'),
        // meta: {
        //   hide: true,
        // },
      },
      {
        path: 'screenTime',
        component: () => import('@/views/screen/screenTime.vue'),
        name: 'screenTime',
      },
      {
        path: 'screenBlock',
        component: () => import('@/views/screen/screenBlock.vue'),
        name: 'screenBlock',
        meta: {
          // name: "联系我们",
          // icon: "icon-lianxiwomen",
        },
      },
      {
        path: 'browserHistory',
        component: () => import('@/views/browser/history.vue'),
        name: 'browserHistory',
      },
      {
        path: 'browserBookmark',
        component: () => import('@/views/browser/bookmark.vue'),
        name: 'browserBookmark',
      },
      {
        path: 'websitesLimit',
        component: () => import('@/views/browser/websitesLimit.vue'),
        name: 'websitesLimit',
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/notFound/notFound.vue'),
        meta: {
          hide: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  // 为了实现移动端和PC端切换时可以携带后缀进入到相同的页面
  store.commit('setHashValue', location.hash);
  let token = window.localStorage.getItem('token');

  // isVibe 0 == demo  1 == 主程序
  if (store.state.isVibe == 0) {
    if (to.meta.requireAuth) {
      next();
    } else {
      router.push('/home');
    }

    if (to.path == '/') {
      router.push('/home');
    }
  } else {
    if (to.meta.requireAuth) {
      if (token) {
        next();
      } else {
        next({ path: '/login' });
      }
    } else {
      next();
    }
    if (to.path == '/') {
      router.push('/home');
    }
  }
});

/* 固定的路由 */
/*
    meta:{
        name:侧边栏名字,
        icon:侧边栏图标,
        hide:是否在侧边栏隐藏
    }
*/

export default router;
