import axios from '@/utils/request';
import { service } from '@/utils/request';
// icloud登录
export const loginIcloud = (data: any) => {
  return service({
    url: '/v1/icloud/sessions',
    method: 'post',
    data,
    timeout: 20 * 1000,
  });
};

// 获取验证码
export const getVerifi = (data: any) => {
  return service({
    url: '/v1/icloud/verify-code',
    method: 'post',
    data,
    timeout: 20 * 1000,
  });
};

// 双重验证
export const icloudVerifi = (data: any) => {
  return service({
    url: '/v1/icloud/double-verify',
    method: 'post',
    data,
    timeout: 20 * 1000,
  });
};

// 获取手机号码
export const getPhone = (data: any) => {
  return service({
    url: '/v1/icloud/phone-numbers',
    method: 'get',
    params: data,
  });
};

// 获取icloud账号信息
export const getIcloudInfo = (appleId: any) => {
  return service({
    url: `/v1/icloud/account-info/${appleId}`,
    method: 'get',
  });
};

// 解绑icloud账号
export const unbindIcloud = (appleId: any) => {
  return service({
    url: `/v1/icloud/${appleId}`,
    method: 'DELETE',
    timeout: 20 * 1000,
  });
};

// 换绑
export const icloudRebound = (data: any) => {
  return service({
    url: '/v1/icloud/rebound',
    method: 'post',
    data,
  });
};
