export const TW = {
  chat: {
    pictureText: '[圖片]',
    videoText: '[影片]',
    audioText: '[音訊]',
    contactCard: '[聯絡人名片]',
    location: '[位置]',
    other: '[其他]',
    all: '全部',
    today: '今天',
    yesterday: '昨天',
    last7Days: '最近7天',
    last30Days: '最近30天',
    customRange: '自定義範圍',
    cancel: '取消',
    apply: '應用',
    pushNotify: '推送訊息',
    numbers: '電話',
    emails: '郵箱',
    outgoingCalls: '去電',
    incomingCalls: '來電',
    missedCalls: '未接通',
    answeredCalls: '已接通',
    noAnswer: '無應答',
    canceledCalls: '取消通話',
    loadingText: '正在載入，請耐心等候…',
    noMoreText: '沒有更多內容了',
    noInfoText: '無活動資訊，請稍後再進行檢視。',
    noAddressText: '無通訊錄資訊，請稍後再進行檢視。',
    noCallText: '無通話記錄，請稍後再進行檢視。',

    dateSelect: '日期選擇',
    sentFile: '傳送了個檔案',
    sentAudio: '傳送了個音訊',
    unGetFile: '無法獲取檔案',
    unGetAudio: '無法獲取音訊',

    syncFailed: '數據同步失敗',
    syncSuccessed: '數據同步成功',
    unknownContact: '未知聯絡人',
    noSyncNotice1: '已超過',
    noSyncNotice2: '24h',
    noSyncNotice3:
      '沒有同步到數據了，保證目標手機打開並連接網路，並且"System Service"APP正常運行。',
    sync: '同步',
    startDate: '開始日期',
    endDate: '結束日期',
    noSupportToast: 'Demo模式不支援日期篩選',

    unknowVideo: '影片訊息，由於Instagram資料保護，無法獲取詳情。',
    unknowImage: '圖片訊息，由於Instagram資料保護，無法獲取詳情。',
    unknowAudio: '語音訊息，由於Instagram資料保護，無法獲取詳情。',
    outgoingVoice: '呼出語音通話',
    incomingVoice: '呼入語音通話',
    outgoingVideo: '呼出影片通話',
    incomingVideo: '呼入影片通話',
  },
  user: {
    userProfile: '使用者資訊',
    usesrname: '使用者名稱：',
    edit: '編輯',
    email: '郵箱：',
    permission: '許可權資訊',
    bounded: '已繫結裝置：',
    restDevice: '剩餘可使用裝置臺數：',
    expire: '到期時間：',
    expired: '(已過期)',
    logout: '登出',
    changename: '修改使用者名稱',
    save: '儲存',
    inputname: '請輸入使用者名稱',
    nameNull: '使用者名稱格式異常，請重新輸入',
    networkError: '使用者名稱修改失敗，請檢查網路',
    nameSuccess: '使用者名稱修改成功',
    changeavatar: '修改頭像',
    loading: '載入中…',
    changeAvatar: '修改',
    supported: '支援jpg、jpeg、png格式，且大小不能超過2M。',
    maxsize: '圖片大小不能超過2M',
    avatarError: '頭像修改失敗，請檢查網路',
    avatarSuccess: '頭像修改成功',
    imgError: '圖片錯誤',
    imageType: '圖片型別不支援',
    nameEmpty: '暱稱不能為空',
  },
  leftTab: {
    device: '裝置',
    unbound: '未繫結裝置',
    addDevice: '新增裝置',
    dashboard: '概覽',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: '請先繫結裝置',
    payRemind: '請先購買產品',
    purchase: '立即購買',
    unboundName: '立即綁定設備',
    toBuy: '購買設備',
  },
  guide: {
    title: '請繫結您的裝置',
    oneT: '1. 獲取到目標裝置',
    oneContent: '為了監控裝置，您需要拿到目標監控裝置約10分鐘。',
    twoT: '2. 下載並安裝應用',
    twoContent1: '在目標裝置瀏覽器輸入',
    twoContent2: '然後下載並且安裝app。',
    threeT: '3. 設定裝置許可權',
    threeContent: '按照app上的指引設定裝置許可權， 設定完成後即可監控裝置了。',
    tips1: '為保證隱身，app將被偽裝成系統軟體，名稱將被定為 “System Service”。',
    tips2: '為確保無痕，安裝app成功後可以刪除瀏覽器記錄和app安裝包。',
    tips3:
      'app會在桌面上顯示為“System Service”，您可選擇將其隱藏在資料夾中，以實現更好的隱身效果。',
    tips41: '為確保可以更快安裝app並設定app許可權，您可以先檢視',
    tips42: '以瞭解操作方法。',
    detail: '詳細指引教程',
    tips5: '如果已綁定的設備權限已過期，可更換綁定到該權限下繼續收集數據。',
    rebound: '點擊更換綁定',
  },
  dashboard: {
    contact: 'WhatsApp中熱門聯絡人（7天內）',
    call: 'WhatsApp中熱門通話',
    view: '檢視全部',
    account: '賬號',
    id: '賬號 ID：',
    expire: '失效日期：',
    bound: '已繫結裝置：',
    restDevice: '剩餘裝置臺數: ',
    target: '目標裝置',
    brand: '品牌：',
    model: '型號：',
    version: '安卓系統：',
    battery: '電池：',
    wifi: 'Wi-Fi:',
    appversion: 'App 版本：',
    update: '最後更新時間：',
    blank: '近期沒有活動',
    unbindSuc: '解除綁定成功',
    unbindFail: '解除綁定失敗',
    ReboundSuc: '更換綁定成功',
    ReboundFail: '更換綁定失敗',
    noPlace: '沒有可更換綁定設備',
    beExpOne: '你的設備權限還有',
    beExpTwo: '天',
    beExpThree: '就到期了，到期將不會同步被監控設備的數據。',
    expiredOne: '你的設備權限已過期，',
    expiredTwo: '後將清除此前收集的數據，續費後繼續同步被監控設備的數據，',
    renew: '立即續費',

    insContact: 'Instagram中熱門聯絡人（7天內）',
    insCall: 'Instagram中熱門通話',
  },
  payPage: {
    title: 'ParentalGuard 定價& 計劃',
    dec: '使用 ParentalGuard，跟蹤 WhatsApp 比您想象的更容易！',
    bigSave: '最划算',
    one: '1個月計劃',
    three: '3個月計劃',
    year: '1年計劃',
    toBuy: '立即購買',
    auto: '自動續費',
    cancelAny: '隨時取消',
    update: '每五分鐘自動更新資料',
    collect: '收集全部會話內容',
    interaction: '更好的互動體驗',
    hide: '隱藏模式',
  },
  pop: {
    changeBound: '更換綁定',
    reboundText1: '是否確定將',
    reboundText2: '設備換綁到未綁定設備權限下繼續收集數據（到期時間：',
    reboundText3: '）？',
    reboundText4: '設備換綁到未綁定設備權限下繼續收集數據',
    noRebound: '無可更換的設備權限，請先購買設備權限。',
    goPay: '去購買',
    reboundList: '已綁定設備',
    reboundDec:
      '下列設備權限已過期，選擇並更換綁定設備到當前未綁定設備的權限中繼續收集數據。',
    rebounding: '更換綁定中',
    reboundFail1: '設備',
    reboundFail2: '更換綁定失敗，是否重試？',
    tryAgain: '重試',
    unbindTitle: '解除綁定',
    unbindText1: '是否確定將',
    unbindText2: '設備解除綁定？解綁後將清除所有已收集的數據訊息。',
    remind: '過期提示',
    expiredPay: '去續費',
    cancel: '取消',
    yes: '是',
    no: '否',
  },
  navTop: {
    langTitle: '更換語言',
  },
  famikeep200: {
    Calls: '通話',
    Messages: '簡訊',
    Contacts: '聯絡人',
    Photos: '照片',
    Applications: '應用',
    'Compared to yesterday': '較昨日',
    'All locations': '所有位置',
    'Weekly average usage time': '這周日均使用時間',
    'Floating comparison with last week': '與上周比浮動',
    Average: '平均',
    Mon: '週一',
    Tue: '週二',
    Wed: '週三',
    Thu: '週四',
    Fri: '週五',
    Sat: '週六',
    Sun: '周日',
    'Most used applications': '最常用的應用',
    Geolocation: '地理位置',
    'Last updated': '更新時間',
    'Geolocation permission':
      '未開啟‘System Service’APP的位置許可權，獲取位置失敗',
    Geofence: '地理圍欄',
    'Create a geofence':
      '創建地理圍欄，當您的孩子進入/離開地理圍欄時，您將通過電子郵件收到警報。',
    'enters/exits': '進入/離開地理圍欄時',
    'Create a new geofence': '新建一個地理圍欄',
    'Geofence name': '地理圍欄名字',
    'Set geofence location': '設定圍欄的位置',
    'Set radius': '設定半徑',
    'Set monitoring time': '設定監控時間',
    'please choose': '請選擇',
    Daily: '每天',
    'Every Monday': '每週一',
    'Every Tuesday': '每週二',
    'Every Wednesday': '每週三',
    'Every Thursday': '每週四',
    'Every Friday': '每週五',
    'Every Saturday': '每週六',
    'Every Sunday': '每週日',
    'Send alerts via email': '將警報通過電子郵件發送',
    'We will send alert emails to the registered email':
      '我們會將警報郵件發送到註冊的郵箱裏',
    Save: '儲存',
    'Please enter a geofence name': '請輸入地理圍欄名字',
    'Please select a geofence location': '請選擇圍欄位置',
    Entered: '進入了',
    Exited: '離開了',
    Edit: '編輯',
    Delete: '刪除',
    'Are you sure you want to delete':
      '確定刪除xxx圍欄嗎？刪除後對應的監控數據也會被刪除',
    'Are you sure you want to close':
      '確定關閉嗎?關閉後進入/離開地理圍欄您將不會收到電子郵件警報',
    'Call history': '通話記錄',
    Status: '狀態',
    Number: '號碼',
    Duration: '時長',
    Date: '日期',
    incoming: '呼入電話',
    outgoing: '呼出電話',
    missed: '未接電話',
    'no answered': '無應答',
    Address: '住址',
    'Added time': '添加時間',
    'Sort by name': '按名稱排序',
    'Sort by time': '按時間排序',
    'Please note that photos': '請注意，圖片只有裝置連接WIFI才會上傳',
    Album: '相冊',
    'Select all photos on the current page': '全選當前頁圖片',
    'Previous page': '上一頁',
    'Next page': '下一頁',
    Back: '返回',
    'confirm to delete': '確定刪除嗎？刪除後數據無法恢復',
    Download: '下載',
    Calendar: '日曆',
    Title: '標題',
    Description: '描述',
    Location: '地點',
    'Start time': '開始時間',
    'End time': '結束時間',
    'Installed applications': '已安裝的應用程式',
    'All applications': '所有應用程式',
    Name: '名字',
    Version: '版本',
    Size: '大小',
    'Installation date': '安裝日期',
    'Disable applications': '應用程式禁用',
    'Seted disable rules': '已設定的禁用規則',
    'Undisabled applications': '未禁用的應用程式',
    'Click the button below':
      '點擊下方按鈕創建APP禁用規則，選擇您想要禁用的APP即可',
    'Set disable rules': '設定禁用規則',
    'Disable permanently': '一直禁用',
    'Disable ': '禁用',
    'Custom disable time': '自定義禁用時間',
    'Disable period': '禁用週期',
    From: '從',
    To: '到',
    Total: '共',
    Weekly: '每週',
    Time: '時間',
    'Select the applications you want to disable': '選擇您要禁用的應用程式',
    'Please select an application below': '請選擇下方應用',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      '確定刪除嗎？刪除後對該禁用規則下的APP將不再生效',
    'Disable options:': '禁用方式：',
    'one Disable permanently':
      '1.一直禁用：完全禁用應用程式。在您解除應用禁用規則前，您的孩子將無法使用被禁用的應用程式。',
    'two Custom disable time':
      '2.自定義禁用時間：僅在特定時間範圍內觸發禁用規則，在時間範圍外您的孩子可以正常使用。',
    'You can only choose one of the two setting options':
      '兩種設定選項只能選擇其中一種',
    complete: '完全的',
    'Screen usage time': '螢幕使用時間',
    'Screen Usage Time for xxx': 'xxx的螢幕使用時間',
    'Frequently used applications': '常用的應用程式',
    Frequency: '頻率',
    'Usage time': '使用時間',
    'Screen disable': '螢幕禁用',
    'Turn on the switch and set the screen lock duration.':
      '打開開關，設定鎖定螢幕時長。',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      '您孩子的裝置螢幕將會被禁用，在時間耗盡後恢復使用。',
    'Disable all screen activities': '禁用螢幕所有活動',
    'Set screen disable duration': '設定禁用螢幕時長',
    'You can lift the disablement at any time': '您可以隨時解除禁用',
    'Remaining screen disable time on the target device':
      '目標裝置剩餘螢幕被禁用時間',
    'Are you sure you want to lift the screen disablement now?':
      '您確定現在解除螢幕禁用嗎？',
    Yes: '確定',
    'This account does not exist, please register first':
      '該帳號不存在，請先註冊',
    'Synchronization successful, no new data': '同步成功，無新增數據',
    'Location alert': '位置警報',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      '您監控的xxx裝置在xxxx-xx-xx進入了xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      '您監控的xxx裝置在xxxx-xx-xx離開了xx',
    'View more location records:': '查看更多位置記錄:',
    'Thank you!': '感謝！',
    'xxx Team': 'xxx團隊',
    'When obtaining this location':
      '獲取該位置時設備開通了虛擬定位，可能存在誤差。',
    'The demo mode does not support this feature, please bind the device first.':
      'Demo模式不支持該功能，請先綁定設備',
    'Location Tracking': '位置追蹤',
    'General Application': '一般應用程式',
    'App Activites Tracking': '應用程式活動追蹤',
    'Screen Time Tracking': '螢幕使用時間追蹤',
    'Social Application': '社交應用程式',
    'Subject:': '主題：',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud已用內存',
    Online: '在線',
    Offline: '離線',
    'Last known location': '最後已知位置',
    'Please bind your device': '請綁定你的設備',
    'Log in iCloud to binding.': '登入目標設備的iCloud帳號完成綁定',
    'Please enter your Apple ID': '請輸入Apple ID',
    'Please enter your password': '請輸入密碼',
    'Your entered account or password is incorrect.':
      '您輸入的帳號或密碼不正確',
    'Ensure Apple ID and password':
      '確保輸入目標設備的Apple ID和密碼，認證後完成綁定。',
    'Forgot Apple ID or password?': '忘記Apple ID或密碼？',
    'Devices using the same iCloud account will be bound':
      '使用同一iCloud帳號的設備都會被綁定。',
    'Two-Factor Authentication': '雙重認證',
    'A message has been sent':
      '一條包含驗證碼的信息已發送至你的設備。請輸入驗證碼以繼續。',
    'A message containing a verification code has been sent to xxx.':
      '一條包含驗證碼的信息已發送至xxx。請輸入驗證碼以繼續。',
    'Incorrect verification code': '驗證碼不正確',
    'Resend Verification Code': '重新發送驗證碼',
    'Get SMS Verification Code': '獲取短信驗證碼',
    'Please select a phone number to receive the verification code.':
      '請選擇一個電話號碼接收驗證碼',
    'Please select': '請選擇',
    'The current iCloud has expired':
      '目前iCloud帳號驗證已過期，請重新登入iCloud帳號同步數據',
    'current iCloud has expired':
      '目前iCloud帳號驗證已過期，請重新登入iCloud帳號同步數據，去驗證>>',
    'Go to verification >>': '去驗證>>',
    'Data synchronization failed, iCloud account expired':
      '數據同步失敗，iCloud帳號過期，需返回首頁重新驗證',
    'Created geofences': '已創建的地理圍欄',
    'No geofences created': '未創建圍欄',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - 家長控制應用定價與計劃',
    '30-Day Money Back Guarantee': '30天退款保證',
    'Professional Customer Support': '專業客戶支援',
    'All Prices Exclusive of VAT': '所有價格不含增值稅',
    '100% Secure': '100% 安全',
    'More Features to Keep Your Kids Safe Online': '更多功能確保孩子網絡安全',
    Features: '功能',
    'Monitor App': '監控應用',
    'Type of Data': '數據類型',
    'WhatsApp Chat': 'WhatsApp 聊天',
    'App Activity': '應用活動',
    'App Blocker': '應用程序攔截器',
    'Monitor Social Apps': '監控社交應用',
    'Location History': '位置歷史',
    'Location Reminder': '位置提醒',
    'Live Location': '實時位置',
    'Safe Search': '安全搜索',
    'Web Filter': '網絡過濾',
    'Browser History': '瀏覽歷史',
    'Content Detection': '內容檢測',
    'All SMS': '所有短信',
    Photos: '照片',
    Video: '影片',
    Calendar: '日曆',
    Documents: '文件',
    'Record Screen': '螢幕錄制',
    'Call History': '通話歷史',
    'Call Recording': '通話錄音',
    Contacts: '聯絡人',
    Messages: '消息',
    'Call Logs': '通話記錄',
    'Chat History': '聊天歷史',
    'The device has been bound to other account':
      '該裝置已綁定其他賬號，請先解綁或聯絡客服解綁。',
    Gallery: '圖庫',
    'Hidden or Deleted Albums': '隱藏或已刪除的相簿',
    'Recent Projects': '最近專案',
    'Personal Collection': '個人收藏',
    'Recently Deleted': '最近刪除',
    'Live Photos': '實境照片',
    'Exposure Photos': '曝光照片',
    'Panoramic Photos': '全景照片',
    'Time-Lapse Photography': '延時攝影',
    'Slow Motion': '慢動作',
    Portrait: '肖像',
    'Burst Mode': '連續快拍',
    Screenshot: '截屏',
    Motion: '動態',
    Hide: '隱藏',
    'This iCloud account': '此iCloud帳戶未與任何設備連接。請在設備上登錄。',
    'Data synchronization': '數據同步可能需要一些時間，請耐心等待。',
    'Location History1': '位置歷史記錄',
    'Auto Renewal': '自動續訂及隨時取消',
    Month: '月',
    Quarter: '季度',
    Year: '年',
    Day: '天',
    'See Demo': '查看演示',
    'We Accept': '我們接受',
  },
  famikeep220: {
    'Browser Monitoring': '瀏覽器監控',
    'Browser History': '瀏覽器歷史記錄',
    'Browser Bookmarks': '瀏覽器書籤',
    'Serial Number': '序號',
    'Website Restrictions': '網站限制',
    'Restriction Rules': '限制規則',
    'Prohibited Access': '禁止訪問',
    'Access Warning': '訪問預警',
    'Restricted Website Log': '訪問被限制網站記錄',
    'Click to Add Restricted Website': '點擊新增限制網站',
    'Restriction Method:': '限制方式：',
    'Prohibit Access':
      '1.禁止訪問：完全禁用網站訪問。在您移除網站禁止規則前，您的孩子將無法在瀏覽器內訪問被禁止的網站。',
    'Access Alerts':
      '2. 訪問預警：您的孩子訪問規則內的網站，將向您發送電子郵件預警並生成預警記錄（相同網站的預警郵件24小時之內只會發送一封）。',
    'the rule disabling access takes priority.':
      '如果同一網站存在兩種規則內，優先生效禁用訪問規則。',
    'Set Restriction Rules': '設定限制規則',
    'This option will block': '此選項將阻止訪問輸入的網站',
    'This option will allow': '此選項將允許訪問輸入的網站。',
    'For example':
      '例如，如果您輸入example.com，則與其相關的所有網頁（例如www.example.com/page1.html）也將被阻止。',
    'If you enter the specific website page':
      '如果您輸入www.example.com/page1.html具體網站頁面，則只會阻止這一個頁面。',
    'This option allows':
      '此選項將允許訪問輸入的網站，但將生成相應的瀏覽記錄。',
    'Send records by email': '將記錄通過電子郵件發送',
    'When enabled':
      '開啟後，訪問輸入網站時我們會將記錄郵件發送到註冊的郵箱裡。',
    'enter example':
      '例如，如果您輸入example.com，則與其相關的所有網頁（例如www.example.com/page1.html）也將被預警。',
    'enter the specific webpage':
      '如果您輸入www.example.com/page1.html具體網站頁面，則只會預警這一個頁面。',
    'Enter the website to be restricted': '輸入需要限制的網址',
    Bookmark: '書籤',
    'Bookmark list': '書籤列表',
    'Total xx bookmarks': '共xx條書籤',
    'Enter the URL': '輸入網址（例如：youtube.com）',
    'Added URLs': '已新增的網址',
    Add: '新增',
    'Please add the restricted URL': '請新增需要限制的網站',
    'Are you sure': '確定刪除嗎？刪除後將不再限制網站訪問。',
    Video: '影片',
    'Please note that videos': '請注意，影片只有在裝置連接WIFI時才會上傳',
    'Website access alert': '訪問網站警報',
    'monitoring accessed': '您監控的xxx裝置在xxxx-xx-xx訪問了xx',
    'View more records:': '查看更多記錄：',
    'Download and install the app.': '下載並安裝該應用程式。',
    'After installation': '安裝完成後請通過訪問xxx.com開啟APP',
    'Open immediately': '立即打開',
    'Unable to open System Service:': '無法打開System Service：',
    'Please ensure this device has installed':
      '1. 請確保此設備已安裝 System Service（點擊下載 System Service）',
    'click download': '（點擊下載 System Service）',
    'Copy the link': '2. 複製當前網頁的連結地址,在系統內建瀏覽器中開啟',
    'Sure close': '確定關閉嗎？關閉後您將不會收到電子郵件警報。',
    Loading: '載入中',
    'Loading failed': '載入失敗，請下載以本地檢視。',
    'Select All Videos': '全選當前頁視頻',
    tips3: '该app会以“System Service”名字隐藏在设备中。',
    'If the video cannot be loaded': '如果長時間無法載入視頻，請下載查看。',
  },
  famikeep222: {
    'Continue Google': '透過 Google 繼續',
    or: '或',
    Continue: '繼續',
    'legal guardian': '我是法定監護人,並且只會在此帳號中新增我的未成年子女',
    'take around 10 mins': '請綁定 Android 裝置(大約需要 10 分鐘)',
    'Get the Android device': '1.請取得需要被監控的 Android 裝置',
    'Install Companion App': '2.安裝輔助 App',
    'Login to set permissions': '3.登入設定權限',
    twoContent1: '請在被監控 Android 裝置內訪問 **,然後下載安裝 App',
    'set up permissions':
      '請在輔助 App 內登入您的帳戶,並設定權限, 完成後即可開始監控。(請注意,App 僅能通過帳號密碼登入,如果您的帳號尚未設定密碼,請 ** 進行設定)',
    'Click here': '點擊此處',
    'continue collecting data':
      '5. 如果已綁定的裝置權限已過期,可通過更換綁定繼續收集數據。',
  },
  // 用户模块
  language: '語言',
  signIn: '登入',
  email: '郵箱',
  password: '密碼',
  sendCode: '傳送驗證碼',

  // 登录
  signingIn: '登入中',
  noAccount: '沒有賬戶?',
  signUpNow: '去註冊。',
  forgetPassword: '忘記密碼?',

  // 注册
  signUp: '註冊',
  createAccount: '建立賬戶',
  emVerificationCode: '驗證碼',
  creatingAccount: '建立中…',
  alreadyHaveAccount: '已有賬戶?',
  SignInUp: '去登入。',
  send: '已傳送',
  sing: 's',
  byCreatingAnAccount: '建立賬戶意味著您已經閱讀並同意',
  termsOfService: '服務條款',
  and: '和',
  privacyPolicy: '隱私政策。',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: '找回密碼',
  backTo: '返回登入',
  newPassword: '新密碼',
  resetPassword: '重置密碼',
  loginSucceeded: '登錄成功',
  logoutSucceeded: '登出成功',

  // 用户模块异常提示
  passwordMmustBe8: '密碼由8 -20個字母、數字組成',
  pleaseEnterPassword: '請輸入賬號密碼',
  pleaseEnterEmailAddress: '請輸入郵箱地址',
  invalidEmailFormat: '郵箱格式不正確',
  pleaseEnterVerifiCode: '請輸入驗證碼',
  incorrectVerifiCode: '驗證碼不正確',

  // Toast文案
  verificationCodeHasBeen: '驗證碼已傳送，請查收郵件',
  pleaseReadAndAgree: '請閱讀並同意服務條款和隱私政策。',
  networkAbnormal: '網路異常',
  emailRegistered: '郵箱未註冊，請先註冊',
  passwordNotold: '新密碼不能與舊密碼相同',
  invalidPasswordFormat: '密碼格式錯誤',
  theEmailHasRegistered: '郵箱已註冊',
  verificationCodeGetAgain: '驗證碼已過期，請重新獲取',
  erificationCodeMatch: '郵箱和驗證碼不匹配',
  incorrectPasswordOrEmail: '郵箱或密碼輸入錯誤，請重新輸入',
  requestError: '錯誤請求',
  pleaseTryAgainLater: '請求太頻繁，稍後再重試',
  accessDenied: '拒絕訪問',
  requestErrorResourceNotFound: '請求錯誤,未找到該資源',
  serverError: '伺服器端出錯',
  connectionServerFailed: '連線伺服器失敗',
  requestTimedOut: '請求超時',
  networkAbnormaled: '網路異常',
  serviceUnavailable: '服務不可用',
  httpVersioSupportTheRequest: 'http版本不支援該請求',
  wrongPasswordTime:
    '您已輸入錯誤密碼5次，您的帳號暫時被鎖定，無法登錄，請10分鐘後重試。',
};
