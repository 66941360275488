export const ES = {
  chat: {
    pictureText: '[Imagen]',
    videoText: '[Vídeo]',
    audioText: '[Audio]',
    contactCard: '[Tarjeta de Contacto]',
    location: '[Ubicación]',
    other: '[Otro]',
    all: 'Todos',
    today: 'Hoy',
    yesterday: 'Ayer',
    last7Days: 'Últimos 7 Días',
    last30Days: 'Últimos 30 Días',
    customRange: 'Seleccione Rango Personalizado',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    pushNotify: 'Notificaciones Push',
    numbers: 'Números',
    emails: 'Correos electrónicos',
    outgoingCalls: 'Llamadas Salientes',
    incomingCalls: 'Llamadas Entrantes',
    missedCalls: 'Llamadas Perdidas',
    answeredCalls: 'Llamadas Contestadas',
    noAnswer: 'Sin respuesta',
    canceledCalls: 'Llamadas Canceladas',
    loadingText: 'Cargando, por favor sea paciente...',
    noMoreText: 'No hay más contenido',
    noInfoText:
      'No hay información del evento, por favor verifique de nuevo más tarde.',
    noAddressText:
      'No hay información de la libreta de direcciones, por favor verifique de nuevo más tarde.',
    noCallText:
      'No hay registro de llamadas, por favor verifique de nuevo más tarde.',
    dateSelect: 'Seleccionar fecha',
    sentFile: 'Envió un archivo',
    sentAudio: 'Envió un audio',
    unGetFile: 'No se puede obtener el archivo',
    unGetAudio: 'No se puede obtener audio',

    syncFailed: '¡La sincronización de datos fallo!',
    syncSuccessed: '¡La sincronización de datos exitoso!',
    unknownContact: 'Contacto desconocido',
    noSyncNotice1: 'Los datos no se han sincronizado durante más de ',
    noSyncNotice2: '24 horas',
    noSyncNotice3:
      '. Asegúrese de que el teléfono de destino esté encendido y conectado a Internet, y que la aplicación "System Service" se esté ejecutando normalmente.',
    sync: 'Sincronizar',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de final',
    noSupportToast:
      'El filtrado de fechas no es compatible con la demostración.',

    unknowVideo:
      'Vídeo. Los detalles no están disponibles debido a la política de privacidad de Instagram.',
    unknowImage:
      'Imagen. Los detalles no están disponibles debido a la política de privacidad de Instagram.',
    unknowAudio:
      'Mensajes de audio.Los detalles no están disponibles debido a la política de privacidad de Instagram.',
    outgoingVoice: 'Llamada de audio saliente',
    incomingVoice: 'Llamada de audio entrante',
    outgoingVideo: 'Videollamada saliente',
    incomingVideo: 'Videollamada entrante',
  },
  user: {
    userProfile: 'Información de Usuario',
    usesrname: 'Nombre:',
    edit: 'Editar',
    email: 'Correo electrónico:',
    permission: 'Información de Permisos',
    bounded: 'Dispositivo(s) enlazado(s):',
    restDevice: 'Dispositivo(s) disponible(s) restante(s):',
    expire: 'Fecha de caducidad:',
    expired: '(Caducado)',
    logout: 'Cerrar sesión',
    changename: 'Cambiar el nombre de usuario',
    save: 'Guardar ',
    inputname: 'Introduzca un nombre de usuario',
    nameNull:
      'El formato del nombre de usuario es anormal, por favor ingrese nuevamente',
    networkError: 'No se pudo cambiar el nombre de usuario, verifique su red',
    nameSuccess: 'El nombre de usuario se modificó correctamente',
    changeavatar: 'Cambiar avatar',
    loading: 'Cargando…',
    changeAvatar: 'Cambiar',
    supported:
      'Admite formatos jpg, jpeg, png y el tamaño no puede exceder los 2M.',
    maxsize: 'El tamaño de la imagen no puede exceder los 2M',
    avatarError: 'No se pudo cambiar el avatar, verifique su red',
    avatarSuccess: 'Avatar se modificó correctamente',
    imgError: 'Error en la imagen',
    imageType: 'Formato de imagen no compatible',
    nameEmpty: 'El nombre de usuario no puede estar en blanco',
  },
  leftTab: {
    device: 'Dispositivo',
    unbound: 'Dispositivo(s) No Enlazado(s)',
    addDevice: 'Agregar Dispositivo',
    dashboard: 'Tablero',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Vincule un dispositivo primero',
    payRemind: 'Compre primero',
    purchase: 'Comprar Ahora',
    unboundName: 'Enlazar dispositivo ahora',
    toBuy: 'Comprar dispositivo',
  },
  guide: {
    title: 'Vincule su dispositivo',
    oneT: '1.Obtener Dispositivo de Destino',
    oneContent:
      'Para monitorear el dispositivo, debe obtener el dispositivo de monitoreo de destino durante aproximadamente 10 minutos.',
    twoT: '2.Descargar & Instalar la Aplicación',
    twoContent1: 'Introduzca',
    twoContent2:
      ' en el navegador del dispositivo de destino, luego descargue e instale la aplicación.',
    threeT: '3.Configurar Permisos de Dispositivo',
    threeContent:
      'Siga las instrucciones de la aplicación para configurar los permisos del dispositivo. Después de la configuración, puede monitorear el dispositivo.',
    tips1:
      "Para garantizar el sigilo, la aplicación se disfrazará como un software del sistema denominado 'System Service'.",
    tips2:
      'Para garantizar la incógnita, puede eliminar los registros del navegador y los paquetes de instalación de la aplicación después de instalar la aplicación correctamente',
    tips3:
      "la aplicación se mostrará como 'System Service'en el escritorio. Puede optar por ocultarlo en una carpeta para un mejor efecto oculto.",
    tips41:
      'Para asegurarse de que puede instalar la aplicación y configurar sus permisos más rápidamente, primero puede ver',
    tips42: 'para aprender cómo hacerlo.',
    detail: 'los tutoriales detallados',
    tips5:
      'Si el permiso del dispositivo vinculado ha caducado, puede cambiar el vínculo del dispositivo y vincularlo al permiso para continuar recopilando datos.',
    rebound: 'Hacer clic para cambiar la vinculación del dispositivo',
  },
  dashboard: {
    contact: 'Top contactos en WhatsApp (dentro de 7 días)',
    call: 'Top Llamadas en WhatsApp',
    view: 'Ver Todo',
    account: 'Cuenta',
    id: 'ID de la Cuenta:',
    expire: 'Fecha de Caducidad:',
    bound: 'Dispositivo Vinculado:',
    restDevice: 'Dispositivo(s) Restante(s):',
    target: 'Acerca del Dispositivo de Destino',
    brand: 'Marca:',
    model: 'Modelo:',
    version: 'Versión de Android:',
    battery: 'Batería:',
    wifi: 'Wi-Fi:',
    appversion: 'Versión de la Aplicación:',
    update: 'Última Actualización:',
    blank: 'Sin Actividad Reciente',
    unbindSuc: '¡Desvincular exitoso!',
    unbindFail: '¡Desvincular  fallo!',
    ReboundSuc: '¡Cambiar la vinculación del dispositivo exitoso!',
    ReboundFail: '¡Cambiar la vinculación del dispositivo fallo!',
    noPlace: '¡Ningún dispositivo para cambiar la unión!',
    beExpOne: 'El permiso de su dispositivo caducará en',
    beExpTwo: 'días',
    beExpThree:
      'y los datos del dispositivo monitoreado no se sincronizarán después de la caducidad.',
    expiredOne:
      'El permiso de su dispositivo ha caducado. Los datos recopilados anteriormente se borrarán en',
    expiredTwo:
      '. Después de la renovación, los datos del dispositivo monitoreado continuarán sincronizándose. ',
    renew: 'Renovar ahora',

    insContact: 'Contactos principales en Instagram',
    insCall: 'Llamadas principales en Instagram',
  },
  payPage: {
    title: 'Precios y planes de ParentalGuard',
    dec: '¡Monitorear WhatsApp con ParentalGuard es más fácil de lo que piensas!',
    bigSave: 'MEJOR OFERTA',
    one: 'Licencia de 1 Mes',
    three: 'Licencia de 3 Meses',
    year: 'Licencia de 1 Año',
    toBuy: 'Comprar',
    auto: 'Renovación Automática',
    cancelAny: 'Cancela en cualquier momento',
    update: 'Actualizar automáticamente los datos cada cinco minutos',
    collect: 'Recopilar todas las conversaciones',
    interaction: 'Mejor experiencia interactiva',
    hide: 'Modo oculto',
  },
  pop: {
    changeBound: 'Cambiar la vinculación del dispositivo',
    reboundText1: '¿Está seguro de cambiar la vinculación del dispositivo',
    reboundText2:
      'y vincularlo con el permiso del dispositivo no vinculado para continuar recopilando datos (fecha de vencimiento:',
    reboundText3: ')?',
    reboundText4:
      'y vincularlo con el permiso del dispositivo no vinculado para continuar recopilando datos',
    noRebound:
      'No hay permiso de dispositivo disponible. Por favor, cómprelo primero.',
    goPay: 'Comprar Ahora',
    reboundList: 'Dispositivo vinculado',
    reboundDec:
      'El permiso del dispositivo ha caducado. Seleccionar un dispositivo y vincúlelo al permiso de dispositivo actualmente no vinculado para continuar recopilando datos.',
    rebounding: 'Cambiando la vinculación del dispositivo...',
    reboundFail1: 'No se pudo cambiar el enlace del dispositivo',
    reboundFail2: '.¿Quieres intentarlo de nuevo?',
    tryAgain: 'Rever',
    unbindTitle: 'Desvincular dispositivo',
    unbindText1: '¿Estás seguro de desvincular el dispositivo',
    unbindText2:
      '? Todos los datos recopilados se borrará después de la desvinculación.',
    remind: 'Aviso de caducidad',
    expiredPay: 'Renovar',
    cancel: 'Cancelar',
    yes: 'Sí',
    no: 'No',
  },
  navTop: {
    langTitle: 'Cambiar idioma',
  },
  famikeep200: {
    Calls: 'Llamadas',
    Messages: 'Mensajes',
    Contacts: 'Contactos',
    Photos: 'Fotos',
    Applications: 'Aplicaciones',
    'Compared to yesterday': 'Comparado con ayer',
    'All locations': 'Todos los lugares',
    'Weekly average usage time': 'Tiempo medio de utilización semanal',
    'Floating comparison with last week':
      'Comparación flotante con la semana pasada',
    Average: 'Media',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mié',
    Thu: 'Jue',
    Fri: 'Vie',
    Sat: 'Sáb',
    Sun: 'Dom',
    'Most used applications': 'Aplicaciones más utilizadas',
    Geolocation: 'Geolocalización',
    'Last updated': 'Última actualización',
    'Geolocation permission':
      "El permiso de ubicación de la aplicación 'Servicio del sistema' está desactivado, la adquisición de la ubicación falla",
    Geofence: 'Geovalla',
    'Create a geofence':
      'Cree una geovalla para recibir alertas por correo electrónico cuando su hijo entre o salga de la zona especificada.',
    'enters/exits': 'entre o salga de la zona especificada.',
    'Create a new geofence': 'Crear una nueva geovalla',
    'Geofence name': 'Nombre de la geovalla',
    'Set geofence location': 'Establecer la ubicación de la geovalla',
    'Set radius': 'Fijar radio',
    'Set monitoring time': 'Establecer el tiempo de supervisión',
    'please choose': 'por favor elige',
    Daily: 'Diario',
    'Every Monday': 'Todos los lunes',
    'Every Tuesday': 'Todos los martes',
    'Every Wednesday': 'Todos los miércoles',
    'Every Thursday': 'Todos los jueves',
    'Every Friday': 'Todos los viernes',
    'Every Saturday': 'Todos los sábados',
    'Every Sunday': 'Todos los domingos',
    'Send alerts via email': 'Enviar alertas por correo electrónico',
    'We will send alert emails to the registered email':
      'Enviaremos correos electrónicos de alerta a la dirección de correo electrónico registrada',
    Save: 'Guardar',
    'Please enter a geofence name': 'Ingrese un nombre de geocerca',
    'Please select a geofence location': 'Seleccione una ubicación de geovalla',
    Entered: 'Entró',
    Exited: 'Salió',
    Edit: 'Editar',
    Delete: 'Borrar',
    'Are you sure you want to delete':
      '¿Está seguro de que desea eliminar la geovalla xxx? Los datos de seguimiento correspondientes también se eliminarán',
    'Are you sure you want to close':
      '¿Estás seguro de que quieres cerrar? No recibirás alertas por correo electrónico al entrar/salir de la geocerca.',
    'Call history': 'Historial de llamadas',
    Status: 'Estado',
    Number: 'Número',
    Duration: 'Duración',
    Date: 'Fecha',
    incoming: 'Llamada Entrante',
    outgoing: 'Llamada Saliente',
    missed: 'Llamada Perdida',
    'no answered': 'Sin respuesta',
    Address: 'Dirección',
    'Added time': 'Añadir tiempo',
    'Sort by name': 'Ordenar por nombre',
    'Sort by time': 'Cronológico',
    'Please note that photos':
      'Ten en cuenta que las fotos sólo se cargarán cuando el dispositivo esté conectado a una red Wi-Fi.',
    Album: 'Álbum',
    'Select all photos on the current page':
      'Seleccionar todas las imágenes de la página actual',
    'Previous page': 'página anterior',
    'Next page': 'Página siguiente',
    Back: 'Volver',
    'confirm to delete':
      '¿Confirmar para eliminar? Los datos no se pueden recuperar después de la eliminación',
    Download: 'descargar',
    Calendar: 'Calendario',
    Title: 'Título',
    Description: 'Descripción',
    Location: 'Ubicación',
    'Start time': 'Hora de inicio',
    'End time': 'Hora de finalización',
    'Installed applications': 'Aplicaciones instaladas',
    'All applications': 'Todas las aplicaciones',
    Name: 'Nombre',
    Version: 'Versión',
    Size: 'Tamaño',
    'Installation date': 'Fecha de instalación',
    'Disable applications': 'Desactivar aplicación',
    'Seted disable rules': 'Conjunto de normas desactivadas',
    'Undisabled applications': 'Aplicaciones no desactivadas',
    'Click the button below':
      'Haga clic en el botón de abajo para crear una regla de desactivación de APP y seleccione la APP que desea desactivar.',
    'Set disable rules': 'Establecer reglas de desactivación',
    'Disable permanently': 'Desactivar permanentemente',
    'Disable ': 'Desactivar',
    'Custom disable time': 'Tiempo de desactivación personalizado',
    'Disable period': 'Período de desactivación',
    From: 'De',
    To: 'A',
    Total: 'Total',
    Weekly: 'semanalmente',
    Time: 'tiempo',
    'Select the applications you want to disable':
      'Seleccione la aplicación que desea desactivar',
    'Please select an application below':
      'Por favor seleccione una aplicación a continuación',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      '¿Estás seguro de que quieres borrar? Las aplicaciones desactivadas bajo esta regla ya no se verán afectadas.',
    'Disable options:': 'Options de désactivation :',
    'one Disable permanently':
      '1. Desactivar permanentemente: Desactiva completamente la aplicación. Tu hijo no podrá utilizar las aplicaciones desactivadas hasta que levantes la desactivación.',
    'two Custom disable time':
      '2. Hora de desactivación personalizada: Sólo activa la regla de inhabilitación durante rangos de tiempo específicos, y tu hijo puede usar las apps normalmente fuera de esos horarios.',
    'You can only choose one of the two setting options':
      'Sólo puede elegir una de las dos opciones de configuración',
    complete: 'completo',
    'Screen usage time': 'Tiempo de uso de la pantalla',
    'Screen Usage Time for xxx': 'Tiempo de uso de pantalla para xxx',
    'Frequently used applications': 'Aplicaciones más utilizadas',
    Frequency: 'Frecuencia',
    'Usage time': 'Tiempo de uso',
    'Screen disable': 'Desactivación de pantalla',
    'Turn on the switch and set the screen lock duration.':
      'Activa el interruptor para establecer el tiempo de bloqueo de la pantalla.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'La pantalla del dispositivo de su hijo se desactivará y se restablecerá una vez transcurrido el tiempo establecido.',
    'Disable all screen activities':
      'Desactivar todas las actividades de pantalla',
    'Set screen disable duration':
      'Establecer la duración de la desactivación de la pantalla',
    'You can lift the disablement at any time':
      'Puede levantar la desactivación en cualquier momento',
    'Remaining screen disable time on the target device':
      'Tiempo restante de pantalla desactivada en el dispositivo de destino',
    'Are you sure you want to lift the screen disablement now?':
      '¿Estás seguro de que quieres levanten la desactivación de la pantalla ahora?',
    Yes: 'Sí',
    'This account does not exist, please register first':
      'Esta cuenta no existe, por favor regístrese primero',
    'Synchronization successful, no new data':
      'Sincronización exitosa, sin datos nuevos',
    'Location alert': 'Alerta de localización',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'El dispositivo xxx que está supervisando entró en el xx en xxxx-xx-xxx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'El dispositivo xxx que está supervisando dejó xx en xxxx-xx-xxx',
    'View more location records:': 'Ver más registros de ubicación.',
    'Thank you!': 'Gracias！',
    'xxx Team': 'Equipo xxx',
    'When obtaining this location':
      'Al obtener esta ubicación, el dispositivo activó el posicionamiento virtual, lo que puede generar imprecisiones.',
    'The demo mode does not support this feature, please bind the device first.':
      'El modo de demostración no es compatible con esta función, vincule primero el dispositivo.',
    'Location Tracking': 'Seguimiento de Ubicación',
    'General Application': 'Aplicación General',
    'App Activites Tracking': 'Seguimiento de Actividades de Apps',
    'Screen Time Tracking': 'Seguimiento del Tiempo de Pantalla',
    'Social Application': 'Aplicación Social',
    'Subject:': 'Sujeto:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'Almacenamiento utilizado de iCloud',
    Online: 'En línea',
    Offline: 'Fuera de línea',
    'Last known location': 'Última ubicación conocida',
    'Please bind your device': 'Por favor, vincule su dispositivo',
    'Log in iCloud to binding.':
      'Inicie sesión en la cuenta de iCloud del dispositivo objetivo para completar la vinculación.',
    'Please enter your Apple ID': 'Por favor, ingrese su Apple ID',
    'Please enter your password': 'Por favor, introduzca su contraseña',
    'Your entered account or password is incorrect.':
      'La cuenta o contraseña que ha ingresado es incorrecta.',
    'Ensure Apple ID and password':
      'Asegúrese de ingresar la Apple ID y la contraseña del dispositivo objetivo, autenticarse y completar la vinculación.',
    'Forgot Apple ID or password?': '¿Olvidaste tu Apple ID o contraseña?',
    'Devices using the same iCloud account will be bound':
      'Los dispositivos que usen la misma cuenta de iCloud se vincularán.',
    'Two-Factor Authentication': 'Autenticación de dos factores',
    'A message has been sent':
      'Se ha enviado un mensaje con un código de verificación a su dispositivo. Por favor, introduzca el código de verificación para continuar.',
    'A message containing a verification code has been sent to xxx.':
      'Se ha enviado un mensaje con un código de verificación a xxx. Por favor, introduzca el código de verificación para continuar.',
    'Incorrect verification code': 'Código de verificación incorrecto',
    'Resend Verification Code': 'Volver a enviar el código de verificación',
    'Get SMS Verification Code': 'Obtener código de verificación SMS',
    'Please select a phone number to receive the verification code.':
      'Por favor, seleccione un número de teléfono para recibir el código de verificación.',
    'Please select': 'Por favor, elija',
    'The current iCloud has expired':
      'La verificación de la cuenta de iCloud actual ha caducado. Inicie sesión nuevamente en su cuenta de iCloud para sincronizar los datos.',
    'current iCloud has expired':
      'La verificación de la cuenta de iCloud actual ha caducado. Inicie sesión nuevamente en su cuenta de iCloud para sincronizar los datos. Ir a la verificación >>',
    'Go to verification >>': 'Ir a la verificación >>',
    'Data synchronization failed, iCloud account expired':
      'Sincronización de datos fallida, cuenta de iCloud caducada, por favor regrese a la página de inicio y verifique nuevamente.',
    'Created geofences': 'Geocercas creadas',
    'No geofences created': 'No se han creado geocercas',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Precios y planes de la aplicación de control parental',
    '30-Day Money Back Guarantee': 'Garantía de devolución de 30 días',
    'Professional Customer Support': 'Soporte al cliente profesional',
    'All Prices Exclusive of VAT': 'Todos los precios sin IVA',
    '100% Secure': '100% Seguro',
    'More Features to Keep Your Kids Safe Online':
      'Más funciones para mantener a tus hijos seguros en línea',
    Features: 'Características',
    'Monitor App': 'Aplicación de monitoreo',
    'Type of Data': 'Tipo de Datos',
    'WhatsApp Chat': 'WhatsApp チャット',
    'App Activity': 'アプリのアクティビティ',
    'App Blocker': 'アプリブロッカー',
    'Monitor Social Apps': 'Monitor de Aplicaciones Sociales',
    'Location History': 'Historial de Ubicaciones',
    'Location Reminder': 'Recordatorio de Ubicación',
    'Live Location': 'Búsqueda segura',
    'Safe Search': 'Búsqueda segura',
    'Web Filter': 'Búsqueda segura',
    'Browser History': 'Historial de navegador',
    'Content Detection': 'Detección de contenido',
    'All SMS': 'Todos los SMS',
    Photos: 'Fotos',
    Video: 'Video',
    Calendar: 'Calendario',
    Documents: 'Documentos',
    'Record Screen': 'Pantalla',
    'Call History': 'Historial de llamadas',
    'Call Recording': 'Grabaciones de llamadas',
    Contacts: 'Contactos',
    Messages: 'Mensajes',
    'Call Logs': 'Historial de llamadas',
    'Chat History': 'Historial de chat',
    'The device has been bound to other account':
      'El dispositivo se ha vinculado a otra cuenta, desvincularlo primero o póngase en contacto con el servicio de atención al cliente para desvincularlo.',
    Gallery: 'Galería',
    'Hidden or Deleted Albums': 'Álbumes ocultos o eliminados',
    'Recent Projects': 'Proyectos',
    'Personal Collection': 'Personal',
    'Recently Deleted': 'Eliminado recientemente',
    'Live Photos': 'Foto en vivo',
    'Exposure Photos': 'Foto de exposición',
    'Panoramic Photos': 'Foto panorámica',
    'Time-Lapse Photography': 'Fotografía de lapso de tiempo',
    'Slow Motion': 'Cámara lenta',
    Portrait: 'Retrato',
    'Burst Mode': 'Captura continua',
    Screenshot: 'Captura de pantalla',
    Motion: 'Dinámico',
    Hide: 'Ocultar',
    'This iCloud account':
      'Esta cuenta de iCloud no está vinculada a ningún dispositivo. Inicie sesión en un dispositivo.',
    'Data synchronization':
      'La sincronización de datos puede llevar algún tiempo, por favor, sea paciente.',
    'Location History1': 'Historial de ubicación',
    'Auto Renewal': 'Renovación automática y cancelación en cualquier momento',
    Month: 'Mes',
    Quarter: 'Trimestre',
    Year: 'Año',
    Day: 'Día',
    'See Demo': 'Ver demo',
    'We Accept': 'Aceptamos',
  },
  famikeep220: {
    'Browser Monitoring': 'Monitorización del navegador',
    'Browser History': 'Historial del navegador',
    'Browser Bookmarks': 'Marcadores del navegador',
    'Serial Number': 'Número de serie',
    'Website Restrictions': 'Restricciones del sitio web',
    'Restriction Rules': 'Reglas de restricción',
    'Prohibited Access': 'Acceso prohibido',
    'Access Warning': 'Advertencia de acceso',
    'Restricted Website Log': 'Registro de sitios restringidos',
    'Click to Add Restricted Website':
      'Haga clic para agregar un sitio web restringido',
    'Restriction Method:': 'Método de restricción:',
    'Prohibit Access':
      '1. Prohibir el acceso: Desactiva completamente el acceso a sitios web. Su hijo no podrá acceder a sitios web restringidos en el navegador hasta que elimine la regla de bloqueo del sitio web.',
    'Access Alerts':
      '2. Alertas de acceso: Cuando su hijo visite sitios web dentro de las reglas, recibirá alertas por correo electrónico y generará registros de alertas (solo se enviará un correo electrónico de alerta en 24 horas para el mismo sitio web).',
    'the rule disabling access takes priority.':
      'Si el mismo sitio web está sujeto a dos reglas diferentes, la regla de desactivación del acceso tiene prioridad.',
    'Set Restriction Rules': 'Establecer reglas de restricción',
    'This option will block':
      'Esta opción bloqueará el acceso a los sitios web introducidos',
    'This option will allow':
      'Esta opción permitirá el acceso al sitio web ingresado.',
    'For example':
      'Por ejemplo, si ingresa example.com, también se bloquearán todas las páginas web asociadas (por ejemplo, www.example.com/page1.html).',
    'If you enter the specific website page':
      'Si ingresa la página web específica www.example.com/page1.html, solo se bloqueará esa página.',
    'This option allows':
      'Esta opción permite el acceso al sitio web introducido pero generará registros de navegación correspondientes.',
    'Send records by email': 'Enviar registros por correo electrónico',
    'When enabled':
      'Cuando está habilitado, enviaremos los registros al correo electrónico registrado al acceder al sitio web ingresado.',
    'enter example':
      'Por ejemplo, si ingresa example.com, también se alertarán todas las páginas web relacionadas (como www.example.com/page1.html).',
    'enter the specific webpage':
      'Si ingresa la página web específica www.example.com/page1.html, solo se alertará esta página.',
    'Enter the website to be restricted':
      'Ingrese el sitio web que se va a restringir',
    Bookmark: 'Marcador',
    'Bookmark list': 'Lista de marcadores',
    'Total xx bookmarks': 'Total de xx marcadores',
    'Enter the URL': 'Ingrese la URL (p. ej., youtube.com)',
    'Added URLs': 'URLs agregadas',
    Add: 'Añadir',
    'Please add the restricted URL': 'Por favor, agregue la URL restringida',
    'Are you sure':
      '¿Seguro que deseas eliminar? La restricción se eliminará después de la eliminación.',
    Video: 'Video',
    'Please note that videos':
      'Tenga en cuenta que los videos solo se cargarán cuando el dispositivo esté conectado a WIFI.',
    'Website access alert': 'Alerta de acceso al sitio web',
    'monitoring accessed':
      'El dispositivo xxx que está monitoreando accedió a xx el xxxx-xx-xx.',
    'View more records:': 'Ver más registros:',
    'Download and install the app.': 'Descarga e instala la aplicación.',
    'After installation':
      'Después de la instalación, abre la aplicación visitando xxx.com.',
    'Open immediately': 'Abrir inmediatamente',
    'Unable to open System Service:': 'No se puede abrir System Service:',
    'Please ensure this device has installed':
      '1. Por favor, asegúrese de que este dispositivo tenga instalado System Service (haga clic para descargar System Service)',
    'click download': '(haga clic para descargar System Service)',
    'Copy the link':
      '2. Copia la dirección del enlace de la página web actual y ábrela en el navegador integrado',
    'Sure close':
      ' | "¿Está seguro de que desea cerrar? No recibirá alertas por correo electrónico después de cerrar.',
    Loading: 'Cargando',
    'Loading failed': 'Error al cargar. Descargue para ver localmente.',
    'Select All Videos': 'Seleccionar todos los vídeos de la página actual',
    tips3:
      "La aplicación se ocultará en el dispositivo bajo el nombre 'System Service'.",
    'If the video cannot be loaded':
      'Si el video no se carga durante un tiempo prolongado, descárguelo y mírelo.',
  },
  famikeep222: {
    'Continue Google': 'Continuar con Google',
    or: 'o',
    Continue: 'Continuar',
    'legal guardian':
      'Soy mayor de edad y sólo añadiré a mis hijos menores a esta cuenta',
    'take around 10 mins':
      'Vincula un dispositivo Android (tardará unos 10 minutos)',
    'Get the Android device': '1. Consigue el dispositivo Android a supervisar',
    'Install Companion App': '2. Instala aplicaciones de asistencia',
    'Login to set permissions': '3. Inicie sesión para establecer permisos',
    twoContent1:
      'Visite ** en el dispositivo Android supervisado y, a continuación, descargue e instale la aplicación.',
    'set up permissions':
      'Inicie sesión con su cuenta en la aplicación  de asistencia y configure los permisos y, a continuación, podrá empezar a monitorizar.(Tenga en cuenta que sólo se puede iniciar sesión en la app con la contraseña de su cuenta, si aún no ha establecido una contraseña para su cuenta, por favor ** para hacerlo).',
    'Click here': 'Haz clic aquí',
    'continue collecting data':
      '5. Si los permisos del dispositivo vinculado han caducado, puedes seguir recopilando datos volviendo a vincularlo.',
  },
  // 用户模块
  language: 'idioma',
  signIn: 'Iniciar Sesión',
  email: 'Correo electrónico',
  password: 'Contraseña',
  sendCode: 'Enviar Código',

  // 登录
  signingIn: 'Iniciando sesión',
  noAccount: '¿No tiene una cuenta?',
  signUpNow: 'Regístrate ahora.',
  forgetPassword: '¿Olvidé la contraseña?',

  // 注册
  signUp: 'Registrarse',
  createAccount: 'Crear Cuenta',
  emVerificationCode: 'Código de verificación',
  creatingAccount: 'Creando Cuenta…',
  alreadyHaveAccount: '¿Ya tienes una cuenta?',
  SignInUp: 'Inicia Sesión.',
  send: 'Enviado',
  sing: 's',
  byCreatingAnAccount: 'Al crear una cuenta, acepta los ',
  termsOfService: 'Términos de Servicio ',
  and: 'y la ',
  privacyPolicy: 'Política de Privacidad.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Recuperar Contraseña',
  backTo: 'Volver a Iniciar Sesión',
  newPassword: 'Nueva Contraseña',
  resetPassword: 'Restablecer Contraseña',
  loginSucceeded: '¡Inicio de sesión exitoso!',
  logoutSucceeded: '¡Cierre de sesión exitoso!',

  // 用户模块异常提示
  passwordMmustBe8:
    'La contraseña debe tener 8 a 20 caracteres y una combinación de letras y números',
  pleaseEnterPassword: 'Introduzca la contraseña de la cuenta',
  pleaseEnterEmailAddress: 'Introduzca la dirección de correo',
  invalidEmailFormat: 'Formato de correo electrónico incorrecto',
  pleaseEnterVerifiCode: 'Introduzca el código de verificación',
  incorrectVerifiCode: 'Código de verificación incorrecto',

  // Toast文案
  verificationCodeHasBeen:
    'Se ha enviado el código de verificación, por favor revise su correo electrónico',
  pleaseReadAndAgree:
    'Por favor, lea y acepte los Términos de Servicio y la Política de Privacidad.',
  networkAbnormal: 'Red anormal',
  emailRegistered:
    'El correo electrónico no está registrado, por favor registra primero',
  passwordNotold: 'La nueva contraseña no puede ser la misma que la anterior',
  invalidPasswordFormat: 'Formato de contraseña incorrecto',
  theEmailHasRegistered: 'El correo electrónico está registrado',
  verificationCodeGetAgain:
    'El código de verificación ha expirado, consíguelo de nuevo',
  erificationCodeMatch:
    'El correo electrónico y el código de verificación no coinciden',
  incorrectPasswordOrEmail:
    'Contraseña o correo electrónico incorrecto, por favor ingresa de nuevo',
  requestError: 'Error de solicitud',
  pleaseTryAgainLater: 'Demasiadas solicitudes, inténtelo de nuevo más tarde',
  accessDenied: 'Acceso denegado',
  requestErrorResourceNotFound:
    'Error de solicitud, no se encontró el recurso.',
  serverError: 'Error del Servidor',
  connectionServerFailed: 'El servidor de conexión falló',
  requestTimedOut: 'Tiempo de espera agotado',
  networkAbnormaled: 'Red anormal',
  serviceUnavailable: 'Servicio indisponible',
  httpVersioSupportTheRequest: 'La versión http no admite la solicitud',
  wrongPasswordTime:
    'Ha ingresado la contraseña incorrecta 5 veces. Su cuenta está bloqueada temporalmente y no se puede iniciar sesión. Vuelva a intentarlo en 10 minutos.',
};
