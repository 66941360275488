export const KO = {
  chat: {
    pictureText: '[사진]',
    videoText: '[비디오]',
    audioText: '[오디오]',
    contactCard: '[연락처 카드]',
    location: '[위치]',
    other: '[기타]',
    all: '전체',
    today: '오늘',
    yesterday: '어제',
    last7Days: '최근 7일',
    last30Days: '최근 30일',
    customRange: '사용자 지정 범위 선택',
    cancel: '취소',
    apply: '적용',
    pushNotify: '푸시 알림',
    numbers: '전화번호',
    emails: '이메일',
    outgoingCalls: '발신 통화',
    incomingCalls: '수신 통화',
    missedCalls: '부재중 전화',
    answeredCalls: '응답된 호출',
    noAnswer: '대답 없음',
    canceledCalls: '취소된 통화',
    loadingText: '로드 중입니다. 기다려 주십시오...',
    noMoreText: '더 이상의 내용이 없습니다.',
    noInfoText: '최근 활동 정보가 없습니다. 나중에 다시 확인하십시오.',
    noAddressText: '연락처 정보가 없습니다. 나중에 다시 확인하십시오.',
    noCallText: '통화 기록이 없습니다. 나중에 다시 확인하십시오.',

    dateSelect: '날짜 선택',
    sentFile: '파일을 전송했습니다.',
    sentAudio: '오디오를 전송했습니다.',
    unGetFile: '파일을 가져올 수 없습니다.',
    unGetAudio: '오디오를 가져올 수 없습니다.',

    syncFailed: '데이터 동기화 실패!',
    syncSuccessed: '데이터 동기화 성공!',
    unknownContact: '알 수 없는 연락처',
    noSyncNotice1: '데이터가 ',
    noSyncNotice2: '24시간',
    noSyncNotice3:
      " 이상 동기화되지 않았습니다. 대상 휴대폰이 켜져 있고 인터넷에 연결되어 있으며 'System Service' 앱이 정상적으로 작동하고 있는지 확인하십시오.",
    sync: '동기화',
    startDate: '시작일자',
    endDate: '종료일자',
    noSupportToast: '데모 모드에서는 날짜 필터링이 지원되지 않습니다.',
    unknowVideo:
      '영상입니다. 인스타그램 데이터 보호로 인해 자세한 내용을 알 수 없습니다.',
    unknowImage:
      '사진입니다. 인스타그램 데이터 보호로 인해 자세한 내용을 알 수 없습니다.',
    unknowAudio:
      '음성메시지입니다. 인스타그램 데이터 보호로 인해 자세한 내용을 알 수 없습니다.',
    outgoingVoice: '발신 음성 통화',
    incomingVoice: '수신 음성 통화',
    outgoingVideo: '발신 영상 통화',
    incomingVideo: '수신 영상 통화',
  },
  user: {
    userProfile: '프로필',
    usesrname: '사용자 이름:',
    edit: '수정',
    email: '이메일:',
    permission: '권한 정보',
    bounded: '연동된 장치:',
    restDevice: '사용 가능한 나머지 장치 수:',
    expire: '만료 날짜:',
    expired: '(만료됨)',
    logout: '로그아웃',
    changename: '사용자 이름 변경',
    save: '저장',
    inputname: '사용자 이름을 입력하십시오.',
    nameNull: '사용자 이름 형식이 잘못되었습니다. 다시 입력하십시오.',
    networkError: '사용자 이름을 변경하지 못했습니다. 네트워크를 확인하십시오.',
    nameSuccess: '사용자 이름이 변경되었습니다.',
    changeavatar: '프로필사진 변경',
    loading: '로드 중...',
    changeAvatar: '변경',
    supported: 'jpg, jpeg, png 형식을 지원하며 크기는 2M을 초과할 수 없습니다.',
    maxsize: '이미지 크기는 2M을 초과할 수 없습니다.',
    avatarError: '프로필사진을 변경하지 못했습니다. 네트워크를 확인하십시오.',
    avatarSuccess: '프로필사진이 성공적으로 변경되었습니다.',
    imgError: '이미지 오류',
    imageType: '이미지 형식이 지원되지 않습니다.',
    nameEmpty: '사용자 이름을 입력하십시오.',
  },
  leftTab: {
    device: '장치',
    unbound: '연동되지 않은 장치',
    addDevice: '장치 추가',
    dashboard: '개요',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: '먼저 장치를 연동하십시오.',
    payRemind: '먼저 구입하십시오.',
    purchase: '바로 구매',
    unboundName: '바로 장치를 연동하기',
    toBuy: '장치 구입',
  },
  guide: {
    title: '장치를 연동하십시오.',
    oneT: '1. 대상 장치 가져오기',
    oneContent:
      '장치를 모니터링하려면 대상 모니터링 장치를 10분 정도 가져와야 합니다.',
    twoT: '2. 앱 다운로드 및 설치',
    twoContent1: '대상 장치 브라우저에',
    twoContent2: '을 입력한 다음 앱을 다운로드하여 설치합니다.',
    threeT: '3. 장치 권한 설정',
    threeContent:
      '앱의 지침에 따라 장치 권한을 설정합니다. 설정 후 장치를 모니터링할 수 있습니다.',
    tips1:
      "은닉을 보장하기 위해, 앱은 'System Service'라는 이름의 시스템 소프트웨어로 위장됩니다.",
    tips2:
      '앱 설치 후 브라우저 기록 및 앱 설치 패키지를 삭제하면 은닉을 보장할 수 있습니다.',
    tips3:
      "앱은 바탕 화면에 'System Service'라는 이름으로 노출하게 됩니다. 더 좋은 은닉 효과를 위해 폴더에 숨기도록 선택할 수 있습니다.",
    tips41: '앱을 설치하고 권한을 보다 빨리 설정할 수 있도록 먼저',
    tips42: '를 보고 방법을 배울 수 있습니다.',
    detail: '단계별 가이드',
    tips5:
      '연결된 장치의 사용 권한이 만료된 경우, 연결장치를 변경하고 해당 장치를 사용 권한에 연결하여 데이터 수집을 계속할 수 있습니다.',
    rebound: '클릭하여 연결장치 변경하기',
  },
  dashboard: {
    contact: 'WhatsApp 주요 연락처(7일 내)',
    call: 'WhatsApp 주요 통화',
    view: '모두 보기',
    account: '계정',
    id: '계정 ID:',
    expire: '만료 날짜:',
    bound: '연동된 장치:',
    restDevice: '나머지 장치 수:',
    target: '대상 장치 정보',
    brand: '브랜드:',
    model: '모델:',
    version: 'Android 버전:',
    battery: '배터리:',
    wifi: 'Wi-Fi:',
    appversion: '앱 버전:',
    update: '마지막 업데이트 시간:',
    blank: '최근 활동 없음',
    unbindSuc: '연결장치 해제 성공!',
    unbindFail: '연결장치 해제 실패!',
    ReboundSuc: '연결장치 변경에 성공!',
    ReboundFail: '연결장치 변경에 실패!',
    noPlace: '변경 가능한 연결장치가 없습니다.',
    beExpOne: '장치 권한이',
    beExpTwo: '일',
    beExpThree:
      '후 만료됩니다. 만료 후 모니터링되는 장치의 데이터가 동기화되지 않습니다. ',
    expiredOne: '장치 권한이 만료되었습니다. 이전에 수집된 데이터는 ',
    expiredTwo:
      '후에 삭제됩니다. 갱신 후 모니터링되는 장치의 데이터를 계속 동기화합니다.',
    renew: '즉시 갱신 ',

    insContact: '인스타그램 주요 연락처 (지난 7일)',
    insCall: '인스타그램 주요 통화',
  },
  payPage: {
    title: 'ParentalGuard 가격 & 플랜',
    dec: 'ParentalGuard을 사용하여 WhatsApp을 모니터링하는 것은 생각보다 쉽습니다!',
    bigSave: '대폭 절약',
    one: '1개월 플랜',
    three: '3개월 플랜',
    year: '1년 플랜',
    toBuy: '바로 구매',
    auto: '자동 갱신',
    cancelAny: '언제든지 취소 가능',
    update: '5분마다 데이터 자동 업데이트',
    collect: '모든 대화 수집',
    interaction: '더 나은 상호작용 경험',
    hide: '숨김 모드',
  },
  pop: {
    changeBound: '연결장치 변경',
    reboundText1: '',
    reboundText2:
      ' 장치를 연결되지 않은 장치 권한으로 변경하여 데이터 수집하시겠습니까(만료 날짜:',
    reboundText3: ')?',
    reboundText4:
      '장치를 연결되지 않은 장치 권한으로 변경하여 데이터 수집하시겠습니까',
    noRebound: '연결 변경 권한이 없습니다. 먼저 장치 권한을 구입하십시오.',
    goPay: '바로 구매',
    reboundList: '연결된 장치',
    reboundDec:
      '아래 장치의 사용 권한이 만료되었습니다. 데이터 수집을 계속하려면 장치를 선택하고 현재 연결되지 않은 장치 권한에 연결하십시오.',
    rebounding: '연결장치 변경 중...',
    reboundFail1: '',
    reboundFail2: '연결장치 변경에 실패하였습니다. 다시 시도하시겠습니까?',
    tryAgain: '재시도',
    unbindTitle: '연결 해제',
    unbindText1: '',
    unbindText2:
      '연결장치를 해제하시겠습니까? 해제 후 수집된 모든 데이터 정보가 삭제됩니다.',
    remind: '만료 알림',
    expiredPay: '갱신하기',
    cancel: '취소하기',
    yes: '네',
    no: '아니요',
  },
  navTop: {
    langTitle: '언어 변경',
  },
  famikeep200: {
    Calls: '통화',
    Messages: '메시지',
    Contacts: '연락처',
    Photos: '사진',
    Applications: '앱',
    'Compared to yesterday': '어제보다',
    'All locations': '모든 위치',
    'Weekly average usage time': '이번 주 평균 사용 시간',
    'Floating comparison with last week': '지난 주와 대비 변동',
    Average: '평균',
    Mon: '월요일',
    Tue: '화요일',
    Wed: '수요일',
    Thu: '목요일',
    Fri: '금요일',
    Sat: '토요일',
    Sun: '일요일',
    'Most used applications': '가장 자주 쓰는 앱',
    Geolocation: '지리 위치',
    'Last updated': '업데이트 시간',
    'Geolocation permission':
      "'System Service' 앱에 대한 위치 정보 권한이 비활성화되어 위치를 가져오는데 실패하였습니다",
    Geofence: '지오펜스',
    'Create a geofence':
      '자녀가 지정된 지역에 들어오거나 나갈 때 이메일로 알림을 받기 위해 지오펜스를 생성하십시오.',
    'enters/exits': '지정된 지역에 들어오거나 나갈',
    'Create a new geofence': '새로운 지오펜스 생성',
    'Geofence name': '지오펜스 이름',
    'Set geofence location': '지오펜스 위치 설정',
    'Set radius': '반지름 설정',
    'Set monitoring time': '모니터링 시간 설정',
    'please choose': '선택해주세요',
    Daily: '매일',
    'Every Monday': '매주 월요일',
    'Every Tuesday': '매주 화요일',
    'Every Wednesday': '매주 수요일',
    'Every Thursday': '매주 목요일',
    'Every Friday': '매주 금요일',
    'Every Saturday': '매주 토요일',
    'Every Sunday': '매주 일요일',
    'Send alerts via email': '이메일을 통해 알림을 보냅니다',
    'We will send alert emails to the registered email':
      '등록된 이메일로 알림 이메일을 보낼 겁니다.',
    Save: '저장',
    'Please enter a geofence name': '지오펜스 이름을 입력해 주십시오',
    'Please select a geofence location': '지오펜스 위치를 선택하십시오',
    Entered: '들어왔음',
    Exited: '나갔음',
    Edit: '편집',
    Delete: '삭제',
    'Are you sure you want to delete':
      '‘xxx’ 지오펜스를 삭제하시겠습니까? 해당 지오펜스에 대한 모니터링 데이터도 함께 삭제될 겁니다.',
    'Are you sure you want to close':
      '닫으시겠습니까? 지오펜스에 들어가거나 나갈 때 이메일 알림을 받지 않습니다.',
    'Call history': '통화 기록',
    Status: '상태',
    Number: '번호',
    Duration: '기간',
    Date: '날짜',
    incoming: '수신 전화',
    outgoing: '발신 전화',
    missed: '부재 중 전화',
    'no answered': '대답 없음',
    Address: '주소',
    'Added time': '추가 시간',
    'Sort by name': '이름순으로 정렬',
    'Sort by time': '시간순으로 정렬',
    'Please note that photos':
      '사진이 장치가 WiFi에 연결되어 있는 경우에만 업로드됩니다.',
    Album: '앨범',
    'Select all photos on the current page': '현재 페이지의 모든 사진 선택',
    'Previous page': '이전 페이지',
    'Next page': '다음 페이지',
    Back: '뒤로',
    'confirm to delete': '삭제를 확인하시겠습니까? 삭제 후 데이터 복구 불가',
    Download: '다운로드',
    Calendar: '달력',
    Title: '제목',
    Description: '설명',
    Location: '위치',
    'Start time': '시작 시간',
    'End time': '종료 시간',
    'Installed applications': '설치된 앱',
    'All applications': '모든 앱',
    Name: '이름',
    Version: '버전',
    Size: '크기',
    'Installation date': '설치 날짜',
    'Disable applications': '앱 비활성화',
    'Seted disable rules': '설치된 비활성화 규칙',
    'Undisabled applications': '비활성화되지 않은 앱',
    'Click the button below':
      '아래 버튼을 클릭하여 앱 비활성화 규칙을 생성하고 비활성화하고자 하는 앱을 선택하십시오.',
    'Set disable rules': '비활성화 규치 설정',
    'Disable permanently': '영구적으로 비활성화',
    'Disable ': '비활성화',
    'Custom disable time': '사용자 정의 비활성화 시간 설정',
    'Disable period': '비활성화 기간',
    From: '부터',
    To: '까지',
    Total: '전체',
    Weekly: '주간',
    Time: '시간',
    'Select the applications you want to disable':
      '비활성화하려는 앱을 선택하십시오',
    'Please select an application below': '아래 앱을 선택하세요',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      '삭제하시겠습니까? 이 규칙 아래의 비활성화된 앱은 더 이상 영향을 받지 않습니다.',
    'Disable options:': '비활성화 옵션:',
    'one Disable permanently':
      '1. 영구적으로 비활성화: 앱을 완전히 비활성화합니다. 귀하가 비활성화를 해제할 때까지 자녀는 비활성화된 앱을 사용할 수 없습니다.',
    'two Custom disable time':
      '2. 사용자 정의 비활성화 시간: 특정 시간 범위 내에서만 비활성화 규칙이 작동되며, 해당 시간 이외에는 귀하의 자녀가 앱을 정상적으로 사용할 수 있습니다.',
    'You can only choose one of the two setting options':
      '두 가지 설정 옵션 중 하나만 선택할 수 있습니다',
    complete: '완벽한',
    'Screen usage time': '스크린 사용 시간',
    'Screen Usage Time for xxx': 'xxx의 스크린 사용 시간',
    'Frequently used applications': '자주 쓰는 앱',
    Frequency: '주기',
    'Usage time': '사용 시간',
    'Screen disable': '스크린 비활성화',
    'Turn on the switch and set the screen lock duration.':
      '스위치를 켜고 화면 잠금 시간을 설정하십시오.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      '귀하의 자녀의 장치 화면은 비활성화되며 설정된 시간이 경과하면 다시 복원됩니다.',
    'Disable all screen activities': '모든 화면 활동 비활성화',
    'Set screen disable duration': '화면 비활성화 시간 설정',
    'You can lift the disablement at any time': '언제든지 비활성화 해제 가능',
    'Remaining screen disable time on the target device':
      '대상 장치의 남은 화면 비활성화된 시간',
    'Are you sure you want to lift the screen disablement now?':
      '지금 화면 비활성화를 해제하시겠습니까?',
    Yes: '네',
    'This account does not exist, please register first':
      '이 계정은 존재하지 않습니다. 먼저 등록해 주십시오',
    'Synchronization successful, no new data': '동기화 성공, 새 데이터 없음',
    'Location alert': '위치 알림',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'xxx 디바이스가 xxxx-xx-xx에 xx 지역에 들어왔습니다',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'xxx 디바이스가 xxxx-xx-xx에 xx 지역에 나갔습니다',
    'View more location records:': '더 많은 위치 기록 확인:',
    'Thank you!': '감사합니다!',
    'xxx Team': 'xxx팀',
    'When obtaining this location':
      '이 위치를 얻을 때 장치가 가상 위치 지정을 활성화하여 부정확할 수 있습니다.',
    'The demo mode does not support this feature, please bind the device first.':
      '데모 모드는 이 기능을 지원하지 않습니다. 먼저 장치를 바인딩하십시오.',
    'Location Tracking': '위치 추적',
    'General Application': '일반 앱',
    'App Activites Tracking': '앱 활동 추적',
    'Screen Time Tracking': '화면 시간 추적',
    'Social Application': '소셜 앱',
    'Subject:': '주제:',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud 사용된 스토리지',
    Online: '온라인',
    Offline: '오프라인',
    'Last known location': '마지막으로 알려진 위치',
    'Please bind your device': '장치를 바인딩해 주세요',
    'Log in iCloud to binding.':
      '대상 장치의 iCloud 계정에 로그인하여 바인딩을 완료하세요.',
    'Please enter your Apple ID': 'Apple ID를 입력하세요',
    'Please enter your password': '비밀번호를 입력하세요',
    'Your entered account or password is incorrect.':
      '입력한 계정 또는 비밀번호가 올바르지 않습니다.',
    'Ensure Apple ID and password':
      '대상 장치의 Apple ID 및 비밀번호를 입력하고 인증을 수행하여 바인딩을 완료하십시오.',
    'Forgot Apple ID or password?': 'Apple ID 또는 비밀번호를 잊어 버렸나요?',
    'Devices using the same iCloud account will be bound':
      '동일한 iCloud 계정을 사용하는 장치들이 바인딩됩니다.',
    'Two-Factor Authentication': '이중 인증',
    'A message has been sent':
      '인증 코드가 포함 된 메시지가 장치로 전송되었습니다. 계속하려면 인증 코드를 입력하세요.',
    'A message containing a verification code has been sent to xxx.':
      '메시지에 인증 코드가 포함 된 xxx로 전송되었습니다. 계속하려면 인증 코드를 입력하세요.',
    'Incorrect verification code': '잘못된 인증 코드',
    'Resend Verification Code': '인증 코드 다시 보내기',
    'Get SMS Verification Code': 'SMS 인증 코드 받기',
    'Please select a phone number to receive the verification code.':
      '인증 코드를 받을 전화 번호를 선택해 주세요.',
    'Please select': '선택해 주세요',
    'The current iCloud has expired':
      '현재 iCloud 계정 인증이 만료되었습니다. 데이터를 동기화하려면 iCloud 계정에 다시 로그인하십시오. 인',
    'current iCloud has expired':
      '현재 iCloud 계정 인증이 만료되었습니다. 데이터를 동기화하려면 iCloud 계정에 다시 로그인하십시오. 인증으로 이동 >>',
    'Go to verification >>': '인증하러 가기 >>',
    'Data synchronization failed, iCloud account expired':
      '데이터 동기화 실패하여 iCloud 계정 만료하기 때문에 홈페이지로 돌아가서 다시 확인하십시오.',
    'Created geofences': '생성된 지오펜스',
    'No geofences created': '지오펜스가 생성되지 않았습니다',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - 부모 관리 어플 가격 및 요금제',
    '30-Day Money Back Guarantee': '30일 환불 보장',
    'Professional Customer Support': '전문 고객 지원',
    'All Prices Exclusive of VAT': '모든 가격은 부가세를 포함하지 않음',
    '100% Secure': '100% 안전',
    'More Features to Keep Your Kids Safe Online':
      '더 많은 기능으로 자녀들을 온라인에서 안전하게 지켜보세요',
    Features: '기능',
    'Monitor App': '모니터링 앱',
    'Type of Data': '데이터 유형',
    'WhatsApp Chat': 'WhatsApp 채팅',
    'App Activity': '앱 활동',
    'App Blocker': '앱 차단기',
    'Monitor Social Apps': '소셜 앱 모니터링',
    'Location History': '위치 기록',
    'Location Reminder': '위치 알림',
    'Live Location': '실시간 위치',
    'Safe Search': '안전한 검색',
    'Web Filter': '웹 필터',
    'Browser History': '브라우저 기록',
    'Content Detection': '콘텐츠 감지',
    'All SMS': '모든 SMS',
    Photos: '사진',
    Video: '비디오',
    Calendar: '캘린더',
    Documents: '문서',
    'Record Screen': '화면 녹화',
    'Call History': '통화 기록',
    'Call Recording': '통화 녹음',
    Contacts: '연락처',
    Messages: '메시지',
    'Call Logs': '통화 기록',
    'Chat History': '채팅 기록',
    'The device has been bound to other account':
      '해당 장치는 이미 다른 계정이 연동되어 있으니 먼저 연동해제하시거나 지원 센터에 연락하여 연동을 해제하십시오.',
    Gallery: '갤러리',
    'Hidden or Deleted Albums': '숨겨진 또는 삭제된 앨범',
    'Recent Projects': '최근 프로젝트',
    'Personal Collection': '개인',
    'Recently Deleted': '최근 삭제',
    'Live Photos': '라이브 사진',
    'Exposure Photos': '노출 사진',
    'Panoramic Photos': '파노라마 사진',
    'Time-Lapse Photography': '타임 랩스 사진',
    'Slow Motion': '슬로모션',
    Portrait: '초상화',
    'Burst Mode': '연속 촬영',
    Screenshot: '스크린 샷',
    Motion: '다이내믹',
    Hide: '숨기기',
    'This iCloud account':
      '이 iCloud 계정은 어떤 기기와도 연결되어 있지 않습니다. 기기에서 로그인하십시오.',
    'Data synchronization':
      '데이터 동기화에 시간이 걸릴 수 있으므로 기다려 주세요.',
    'Location History1': '위치 기록',
    'Auto Renewal': '자동 갱신 및 언제든 취소',
    Month: '월',
    Quarter: '분기',
    Year: '년',
    Day: '일',
    'See Demo': '데모보기',
    'We Accept': '사용 가능한 결제수단',
  },
  famikeep220: {
    'Browser Monitoring': '브라우저 모니터링',
    'Browser History': '브라우저 기록',
    'Browser Bookmarks': '브라우저 북마크',
    'Serial Number': '일련 번호',
    'Website Restrictions': '웹 사이트 제한',
    'Restriction Rules': '제한 규칙',
    'Prohibited Access': '액세스 제한',
    'Access Warning': '액세스 경고',
    'Restricted Website Log': '제한 웹 사이트 로그',
    'Click to Add Restricted Website': '제한 웹 사이트 추가',
    'Restriction Method:': '제한 방식:',
    'Prohibit Access':
      '1. 접근 금지: 웹 사이트 액세스를 완전히 비활성화합니다. 웹 사이트 차단 규칙을 제거할 때까지, 자녀는 브라우저 내에서 제한된 웹 사이트에 액세스할 수 없습니다.',
    'Access Alerts':
      '2. 액세스 경보: 규칙 내에서 자녀가 웹 사이트를 방문하면 이메일 경보를 받고 경보 레코드를 생성합니다 (같은 웹 사이트에 대한 경보 이메일은 24시간 이내에 하나만 전송됩니다).',
    'the rule disabling access takes priority.':
      '동일한 웹 사이트가 두 가지 다른 규칙의 대상인 경우 액세스 비활성 규칙이 우선합니다.',
    'Set Restriction Rules': '제한 규칙 설정',
    'This option will block':
      '이 옵션을 선택하면 입력한 웹 사이트에 대한 액세스가 차단됩니다',
    'This option will allow':
      '이 옵션은 입력한 웹 사이트에 액세스를 허용합니다.',
    'For example':
      '예를 들어, example.com을 입력하면 모든 관련 웹 페이지 (예: www.example.com/page1.html)도 차단됩니다.',
    'If you enter the specific website page':
      '구체적인 웹 사이트 페이지 www.example.com/page1.html를 입력하면 해당 페이지만 차단됩니다.',
    'This option allows':
      '이 옵션은 입력한 웹 사이트에 대한 액세스를 허용하지만 해당 브라우징 레코드를 생성합니다.',
    'Send records by email': '레코드를 이메일로 전송',
    'When enabled':
      '활성화되면 입력한 웹 사이트에 액세스 할 때 등록된 이메일로 레코드를 보내드립니다.',
    'enter example':
      '예를 들어, example.com을 입력하면 해당 관련 웹페이지 (www.example.com/page1.html과 같은)도 경고됩니다.',
    'enter the specific webpage':
      '특정 웹페이지인 www.example.com/page1.html을 입력하면 이 페이지만 경고됩니다.',
    'Enter the website to be restricted': '제한하려는 웹 사이트를 입력하십시오',
    Bookmark: '즐겨찾기',
    'Bookmark list': '즐겨찾기 목록',
    'Total xx bookmarks': '총 xx 북마크',
    'Enter the URL': 'URL을 입력하세요 (예: youtube.com)',
    'Added URLs': '추가된 URL',
    Add: '추가',
    'Please add the restricted URL': '제한된 URL을 추가하세요',
    'Are you sure': '삭제하시겠습니까? 삭제 후에는 제한이 해제됩니다.',
    Video: '비디오',
    'Please note that videos':
      '비디오는 장치가 WIFI에 연결되어 있을 때만 업로드됩니다.',
    'Website access alert': '웹 사이트 접근 경보',
    'monitoring accessed':
      '모니터링 중인 xxx 장치가 xxxx-xx-xx에 xx에 액세스했습니다.',
    'View more records:': '더 많은 레코드 보기:',
    'Download and install the app.': '앱을 다운로드하고 설치하세요.',
    'After installation': '설치가 완료되면 xxx.com을 방문하여 앱을 열어주세요.',
    'Open immediately': '즉시 열기',
    'Unable to open System Service:': 'System Service를 열 수 없습니다:',
    'Please ensure this device has installed':
      '1. 이 장치에 System Service가 설치되어 있는지 확인해주세요 (System Service 다운로드를 위해 클릭)',
    'click download': '(System Service 다운로드를 위해 클릭)',
    'Copy the link':
      '2. 현재 웹페이지의 링크 주소를 복사하여 기본 브라우저에서 열기',
    'Sure close': '닫으시겠습니까? 닫으면 이메일 알림을 받을 수 없습니다.',
    Loading: '로딩 중',
    'Loading failed': '로딩 실패. 로컬로 보려면 다운로드하십시오.',
    'Select All Videos': '현재 페이지의 모든 동영상 선택',
    tips3: "해당 앱은 기기에서 'System Service'라는 이름으로 숨겨집니다.",
    'If the video cannot be loaded':
      '비디오가 장시간로드되지 않으면 다운로드하여 확인하십시오.',
  },
  famikeep222: {
    'Continue Google': 'Google로 계속',
    or: '또는',
    Continue: '계속',
    'legal guardian': '이 계정에 연결된 미성년자의 후견인입니다',
    'take around 10 mins': '안드로이드 기기 연결(약 10분 소요)',
    'Get the Android device': '1. 모니터 대상 안드로이드 기기 준비',
    'Install Companion App': '2. 도우미 앱 설치',
    'Login to set permissions': '3. 로그인하여 사용 권한 설정',
    twoContent1:
      '모니터 대상 안드로이드 기기에서 **에 접속하여 앱 다운로드 및 설치',
    'set up permissions':
      '도우미 앱에 계정으로 로그인하고 사용 권한을 설정한 후 모니터링을 시작할 수 있습니다. (앱에는 계정 비밀번호로만 로그인할 수 있습니다. 비밀번호가 설정되지 않은 경우 **를 통해 설정하세요).',
    'Click here': '여기 클릭',
    'continue collecting data':
      '5. 연결된 기기의 권한이 만료된 경우 다시 바인딩하여 데이터 수집을 계속할 수 있습니다.',
  },
  // 用户模块
  language: '언어',
  signIn: '로그인',
  email: '이메일',
  password: '비밀번호',
  sendCode: '코드 전송',

  // 登录
  signingIn: '로그인 중',
  noAccount: '계정이 없으신가요?',
  signUpNow: '지금 가입하기',
  forgetPassword: '비밀번호를 잊어버리셨나요?',

  // 注册
  signUp: '가입',
  createAccount: '계정 만들기',
  emVerificationCode: '이메일 확인 코드',
  creatingAccount: '계정 만드는 중...',
  alreadyHaveAccount: '계정이 이미 있습니까?',
  SignInUp: '로그인하기',
  send: '전송됨',
  sing: '초',
  byCreatingAnAccount: '계정을 만드는 것은',
  termsOfService: '서비스 약관',
  and: '및',
  privacyPolicy: '개인 정보 보호 정책',
  privacyEnd: '에 동의한다는 것을 의미합니다.',

  // 忘记密码
  retrievePassword: '비밀번호 찾기',
  backTo: '로그인 페이지로 돌아가기',
  newPassword: '새 비밀번호',
  resetPassword: '비밀번호 재설정',
  loginSucceeded: '로그인 성공!',
  logoutSucceeded: '로그아웃 성공!',

  // 用户模块异常提示
  passwordMmustBe8:
    '비밀번호는 8-20자여야 하며 문자와 숫자의 조합이어야 합니다.',
  pleaseEnterPassword: '비밀번호를 입력하십시오.',
  pleaseEnterEmailAddress: '이메일 주소를 입력하십시오.',
  invalidEmailFormat: '잘못된 이메일 형식입니다.',
  pleaseEnterVerifiCode: '확인 코드를 입력하십시오.',
  incorrectVerifiCode: '잘못된 확인 코드입니다.',

  // Toast文案
  verificationCodeHasBeen: '확인 코드가 전송되었습니다. 이메일을 확인하십시오.',
  pleaseReadAndAgree: '서비스 약관 및 개인 정보 보호 정책을 읽고 동의하십시오.',
  networkAbnormal: '네트워크 이상',
  emailRegistered: '가입되지 않은 이메일입니다. 먼저 가입하십시오.',
  passwordNotold: '새 비밀번호는 이전 비밀번호와 같을 수 없습니다.',
  invalidPasswordFormat: '잘못된 비밀번호 형식입니다.',
  theEmailHasRegistered: '이미 가입된 이메일입니다.',
  verificationCodeGetAgain: '확인 코드가 만료되었습니다. 다시 전송하십시오.',
  erificationCodeMatch: '이메일과 확인 코드가 일치하지 않습니다.',
  incorrectPasswordOrEmail:
    '비밀번호 혹은 이메일을 잘못 입력하였습니다. 다시 입력하십시오.',
  requestError: '요청 오류',
  pleaseTryAgainLater: '너무 자주 요청했습니다. 나중에 다시 시도하십시오.',
  accessDenied: '액세스 거부됨',
  requestErrorResourceNotFound: '요청 오류, 리소스를 찾을 수 없습니다.',
  serverError: '서버 오류',
  connectionServerFailed: '연결 서버 실패',
  requestTimedOut: '요청 시간 초과',
  networkAbnormaled: '네트워크 이상',
  serviceUnavailable: '서비스를 사용할 수 없음',
  httpVersioSupportTheRequest: 'http 버전이 요청을 지원하지 않습니다.',
  wrongPasswordTime:
    '비밀번호를 5번 잘못 입력하셨습니다. 계정이 일시적으로 잠겨 있어 로그인할 수 없습니다. 10분 후에 다시 시도해 주세요.',
};
