import mutations from './mutations';
import actions from './actions';

const headerStore = {
  state: {
    syncLoading: false,
  },
  mutations,
  actions,
};
export default headerStore;
