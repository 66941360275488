export const MS = {
  chat: {
    pictureText: '[Gambar]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Kad Kenalan]',
    location: '[Lokasi]',
    other: '[Lain-lain]',
    all: 'Semua',
    today: 'Hari ini',
    yesterday: 'Semalam',
    last7Days: '7 Hari Terakhir',
    last30Days: '30 Hari Terakhir',
    customRange: 'Pilih Julat Tersuai',
    cancel: 'Batal',
    apply: 'Mohon',
    pushNotify: 'Pemberitahuan Tolak',
    numbers: 'Nombor',
    emails: 'E-mel',
    outgoingCalls: 'Panggilan Keluar',
    incomingCalls: 'Panggilan Masuk',
    missedCalls: 'Panggilan tidak dijawab',
    answeredCalls: 'Panggilan Dijawab',
    noAnswer: 'Tiada jawapan',
    canceledCalls: 'Panggilan Dibatalkan',
    loadingText: 'Memuatkan, sila bersabar...',
    noMoreText: 'Tiada isi lagi',
    noInfoText: 'Tiada maklumat acara, sila semak semula kemudian.',
    noAddressText: 'Tiada maklumat buku alamat, sila semak semula kemudian.',
    noCallText: 'Tiada log panggilan, sila semak semula kemudian.',

    dateSelect: 'Plih tarikh',
    sentFile: 'Menghantar fail',
    sentAudio: 'Menghantar audio',
    unGetFile: 'Tidak dapat fail',
    unGetAudio: 'Tidak dapat audio',

    syncFailed: 'Penyegerakan data gagal!',
    syncSuccessed: 'Penyegerakan data berjaya!',
    unknownContact: 'Kenalan tidak dikenali',
    noSyncNotice1: 'Data tidak disegerakkan selama lebih daripada ',
    noSyncNotice2: '24 jam',
    noSyncNotice3:
      '. Sila pastikan bahawa telefon sasaran dihidupkan dan disambungkan ke Internet, dan APP "System Service" berjalan normal.',
    sync: 'Sync',
    startDate: 'Tarikh mula',
    endDate: 'Tarikh tamat',
    noSupportToast: 'Penapisan tarikh tidak disokong dalam Demo.',

    unknowVideo:
      'Video. Butiran tidak tersedia kerana dasar privasi Instagram.',
    unknowImage:
      'Gambar. Butiran tidak tersedia kerana dasar privasi Instagram.',
    unknowAudio:
      'Mesej suara. Butiran tidak tersedia kerana dasar privasi Instagram.',
    outgoingVoice: 'Panggilan audio keluar',
    incomingVoice: 'Panggilan audio masuk',
    outgoingVideo: 'Panggilan video keluar',
    incomingVideo: 'Panggilan video masuk',
  },
  user: {
    userProfile: 'Profil Pengguna',
    usesrname: 'Nama Pengguna:',
    edit: 'Sunting',
    email: 'E-mel:',
    permission: 'Maklumat Kebenaran',
    bounded: 'Peranti Terikat:',
    restDevice: 'Baki Peranti yang Tersedia:',
    expire: 'Tarikh Luput:',
    expired: '(Tamat Tempoh)',
    logout: 'Log Keluar',
    changename: 'Tukar Nama Pengguna',
    save: 'Jimat',
    inputname: 'Sila masukkan nama pengguna',
    nameNull: 'Format nama pengguna tidak normal, sila masukkan sekali lagi',
    networkError: 'Gagal menukar nama pengguna, sila semak rangkaian anda',
    nameSuccess: 'Nama pengguna berjaya ditukar',
    changeavatar: 'Tukar avatar',
    loading: 'Memuatkan…',
    changeAvatar: 'Ubah',
    supported:
      'Sokong format jpg, jpeg, png dan saiznya tidak boleh melebihi 2M.',
    maxsize: 'Saiz imej tidak boleh melebihi 2M',
    avatarError: 'Gagal menukar avatar, sila semak rangkaian anda',
    avatarSuccess: 'Avatar berjaya ditukar',
    imgError: 'Ralat imej',
    imageType: 'Jenis imej tidak disokong',
    nameEmpty: 'Nama pengguna tidak boleh kosong',
  },
  leftTab: {
    device: 'Peranti',
    unbound: 'Peranti Tidak Terikat',
    addDevice: 'Tambah peranti',
    dashboard: 'Papan pemuka',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Sila ikat peranti dahulu',
    payRemind: 'Sila beli dahulu',
    purchase: 'Beli Sekarang',
    unboundName: 'Ikat peranti sekarang',
    toBuy: 'membeli peralatan',
  },
  guide: {
    title: 'Sila ikat peranti anda',
    oneT: '1. Dapatkan Peranti Sasaran',
    oneContent:
      'Untuk memantau peranti, anda perlu mendapatkan peranti pemantauan sasaran selama kira-kira 10 minit.',
    twoT: '2. Muat turun & Pasang App',
    twoContent1: 'Masukkan',
    twoContent2:
      ' dalam penyemak imbas peranti sasaran, kemudian muat turun dan pasang app.',
    threeT: '3. Sediakan Kebenaran Peranti',
    threeContent:
      'Ikut arahan pada app untuk menyediakan kebenaran peranti. Selepas menetapkan, anda boleh memantau peranti.',
    tips1:
      "Untuk memastikan senyap, app itu akan menyamar sebagai perisian sistem bernama 'System Service'.",
    tips2:
      'Untuk memastikan inkognito, anda boleh memadamkan rekod penyemak imbas dan pakej pemasangan apl selepas memasang app itu dengan jayanya',
    tips3:
      "app itu akan dipaparkan sebagai 'System Service' pada desktop. Anda boleh memilih untuk menyembunyikannya dalam folder untuk kesan senyap yang lebih baik.",
    tips41:
      'Untuk memastikan anda boleh memasang app dan menyediakan kebenarannya dengan lebih cepat, anda boleh melihat ',
    tips42: 'terlebih dahulu untuk mengetahui cara melakukannya.',
    detail: 'tutorial terperinci',
    tips5:
      'Jika kebenaran peranti terikat telah tamat tempoh, anda boleh menukar pengikatan peranti dan mengikatnya dengan kebenaran untuk meneruskan pengumpulan data.',
    rebound: 'Klik untuk menukar pengikatan peranti',
  },
  dashboard: {
    contact: 'Kenalan Teratas dalam WhatsApp (dalam 7 hari)',
    call: 'Panggilan Teratas di WhatsApp',
    view: 'Lihat semua',
    account: 'Akaun',
    id: 'ID Akaun:',
    expire: 'Tarikh Luput:',
    bound: 'Peranti Terikat:',
    restDevice: 'Peranti yang Selebihnya:',
    target: 'Perihal Peranti Sasaran',
    brand: 'Jenama:',
    model: 'Model:',
    version: 'Versi Android:',
    battery: 'Bateri:',
    wifi: 'Wi-Fi:',
    appversion: 'Versi App:',
    update: 'Kemas Kini Terakhir:',
    blank: 'Tiada Aktiviti Terkini',
    unbindSuc: 'Unbind berjaya!',
    unbindFail: 'Gagal melepaskan ikatan!',
    ReboundSuc: 'Tukar pengikatan peranti berjaya!',
    ReboundFail: 'Tukar pengikatan peranti gagal!',
    noPlace: 'Tiada peranti untuk menukar pengikatan!',
    beExpOne: 'Kebenaran peranti anda akan tamat tempoh dalam masa',
    beExpTwo: 'hari',
    beExpThree:
      'dan data peranti yang dipantau tidak akan disegerakkan selepas tamat tempoh.',
    expiredOne:
      'Kebenaran peranti anda telah tamat tempoh. Data yang dikumpul sebelum ini akan dikosongkan dalam masa',
    expiredTwo:
      '. Selepas pembaharuan, data peranti yang dipantau akan terus disegerakkan.',
    renew: 'Baharui sekarang',

    insContact: 'Kenalan Teratas di Instagram (dalam 7 hari)',
    insCall: 'Panggilan Teratas di Instagram',
  },
  payPage: {
    title: 'Harga & Pelan ParentalGuard',
    dec: 'Memantau WhatsApp dengan ParentalGuard lebih mudah dari anda fikir!',
    bigSave: 'JIMAT BESAR',
    one: 'Pelan 1 Bulan',
    three: 'Pelan 3 Bulan',
    year: 'Pelan 1 Tahun',
    toBuy: 'Beli',
    auto: 'Pembaharuan Auto',
    cancelAny: 'Batal Bila-bila Masa',
    update: 'Kemas kini data secara auto setiap lima minit',
    collect: 'Kumpul semua perbualan',
    interaction: 'Pengalaman interaktif yang lebih baik',
    hide: 'Tersembunyi Mod',
  },
  pop: {
    changeBound: 'Tukar Pengikatan Peranti',
    reboundText1: 'Adakah anda pasti menukar pengikatan peranti',
    reboundText2:
      'dan mengikatnya pada kebenaran peranti yang tidak terikat untuk meneruskan pengumpulan data (Tarikh tamat tempoh:',
    reboundText3: ')?',
    reboundText4:
      'dan mengikatnya pada kebenaran peranti yang tidak terikat untuk meneruskan pengumpulan data',
    noRebound: 'Tiada kebenaran peranti tersedia. Sila beli dahulu.',
    goPay: 'Beli sekarang',
    reboundList: 'Peranti Terikat',
    reboundDec:
      'Kebenaran peranti berikut telah tamat tempoh. Pilih peranti dan ikatkannya pada kebenaran peranti yang tidak terikat pada masa ini untuk terus mengumpul data.',
    rebounding: 'Menukar pengikatan peranti...',
    reboundFail1: 'Gagal menukar pengikatan peranti',
    reboundFail2: '. Adakah anda mahu mencuba lagi?',
    tryAgain: 'Cuba semula',
    unbindTitle: 'Lepaskan Peranti',
    unbindText1: 'Adakah anda pasti untuk melepaskan peranti',
    unbindText2:
      '? Semua maklumat data yang dikumpul akan dikosongkan selepas tidak mengikat.',
    remind: 'Cepat Tamat Tempoh',
    expiredPay: 'Memperbaharui',
    cancel: 'Batal',
    yes: 'Ya',
    no: 'Tidak',
  },
  navTop: {
    langTitle: 'Tukar Bahasa',
  },
  famikeep200: {
    Calls: 'Panggilan',
    Messages: 'Mesej',
    Contacts: 'Kenalan',
    Photos: 'Foto',
    Applications: 'Aplikasi',
    'Compared to yesterday': 'Berbanding semalam',
    'All locations': 'Semua lokasi',
    'Weekly average usage time': 'Purata masa penggunaan mingguan',
    'Floating comparison with last week':
      'Perbandingan terapung dengan minggu lepas',
    Average: 'Purata',
    Mon: 'Isn',
    Tue: 'Sel',
    Wed: 'Rab',
    Thu: 'Kha',
    Fri: 'Jum',
    Sat: 'Sab',
    Sun: 'Aha',
    'Most used applications': 'Aplikasi yang paling banyak digunakan',
    Geolocation: 'Geolokasi',
    'Last updated': 'Dikemas kini',
    'Geolocation permission':
      "Kebenaran geolokasi untuk apl 'System Service' dilumpuhkan, gagal untuk mengambil lokasi",
    Geofence: 'Geofence',
    'Create a geofence':
      'Cipta geofence untuk menerima makluman melalui e-mel apabila anak anda masuk/keluar dari kawasan yang ditentukan',
    'enters/exits': 'masuk/keluar dari kawasan yang ditentukan',
    'Create a new geofence': 'Buat geofence baru',
    'Geofence name': 'Nama Geofence',
    'Set geofence location': 'Tetapkan lokasi geofence',
    'Set radius': 'Tetapkan jejari',
    'Set monitoring time': 'Tetapkan masa pemantauan',
    'please choose': 'sila pilih',
    Daily: 'Setiap hari',
    'Every Monday': 'Setiap Isnin',
    'Every Tuesday': 'Setiap Selasa',
    'Every Wednesday': 'Setiap Rabu',
    'Every Thursday': 'Setiap Khamis',
    'Every Friday': 'Setiap Jumaat',
    'Every Saturday': 'Setiap Sabtu',
    'Every Sunday': 'Setiap Ahad',
    'Send alerts via email': 'Hantar makluman melalui e-mel',
    'We will send alert emails to the registered email':
      'Kami akan menghantar e-mel makluman kepada e-mel yang didaftarkan',
    Save: 'Jimat',
    'Please enter a geofence name': 'Sila masukkan nama geofence',
    'Please select a geofence location': 'Sila pilih lokasi geofence',
    Entered: 'Masuk',
    Exited: 'Keluar',
    Edit: 'Sunting',
    Delete: 'Padam',
    'Are you sure you want to delete':
      'Adakah anda pasti mahu memadamkan geofence xxx? Data pemantauan yang sepadan juga akan dipadamkan',
    'Are you sure you want to close':
      'Adakah anda pasti mahu menutup? Anda tidak akan menerima makluman e-mel apabila memasuki/meninggalkan geofence',
    'Call history': 'Sejarah panggilan',
    Status: 'Status',
    Number: 'Nombor',
    Duration: 'Tempoh',
    Date: 'Tarikh',
    incoming: 'Panggilan masuk',
    outgoing: 'Panggilan keluar',
    missed: 'Panggilan tidak dijawab',
    'no answered': 'Tiada jawapan',
    Address: 'Alamat',
    'Added time': 'Menambah masa',
    'Sort by name': 'Isih mengikut nama',
    'Sort by time': 'Susun mengikut masa',
    'Please note that photos':
      'Sila ambil perhatian bahawa foto akan dimuat naik hanya apabila peranti disambungkan ke Wi-Fi',
    Album: 'Album',
    'Select all photos on the current page':
      'Pilih semua foto pada halaman semasa',
    'Previous page': 'Halaman sebelumnya',
    'Next page': 'Halaman seterusnya',
    Back: 'Belakang',
    'confirm to delete':
      'mengesahkan untuk memadam? Data tidak boleh dipulihkan selepas pemadaman',
    Download: 'muat turun',
    Calendar: 'Kalendar',
    Title: 'Tajuk',
    Description: 'Penerangan',
    Location: 'Lokasi',
    'Start time': 'Masa mula',
    'End time': 'Masa tamat',
    'Installed applications': 'Aplikasi yang dipasang',
    'All applications': 'Semua aplikasi',
    Name: 'Nama',
    Version: 'Versi',
    Size: 'Saiz',
    'Installation date': 'Tarikh pemasangan',
    'Disable applications': 'Lumpuhkan aplikasi',
    'Seted disable rules': 'Tetapkan peraturan lumpuhkan',
    'Undisabled applications': 'Aplikasi yang tidak dilumpuhkan',
    'Click the button below':
      'Klik butang di bawah untuk membuat peraturan lumpuhkan app dan pilih app yang ingin anda lumpuhkan',
    'Set disable rules': 'Tetapkan peraturan lumpuhkan',
    'Disable permanently': 'Lumpuhkan secara kekal',
    'Disable ': 'Lumpuhkan',
    'Custom disable time': 'Masa lumpuh tersuai',
    'Disable period': 'Lumpuhkan tempoh',
    From: 'Dari',
    To: 'Sehingga',
    Total: 'Jumlah',
    Weekly: 'setiap minggu',
    Time: 'masa',
    'Select the applications you want to disable':
      'Pilih aplikasi yang ingin anda lumpuhkan',
    'Please select an application below': 'Sila pilih permohonan di bawah',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Adakah anda pasti ingin memadam? App yang dilumpuhkan di bawah peraturan ini tidak akan terjejas lagi',
    'Disable options:': 'Lumpuhkan pilihan:',
    'one Disable permanently':
      '1. Lumpuhkan secara kekal: Lumpuhkan sepenuhnya aplikasi. Anak anda tidak akan dapat menggunakan app yang dilumpuhkan sehingga anda membatalkannya.',
    'two Custom disable time':
      '2. Masa lumpuh tersuai: Hanya mencetuskan peraturan nyahdaya semasa julat masa tertentu dan anak anda boleh menggunakan apl tersebut secara normal di luar masa tersebut.',
    'You can only choose one of the two setting options':
      'Anda hanya boleh memilih satu daripada dua pilihan tetapan',
    complete: 'lengkap',
    'Screen usage time': 'Masa penggunaan skrin',
    'Screen Usage Time for xxx': 'Masa Penggunaan Skrin pada xxx',
    'Frequently used applications': 'Aplikasi yang kerap digunakan',
    Frequency: 'Kekerapan',
    'Usage time': 'Masa penggunaan',
    'Screen disable': 'Lumpuhkan skrin',
    'Turn on the switch and set the screen lock duration.':
      'Hidupkan suis dan tetapkan tempoh kunci skrin.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Skrin peranti anak anda akan dilumpuhkan dan akan dipulihkan selepas masa yang ditetapkan digunakan.',
    'Disable all screen activities': 'Lumpuhkan semua aktiviti skrin',
    'Set screen disable duration': 'Tetapkan tempoh nyahdayakan skrin',
    'You can lift the disablement at any time':
      'Anda boleh mengangkat hilang upaya pada bila-bila masa',
    'Remaining screen disable time on the target device':
      'Baki skrin melumpuhkan masa pada peranti sasaran',
    'Are you sure you want to lift the screen disablement now?':
      'Adakah anda pasti mahu mengangkat kelumpuhan skrin sekarang?',
    Yes: 'Ya',
    'This account does not exist, please register first':
      'Akaun ini tidak wujud, sila daftar dahulu',
    'Synchronization successful, no new data':
      'Penyegerakan berjaya, tiada data baharu',
    'Location alert': 'Makluman lokasi',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Peranti xxx yang anda pantau memasukkan xx pada xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Peranti xxx yang anda pantau telah keluar dari xx pada xxxx-xx-xx',
    'View more location records:': 'Lihat lebih banyak rekod lokasi:',
    'Thank you!': 'Terima kasih!',
    'xxx Team': 'Pasukan xxx',
    'When obtaining this location':
      'Apabila mendapatkan lokasi ini, peranti mengaktifkan kedudukan maya, yang mungkin membawa kepada ketidaktepatan.',
    'The demo mode does not support this feature, please bind the device first.':
      'Mod demo tidak menyokong ciri ini, sila ikat peranti dahulu.',
    'Location Tracking': 'Penjejak Lokasi',
    'General Application': 'Aplikasi Am',
    'App Activites Tracking': 'Penjejak Aktiviti Aplikasi',
    'Screen Time Tracking': 'Penjejak Masa Skrin',
    'Social Application': 'Aplikasi Sosial',
    'Subject:': 'Subjek:',
  },
  famikeep210: {
    'iCloud used storage': 'Penyimpanan iCloud yang digunakan',
    Online: 'Dalam Talian',
    Offline: 'Luar Talian',
    'Last known location': 'Lokasi terakhir yang diketahui',
    'Please bind your device': 'Sila mengikat peranti anda',
    'Log in iCloud to binding.':
      'Log masuk ke akaun iCloud peranti sasaran untuk menyelesaikan pengikatan.',
    'Please enter your Apple ID': 'Sila masukkan Apple ID anda',
    'Please enter your password': 'Sila masukkan kata laluan anda',
    'Your entered account or password is incorrect.':
      'Akaun atau kata laluan yang anda masukkan tidak betul.',
    'Ensure Apple ID and password':
      'Pastikan anda memasukkan Apple ID dan kata laluan peranti sasaran, mengautentikasi, dan menyelesaikan pengikatan.',
    'Forgot Apple ID or password?': 'Lupa Apple ID atau kata laluan?',
    'Devices using the same iCloud account will be bound':
      'Peranti yang menggunakan akaun iCloud yang sama akan diikatkan.',
    'Two-Factor Authentication': 'Pengesahan Dua Faktor',
    'A message has been sent':
      'Satu mesej yang mengandungi kod pengesahan telah dihantar ke peranti anda. Sila masukkan kod pengesahan untuk teruskan.',
    'A message containing a verification code has been sent to xxx.':
      'Satu mesej yang mengandungi kod pengesahan telah dihantar ke xxx. Sila masukkan kod pengesahan untuk teruskan.',
    'Incorrect verification code': 'Kod pengesahan tidak betul',
    'Resend Verification Code': 'Hantar Semula Kod Pengesahan',
    'Get SMS Verification Code': 'Dapatkan Kod Pengesahan SMS',
    'Please select a phone number to receive the verification code.':
      'Sila pilih nombor telefon untuk menerima kod pengesahan.',
    'Please select': 'Sila pilih',
    'The current iCloud has expired':
      'Pengesahan akaun iCloud semasa telah tamat tempoh. Sila log masuk ke akaun iCloud anda sekali lagi untuk menyelaraskan data. ',
    'current iCloud has expired':
      'Pengesahan akaun iCloud semasa telah tamat tempoh. Sila log masuk ke akaun iCloud anda sekali lagi untuk menyelaraskan data. Pergi ke pengesahan >>',
    'Go to verification >>': 'Pergi ke pengesahan >>',
    'Data synchronization failed, iCloud account expired':
      'Segera kegagalan penyegerakan data, akaun iCloud tamat tempoh, sila kembali ke laman utama dan sahkan semula.',
    'Created geofences': 'Geofen pembolehubah yang dibuat',
    'No geofences created': 'Tiada geofen yang dicipta',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Harga & Rancangan Aplikasi Kawalan Ibu Bapa',
    '30-Day Money Back Guarantee': 'Jaminan Wang Kembali 30 Hari',
    'Professional Customer Support': 'Sokongan Pelanggan Profesional',
    'All Prices Exclusive of VAT': 'Harga Tidak Termasuk Cukai',
    '100% Secure': '100% Selamat',
    'More Features to Keep Your Kids Safe Online':
      'Lebih Banyak Ciri untuk Menjaga Anak-anak Anda Selamat Dalam Talian',
    Features: 'Ciri-Ciri',
    'Monitor App': 'Aplikasi Monitor',
    'Type of Data': 'Jenis Data',
    'WhatsApp Chat': 'WhatsApp 聊天',
    'App Activity': 'App 活动',
    'App Blocker': 'App 阻止器',
    'Monitor Social Apps': 'Pemantauan Aplikasi Sosial',
    'Location History': 'Sejarah Lokasi',
    'Location Reminder': 'Peringatan Lokasi',
    'Live Location': 'Lokasi Langsung',
    'Safe Search': 'Carian Selamat',
    'Web Filter': 'Penapis Web',
    'Browser History': 'Sejarah Pelayar',
    'Content Detection': 'Pengesanan Kandungan',
    'All SMS': 'Semua SMS',
    Photos: 'Gambar',
    Video: 'Video',
    Calendar: 'Kalendar',
    Documents: 'Dokumen',
    'Record Screen': 'Skrin',
    'Call History': 'Sejarah Panggilan',
    'Call Recording': 'Rakaman Panggilan',
    Contacts: 'Kenalan',
    Messages: 'Mesej',
    'Call Logs': 'Log Panggilan',
    'Chat History': 'Sejarah Chat',
    'The device has been bound to other account':
      'Peranti telah diikat ke akaun lain, sila buka ikatannya dahulu atau hubungi perkhidmatan pelanggan untuk menyahikatnya.',
    Gallery: 'Galeri',
    'Hidden or Deleted Albums': 'Album Tersembunyi atau Dihapus',
    'Recent Projects': 'Projek Terkini',
    'Personal Collection': 'Peribadi',
    'Recently Deleted': 'Baru-baru ini dihapus',
    'Live Photos': 'Gambar Langsung',
    'Exposure Photos': 'Gambar Pendedahan',
    'Panoramic Photos': 'Gambar Pano',
    'Time-Lapse Photography': 'Gambar Lapse Masa',
    'Slow Motion': 'Perlahan',
    Portrait: 'Potret',
    'Burst Mode': 'Rakaman Berterusan',
    Screenshot: 'Tangkapan Skrin',
    Motion: 'Dinamik',
    Hide: 'Sembunyi',
    'This iCloud account':
      'Akaun iCloud ini tidak disambungkan kepada mana-mana peranti. Sila log masuk pada peranti.',
    'Data synchronization':
      'Sinkronisasi data mungkin memerlukan sedikit masa, sila bersabar.',
    'Location History1': 'Sejarah Lokasi',
    'Auto Renewal': 'Pemulihan Auto & Batal Bila-Bila Masa',
    Month: 'Bulan',
    Quarter: 'Suku Tahun',
    Year: 'Tahun',
    Day: 'Hari',
    'See Demo': 'Lihat Demo',
    'We Accept': 'Kami menerima',
  },
  famikeep220: {
    'Browser Monitoring': 'Pemantauan Pelayar',
    'Browser History': 'Sejarah Pelayar',
    'Browser Bookmarks': 'Penanda Pelayar',
    'Serial Number': 'Nombor Siri',
    'Website Restrictions': 'Sekatan Laman Web',
    'Restriction Rules': 'Peraturan Sekatan',
    'Prohibited Access': 'Akses Terlarang',
    'Access Warning': 'Amaran Akses',
    'Restricted Website Log': 'Log Laman Web Terhad',
    'Click to Add Restricted Website': 'Klik untuk Menambah Laman Web Terhad',
    'Restriction Method:': 'Cara Kawalan:',
    'Prohibit Access':
      '1. Akses Terhad: 1. Terhadkan akses ke laman web. 1. Akses Terhad: 1. Terhadkan akses ke laman web.',
    'Access Alerts':
      '2. Amaran Akses: Apabila anak anda mengunjungi laman web dalam peraturan, anda akan menerima amaran e-mel dan menghasilkan rekod amaran (hanya satu e-mel amaran akan dihantar dalam masa 24 jam untuk laman web yang sama).',
    'the rule disabling access takes priority.':
      'Jika laman web yang sama tunduk pada dua peraturan yang berbeza, peraturan yang melarang akses mempunyai keutamaan.',
    'Set Restriction Rules': 'Tetapkan Peraturan Sekatan',
    'This option will block':
      'Pilihan ini akan memblokir akses ke laman web yang dimasukkan',
    'This option will allow':
      'Pilihan ini akan membolehkan akses ke laman web yang dimasukkan.',
    'For example':
      'Sebagai contoh, jika anda memasukkan example.com, semua laman web yang berkaitan (contohnya www.example.com/page1.html) juga akan disekat.',
    'If you enter the specific website page':
      'Jika anda memasukkan halaman laman web yang khusus www.example.com/page1.html, hanya halaman itu yang akan disekat.',
    'This option allows':
      'Pilihan ini membenarkan akses ke laman web yang dimasukkan tetapi akan menjana rekod melayari yang berkaitan.',
    'Send records by email': 'Hantar rekod melalui e-mel',
    'When enabled':
      'Apabila diaktifkan, kami akan menghantar rekod ke alamat emel berdaftar semasa mengakses laman web yang dimasukkan.',
    'enter example':
      'Contohnya, jika anda memasukkan example.com, semua halaman web yang berkaitan (seperti www.example.com/page1.html) juga akan diingatkan.',
    'enter the specific webpage':
      'Jika anda memasukkan halaman web yang spesifik www.example.com/page1.html, hanya satu halaman ini yang akan diingatkan.',
    'Enter the website to be restricted':
      'Masukkan laman web yang perlu dihadkan',
    Bookmark: 'Tandakan',
    'Bookmark list': 'Senarai Tandakan',
    'Total xx bookmarks': 'Jumlah xx penanda',
    'Enter the URL': 'Masukkan URL (contohnya, youtube.com)',
    'Added URLs': 'URL yang ditambahkan',
    Add: 'Tambah',
    'Please add the restricted URL': 'Sila tambah URL terhad',
    'Are you sure':
      'Adakah anda pasti mahu padam? Sekatan akan dikeluarkan selepas pemadaman.',
    Video: 'Video',
    'Please note that videos':
      'Sila ambil perhatian bahawa video hanya akan dimuat naik apabila peranti disambungkan ke WIFI.',
    'Website access alert': 'Amaran akses laman web',
    'monitoring accessed':
      'Peranti xxx yang anda pantau telah mengakses xx pada xxxx-xx-xx.',
    'View more records:': 'Lihat lebih banyak rekod:',
    'Download and install the app.': 'Muat turun dan pasang aplikasi.',
    'After installation':
      'Selepas pemasangan, sila buka aplikasi dengan melayari xxx.com.',
    'Open immediately': 'Buka segera',
    'Unable to open System Service:': 'Tidak dapat membuka System Service:',
    'Please ensure this device has installed':
      '1. Sila pastikan bahawa peranti ini telah memasang System Service (klik untuk memuat turun System Service)',
    'click download': '(klik untuk memuat turun System Service)',
    'Copy the link':
      '2. Salin alamat pautan laman web semasa dan buka dalam pelayar terbina dalam',
    'Sure close':
      'Adakah anda pasti mahu menutup? Anda tidak akan menerima amaran e-mel selepas menutup.',
    Loading: 'Sedang Memuat',
    'Loading failed': 'Gagal memuat. Sila muat turun untuk paparan tempatan.',
    'Select All Videos': 'Pilih Semua Video di Halaman Ini',
    tips3:
      "Aplikasi ini akan tersembunyi di perangkat di bawah nama 'System Service'.",
    'If the video cannot be loaded':
      'Jika video tidak dapat dimuat untuk jangka waktu yang lama, sila muat turun dan lihat.',
  },
  famikeep222: {
    'Continue Google': 'Teruskan dengan Google',
    or: 'atau',
    Continue: 'Teruskan',
    'legal guardian':
      'Saya orang dewasa dan hanya akan menambahkan anak bawah umur saya ke akaun ini',
    'take around 10 mins':
      'Sila ikat peranti Android (mengambil masa lebih kurang 10 minit)',
    'Get the Android device': '1. Dapatkan peranti Android yang perlu dipantau',
    'Install Companion App': '2. Pasang Aplikasi Pembantu',
    'Login to set permissions': '3. Log masuk untuk tetapkan kebenaran',
    twoContent1:
      'Sila akses ** daripada peranti Android yang dipantau untuk memuat turun dan memasang aplikasi',
    'set up permissions':
      'Sila log masuk ke akaun anda dalam Aplikasi Pembantu dan tetapkan kebenaran, kemudian anda boleh memulakan pemantauan. (Sila ambil perhatian bahawa aplikasi hanya boleh log masuk melalui kata laluan akaun, sila ** jika anda belum menetapkan kata laluan).',
    'Click here': 'Klik di sini',
    'continue collecting data':
      '5. Jika kebenaran peranti terikat telah tamat tempoh, anda boleh terus mengumpul data dengan menukar pengikatan.',
  },
  // 用户模块
  language: 'Bahasa',
  signIn: 'Log Masuk',
  email: 'E-mel',
  password: 'Kata laluan',
  sendCode: 'Hantar Kod',

  // 登录
  signingIn: 'Log Masuk',
  noAccount: 'Tiada akaun?',
  signUpNow: 'Daftar sekarang.',
  forgetPassword: 'Lupa kata laluan anda?',

  // 注册
  signUp: 'Daftar',
  createAccount: 'Buat akaun',
  emVerificationCode: 'Kod Pengesahan',
  creatingAccount: 'Membuat  Akaun…',
  alreadyHaveAccount: 'Sudah mempunyai akaun?',
  SignInUp: 'Log Masuk.',
  send: 'Dihantar',
  sing: 'saat',
  byCreatingAnAccount: 'Dengan membuat akaun, anda bersetuju menerima ',
  termsOfService: 'Syarat Perkhidmatan',
  and: ' dan ',
  privacyPolicy: 'Dasar Privasi.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Dapatkan kata laluan',
  backTo: 'Kembali ke Log masuk',
  newPassword: 'Kata laluan baru',
  resetPassword: 'Menetapkan kata laluan',
  loginSucceeded: 'Log masuk berjaya!',
  logoutSucceeded: 'Log keluar berjaya!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Kata laluan mestilah 8-20 aksara dan gabungan huruf dan nombor',
  pleaseEnterPassword: 'Sila masukkan kata laluan akaun anda',
  pleaseEnterEmailAddress: 'Sila masukkan alamat e-mel anda',
  invalidEmailFormat: 'Format e-mel tidak sah',
  pleaseEnterVerifiCode: 'Sila masukkan kod pengesahan',
  incorrectVerifiCode: 'Kod pengesahan yang salah',

  // Toast文案
  verificationCodeHasBeen:
    'Kod pengesahan telah dihantar, sila semak e-mel anda',
  pleaseReadAndAgree:
    'Sila baca dan bersetuju menerima Syarat Perkhidmatan dan Dasar Privasi.',
  networkAbnormal: 'Rangkaian tidak normal',
  emailRegistered: 'Email belum didaftarkan, sila daftar dahulu',
  passwordNotold:
    'Kata laluan baru anda tidak boleh sama dengan kata laluan lama',
  invalidPasswordFormat: 'Format kata laluan tidak sah',
  theEmailHasRegistered: 'E-mel telah didaftarkan',
  verificationCodeGetAgain:
    'Kod pengesahan telah tamat tempoh, sila dapatkannya semula',
  erificationCodeMatch: 'E-mel dan kod pengesahan tidak sepadan',
  incorrectPasswordOrEmail:
    'Kata laluan atau e-mel yang salah, sila masukkan sekali lagi',
  requestError: 'Ralat Permintaan',
  pleaseTryAgainLater: 'Terlalu banyak permintaan. Sila cuba sebentar lagi',
  accessDenied: 'Akses Dinafikan',
  requestErrorResourceNotFound: 'Ralat permintaan, sumber tidak ditemui.',
  serverError: 'Ralat Pelayan',
  connectionServerFailed: 'Pelayan sambungan gagal',
  requestTimedOut: 'Permintaan tamat masa',
  networkAbnormaled: 'Rangkaian tidak normal',
  serviceUnavailable: 'Perkhidmatan tidak tersedia',
  httpVersioSupportTheRequest: 'Versi http tidak menyokong permintaan itu',
  wrongPasswordTime:
    'Anda telah memasukkan kata laluan yang salah sebanyak 5 kali. Akaun anda dikunci buat sementara waktu dan tidak boleh dilog masuk. Sila cuba lagi dalam masa 10 minit.',
};
