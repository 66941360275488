import { createStore } from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';
import headerStore from './header/index';
import generalAppStore from './generalApp/index';

interface currentDeviceInfo {
  // android
  name?: string;
  model?: string;
  id?: string;
  android_version?: string;
  apk_version?: string;
  battery?: string;
  brand?: string;
  last_update_at?: any;
  wifi?: string;
  expire_at?: any;
  is_expired?: boolean;

  // ios需要的
  apple_id?: string;
  is_active?: boolean;
  storage_usage?: any;
}

export default createStore({
  state: {
    sidebarMenu: [] /** 导航菜单 */,
    currentMenu: '' /** 当前active导航菜单 */,
    /* 导航菜单是否折叠 */
    isSidebarNavCollapse: false,
    /* 面包屑导航列表 */
    crumbList: [],
    isShowUpgradeDialog: false, // 是否显示升级弹窗
    isShowPaidDialog: false, // 是否显示正在付款弹窗
    myPermission: [], // 我的路由
    modalStatus: '1', // 0:啥框都不显示， 1： 登录， 2. 注册，  3. 重置密码
    loginStatus: false, // false : 未登录  true ： 已登录
    loginTime: 0, // 登录的响应时长
    isLoginExpired: false, // 是否登录失效
    expired: false, // 判断用户权限是否过期
    // 当前选择设备
    currentDevice: 0,
    currentDeviceId: null,
    // 当前展示设备信息
    currentDeviceInfo: {} as currentDeviceInfo,
    // 个人信息
    userInfo: {
      id: '',
      email: '',
      name: '',
      profile_picture_url: '',
    },
    // 权限信息
    subscripts: {
      begins_at: '',
      expires_at: '',
      total_effective_pit: 0,
      effective_device_num: 0,
      effective_icloud_num: 0,
    },
    // 设备信息(可以在登陆的时候从后端获取)
    deviceInfo: [],
    // 过期设备列表
    expiredDevices: [],
    // 已绑定设备
    boundedDevice: [],
    singleDeviceShow: true,
    overLoading: false, // loading加载
    activeTabName: 'first', // 记录左侧tab栏的当前激活的tabItem名称
    clickItemKey: null, // 记录左侧tab栏chat列表中点击的item的key,
    clickMailItemDetail: {}, // 记录左侧tab栏mail列表中当前被点击的item数据
    clickPhoneItemDetail: {}, // 记录左侧tab栏phone通话列表中当前被点击的item数据
    datePickerValue: null, // 记录日期时间选择器的值
    menuList2: [], // 左侧菜单栏列表
    // 当前选择菜单
    defaultMenu: 'home',
    // boundImg: false, // 控制当前设备的图片是已绑定还是未绑定
    isDemo: 0, // 是否是demo演示模式
    source: null, // 用于取消上一次的请求
    hashValue: '', // 存储当前hash值
    currentLang: '', // 持久化存储当前选中的语言
    downloadNumber: 0, // 累计统计下载文件总量次数
    sessionClickItem: null, // 会话列表当前激活项
    payFrom: 1, // 从哪一个途径访问的购买页（0代表purchase，1代表addDevice,2代表续费相关入口）
    perStatus: 'User_Buy_Permission', // 用于埋点区分用户购买权限前的权限状态
    payMenu: 'Month', // 记录用户的购买套餐选择，供埋点使用
    // isClick: false,
    clickGoPay: false, // 记录是否是点击了gotopay而出现的addDevice,
    phoneSource: null, // 用于频繁请求时，取消上一次的通讯录列表请求
    calenderSource: null, // 用于频繁请求时，取消上一次的日历列表请求
    callLogsSource: null, // 用于频繁请求时，取消上一次的通话记录列表请求
    appListSource: null, // 用于频繁请求时，取消上一次的应用列表请求
    targetDeviceLength: false, // 标识获取的当前设备信息是否有值
    wsObj: null, // ws实例
    whatsappFrom: '', // 用户记录进入whatsapp模块的埋点
    messageToastType: 0, // 通知弹窗类型 0:什么都不显示  1: 24h更新通知
    isClickUpdateBtn: false, // 是否是点击了同步按钮
    beExpired: 0, // 记录快过期的倒计时
    isExpired: 0, // 已过期30天内倒计时
    clickedArray: [], // 记录当前设备当日是否点击过
    autoRenew: false, // 标识用户是否开启了自动续费
    deviceInfoSource: null, // 用于取消频繁的获取设备信息的接口请求
    currentMonitorApp: '', // 记录当前被浏览的监控app名称，用于埋点
    appFrom: '', // 记录用户从哪个地方点击进入被监控的app聊天模块的埋点
    isVibe: 0, // 0 == demo  1 == 主程序  长期存在，谨防干掉
    chatListSource: null, // 用于频繁请求时，取消上一次的会话列表请求
    photoInfoSource: null, //用于取消频繁的获取图片的接口请求
    deviceType: 0, //设备类型 0：默认安卓 1：ios
    currentAppleId: null, // 当前苹果id
    isShowBack: false, // 是否在登录页面显示back返回按钮
    isExpiredLogin: false, // 是否需要再次验证
    accessToken: '', //mapbox的token
    frontPath: '', // 进入iosLogin之前的路由
    pendingRequest: [], //正在请求的接口
    equipmentType: 0, // 0表示未web端，1表示为移动端，用来进行移动端兼容,
    selectMeal: 0, // 购买前选择的是ios还是android。0为android，1为ios
    isShowMore750: true, //屏幕大小是否大于等于750
  },
  mutations,
  actions,
  modules: {
    headerStore,
    generalAppStore,
  },
  // 提交代码时记得取消注释
  plugins: [
    // Vuex数据状态持久化
    createPersistedState({
      // key: 'vuex', // 存储是的名字
      storage: window.localStorage,
      reducer(val) {
        return {
          modalStatus: val.modalStatus,
          // 只储存state中的token
          defaultMenu: val.defaultMenu,
          deviceInfo: val.deviceInfo,
          expiredDevices: val.expiredDevices,
          userInfo: val.userInfo,
          subscripts: val.subscripts,
          currentDevice: val.currentDevice,
          currentDeviceId: val.currentDeviceId,
          currentAppleId: val.currentAppleId,
          currentDeviceInfo: val.currentDeviceInfo,
          currentMenu: val.currentMenu,
          loginTime: val.loginTime,
          // activeTabName: val.activeTabName,
          // clickItemKey: val.clickItemKey,
          menuList2: val.menuList2,
          singleDeviceShow: val.singleDeviceShow,
          isDemo: val.isDemo,
          boundedDevice: val.boundedDevice,
          hashValue: val.hashValue,
          currentLang: val.currentLang,
          downloadNumber: val.downloadNumber,
          payFrom: val.payFrom,
          perStatus: val.perStatus,
          payMenu: val.payMenu,
          // boundImg: val.boundImg,
          clickGoPay: val.clickGoPay,
          expired: val.expired,
          targetDeviceLength: val.targetDeviceLength,
          // clickMailItemDetail: val.clickMailItemDetail,
          // sessionClickItem: val.sessionClickItem,
          wsObj: val.wsObj,
          // whatsappFrom: val.whatsappFrom,
          isClickUpdateBtn: val.isClickUpdateBtn,
          messageToastType: val.messageToastType,
          beExpired: val.beExpired,
          isExpired: val.isExpired,
          clickedArray: val.clickedArray,
          autoRenew: val.autoRenew,
          isVibe: val.isVibe, //  长期存在，谨防干掉
          // @ts-ignore
          headerStore: val.headerStore,
          // @ts-ignore
          generalAppStore: val.generalAppStore,
          deviceType: val.deviceType,
          isShowBack: val.isShowBack,
          isExpiredLogin: val.isExpiredLogin,
          accessToken: val.accessToken,
        };
      },
    }),
  ],
});
