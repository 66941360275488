import axios from '@/utils/request';
import emitter from '@/utils/eventBus';

// export const upApi = (data: any) => {
//   return axios({
//     url: "/users",
//     method: "post",
//     data,
//   });
// };

// 用户登录
export function loginApi(data: any) {
  return axios({
    url: '/v1/sessions',
    method: 'post',
    data,
  });
}

// 邮箱验证码
export function emailcode(data: any) {
  return axios({
    url: '/v1/email-code',
    method: 'post',
    data,
  });
}

// 注册
export function register(data: any) {
  return axios({
    url: '/v1/users',
    method: 'post',
    data,
  });
}

// 重置密码验证
export function resetPassword(data: any) {
  return axios({
    url: '/v1/users/password-reset',
    method: 'post',
    data,
  });
}

// 登出  ok
export function signout() {
  return axios({
    url: '/v1/sessions',
    method: 'delete',
  });
}

// 获取用户信息
export function userInfo(data: any) {
  return axios({
    url: `/v1/users/${localStorage.getItem('user_id')}`,
    method: 'get',
    data,
  });
}

// 修改用户信息
export function changeUserInfo(data: any) {
  return axios({
    url: `/v1/users/${localStorage.getItem('user_id')}`,
    method: 'patch',
    data,
  });
}

// 获取上传头像的地址
export function getAvatarUrl(data: any) {
  return axios({
    url: '/v1/upload-url',
    method: 'post',
    data,
  });
}

// 获取购物车地址
export function getCartUrl(params: any) {
  return axios({
    url: '/v1/cart-url',
    method: 'get',
    params: params,
  });
}

// demo中的登录以及获取用户信息

// 用户登录
export function loginDemo() {
  return axios({
    url: '/v1/demo-sessions',
    method: 'post',
  });
}
// 获取用户信息
export function userInfoDemo(data: any) {
  return axios({
    url: '/v1/demo-userinfo',
    method: 'get',
    data,
  });
}

// 获取谷歌授权地址
export function getGoogleUrl(params: any) {
  return axios({
    url: '/v1/google/oauth-url',
    method: 'get',
    params: params,
  });
}

// 谷歌回调登录
export function googleCallback(data: any) {
  return axios({
    url: '/v1/google/sessions',
    method: 'post',
    data,
  });
}

// 新设备绑定以及用户权限变更事件推送
// const wsReqUrl = "ws://192.168.6.26:8000/api";
// const wsReqUrl = "wss://famikeep-test.afirstsoft.cn/api"; // 目标测试地址
let wsReqUrl: any;
// if (window.location.origin.includes('parental')) {
//   wsReqUrl = 'wss://my.parentalguard.com/api';
// } else {
//   wsReqUrl = 'wss://my.famikeep.com/api'; // 目标线上地址
// }
let originUrl = window.location.origin;
if (
  originUrl.includes('famikeep-test') ||
  originUrl.includes('famikeep-demo')
) {
  wsReqUrl = 'wss://famikeep-test.afirstsoft.cn/api';
} else if (
  originUrl.includes('my.famikeep') ||
  originUrl.includes('demo.famikeep')
) {
  wsReqUrl = 'wss://my.famikeep.com/api';
} else if (
  originUrl.includes('parentalguard-test') ||
  originUrl.includes('parentalguard-demo')
) {
  wsReqUrl = 'wss://parentalguard-test.afirstsoft.cn/api';
} else if (
  originUrl.includes('my.parentalguard') ||
  originUrl.includes('demo.parentalguard')
) {
  wsReqUrl = 'wss://my.parentalguard.com/api';
} else {
  wsReqUrl = 'wss://parentalguard-test.afirstsoft.cn/api';
  // wsReqUrl = 'wss://my.parentalguard.com/api';
}
// const wsReqUrl = process.env.NODE_ENV == 'development' ? 'wss://famikeep-test.afirstsoft.cn/api' : 'wss://my.famikeep.com/api'
export function progressbarWs(token: any, isCloes: any) {
  return new Promise((resolve, reject) => {
    var ws = new WebSocket(wsReqUrl + '/v1/ws/notice');
    ws.onopen = function (evt) {
      console.log('Connection open ...');
      if (isCloes == '0') {
        ws.close();
        return;
      }
      let to = 'auth-';
      let tokens = to.concat(token);
      ws.send(tokens);
      resolve(ws);
    };
    ws.onmessage = function (res) {
      localStorage.setItem('oos', JSON.parse(res.data));
      if (JSON.parse(res.data) == 2) {
        location.reload();
      } else if (JSON.parse(res.data) == 1) {
        // location.reload();
        emitter.emit('refresh');
      }
    };
    ws.onclose = function (e) {
      //console.log("Connection closed.");
    };
    ws.onerror = function (err) {
      console.log('ws错误' + err);
      reject(err);
    };
    // resolve(ws);
  });
}
