export const EN = {
  chat: {
    pictureText: '[Picture]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Contact Card]',
    location: '[Location]',
    other: '[Other]',
    all: 'All',
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    customRange: 'Select Custom Range',
    cancel: 'Cancel',
    apply: 'Apply',
    pushNotify: 'Push Notifications',
    numbers: 'Numbers',
    emails: 'Emails',
    outgoingCalls: 'Outgoing Calls',
    incomingCalls: 'Incoming Calls',
    missedCalls: 'Missed Calls',
    answeredCalls: 'Answered Calls',
    noAnswer: 'No answer',
    canceledCalls: 'Canceled Calls',
    loadingText: 'Loading, please be patient...',
    noMoreText: 'There is no more content',
    noInfoText: 'No event information, please check again later.',
    noAddressText: 'No address book information, please check again later.',
    noCallText: 'No call log, please check again later.',
    dateSelect: 'Select date',
    sentFile: 'Sent a file',
    sentAudio: 'Sent an audio',
    unGetFile: 'Unable to get file',
    unGetAudio: 'Unable to get audio',

    syncFailed: 'Data synchronization failed!',
    syncSuccessed: 'Data synchronization succeeded!',
    unknownContact: 'Unknown contact',
    noSyncNotice1: 'Data has not been synchronized for more than ',
    noSyncNotice2: '24 hours',
    noSyncNotice3:
      '. Please make sure that the target phone is turned on and connected to the Internet, and the "System Service" APP is running normally.',
    sync: 'Sync',
    startDate: 'Start date',
    endDate: 'End date',
    noSupportToast: 'Date filtering is not supported in Demo.',

    unknowVideo:
      "Video. Details are not available due to Instagram's privacy policy.",
    unknowImage:
      "Picture. Details are not available due to Instagram's privacy policy.",
    unknowAudio:
      "Voice message. Details are not available due to Instagram's privacy policy.",
    outgoingVoice: 'Outgoing audio call',
    incomingVoice: 'Incoming audio call',
    outgoingVideo: 'Outgoing video call',
    incomingVideo: 'Incoming video call',
  },
  user: {
    userProfile: 'User Profile',
    usesrname: 'Username:',
    edit: 'Edit',
    email: 'Email:',
    permission: 'Permission Infomation',
    bounded: 'Bound device(s):',
    restDevice: 'Remaining available device(s):',
    expire: 'Expiration date:',
    expired: '(Expired)',
    logout: 'Log out',
    changename: 'Change username',
    save: 'Save',
    inputname: 'Please enter a username',
    nameNull: 'The username format is abnormal, please enter again',
    networkError: 'Failed to change the username, please check your network',
    nameSuccess: 'Username changed successfully',
    changeavatar: 'Change avatar',
    loading: 'Loading…',
    changeAvatar: 'Change',
    supported: 'Support jpg, jpeg, png formats, and the size cannot exceed 2M.',
    maxsize: 'The image size cannot exceed 2M',
    avatarError: 'Failed to change the avatar, please check your network',
    avatarSuccess: 'Avatar changed successfully',
    imgError: 'Image error',
    imageType: 'Image type not supported',
    nameEmpty: 'Username cannot be blank',
  },
  leftTab: {
    device: 'Device',
    unbound: 'Unbound Device',
    addDevice: 'Add Device',
    dashboard: 'Dashboard',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Please bind a device first',
    payRemind: 'Please purchase first',
    purchase: 'Buy Now',
    unboundName: 'Bind Device Now',
    toBuy: 'Buy Device',
  },
  guide: {
    title: 'Please bind your device',
    oneT: '1. Get Target Device',
    oneContent:
      'To monitor the device, you need to get the target monitored device for about 10 minutes.',
    twoT: '2. Download & Install the App',
    twoContent1: 'Enter',
    twoContent2:
      'in the target device browser, then download and install the app.',
    threeT: '3. Set up Device Permission',
    threeContent:
      'Follow the instructions on the app to set up device permission. After setting, you can monitor the device.',
    tips1:
      "To ensure stealth, the app will be disguised as system software named 'System Service'.",
    tips2:
      'To ensure incognito, you can delete browser records and app installation packages after installing the app successfully.',
    tips3:
      "The app will be displayed as 'System Service' on the desktop. You can choose to hide it in a folder for better stealth effect.",
    tips41:
      'To ensure that you can install the app and set up its permission more quickly, you can view ',
    tips42: 'first to learn how to do it.',
    detail: 'the detailed tutorials',
    tips5:
      ' If the bound device permission has expired, you can change the device binding and bind it to the permission to continue collecting data. ',
    rebound: 'Click to change device binding',
  },
  dashboard: {
    contact: 'Top Contacts on WhatsApp (within 7 days)',
    call: 'Top Calls on WhatsApp',
    view: 'View All',
    account: 'Account',
    id: 'Account  ID:',
    expire: 'Expiration Date:',
    bound: 'Bound Device(s):',
    restDevice: 'Remaining Device(s):',
    target: 'About Target Device',
    brand: 'Brand:',
    model: 'Model:',
    version: 'Android Version:',
    battery: 'Battery:',
    wifi: 'Wi-Fi:',
    appversion: 'App Version:',
    update: 'Last Updated:',
    blank: 'No Recent Activity',
    unbindSuc: 'Unbind succeeded!',
    unbindFail: 'Unbind failed!',
    ReboundSuc: 'Change device binding succeeded!',
    ReboundFail: 'Change device binding failed!',
    noPlace: 'No device to change the binding!',
    beExpOne: 'Your device permission will expire in ',
    beExpTwo: 'days',
    beExpThree:
      ', and the monitored device data will not be synchronized after expiration.',
    expiredOne:
      'Your device permission has expired. The previously collected data will be cleared in ',
    expiredTwo:
      '. After renewal, the monitored device data will continue to be synchronized. ',
    renew: 'Renew now',
    insContact: 'Top Contacts on Instagram (within 7 days)',
    insCall: 'Top Calls on Instagram',
  },
  payPage: {
    title: 'ParentalGuard Pricing & Plans',
    dec: 'Monitoring WhatsApp with ParentalGuard is easier than you think!',
    bigSave: 'BIG SAVE',
    one: '1-Month Plan',
    three: '3-Month Plan',
    year: '1-Year Plan',
    toBuy: 'Buy Now',
    auto: 'Auto Renewal',
    cancelAny: 'Cancel Anytime',
    update: 'Automatically update data every five minutes',
    collect: 'Collect all conversations',
    interaction: 'Better interactive experience',
    hide: 'Stealth mode',
    // 弹窗部分
    exclude: 'Exclude VAT',
    enterTitle: 'Buy FamiKeep for WhatsApp',
    enterSubmit: 'Continue',
    contact: 'Unable to access at present, please contact us',
  },
  pop: {
    changeBound: 'Change Device Binding',
    reboundText1: 'Are you sure to change the',
    reboundText2:
      'device binding and bind it to the unbound device permission to continue collecting data (Expiration date:',
    reboundText3: ')?',
    reboundText4:
      'device binding and bind it to the unbound device permission to continue collecting data',
    noRebound: 'No available device permission. Please buy it first.',
    goPay: 'Buy Now',
    reboundList: 'Bound Device',
    reboundDec:
      'The following device permission has expired. Select a device and bind it to the currently unbound device permission to continue collecting data.',
    rebounding: 'Changing device binding...',
    reboundFail1: 'Failed to change the',
    reboundFail2: 'device binding. Do you want to try again?',
    tryAgain: 'Retry',
    unbindTitle: 'Unbind Device',
    unbindText1: 'Are you sure to unbind the',
    unbindText2:
      'device? All collected data information will be cleared after unbinding.',
    remind: 'Expiration Prompt',
    expiredPay: 'Renew',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
  },
  navTop: {
    langTitle: 'Change Language',
  },
  famikeep200: {
    Calls: 'Calls',
    Messages: 'Messages',
    Contacts: 'Contacts',
    Photos: 'Photos',
    Applications: 'Applications',
    'Compared to yesterday': 'Compared to yesterday',
    'All locations': 'All locations',
    'Weekly average usage time': 'Weekly average usage time',
    'Floating comparison with last week':
      'Floating percentage change compared to last week',
    Average: 'Average',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    'Most used applications': 'Most used Apps',
    Geolocation: 'Geolocation',
    'Last updated': 'Last updated',
    'Geolocation permission':
      "Geolocation permission for the 'System Service' app is disabled, failed to fetch location",
    Geofence: 'Geofence',
    'Create a geofence':
      'Create a geofence to receive alerts via email when your child enters/exits the specified area',
    'enters/exits': 'enters/exits the specified area',
    'Create a new geofence': 'Create a new geofence',
    'Geofence name': 'Geofence name',
    'Set geofence location': '	Set geofence location',
    'Set radius': 'Set radius',
    'Set monitoring time': 'Set monitoring time',
    'please choose': 'please choose',
    Daily: 'Daily',
    'Every Monday': 'Every Monday',
    'Every Tuesday': 'Every Tuesday',
    'Every Wednesday': 'Every Wednesday',
    'Every Thursday': 'Every Thursday',
    'Every Friday': 'Every Friday',
    'Every Saturday': 'Every Saturday',
    'Every Sunday': 'Every Sunday',
    'Send alerts via email': 'Send alerts via email',
    'We will send alert emails to the registered email':
      'We will send alert emails to the registered email',
    Save: 'Save',
    'Please enter a geofence name': 'Please enter a geofence name',
    'Please select a geofence location': 'Please select a geofence location',
    Entered: 'Entered',
    Exited: 'Exited',
    Edit: 'Edit',
    Delete: 'Delete',
    'Are you sure you want to delete':
      'Are you sure you want to delete the xxx geofence? Corresponding monitoring data will also be deleted',
    'Are you sure you want to close':
      'Are you sure you want to close? You will not receive email alerts when entering/leaving the geofence',
    'Call history': 'Call history',
    Status: 'Status',
    Number: 'Number',
    Duration: 'Duration',
    Date: 'Date',
    incoming: 'Incoming call',
    outgoing: 'Outgoing call',
    missed: 'Missed call',
    'no answered': 'No answer',
    Address: 'Address',
    'Added time': 'Added time',
    'Sort by name': 'Sort by name',
    'Sort by time': 'Sort by time',
    'Please note that photos':
      'Please note that photos will be uploaded only when the device is connected to Wi-Fi',
    Album: 'Album',
    'Select all photos on the current page':
      'Select all photos on the current page',
    'Previous page': 'Previous page',
    'Next page': 'Next page',
    Back: 'Back',
    'confirm to delete':
      'confirm to delete? Data cannot be recovered after deletion',
    Download: 'Download',
    Calendar: 'Calendar',
    Title: 'Title',
    Description: 'Description',
    Location: 'Location',
    'Start time': 'Start time',
    'End time': 'End time',
    'Installed applications': 'Installed Apps',
    'All applications': 'All applications',
    Name: 'Name',
    Version: 'Version',
    Size: 'Size',
    'Installation date': 'Installation date',
    'Disable applications': 'App Limit',
    'Seted disable rules': 'Set disable rules',
    'Undisabled applications': 'Undisabled applications',
    'Click the button below':
      'Click the button below to create an app disable rule, and choose the apps you want to disable',
    'Set disable rules': 'Set disable rules',
    'Disable permanently': 'Disable permanently',
    'Disable ': 'Disable ',
    'Custom disable time': 'Custom disable time',
    'Disable period': 'Disable period',
    From: 'From',
    To: 'To',
    Total: 'Total',
    Weekly: 'Weekly',
    Time: 'Time',
    'Select the applications you want to disable':
      'Select the applications you want to disable',
    'Please select an application below': 'Please select an application below',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Are you sure you want to delete? The disabled apps under this rule will no longer be affected',
    'Disable options:': 'Disable options:',
    'one Disable permanently':
      "1. Disable permanently: Completely disables the application. Your child won't be able to use the disabled apps until you lift the disablement.",
    'two Custom disable time':
      '2. Custom disable time: Only triggers the disablement rule during specific time ranges, and your child can use the apps normally outside of those times.',
    'You can only choose one of the two setting options':
      'You can only choose one of the two setting options',
    complete: 'complete',
    'Screen usage time': 'Screen Time',
    'Screen Usage Time for xxx': 'Screen Time for xxx',
    'Frequently used applications': 'Frequently used applications',
    Frequency: 'Frequency',
    'Usage time': 'Usage time',
    'Screen disable': 'Screen Limit',
    'Turn on the switch and set the screen lock duration.':
      'Turn on the switch and set the screen lock duration.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      "Your child's device screen will be disabled and will be restored after the set time is consumed.",
    'Disable all screen activities': 'Block all screen activities',
    'Set screen disable duration': 'Set screen disable duration',
    'You can lift the disablement at any time':
      'You can lift the disablement at any time',
    'Remaining screen disable time on the target device':
      'Remaining screen disable time on the target device',
    'Are you sure you want to lift the screen disablement now?':
      'Are you sure you want to lift the screen disablement now?',
    Yes: 'Yes',
    'This account does not exist, please register first':
      'This account does not exist, please register first',
    'Synchronization successful, no new data':
      'Synchronization successful, no new data',
    'Location alert': 'Location alert',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      "The xxx device you're monitoring entered xx on xxxx-xx-xx",
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      "The xxx device you're monitoring exited xx on xxxx-xx-xx",
    'View more location records:': 'View more location records:',
    'Thank you!': 'Thank you!',
    'xxx Team': 'xxx Team',
    'When obtaining this location':
      'When obtaining this location, the device activated virtual positioning, which may lead to inaccuracies.',
    'The demo mode does not support this feature, please bind the device first.':
      'The demo mode does not support this feature, please bind the device first.',
    'Location Tracking': 'Location Tracking',
    'General Application': 'General Application',
    'App Activites Tracking': 'App Activites Tracking',
    'Screen Time Tracking': 'Screen Time Tracking',
    'Social Application': 'Social Application',
    'Subject:': 'Subject:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud used storage',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Last known location',
    'Please bind your device': 'Please bind your device',
    'Log in iCloud to binding.':
      "Log in to the target device's iCloud account to complete the binding.",
    'Please enter your Apple ID': 'Please enter your Apple ID',
    'Please enter your password': 'Please enter your password',
    'Your entered account or password is incorrect.':
      'Your entered account or password is incorrect.',
    'Ensure Apple ID and password':
      'Ensure you enter the Apple ID and password of the target device, authenticate, and complete the binding.',
    'Forgot Apple ID or password?': 'Forgot Apple ID or password?',
    'Devices using the same iCloud account will be bound':
      'Devices using the same iCloud account will be bound.',
    'Two-Factor Authentication': 'Two-Factor Authentication',
    'A message has been sent':
      'A message containing a verification code has been sent to your device. Please enter the verification code to continue.',
    'A message containing a verification code has been sent to xxx.':
      'A message containing a verification code has been sent to xxx. Please enter the verification code to continue.',
    'Incorrect verification code': 'Incorrect verification code',
    'Resend Verification Code': 'Resend Verification Code',
    'Get SMS Verification Code': 'Get SMS Verification Code',
    'Please select a phone number to receive the verification code.':
      'Please select a phone number to receive the verification code.',
    'Please select': 'Please select',
    'The current iCloud has expired':
      'The current iCloud account verification has expired. Please log in to your iCloud account again to synchronize data. ',
    'current iCloud has expired':
      'The current iCloud account verification has expired. Please log in to your iCloud account again to synchronize data. Go to verification >>',
    'Go to verification >>': 'Go to verification >>',
    'Data synchronization failed, iCloud account expired':
      'Data synchronization failed, iCloud account expired, please return to the homepage and reverify.',
    'Created geofences': 'Created geofences',
    'No geofences created': 'No geofences created',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Parental Control App Pricing & Plans',
    '30-Day Money Back Guarantee': '30-Day Money Back Guarantee',
    'Professional Customer Support': 'Professional Customer Support',
    'All Prices Exclusive of VAT': 'All Prices Exclusive of VAT',
    '100% Secure': '100% Secure',
    'More Features to Keep Your Kids Safe Online':
      'More Features to Keep Your Kids Safe Online',
    Features: 'Features',
    'Monitor App': 'Monitor App',
    'Type of Data': 'Type of Data',
    'WhatsApp Chat': 'WhatsApp Chat',
    'App Activity': 'App Activity',
    'App Blocker': 'App Blocker',
    'Monitor Social Apps': 'Monitor Social Apps',
    'Location History': 'Location History',
    'Location Reminder': 'Location Reminder',
    'Live Location': 'Live Location',
    'Safe Search': 'Safe Search',
    'Web Filter': 'Web Filter',
    'Browser History': 'Browser History',
    'Content Detection': 'Content Detection',
    'All SMS': 'All SMS',
    Photos: 'Photos',
    Video: 'Video',
    Calendar: 'Calendar',
    Documents: 'Documents',
    'Record Screen': 'Record Screen',
    'Call History': 'Call History',
    'Call Recording': 'Call Recording',
    Contacts: 'Contacts',
    Messages: 'Messages',
    'Call Logs': 'Call Logs',
    'Chat History': 'Chat History',
    'The device has been bound to other account':
      'The device has been bound to other account, please unbind it first or contact customer service to unbind it.',
    Gallery: 'Gallery',
    'Hidden or Deleted Albums': 'Hidden or Deleted Albums',
    'Recent Projects': 'Recent Projects',
    'Personal Collection': 'Personal Collection',
    'Recently Deleted': 'Recently Deleted',
    'Live Photos': 'Live Photos',
    'Exposure Photos': 'Exposure Photos',
    'Panoramic Photos': 'Panoramic Photos',
    'Time-Lapse Photography': 'Time-Lapse Photography',
    'Slow Motion': 'Slow Motion',
    Portrait: 'Portrait',
    'Burst Mode': 'Burst Mode',
    Screenshot: 'Screenshot',
    Motion: 'Motion',
    Hide: 'Hide',
    'This iCloud account':
      'This iCloud account is not linked to any device. Please log in on a device.',
    'Data synchronization':
      'Data synchronization may take some time, please be patient.',
    'Location History1': 'Location History',
    'Auto Renewal': 'Auto Renewal & Cancel Anytime',
    Month: 'Month',
    Quarter: 'Quarter',
    Year: 'Year',
    Day: 'Day',
    'See Demo': 'See Demo',
    'We Accept': 'We Accept',
  },
  famikeep220: {
    'Browser Monitoring': 'Browser Monitoring',
    'Browser History': 'Browser History',
    'Browser Bookmarks': 'Browser Bookmarks',
    'Serial Number': 'Serial Number',
    'Website Restrictions': 'Website Restrictions',
    'Restriction Rules': 'Restriction Rules',
    'Prohibited Access': 'Prohibited Access',
    'Access Warning': 'Access Warning',
    'Restricted Website Log': 'Restricted Website Log',
    'Click to Add Restricted Website': 'Click to Add Restricted Website',
    'Restriction Method:': 'Restriction Method:',
    'Prohibit Access':
      "1. Prohibit Access: Completely disable website access. Your child won't be able to access restricted websites within the browser until you remove the website blocking rule.",
    'Access Alerts':
      '2. Access Alerts: When your child visits websites within the rules, you will receive email alerts and generate alert records (only one alert email will be sent within 24 hours for the same website).',
    'the rule disabling access takes priority.':
      'If the same website is subject to two different rules, the Prohibited Access rule takes priority.',
    'Set Restriction Rules': 'Set Restriction Rules',
    'This option will block':
      'This option will block access to the entered websites',
    'This option will allow':
      'This option will allow access to the entered website.',
    'For example':
      'For example, if you enter example.com, all associated web pages (e.g., www.example.com/page1.html) will also be blocked.',
    'If you enter the specific website page':
      'If you enter the specific website page www.example.com/page1.html, it will only block that one page.',
    'This option allows':
      'This option allows access to the entered website but will generate corresponding browsing records.',
    'Send records by email': 'Send records by email',
    'When enabled':
      'When enabled, we will send the records to the registered email when accessing the entered website.',
    'enter example':
      'For example, if you enter example.com, all related webpages (such as www.example.com/page1.html) will also be alerted.',
    'enter the specific webpage':
      'If you enter the specific webpage www.example.com/page1.html, only this one page will be alerted.',
    'Enter the website to be restricted': 'Enter the website to be restricted',
    Bookmark: 'Bookmark',
    'Bookmark list': 'Bookmark list',
    'Total xx bookmarks': 'Total xx bookmarks',
    'Enter the URL': 'Enter the URL (e.g., youtube.com)',
    'Added URLs': 'Added URLs',
    Add: 'Add',
    'Please add the restricted URL': 'Please add the restricted URL',
    'Are you sure':
      'Are you sure you want to delete? Restriction will be removed after deletion.',
    Video: 'Video',
    'Please note that videos':
      'Please note that videos will only upload when the device is connected to WIFI.',
    'Website access alert': 'Website access alert',
    'monitoring accessed':
      "The xxx device you're monitoring accessed xx on xxxx-xx-xx.",
    'View more records:': 'View more records:',
    'Download and install the app.': 'Download and install the app.',
    'After installation':
      'After installation, please open the app by visiting xxx.com.',
    'Open immediately': 'Open immediately',
    'Unable to open System Service:': 'Unable to open System Service:',
    'Please ensure this device has installed':
      '1. Please ensure this device has installed System Service (click to download System Service)',
    'click download': '(click to download System Service)',
    'Copy the link':
      '2. Copy the link address of the current webpage and open it in the built-in browser',
    'Sure close':
      'Are you sure you want to close? You will not receive email alerts after closing.',
    Loading: 'Loading',
    'Loading failed': 'Loading failed. Please download to view locally.',
    'Select All Videos': 'Select All Videos on Current Page',
    tips3:
      "The app will be hidden on the device under the name 'System Service.'",
    'If the video cannot be loaded':
      'If the video cannot be loaded for an extended period, please download and view.',
  },
  famikeep222: {
    'Continue Google': 'Continue with Google',
    or: 'or',
    Continue: 'Continue',
    'legal guardian':
      'I am the legal guardian of any underage children linked to this account',
    'take around 10 mins':
      'Please bind an Android device (will take around 10 mins)',
    'Get the Android device': '1. Get the Android device to be monitored',
    'Install Companion App': '2. Install Companion App',
    'Login to set permissions': '3. Login to set permissions',
    twoContent1:
      'Please access ** from monitored Android device to download and install app',
    'set up permissions':
      'Please login your account in Companion App and set up permissions, then you can start monitoring. (Please note app can only be logged in via account password, please ** if you have not set a password.)',
    'Click here': 'Click here',
    'continue collecting data':
      '5. If the permissions for the bound device have expired, you can continue collecting data by rebinding.',
  },
  // 用户模块
  language: 'Language',
  signIn: 'Sign In',
  email: 'Email',
  password: 'Password',
  sendCode: 'Send Code',

  // 登录
  signingIn: 'Signing in',
  noAccount: 'No account?',
  signUpNow: 'Sign up now.',
  forgetPassword: 'Forget your password?',

  // 注册
  signUp: 'Sign Up',
  createAccount: 'Create Account',
  emVerificationCode: 'Email Verification Code',
  creatingAccount: 'Creating  Account…',
  alreadyHaveAccount: 'Already have an account?',
  SignInUp: 'Sign In.',
  send: 'Sent',
  sing: 's',
  byCreatingAnAccount: 'By creating an account, you agree to the',
  termsOfService: 'Terms of Service',
  and: 'and',
  privacyPolicy: 'Privacy Policy.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Retrieve password',
  backTo: 'Back to Sign in',
  newPassword: 'New Password',
  resetPassword: 'Reset Password',
  loginSucceeded: 'Login succeeded!',
  logoutSucceeded: 'Logout succeeded!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Password must be 8-20 characters and a combination of letters and numbers',
  pleaseEnterPassword: 'Please enter your account password',
  pleaseEnterEmailAddress: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  pleaseEnterVerifiCode: 'Please enter the verification code',
  incorrectVerifiCode: 'Incorrect verification code',

  // Toast文案
  verificationCodeHasBeen:
    'Verification code has been sent, please check your email',
  pleaseReadAndAgree:
    'Please read and agree to the Terms of Service and Privacy Policy.',
  networkAbnormal: 'Network abnormal',
  emailRegistered: 'The email has not been registered, please register first',
  passwordNotold: 'Your new password cannot be the same as the old password',
  invalidPasswordFormat: 'Invalid password format',
  theEmailHasRegistered: 'The email has been registered',
  verificationCodeGetAgain:
    'The verification code has expired, please get it again',
  erificationCodeMatch: 'Email and verification code do not match',
  incorrectPasswordOrEmail: 'Incorrect password or email, please enter again',
  requestError: 'Request error',
  pleaseTryAgainLater: 'Too many requests, please try again later',
  accessDenied: 'Access denied',
  requestErrorResourceNotFound: 'Request error, the resource was not found.',
  serverError: 'Server error',
  connectionServerFailed: 'Connection server failed',
  requestTimedOut: 'Request timed out',
  networkAbnormaled: 'Network abnormal',
  serviceUnavailable: 'Service unavailable',
  httpVersioSupportTheRequest: "The http version doesn't support the request",
  wrongPasswordTime:
    'You have entered the wrong password 5 times. Your account is temporarily locked and cannot be logged in. Please try again in 10 minutes.',
};
