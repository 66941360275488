import { END_ALIGNMENT } from 'element-plus/es/components/virtual-list/src/defaults';

export const AR = {
  chat: {
    pictureText: '[صورة]',
    videoText: '[فيديو]',
    audioText: '[صوتي]',
    contactCard: '[بطاقة جهة الاتصال]',
    location: '[موقع]',
    other: '[آخر]',
    all: 'الكل',
    today: 'اليوم',
    yesterday: 'أمس',
    last7Days: 'اخر 7 ايام',
    last30Days: 'آخر 30 يومًا',
    customRange: 'إعدادات مخصصة',
    cancel: 'إلغاء',
    apply: 'التطبيقات',
    pushNotify: 'دفع الإخطار',
    numbers: 'المحمول',
    emails: 'البريد الإلكتروني',
    outgoingCalls: 'مكالمة صادرة',
    incomingCalls: 'مكالمة واردة',
    missedCalls: 'مكالمة فائتة',
    answeredCalls: 'مكالمة اجابة',
    noAnswer: 'لا اجابة',
    canceledCalls: 'تم إلغاء المكالمة',
    loadingText: 'جاري التحميل، يرجى الانتظار بصبر ...',
    noMoreText: 'لا يوجد المزيد من المحتوى',
    noInfoText: 'لا توجد معلومات عن الحدث، يرجى التحقق مرة أخرى لاحقًا',
    noAddressText:
      'لا توجد معلومات عن دفتر العناوين، يرجى التحقق مرة أخرى لاحقًا',
    noCallText: 'لا يوجد سجل مكالمات، يرجى التحقق مرة أخرى لاحقًا',
    dateSelect: 'حدد تاريخ',
    sentFile: 'أرسل ملفًا',
    sentAudio: 'أرسل صوتًا',
    unGetFile: 'تعذر الحصول على الملف',
    unGetAudio: 'تعذر الحصول على الصوت',

    syncFailed: 'فشل مزامنة البيانات!',
    syncSuccessed: 'مزامنة البيانات بنجاح!',
    unknownContact: 'جهة اتصال غير معروفة',
    noSyncNotice1:
      'لم تكن هناك مزامنة بيانات لأكثر من يُرجى التأكد من تشغيل هاتفك المستهدف وتوصيله بالشبكة، وأن تطبيق "System Service" يعمل بشكل طبيعي.',
    noSyncNotice2: ' 24 ساعة. ',
    noSyncNotice3:
      ' يُرجى التأكد من تشغيل هاتفك المستهدف وتوصيله بالشبكة، وأن تطبيق ',
    sync: 'مزامنة',
    startDate: 'تاريخ البدء',
    endDate: 'تاريخ الانتهاء',
    noSupportToast: 'وضع "Demo" لا يدعم تصفية التاريخ',

    unknowVideo: 'رسالة فيديو. التفاصيل غير متوفرة بسبب سياسة خصوصية إنستغرام.',
    unknowImage: 'رسالة صورة. التفاصيل غير متوفرة بسبب سياسة خصوصية إنستغرام.',
    unknowAudio: 'رسالة صوتية. التفاصيل غير متوفرة بسبب سياسة خصوصية إنستغرام.',
    outgoingVoice: 'مكالمة صوتية صادرة',
    incomingVoice: 'مكالمة صوتية واردة',
    outgoingVideo: 'مكالمة فيديو صادرة',
    incomingVideo: 'مكالمة فيديو واردة',
  },
  user: {
    userProfile: 'معلومات المستخدم',
    usesrname: 'اسم المستخدم:',
    edit: 'تحرير',
    email: 'البريد ',
    permission: 'معلومات الإذن',
    bounded: 'الجهاز (الأجهزة) المربوط:',
    restDevice: 'الجهاز (الأجهزة) المتبقية المتاحة:',
    expire: 'تاريخ إنتهاء الصلاحية:',
    expired: '(منتهي الصلاحية)',
    logout: 'تسجيل خروج',
    changename: 'تغيير اسم المستخدم',
    save: 'حفظ',
    inputname: 'الرجاء ادخال اسم المستخدم',
    nameNull: 'تنسيق اسم المستخدم غير طبيعي، يرجى إدخاله مرة أخرى',
    networkError: 'فشل تغيير اسم المستخدم، يرجى التحقق من شبكتك',
    nameSuccess: 'تم تغيير اسم المستخدم بنجاح',
    changeavatar: 'تغيير الصورة الرمزية',
    loading: 'جار التحميل…',
    changeAvatar: 'تغيير ',
    supported: 'دعم تنسيقات jpg و jpeg و png ولا يمكن أن يتجاوز الحجم 2M.',
    maxsize: 'حجم الصورة لا يمكن أن يتجاوز 2M',
    avatarError: 'فشل في تغيير الصورة الرمزية، يرجى التحقق من شبكتك',
    avatarSuccess: 'تم تغيير الصورة الرمزية بنجاح',
    imgError: 'خطأ في الصورة',
    imageType: 'نوع الصورة غير مدعوم',
    nameEmpty: 'اسم المستخدم لا يمكن أن يكون فارغا',
  },
  leftTab: {
    device: 'جهاز',
    unbound: 'جهاز (أجهزة) غير منضم',
    addDevice: 'اضف جهاز',
    dashboard: 'الصفحة الرئيسية',
    whatsapp: 'واتساب',
    instagram: 'Instagram',
    bindRemind: 'الرجاء ربط الجهاز أولاً',
    payRemind: 'الرجاء الشراء أولا',
    purchase: 'اشتري الآن',
    unboundName: 'اربط الجهاز الآن',
    toBuy: 'شراء الجهاز',
  },
  guide: {
    title: 'يرجى ربط جهازك',
    oneT: '1. احصل على الجهاز المستهدف',
    oneContent:
      'لمراقبة الجهاز، تحتاج إلى الحصول على جهاز مراقبة الهدف لمدة 10 دقائق تقريبًا.',
    twoT: '2. قم بتنزيل التطبيق وتثبيته',
    twoContent1: 'أدخل',
    twoContent2: ' في متصفح الجهاز المستهدف، ثم قم بتنزيل التطبيق وتثبيته.',
    threeT: '3. إعداد أذونات الجهاز',
    threeContent:
      'اتبع التعليمات الموجودة على التطبيق لإعداد أذونات الجهاز. بعد الإعداد، يمكنك مراقبة الجهاز.',
    tips1:
      "لضمان التخفي، يتم إخفاء التطبيق في صورة برنامج نظام يسمى 'System Service'.",
    tips2:
      'لضمان وضع التصفح المتخفي، يمكنك حذف سجلات المتصفح وحزم تثبيت التطبيق بعد تثبيت التطبيق بنجاح',
    tips3:
      "سيتم عرض التطبيق باسم 'System Service على سطح المكتب. يمكنك اختيار إخفائه في مجلد للحصول على تأثير تسلل أفضل.",
    tips41:
      'للتأكد من أنه يمكنك تثبيت التطبيق وإعداد أذوناته بشكل أسرع، يمكنك عرض البرامج',
    tips42: ' أولاً لمعرفة كيفية القيام بذلك.',
    detail: 'التعليمية التفصيلية',
    tips5:
      'ذا انتهت صلاحية الجهاز المرتبط، يمكنك استبدال الجهاز المرتبط، والاستمرار في مزامنة البيانات بعد نجاح الربط. ',
    rebound: 'انقر لتغيير الربط',
  },
  dashboard: {
    contact: 'أهم جهات الاتصال في WhatsApp (خلال 7 أيام)',
    call: 'أهم المكالمات على WhatsApp',
    view: 'عرض الكل',
    account: 'الحساب',
    id: 'الحساب ID:',
    expire: 'تاريخ إنتهاء الصلاحية:',
    bound: 'الجهاز المربوط:',
    restDevice: 'عدد الجهاز (الأجهزة) المتبقية:',
    target: 'حول الجهاز المستهدف:',
    brand: 'ماركة:',
    model: 'الطراز:',
    version: 'نسخة أندرويد:',
    battery: 'البطارية:',
    wifi: 'WLAN:',
    appversion: 'إصدار البرنامج:',
    update: 'وقت آخر تحديث:',
    blank: 'لا نشاط مؤخرًا',
    unbindSuc: 'نجح إلغاء الربط!',
    unbindFail: 'فشل إلغاء الربط!',
    ReboundSuc: 'نجح تغيير ربط الجهاز!',
    ReboundFail: 'فشل تغيير ربط الجهاز!',
    noPlace: 'لا يوجد جهاز لتغيير الربط!',
    beExpOne: 'ستنتهي صلاحية إذن جهازك  خلال',
    beExpTwo: 'أيام،',
    beExpThree:
      'ولن تتم مزامنة بيانات الجهاز الذي يخضع للمراقبة عند انتهاء صلاحيته.',
    expiredOne:
      'انتهت صلاحية إذن جهازك. سيتم مسح البيانات التي تم جمعها مسبقًا في غضون',
    expiredTwo: 'بعد التجديد، ستستمر مزامنة بيانات الجهاز المراقبة. ',
    renew: 'جدد الآن ',
    insContact: 'أهم جهات الاتصال على إنستغرام (خلال 7 أيام)',
    insCall: 'أهم المكالمات على إنستغرام',
  },
  payPage: {
    title: 'اختر خطتك من Famikeep',
    dec: 'مراقبة الواتس اب مع Famikeep أسهل مما تعتقد!',
    bigSave: 'خصم كبير',
    one: '1 شهر رخصة',
    three: '3 اشهر رخصة',
    year: '1 سنة رخصة',
    toBuy: 'اشتري الآن',
    auto: 'التجديد التلقائي',
    cancelAny: 'إلغاء في أي وقت',
    update: 'تحديث البيانات تلقائيًا كل خمس دقائق',
    collect: 'اجمع كل المحادثات',
    interaction: 'تجربة تفاعلية أفضل',
    hide: 'وضع التخفي',
  },
  pop: {
    changeBound: 'تغيير ربط الجهاز',
    reboundText1: 'هل أنت متأكد من تغيير جهاز',
    reboundText2:
      ' إلى جهاز غير منضم لمتابعة جمع البيانات (وقت انتهاء الصلاحية:',
    reboundText3: ')؟',
    reboundText4: ' إلى جهاز غير منضم لمتابعة جمع البيانات',
    noRebound: 'لا يوجد إذن جهاز متاح. الرجاء شرائه أولا.',
    goPay: 'اشتر الآن',
    reboundList: 'الجهاز المحمول المرتبط',
    reboundDec:
      'انتهت صلاحية أذونات الجهاز التالية. حدد واستبدل الجهاز المرتبط بإذن الجهاز الحالي غير المنضم لمتابعة تجميع البيانات.',
    rebounding: 'جارٍ استبدال الجهاز ...',
    reboundFail1: 'فشل استبدال الجهاز',
    reboundFail2: ' والربط. هل تريد المحاولة مرة أخرى؟',
    tryAgain: 'أعد المحاولة',
    unbindTitle: ' إلغاء الربط',
    unbindText1: 'هل أنت متأكد من إلغاء ربط جهاز',
    unbindText2:
      '؟ سيتم مسح جميع معلومات البيانات التي تم جمعها بعد إلغاء الربط.',
    remind: 'تذكير انتهاء الصلاحية',
    expiredPay: 'جدد الآن',
    cancel: 'إلغاء',
    yes: 'نعم',
    no: 'لا',
  },
  navTop: {
    langTitle: 'تغيير اللغة',
  },
  famikeep200: {
    Calls: 'المكالمات',
    Messages: 'رسائل',
    Contacts: 'جهات الاتصال',
    Photos: 'الصور',
    Applications: 'التطبيقات',
    'Compared to yesterday': 'مقارنة بالأمس',
    'All locations': 'جميع المواقع',
    'Weekly average usage time': 'متوسط وقت الاستخدام الأسبوعي',
    'Floating comparison with last week': 'مقارنة عائمة مع الأسبوع الماضي',
    Average: 'متوسط',
    Mon: 'ن',
    Tue: 'ث',
    Wed: 'ر',
    Thu: 'خ',
    Fri: 'ج',
    Sat: 'س',
    Sun: 'ح',
    'Most used applications': 'ش',
    Geolocation: 'تحديد الموقع الجغرافي',
    'Last updated': 'آخر تحديث',
    'Geolocation permission':
      "تم تعطيل إذن تحديد الموقع الجغرافي لتطبيق 'خدمة النظام' ، وفشل في جلب الموقع",
    Geofence: 'سياج جغرافي',
    'Create a geofence':
      'قم بإنشاء سياج جغرافي لتلقي التنبيهات عبر البريد الإلكتروني عندما يدخل طفلك / يخرج من المنطقة المحددة',
    'enters/exits': 'طفلك / يخرج من المنطقة المحددة',
    'Create a new geofence': 'إنشاء سياج جغرافي جديد',
    'Geofence name': 'اسم السياج الجغرافي',
    'Set geofence location': 'تعيين موقع السياج الجغرافي',
    'Set radius': 'تعيين نصف القطر',
    'Set monitoring time': 'ضبط وقت المراقبة',
    'please choose': 'اختر من فضلك',
    Daily: 'يوميًا',
    'Every Monday': 'كل اثنين',
    'Every Tuesday': 'كل ثلاثاء',
    'Every Wednesday': 'كل أربعاء',
    'Every Thursday': 'كل يوم خميس',
    'Every Friday': 'كل جمعة',
    'Every Saturday': 'كل سبت',
    'Every Sunday': 'كل احد',
    'Send alerts via email': 'إرسال التنبيهات عبر البريد الإلكتروني',
    'We will send alert emails to the registered email':
      'سنرسل رسائل تنبيه بالبريد الإلكتروني إلى البريد الإلكتروني المسجل',
    Save: 'يحفظ',
    'Please enter a geofence name': 'الرجاء إدخال اسم السياج الجغرافي',
    'Please select a geofence location': 'الرجاء تحديد موقع السياج الجغرافي',
    Entered: 'دخلت',
    Exited: 'خرج',
    Edit: 'يحرر',
    Delete: 'يمسح',
    'Are you sure you want to delete':
      'هل أنت متأكد أنك تريد حذف xxx السياج الجغرافي؟ سيتم أيضًا حذف بيانات المراقبة المقابلة',
    'Are you sure you want to close':
      'هل أنت متأكد أنك تريد الإغلاق؟ لن تتلقى تنبيهات عبر البريد الإلكتروني عند الدخول/الخروج من السياج الجغرافي',
    'Call history': 'سجل المكالمات',
    Status: 'حالة',
    Number: 'رقم',
    Duration: 'مدة',
    Date: 'تاريخ',
    incoming: 'مكالمة واردة',
    outgoing: 'مكالمة خارجية',
    missed: 'مكالمة فائتة',
    'no answered': 'لا اجابة',
    Address: 'عنوان',
    'Added time': 'الوقت المضاف',
    'Sort by name': 'الترتيب حسب الاسم',
    'Sort by time': 'فرز حسب الوقت',
    'Please note that photos':
      'يرجى ملاحظة أنه سيتم تحميل الصور فقط عندما يكون الجهاز متصلاً بشبكة Wi-Fi',
    Album: 'الألبوم',
    'Select all photos on the current page': 'حدد كل الصور في الصفحة الحالية',
    'Previous page': 'الصفحة السابقة',
    'Next page': 'الصفحة التالية',
    Back: 'خلف',
    'confirm to delete': 'تأكيد الحذف؟ لا يمكن استعادة البيانات بعد الحذف',
    Download: 'تحميل',
    Calendar: 'تقويم',
    Title: 'عنوان',
    Description: 'وصف',
    Location: 'موقع',
    'Start time': 'وقت البدء',
    'End time': 'وقت النهاية',
    'Installed applications': 'التطبيقات المثبتة',
    'All applications': 'كل التطبيقات',
    Name: 'اسم',
    Version: 'إصدار',
    Size: 'مقاس',
    'Installation date': 'تاريخ التثبيت',
    'Disable applications': 'تعطيل التطبيقات',
    'Seted disable rules': 'قم بتعيين قواعد التعطيل',
    'Undisabled applications': 'تطبيقات غير معاقة',
    'Click the button below':
      'انقر فوق الزر أدناه لإنشاء قاعدة تعطيل التطبيق ، واختر التطبيقات التي تريد تعطيلها',
    'Set disable rules': 'قم بتعيين قواعد التعطيل',
    'Disable permanently': 'تعطيل بشكل دائم',
    'Disable ': 'إبطال',
    'Custom disable time': 'وقت التعطيل المخصص',
    'Disable period': 'فترة تعطيل',
    From: 'من',
    To: 'إلى',
    Total: 'المجموع',
    Weekly: 'أسبوعي',
    Time: 'وقت',
    'Select the applications you want to disable':
      'حدد التطبيقات التي تريد تعطيلها',
    'Please select an application below': 'الرجاء تحديد تطبيق أدناه',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'هل أنت متأكد أنك تريد حذف؟ لن تتأثر التطبيقات المعطلة بموجب هذه القاعدة بعد الآن',
    'Disable options:': 'تعطيل الخيارات:',
    'one Disable permanently':
      '1. تعطيل نهائيًا: يعطل التطبيق تمامًا. لن يتمكن طفلك من استخدام التطبيقات المعطلة حتى ترفع التعطيل.',
    'two Custom disable time':
      '2. وقت التعطيل المخصص: يتم تشغيل قاعدة التعطيل فقط خلال نطاقات زمنية محددة ، ويمكن لطفلك استخدام التطبيقات بشكل طبيعي خارج تلك الأوقات.',
    'You can only choose one of the two setting options':
      'يمكنك فقط اختيار واحد من خياري الإعداد',
    complete: 'مكتمل',
    'Screen usage time': 'وقت استخدام الشاشة',
    'Screen Usage Time for xxx': 'وقت استخدام الشاشة لـ xxx',
    'Frequently used applications': 'التطبيقات المستخدمة بكثرة',
    Frequency: 'تكرار',
    'Usage time': 'وقت الاستخدام',
    'Screen disable': 'تعطيل الشاشة',
    'Turn on the switch and set the screen lock duration.':
      'قم بتشغيل المفتاح واضبط مدة قفل الشاشة.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'سيتم تعطيل شاشة جهاز طفلك وستتم استعادتها بعد استهلاك الوقت المحدد.',
    'Disable all screen activities': 'قم بتعطيل كافة أنشطة الشاشة',
    'Set screen disable duration': 'ضبط مدة تعطيل الشاشة',
    'You can lift the disablement at any time': 'يمكنك رفع العجز في أي وقت',
    'Remaining screen disable time on the target device':
      'الوقت المتبقي لتعطيل الشاشة على الجهاز المستهدف',
    'Are you sure you want to lift the screen disablement now?':
      'هل أنت متأكد أنك تريد رفع تعطيل الشاشة الآن؟',
    Yes: 'نعم',
    'This account does not exist, please register first':
      'هذا الحساب غير موجود ، يرجى التسجيل أولاً',
    'Synchronization successful, no new data':
      'المزامنة ناجحة ، لا توجد بيانات جديدة',
    'Location alert': 'تنبيه الموقع',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'أدخل جهاز xxx الذي تراقبه xx في xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'خرج جهاز xxx الذي تراقبه من xx على xxxx-xx-xx',
    'View more location records:': 'عرض المزيد من سجلات الموقع:',
    'Thank you!': 'شكرًا لك!',
    'xxx Team': 'xxx فريق',
    'When obtaining this location':
      'عند الحصول على هذا الموقع ، قام الجهاز بتنشيط تحديد المواقع الافتراضي ، مما قد يؤدي إلى عدم الدقة.',
    'The demo mode does not support this feature, please bind the device first.':
      'لا يدعم الوضع التجريبي هذه الميزة ، يرجى ربط الجهاز أولاً.',
    'Location Tracking': 'تتبع الموقع',
    'General Application': 'تطبيق عام',
    'App Activites Tracking': 'تتبع أنشطة التطبيقات',
    'Screen Time Tracking': 'تتبع وقت الشاشة',
    'Social Application': 'تطبيق اجتماعي',
    'Subject:': 'موضوع:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'تخزين iCloud المستخدم',
    Online: 'عبر الإنترنت',
    Offline: 'غير متصل',
    'Last known location': 'الموقع الأخير المعروف',
    'Please bind your device': 'الرجاء ربط جهازك',
    'Log in iCloud to binding.':
      'قم بتسجيل الدخول إلى حساب iCloud الخاص بالجهاز المستهدف لإكمال الربط.',
    'Please enter your Apple ID': 'الرجاء إدخال Apple ID الخاص بك',
    'Please enter your password': 'الرجاء إدخال كلمة المرور',
    'Your entered account or password is incorrect.':
      'الحساب أو كلمة المرور التي أدخلتها غير صحيحة.',
    'Ensure Apple ID and password':
      'تأكد من إدخال Apple ID وكلمة المرور لجهاز المستهدف والمصادقة وإكمال الربط..',
    'Forgot Apple ID or password?': 'هل نسيت Apple ID أو كلمة المرور؟',
    'Devices using the same iCloud account will be bound':
      'ستتم ربط الأجهزة التي تستخدم نفس حساب iCloud.',
    'Two-Factor Authentication': 'المصادقة الثنائية',
    'A message has been sent':
      'تم إرسال رسالة تحتوي على رمز التحقق إلى جهازك. الرجاء إدخال رمز التحقق للمتابعة.',
    'A message containing a verification code has been sent to xxx.':
      'تم إرسال رسالة تحتوي على رمز التحقق إلى xxx. الرجاء إدخال رمز التحقق للمتابعة.',
    'Incorrect verification code': 'رمز التحقق غير صحيح',
    'Resend Verification Code': 'إعادة إرسال رمز التحقق',
    'Get SMS Verification Code': 'الحصول على رمز التحقق عبر الرسائل النصية',
    'Please select a phone number to receive the verification code.':
      'الرجاء تحديد رقم هاتف لتلقي رمز التحقق.',
    'Please select': 'الرجاء تحديد',
    'The current iCloud has expired':
      'انتهت صلاحية التحقق من حساب iCloud الحالي. يرجى تسجيل الدخول مرة أخرى إلى حساب iCloud الخاص بك لمزامنة البيانات. ',
    'current iCloud has expired':
      'انتهت صلاحية التحقق من حساب iCloud الحالي. يرجى تسجيل الدخول مرة أخرى إلى حساب iCloud الخاص بك لمزامنة البيانات. انتقل إلى التحقق >>',
    'Go to verification >>': 'انتقل إلى التحقق >>',
    'Data synchronization failed, iCloud account expired':
      'فشلت مزامنة البيانات ، انتهت صلاحية حساب iCloud ، يرجى العودة إلى الصفحة الرئيسية وإعادة التحقق.',
    'Created geofences': 'الحواجز الجغرافية التي تم إنشاؤها',
    'No geofences created': 'لم يتم إنشاء حواجز جغرافية',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - تسعير وخطط تطبيق مراقبة الوالدين',
    '30-Day Money Back Guarantee': 'ضمان استعادة الأموال خلال 30 يومًا',
    'Professional Customer Support': 'دعم العملاء المحترفين',
    'All Prices Exclusive of VAT': 'جميع الأسعار لا تشمل ضريبة القيمة المضافة',
    '100% Secure': '100% بنسبة آمن',
    'More Features to Keep Your Kids Safe Online':
      'المزيد من الميزات للحفاظ على أمان أطفالك على الإنترنت',
    Features: 'سمات',
    'Monitor App': 'مراقبة التطبيق',
    'Type of Data': 'نوع البيانات',
    'WhatsApp Chat': 'WhatsApp دردشة ',
    'App Activity': 'نشاط التطبيق',
    'App Blocker': '  مانع التطبيقع   ',
    'Monitor Social Apps': 'مراقبة التطبيقات الاجتماعية',
    'Location History': 'تاريخ الموقع',
    'Location Reminder': 'تذكير الموقع',
    'Live Location': 'الموقع المباشر',
    'Safe Search': 'البحث الآمن',
    'Web Filter': 'تصفية الويب',
    'Browser History': 'التاريخ المتصفح',
    'Content Detection': 'اكتشاف المحتوى',
    'All SMS': 'جميع SMS',
    Photos: 'صور',
    Video: 'فيديو',
    Calendar: 'تقويم',
    Documents: 'وثائق',
    'Record Screen': 'شاشة التسجيل',
    'Call History': 'التاريخ المكالمات',
    'Call Recording': 'تسجيل المكالمات',
    Contacts: 'جهات الاتصال',
    Messages: 'رسائل',
    'Call Logs': 'سجلات المكالمات',
    'Chat History': 'تاريخ الدردشة',
    'The device has been bound to other account':
      'تم ربط الجهاز بحساب آخر، يرجى إلغاء ربطه أولاً أو الاتصال بخدمة العملاء لإلغاء ربطه.',
    Gallery: 'معرض',
    'Hidden or Deleted Albums': 'البومات مخفية أو محذوفة',
    'Recent Projects': 'المشاريع الأخيرة',
    'Personal Collection': 'شخصي',
    'Recently Deleted': 'حذف مؤخرًا',
    'Live Photos': 'صورة حية',
    'Exposure Photos': 'صورة التعرض',
    'Panoramic Photos': 'صورة بانورامية',
    'Time-Lapse Photography': 'تصوير فائق الوقت',
    'Slow Motion': 'حركة بطيئة',
    Portrait: 'لَوحَة',
    'Burst Mode': 'التقاط متواصل',
    Screenshot: 'لقطة الشاشة',
    Motion: 'ديناميكي',
    Hide: 'إخفاء',
    'This iCloud account':
      'هذا الحساب في iCloud غير مرتبط بأي جهاز. يرجى تسجيل الدخول على جهاز.',
    'Data synchronization':
      'يمكن أن تستغرق مزامنة البيانات بعض الوقت، يرجى التحلي بالصبر.',
    'Location History1': 'تاريخ الموقع',
    'Auto Renewal': 'التجديد التلقائي والإلغاء في أي وقت',
    Month: 'شهر',
    Quarter: 'ربع',
    Year: 'عام',
    Day: 'يوم',
    'See Demo': 'انظر العرض',
    'We Accept': 'نحن نقبل',
  },
  famikeep220: {
    'Browser Monitoring': 'مراقبة المتصفح',
    'Browser History': 'تاريخ المتصفح',
    'Browser Bookmarks': 'إشارات المرجعية التصفح',
    'Serial Number': 'رقم سري',
    'Website Restrictions': 'قيود الموقع ',
    'Restriction Rules': 'قواعد القيود',
    'Prohibited Access': 'الوصول ممنوع',
    'Access Warning': 'تحذير الوصول',
    'Restricted Website Log': 'سجل مواقع مقيدة',
    'Click to Add Restricted Website': 'انقر لإضافة موقع  مقيد',
    'Restriction Method:': ':طريقة القيد',
    'Prohibit Access':
      '1. حظر الوصول: طفلك لن يتمكن من الوصول إلى مواقع الويب المحظورة داخل المستعرض حتى تقوم بإزالة قاعدة حظر الموقع.',
    'Access Alerts':
      '2. تنبيهات الوصول: عندما يقوم طفلك بزيارة مواقع الويب وفقًا للقواعد، ستتلقى تنبيهات بالبريد الإلكتروني وسيتم إنشاء سجلات التنبيه (سيتم إرسال بريد إلكتروني واحد فقط للتنبيه في غضون 24 ساعة لنفس الموقع).',
    'the rule disabling access takes priority.':
      'إذا كان موقع الويب نفسه يخضع لقاعدتين مختلفتين، تأخذ قاعدة تعطيل الوصول الأولوية.',
    'Set Restriction Rules': 'تعيين قواعد القيد',
    'This option will block': 'ستمنع هذه الخيارات الوصول إلى المواقع المُدخلة',
    'This option will allow':
      'ستسمح هذه الخيارات بالوصول إلى الموقع الذي تم إدخاله.',
    'For example':
      'على سبيل المثال، إذا قمت بإدخال example.com، سيتم حظر جميع صفحات الويب المرتبطة (مثل www.example.com/page1.html) أيضًا.',
    'If you enter the specific website page':
      'إذا أدخلت صفحة الموقع الإلكتروني الخاصة www.example.com/page1.html، سيتم حظر تلك الصفحة فقط.',
    'This option allows':
      'تسمح هذه الخيارات بالوصول إلى الموقع الإلكتروني الذي تم إدخاله ولكنه سيقوم بإنشاء سجلات التصفح المقابلة.',
    'Send records by email': 'أرسل السجلات عبر البريد الإلكتروني',
    'When enabled':
      'عند التمكين ، سنرسل السجلات إلى البريد الإلكتروني المسجل عند الوصول إلى الموقع الإلكتروني المدخل.',
    'enter example':
      'على سبيل المثال، إذا أدخلت example.com، سيتم تنبيه جميع صفحات الويب ذات الصلة (مثل www.example.com/page1.html) أيضًا.',
    'enter the specific webpage':
      'إذا أدخلت الصفحة الويب المحددة www.example.com/page1.html، سيتم تنبيه هذه الصفحة فقط.',
    'Enter the website to be restricted': 'أدخل الموقع  المراد تقييده',
    Bookmark: 'إشارة مرجعية',
    'Bookmark list': 'قائمة الإشارات المرجعية',
    'Total xx bookmarks': 'إجمالي xx الإشارات المرجعية',
    'Enter the URL': 'أدخل عنوان URL (مثل: youtube.com)',
    'Added URLs': 'عناوين URL المضافة',
    Add: 'أضف ',
    'Please add the restricted URL': 'الرجاء إضافة العنوان URLالمحدود',
    'Are you sure': 'هل أنت متأكد أنك تريد الحذف؟ سيتم إزالة القيود بعد الحذف.',
    Video: 'فيديو',
    'Please note that videos':
      'يرجى ملاحظة أن مقاطع الفيديو ستتم مشاركتها فقط عندما يكون الجهاز متصلاً بشبكة WIFI.',
    'Website access alert': 'تنبيه الوصول إلى الموقع ',
    'monitoring accessed':
      'الجهاز xxx الذي تراقبه قام بالوصول إلى xx في xxxx-xx-xx.',
    'View more records:': ':عرض المزيد من السجلات',
    'Download and install the app.': 'قم بتنزيل التطبيق وتثبيته.',
    'After installation': 'بعد التثبيت، يرجى فتح التطبيق بزيارة xxx.com.',
    'Open immediately': 'فتح على الفور',
    'Unable to open System Service:': 'غير قادر على فتح System Service:',
    'Please ensure this device has installed':
      '1. يرجى التأكد من أن هذا الجهاز قد قام بتثبيت System Service (انقر لتحميل System Service)',
    'click download': '(انقر لتحميل System Service)',
    'Copy the link':
      '2. انسخ عنوان وصلة صفحة الويب الحالية وافتحها في المتصفح المدمج',
    'Sure close': ' تتلقى تنبيهات البريد الإلكتروني بعد الإغلاق.',
    Loading: 'تحميل',
    'Loading failed': 'فشل التحميل. يرجى تنزيل الملف للعرض المحلي.',
    'Select All Videos': 'تحديد جميع مقاطع الفيديو في الصفحة الحالية',
    tips3: "سيتم إخفاء التطبيق على الجهاز تحت اسم 'خدمة النظام'.",
    'If the video cannot be loaded':
      'إذا لم يتمكن الفيديو من التحميل لفترة طويلة، يرجى تحميله ومشاهدته.',
  },
  famikeep222: {
    'Continue Google': 'متابعة مع Google',
    or: 'أو',
    Continue: 'واصل',
    'legal guardian':
      'أنا الوصي القانوني على أي أطفال قاصرين مرتبطين بهذا الحساب',
    'take around 10 mins': 'يُرجى ربط جهاز Android (سيستغرق حوالي 10 دقائق)',
    'Get the Android device': '1. احصل على جهاز Android المراد مراقبته',
    'Install Companion App': '2. قم بتثبيت التطبيق المصاحب',
    'Login to set permissions': '3. قم بتسجيل الدخول لتعيين الأذونات',
    twoContent1:
      'يرجى الوصول إلى ** من جهاز Android الخاضع للمراقبة لتنزيل التطبيق وتثبيته',
    'set up permissions':
      'يُرجى تسجيل الدخول إلى حسابك في تطبيق المصاحب وإعداد الأذونات ، ثم يمكنك البدء في المراقبة. (يرجى ملاحظة أن التطبيق يمكن تسجيل الدخول إليه فقط عبر كلمة مرور الحساب ، يرجى ** إذا لم تقم بتعيين كلمة مرور بعد).',
    'Click here': 'انقر هنا',
    'continue collecting data':
      '5. إذا انتهت صلاحية أذونات الجهاز المرتبط ، يمكنك مواصلة جمع البيانات عن طريق إعادة الربط.',
  },
  // 用户模块
  language: 'اللغة',
  signIn: 'تسجيل ',
  email: 'البريد الإلكتروني',
  password: 'كلمة المرور',
  sendCode: 'أرسل رمز التحقق',

  // 登录
  signingIn: 'تسجيل الدخول',
  noAccount: 'لا حساب؟',
  signUpNow: 'تسجيل الأن',
  forgetPassword: 'هل نسيت كلمة المرور؟',

  // 注册
  signUp: ' تسجيل',
  createAccount: 'انشاء حساب',
  emVerificationCode: 'كود التحقق من البريد الإلكتروني',
  creatingAccount: 'جاري إنشاء الحساب ...',
  alreadyHaveAccount: 'هل لديك حساب؟ اذهب إلى',
  SignInUp: 'اذهب لتسجيل الدخول',
  send: 'تم إرسال',
  sing: 's',
  byCreatingAnAccount: 'إنشاء حساب، يعني أنك قد قرأت ووافقت على',
  termsOfService: ' شروط الخدمة',
  and: 'و',
  privacyPolicy: 'سياسة الخصوصية',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'استرجاع كلمة السر',
  backTo: 'العودة إلى تسجيل الدخول',
  newPassword: 'كلمة السر الجديدة',
  resetPassword: 'إعادة تعيين كلمة المرور',
  loginSucceeded: '!تسجيل الدخول بنجاح',
  logoutSucceeded: '!تسجيل الخروج بنجاح',

  // 用户模块异常提示
  passwordMmustBe8:
    'يجب أن تتكون كلمة المرور من 8 إلى 20 حرفًا ومجموعة من الأحرف والأرقام',
  pleaseEnterPassword: 'الرجاء إدخال كلمة مرور حسابك',
  pleaseEnterEmailAddress: 'الرجاء إدخال عنوان البريد الإلكتروني',
  invalidEmailFormat: 'تنسيق بريد إلكتروني غير صالح',
  pleaseEnterVerifiCode: 'الرجاء إدخال رمز التحقق',
  incorrectVerifiCode: 'رمز التحقق غير صحيح',

  // Toast文案
  verificationCodeHasBeen:
    'تم إرسال رمز التحقق، يرجى التحقق من بريدك الإلكتروني.',
  pleaseReadAndAgree: 'يرجى قراءة شروط الخدمة وسياسة الخصوصية والموافقة عليها.',
  networkAbnormal: 'شبكة غير طبيعية',
  emailRegistered: 'لم يتم تسجيل البريد الإلكتروني، يرجى التسجيل أولاً.',
  passwordNotold:
    'لا يمكن أن تكون كلمة المرور الجديدة هي نفسها كلمة المرور القديمة',
  invalidPasswordFormat: 'صيغة كلمة السر غير صحيحة',
  theEmailHasRegistered: 'هذا البريد الالكتروني مسجل سابقا',
  verificationCodeGetAgain:
    'انتهت صلاحية رمز التحقق، يرجى الحصول عليه مرة أخرى',
  erificationCodeMatch: 'البريد الإلكتروني ورمز التحقق غير متطابقين',
  incorrectPasswordOrEmail:
    'كلمة المرور أو البريد الإلكتروني غير صحيح، يرجى إدخال مرة أخرى',
  requestError: 'خطأ الطلب',
  pleaseTryAgainLater: 'طلبك كثيرة، الرجاء المحاولة لاحقا.',
  accessDenied: 'رفض الوصول',
  requestErrorResourceNotFound: 'خطأ في الطلب، لم يتم العثور على المورد.',
  serverError: 'خطأ في الخادم',
  connectionServerFailed: 'فشل خادم الاتصال',
  requestTimedOut: 'الطلب منتهي المدة',
  networkAbnormaled: 'شبكة غير طبيعية',
  serviceUnavailable: 'الخدمة غير متوفرة',
  httpVersioSupportTheRequest: 'إصدار http لا يدعم الطلب',
  wrongPasswordTime:
    'لقد أدخلت كلمة مرور خاطئة 5 مرات. حسابك مغلق مؤقتًا ولا يمكن تسجيل الدخول. يرجى المحاولة مرة أخرى بعد 10 دقائق.',
};
