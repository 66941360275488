const mutations: any = {
  setIsShowAppBlockLeft(state: any, status: boolean) {
    console.log('是否展示左侧菜单栏');
    state.isShowAppBlockLeft = status;
  },
  setIsShowMessageLeft(state: any, status: boolean) {
    console.log('是否展示左侧菜单栏');
    state.isShowMessageLeft = status;
  },
  setIsShowWhatsAppLeft(state: any, status: boolean) {
    console.log('是否展示左侧菜单栏');
    state.isShowWhatsAppLeft = status;
  },
  setIsShowInstagramLeft(state: any, status: boolean) {
    console.log('是否展示左侧菜单栏');
    state.isShowInstagramLeft = status;
  },
};
export default mutations;
