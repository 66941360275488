export const PT = {
  chat: {
    pictureText: '[Imagem]',
    videoText: '[Vídeo]',
    audioText: '[Áudio]',
    contactCard: '[Carta de Contato]',
    location: '[Localização]',
    other: '[Outro]',
    all: 'Tudo',
    today: 'Hoje',
    yesterday: 'Ontem',
    last7Days: 'Últimos 7 Dias',
    last30Days: 'Últimos 30 Dias',
    customRange: 'Selecionar Período Customizado',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    pushNotify: 'Notificações Push',
    numbers: 'Número',
    emails: 'Emails',
    outgoingCalls: 'Chamadas Efetuada',
    incomingCalls: 'Chamadas Recebidas',
    missedCalls: 'Chamadas Perdidas',
    answeredCalls: 'Chamadas Atendidas',
    noAnswer: 'Sem Resposta',
    canceledCalls: 'Chamadas Canceladas',
    loadingText: 'Carregando, por favor espere...',
    noMoreText: 'Não há mais conteúdo',
    noInfoText:
      'Sem informação do evento, por favor verifique novamente  mais tarde.',
    noAddressText:
      'Sem informação do contato, por favor verifique novamente mais tarde.',
    noCallText:
      'Sem Informação do Histórico de Chamadas. Por Favor Verifique Novamente Mais Tarde.',

    dateSelect: 'Selecione a data',
    sentFile: 'Enviou um arquivo',
    sentAudio: 'Enviou um áudio',
    unGetFile: 'Não foi possível  obter o arquivo',
    unGetAudio: 'Não foi possível obter o áudio',

    syncFailed: 'Falha ao sincronizar dados!',
    syncSuccessed: 'Sincronizar dados com sucesso!',
    unknownContact: 'Contato desconhecido',
    noSyncNotice1: 'Os dados não foram sincronizados por mais de ',
    noSyncNotice2: '24 horas',
    noSyncNotice3:
      '. Verifique se o telefone de destino está ligado e conectado à Internet e se o aplicativo "System Service" está funcionando normalmente.',
    sync: 'Sincronizar',
    startDate: 'Data de Início',
    endDate: 'Data de Término',
    noSupportToast: 'A filtragem de data não é suportada no Demo.',

    unknowVideo:
      'Vídeo. Detalhes não disponíveis devido à política de privacidade do Instagram.',
    unknowImage:
      'Foto. Detalhes não disponíveis devido à política de privacidade do Instagram.',
    unknowAudio:
      'Mensagem de Áudio. Detalhes não disponíveis devido à política de privacidade do Instagram.',
    outgoingVoice: 'Ligação de áudio efetuada',
    incomingVoice: 'Ligação de áudio recebida',
    outgoingVideo: 'Ligação de vídeo efetuada',
    incomingVideo: 'Ligação de vídeo recebida',
  },
  user: {
    userProfile: 'Perfil de Usuário',
    usesrname: 'Nome de Usuário',
    edit: 'Editar',
    email: 'Email',
    permission: 'Informação de Permissão',
    bounded: 'Dispositivo(s) vinculado(s):',
    restDevice: 'Dispositivo(s) disponível(is) restante(s):',
    expire: 'Data de expiração:',
    expired: '(Expirado)',
    logout: 'Sair',
    changename: 'Alterar nome de usuário',
    save: 'Salvar',
    inputname: 'Por favor digite o nome de usuário.',
    nameNull: 'O formato de usuário está anormal, digite novamente',
    networkError:
      'Falha ao alterar o nome de usuário, por favor verifique sua rede',
    nameSuccess: 'Alterar nome de usuário com sucesso',
    changeavatar: 'Mudar foto do perfil',
    loading: 'Carregando…',
    changeAvatar: 'Mudar',
    supported:
      'Suporta os formatos de jpg, jpeg, png, e o tamanho não pode exceder 2M.',
    maxsize: 'O tamanho da imagem não pode exceder 2M',
    avatarError: 'Falha ao mudar foto do perfil, por favor verifique sua rede',
    avatarSuccess: 'Mudar foto do perfil com sucesso',
    imgError: 'Erro de imagem',
    imageType: 'Formato da imagem não suportado',
    nameEmpty: 'Nome de utilizador não pode ficar em branco',
  },
  leftTab: {
    device: 'Dispositivo',
    unbound: 'Dispositivo(s) não Vinculado(s)',
    addDevice: 'Adicionar Dispositivo',
    dashboard: 'Dashboard',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Por favor vincule um dispositivo primeiro',
    payRemind: 'Por favor compre primeiro',
    purchase: 'Comprar Agora',
    unboundName: 'Vincule Dispositivo Agora',
    toBuy: 'Compre Dispositivo',
  },
  guide: {
    title: 'Por favor vincule seu dispositivo primeiro',
    oneT: '1. Obter Dispositivo de Destino',
    oneContent:
      'Para monitorar o dispositivo, você precisa de obter o dispositivo de destino por 10 minutos.',
    twoT: '2. Baixar e Instalar o App',
    twoContent1: 'Digite',
    twoContent2:
      ' no navegador do dispositivo de destino, em seguida baixe e instale o app.',
    threeT: '3. Configurar as permissões do dispositivo',
    threeContent:
      'Siga o guia para configurar as permissões do dispositivo. Depois de complementar as configurações, você pode monitorá-lo.',
    tips1:
      "Para garantir a furtividade, o app será disfarçado como o software de sistema e será nomeado como 'System Service'",
    tips2:
      'Para garantir o anônimo, pode deletar o histórico de navegação e pacotes de instalação do app após instalá-lo.',
    tips3:
      "este app será mostrado como 'System Service' na área de trabalho, e você pode ocultá-lo na pasta para um melhor efeito furtivo.",
    tips41:
      'Para garantir que você possa instalar o aplicativo e configurar as permissões mais rapidamente, você pode ver',
    tips42: 'primeiro para aprender como fazê-lo.',
    detail: 'Tutorias mais detalhados',
    tips5:
      'Se a permissão do dispositivo vinculado expirou, você pode alterar a vinculação do dispositivo e vinculá-la à permissão para continuar coletando dados. ',
    rebound: 'Clique para alterar a vinculação do dispositivo',
  },
  dashboard: {
    contact: 'Principais Contatos no WhatsApp (dentro de 7 dias)',
    call: 'Principais Chamadas no WhatsApp',
    view: 'Ver Tudo',
    account: 'Conta',
    id: 'ID de Conta:',
    expire: 'Data de expiração:',
    bound: 'Dispositivo(s) Vinculado(s):',
    restDevice: 'Dispositivo(s) disponível(is) restante(s):',
    target: 'Sobre Dispositivo de Destino',
    brand: 'Marca:',
    model: 'Modelo:',
    version: 'Versão de Android:',
    battery: 'Bateria:',
    wifi: 'Wi-Fi:',
    appversion: 'Versão de App:',
    update: 'Última Atualização:',
    blank: 'Sem Atividade Recente',
    unbindSuc: 'Desvincular com sucesso!',
    unbindFail: 'Falha ao desvincular!',
    ReboundSuc: 'Alterar dispositivo vinculado com sucesso!',
    ReboundFail: 'Falha ao alterar dispositivo vinculado!',
    noPlace: 'Nenhum dispositivo para alterar a vinculação!',
    beExpOne: 'A permissão do seu dispositivo expirará em',
    beExpTwo: 'dias',
    beExpThree:
      'e os dados do dispositivo monitorado não serão sincronizados após a expiração.',
    expiredOne: 'A permissão do seu dispositivo expirou. Após',
    expiredTwo:
      ', os dados coletados anteriormente serão apagados. Após a renovação, os dados do dispositivo monitorado continuarão a ser sincronizados.',
    renew: 'Renove agora',

    insContact: 'Top Contatos no Instagram (dentro de 7 dias)',
    insCall: 'Top Chamadas no Instagram',
  },
  payPage: {
    title: 'Preços e Planos do ParentalGuard',
    dec: 'Monitorar o WhatsApp com o ParentalGuard é mais fácil do que você imagina!',
    bigSave: 'POUPA MAIS',
    one: '1 Mês Licença',
    three: '3 Mês Licença',
    year: '1 Ano Licença',
    toBuy: 'Comprar Agora',
    auto: 'Renovação Automática',
    cancelAny: 'Cancelar a qualquer momento',
    update: 'Atualizar dados automaticamente a cada cinco minutos',
    collect: 'Coletar todas as conversas',
    interaction: 'Melhor experiência interativa',
    hide: 'Modo furtivo',
  },
  pop: {
    changeBound: 'Alterar Dispositivo Vinculado ',
    reboundText1: 'Tem certeza de alterar a vinculação do dispositivo',
    reboundText2:
      'e vinculá-la à permissão do dispositivo não vinculado para continuar coletando dados (Data de expiração:',
    reboundText3: ')?',
    reboundText4:
      'e vinculá-la à permissão do dispositivo não vinculado para continuar coletando dados',
    noRebound:
      'Nenhuma permissão do dispositivo disponível, por favor compre primeiro.',
    goPay: 'Compre Agora',
    reboundList: 'Dispositivo Vinculado',
    reboundDec:
      'As seguintes permissões do dispositivo expiraram. Selecione um dispositivo e vinculá-la à permissão do dispositivo atualmente não vinculada para continuar coletando dados.',
    rebounding: 'Alterando dispositivo vinculado',
    reboundFail1: 'Falha ao alterar a vinculação do dispositivo',
    reboundFail2: '. Queria tentar novamente?',
    tryAgain: 'Tentar novamente',
    unbindTitle: 'Desvincular dispositivo',
    unbindText1: 'Tem certeza de desvincular o dispositivo',
    unbindText2: '? Todos os dados coletados serão apagados.',
    remind: 'Aviso de expiração',
    expiredPay: 'Renove',
    cancel: 'Cancelar',
    yes: 'Sim',
    no: 'Não',
  },
  navTop: {
    langTitle: 'Mudar o idioma',
  },
  famikeep200: {
    Calls: 'Chamadas',
    Messages: 'Mensagens',
    Contacts: 'Contatos',
    Photos: 'Fotos',
    Applications: 'Aplicativos',
    'Compared to yesterday': 'Comparado a Ontem',
    'All locations': 'Todas as localizações',
    'Weekly average usage time': 'Média Diária de Tempo de Uso Desta Semana',
    'Floating comparison with last week': 'Comparado a Semana Passada',
    Average: 'Média',
    Mon: 'Seg.',
    Tue: 'Terç.',
    Wed: 'Qua.',
    Thu: 'Qui.',
    Fri: 'Sex.',
    Sat: 'Sáb.',
    Sun: 'Dom.',
    'Most used applications': 'Aplicativos mais usados',
    Geolocation: 'Geolocalização',
    'Last updated': 'Atualizado:',
    'Geolocation permission':
      "O acesso de geolocalização para o APP 'System Service' está desativado e a aquisição da localização falha",
    Geofence: 'Cerca geográfica',
    'Create a geofence':
      'Crie cercas geográficas para ser alertado por email quando seu filho entrar/sair da área especificada',
    'enters/exits': 'entrar/sair da área especificada',
    'Create a new geofence': 'Criar uma nova cerca geográfica',
    'Geofence name': 'Nome da cerca geográfica',
    'Set geofence location': 'Definir a localização da cerca geográfica',
    'Set radius': 'Definir o raio',
    'Set monitoring time': 'Agendar horários de monitoramento',
    'please choose': 'Agende horários',
    Daily: 'Todos os dias',
    'Every Monday': 'Toda segunda-feira',
    'Every Tuesday': 'Toda terça-feira',
    'Every Wednesday': 'Toda quarta-feira',
    'Every Thursday': 'Toda quinta-feira',
    'Every Friday': 'Toda sexta-feira',
    'Every Saturday': 'Todo sábado',
    'Every Sunday': 'Todo domingo',
    'Send alerts via email': 'Enviar os alertas por email',
    'We will send alert emails to the registered email':
      'Enviaremos o e-mail de alerta para a caixa postal cadastrada',
    Save: 'Salvar',
    'Please enter a geofence name': 'Digite o nome de cerca geográfica',
    'Please select a geofence location':
      'Selecione a localização da cerca geográfico',
    Entered: 'Entrou',
    Exited: 'Saiu',
    Edit: 'Editar',
    Delete: 'Excluir',
    'Are you sure you want to delete':
      'Tem certeza de que quer excluir xxx cerca geográfica? Após a exclusão, os dados de monitoramento correspondentes também serão excluídos',
    'Are you sure you want to close':
      'Quando esta opção estiver desativada, você não receberá os alertas por e-mail ao entrar/sair da cerca geográfica. Tem certeza de que deseja desativá-la?',
    'Call history': 'Histórico das Chamadas',
    Status: 'Estado',
    Number: 'Número',
    Duration: 'Duração',
    Date: 'Data',
    incoming: 'Ligação recebida',
    outgoing: 'Ligação efetuada',
    missed: 'Ligação perdida',
    'no answered': 'Sem Resposta',
    Address: 'Endereço',
    'Added time': 'Data de adicionamento',
    'Sort by name': 'Classificar por Nome',
    'Sort by time': 'Classificar por Data',
    'Please note that photos':
      'Observe que as fotos só serão carregadas se o dispositivo estiver conectado ao WIFI',
    Album: 'Álbuns',
    'Select all photos on the current page': 'Selecionar tudo da página atual',
    'Previous page': 'Página anterior',
    'Next page': 'Próxima página',
    Back: 'Voltar',
    'confirm to delete':
      'As fotos não podem ser recuperadas após a exclusão. Tem certeza de que deseja excluí-las?',
    Download: 'Download',
    Calendar: 'Calendário',
    Title: 'Título',
    Description: 'Descrição',
    Location: 'Localização',
    'Start time': 'Horário de Início',
    'End time': 'Horário de Término',
    'Installed applications': 'Apps instalados',
    'All applications': 'Todos os apps',
    Name: 'Nome',
    Version: 'Versão',
    Size: 'Tamanho',
    'Installation date': 'Data de Instalação',
    'Disable applications': 'Desativar aplicativos',
    'Seted disable rules': 'Regras de Desativação Criadas',
    'Undisabled applications': 'Apps que não estão desativados',
    'Click the button below':
      'Clique no botão abaixo para criar uma regra de desativação de App, e selecione o App que deseja desativar',
    'Set disable rules': 'Definir as Regras de Desativação',
    'Disable permanently': 'Desativar permanentemente',
    'Disable ': 'Desativar',
    'Custom disable time': 'Personalizar o Período de Desativação',
    'Disable period': 'Período de Desativação',
    From: 'De',
    To: 'a',
    Total: 'Total',
    Weekly: 'semanalmente',
    Time: 'Horário',
    'Select the applications you want to disable':
      'Selecioneos os Apps Que Deseja Desativar',
    'Please select an application below': 'Selecione um aplicativo abaixo',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Tem certeza de que quer excluir? Os apps desativados sob esta regra não serão mais afetados',
    'Disable options:': 'Opções de desativação',
    'one Disable permanently':
      '1. Desativar permanentemente: Desativa o app completamente. Seu filho não poderá usar o app desativado até que você suspenda a regra de desativação de apps.',
    'two Custom disable time':
      '2. Personalizar o período de desativação: Só aciona a regra de desativação dentro de um intervalo específico, e seu filho pode usar o app normalmente fora do intervalo.',
    'You can only choose one of the two setting options':
      'Você só pode escolher uma das duas opções de configuração',
    complete: 'Concluído',
    'Screen usage time': 'Tempo de uso da tela',
    'Screen Usage Time for xxx': 'Tempo de Uso da Tela Para xxx',
    'Frequently used applications': 'Apps mais usados',
    Frequency: 'Frequência',
    'Usage time': 'Tempo de uso',
    'Screen disable': 'Desativar a tela',
    'Turn on the switch and set the screen lock duration.':
      'Ativar e definir a duração do tempo do bloqueio da tela.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'A tela do dispositivo do seu filho será desativada e restaurada após o tempo definido ser consumido.',
    'Disable all screen activities': 'Desativar todas as atividades da tela',
    'Set screen disable duration': 'Definir a Duração da Desativação da Tela',
    'You can lift the disablement at any time':
      'Você Pode Suspender a Desativação a Qualquer Momento',
    'Remaining screen disable time on the target device':
      'Tempo Restante de Desativação da Tela no Dispositivo de Destino',
    'Are you sure you want to lift the screen disablement now?':
      'Tem Certeza de Que Quer Suspender a Desativação da Tela Agora?',
    Yes: 'Sim',
    'This account does not exist, please register first':
      'Esta conta não existe, registre-se primeiro',
    'Synchronization successful, no new data':
      'Sincronização bem-sucedida, sem dados novos',
    'Location alert': 'Alerta de localização',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'O dispositivo xxx que você está monitorando entrou xx em xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'O dispositivo xxx que você está monitorando saiu xx em xxxx-xx-xx',
    'View more location records:': 'Veja mais registros de localização:',
    'Thank you!': 'Obrigado!',
    'xxx Team': 'Equipe xxx',
    'When obtaining this location':
      'Ao obter esta localização, o dispositivo ativou o posicionamento virtual, o que pode levar a imprecisões.',
    'The demo mode does not support this feature, please bind the device first.':
      'O modo de demonstração não suporta este recurso, por favor, vincule o dispositivo primeiro.',
    'Location Tracking': 'Rastreamento de Localização',
    'General Application': 'Aplicação Geral',
    'App Activites Tracking': 'Rastreamento de Atividades de Aplicativos',
    'Screen Time Tracking': 'Rastreamento de Tempo de Tela',
    'Social Application': 'Aplicativo Social',
    'Subject:': 'Assunto:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'Armazenamento usado no iCloud',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Última localização conhecida',
    'Please bind your device': 'Por favor, vincule o seu dispositivo',
    'Log in iCloud to binding.':
      'Faça login na conta iCloud do dispositivo de destino para concluir a vinculação.',
    'Please enter your Apple ID': 'Por favor, insira seu Apple ID',
    'Please enter your password': 'Por favor, insira sua senha',
    'Your entered account or password is incorrect.':
      'A conta ou a senha que você digitou está incorreta.',
    'Ensure Apple ID and password':
      'Certifique-se de inserir a Apple ID e a senha do dispositivo de destino, e conclua a vinculação após a autenticação',
    'Forgot Apple ID or password?': 'Esqueceu sua Apple ID ou senha?',
    'Devices using the same iCloud account will be bound':
      'Dispositivos que utilizam a mesma conta iCloud serão vinculados.',
    'Two-Factor Authentication': 'Autenticação de dois fatores',
    'A message has been sent':
      'Uma mensagem com código de verificação foi enviada para o seu dispositivo. Por favor, insira o código de verificação para continuar.',
    'A message containing a verification code has been sent to xxx.':
      'Uma mensagem com código de verificação foi enviada para xxx. Por favor, insira o código de verificação para continuar.',
    'Incorrect verification code': 'Código de verificação incorreto',
    'Resend Verification Code': 'Reenviar o código de verificação',
    'Get SMS Verification Code': 'Obter código de verificação SMS',
    'Please select a phone number to receive the verification code.':
      'Por favor, selecione um número de telefone para receber o código de verificação.',
    'Please select': 'Por favor, selecione',
    'The current iCloud has expired':
      'A verificação da conta atual do iCloud expirou. Por favor, faça login novamente na sua conta do iCloud para sincronizar os dados.',
    'current iCloud has expired':
      'A verificação da conta atual do iCloud expirou. Por favor, faça login novamente na sua conta do iCloud para sincronizar os dados. Ir para a verificação >>',
    'Go to verification >>': 'Ir para verificação >>',
    'Data synchronization failed, iCloud account expired':
      'Falha na sincronização de dados, conta iCloud expirada, por favor, volte para a página inicial e refaça a verificação.',
    'Created geofences': 'Cercas geográficas criadas',
    'No geofences created': 'Sem Dados',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Preços e planos do aplicativo de controle parental',
    '30-Day Money Back Guarantee': 'Garantia de reembolso de 30 dias',
    'Professional Customer Support': 'Suporte Profissional',
    'All Prices Exclusive of VAT': 'IVA excluído em todos os preços mostrados',
    '100% Secure': '100% Seguro',
    'More Features to Keep Your Kids Safe Online':
      'Mais recursos para manter seu filho seguro online',
    Features: 'Recursos',
    'Monitor App': 'Aplicação de monitorização',
    'Type of Data': 'Tipo de Dados',
    'WhatsApp Chat': 'Conversa do WhatsApp',
    'App Activity': 'Atividade do Aplicativo',
    'App Blocker': 'Bloqueador de Aplicativos',
    'Monitor Social Apps': 'Monitorizar Aplicativos Sociais',
    'Location History': 'Histórico de Localização',
    'Location Reminder': 'Lembrete de Localização',
    'Live Location': 'Localização ao Vivo',
    'Safe Search': 'Pesquisa segura',
    'Web Filter': 'Filtro da Web',
    'Browser History': 'Histórico do navegador',
    'Content Detection': 'Detecção de conteúdo',
    'All SMS': 'Todos os SMS',
    Photos: 'Fotos',
    Video: 'Vídeos',
    Calendar: 'Calendário',
    Documents: 'Documentos',
    'Record Screen': 'Gravação da Tela',
    'Call History': 'Histórico de chamadas',
    'Call Recording': 'Gravação de chamadas',
    Contacts: 'Contatos',
    Messages: 'Mensagens',
    'Call Logs': 'Registo de chamadas',
    'Chat History': 'Histórico da conversa',
    'The device has been bound to other account':
      'O dispositivo foi vinculado a outra conta, desvincule-o primeiro ou contacte o suporte para desvinculá-lo.',
    Gallery: 'Galeria',
    'Hidden or Deleted Albums': 'Álbuns ocultos ou excluídos',
    'Recent Projects': 'Recentes',
    'Personal Collection': 'Favoritos',
    'Recently Deleted': 'Excluido recentemente',
    'Live Photos': 'Live Fotos',
    'Exposure Photos': 'Foto de exposição',
    'Panoramic Photos': 'Foto panorâmica',
    'Time-Lapse Photography': 'Time-lapse',
    'Slow Motion': 'Câmara lenta',
    Portrait: 'Retrato',
    'Burst Mode': 'Modo Burst',
    Screenshot: 'Captura de Tela',
    Motion: 'Dinâmico',
    Hide: 'Ocultar',
    'This iCloud account':
      'Esta conta do iCloud não está vinculada a nenhum dispositivo. Por favor, faça login em um dispositivo.',
    'Data synchronization':
      'A sincronização de dados pode levar algum tempo, por favor espere.',
    'Location History1': 'Histórico de localização',
    'Auto Renewal': 'Renovação automática e cancelamento a qualquer momento',
    Month: 'Mês',
    Quarter: 'Trimestre',
    Year: 'Ano',
    Day: 'Dia',
    'See Demo': 'Ver demo',
    'We Accept': 'Aceitamos',
  },
  famikeep220: {
    'Browser Monitoring': 'Monitorização do Navegador',
    'Browser History': 'Histórico do Navegador',
    'Browser Bookmarks': 'Favoritos do Navegador',
    'Serial Number': 'Número de Série',
    'Website Restrictions': 'Restrições de Sites',
    'Restriction Rules': 'Regras de Restrição',
    'Prohibited Access': 'Acesso Proibido',
    'Access Warning': 'Alerta de Acesso',
    'Restricted Website Log': 'Histórico de Sites Restritos',
    'Click to Add Restricted Website':
      'Clique para adicionar site a ser restrito',
    'Restriction Method:': 'Método de Restrição:',
    'Prohibit Access':
      '1. Acesso Proibido: Bloquear o acesso a site. Seu filho não poderá acessar aos sites proibidos até que você remova a restrição.',
    'Access Alerts':
      '2. Alertas de Acesso: Quando seu filho acessa os sites restritos, a alerta por email será enviada para você e o histórico de alerta será gerado (Email de alerta para o mesmo site será enviado apenas uma vez em 24 horas.).',
    'the rule disabling access takes priority.':
      'Se o mesmo site estiver sujeito a duas regras diferentes, a regra de desativação de acesso tem prioridade.',
    'Set Restriction Rules': 'Definir as Regras de Restrição',
    'This option will block': 'Bloquear o acesso ao site ou domínio inserido',
    'This option will allow': 'Esta opção permitirá o acesso ao site inserido.',
    'For example':
      'Por exemplo, se inserir example.com, todas as páginas deste domínio(p. ex.: www.example.com/page1.html) também serão bloqueadas.',
    'If you enter the specific website page':
      'Se inserir um URL exato (p. ex.:  www.example.com/page1.html), apenas esse url será bloqueado.',
    'This option allows':
      'Permitir acesso ao site inserido e gerar histórico de navegação',
    'Send records by email': 'Enviar histórico por e-mail',
    'When enabled':
      'Quando ativada, enviaremos o histórico para seu email registrado ao acessar o site inserido.',
    'enter example':
      'Por exemplo, se inserir example.com, também serão emitidos alertas para todas as páginas deste domínio(p. ex.: www.example.com/page1.html) ',
    'enter the specific webpage':
      'Se inserir um URL exato (p. ex.:  www.example.com/page1.html), apenas este URL será alertado.',
    'Enter the website to be restricted': 'Insira o site a ser restrito',
    Bookmark: 'Favoritos',
    'Bookmark list': 'Lista de Favoritos',
    'Total xx bookmarks': 'Total de xx favoritos',
    'Enter the URL': 'Insira o URL (por exemplo, youtube.com)',
    'Added URLs': 'URLs inserido',
    Add: 'Adicionar',
    'Please add the restricted URL': 'Por favor, adicione o URL a ser restrita',
    'Are you sure':
      'Tem certeza de quer excluir o site? A sua restrição será removida após a exclusão.',
    Video: 'Vídeo',
    'Please note that videos':
      'Os vídeos só serão carregados quando o dispositivo estiver conectado ao WIFI.',
    'Website access alert': 'Alerta de acesso a site',
    'monitoring accessed':
      'O dispositivo xxx monitorado acessou xx em xxxx-xx-xx.',
    'View more records:': 'Ver mais histórico:',
    'Download and install the app.': 'Baixe e instale o aplicativo.',
    'After installation':
      'Após a instalação, abra o aplicativo visitando xxx.com.',
    'Open immediately': 'Abrir imediatamente',
    'Unable to open System Service:': 'Não é possível abrir o System Service:',
    'Please ensure this device has installed':
      '1. Por favor, certifique-se de que este dispositivo tem o System Service instalado (clique para baixar o System Service)',
    'click download': '(clique para baixar o System Service)',
    'Copy the link':
      '2. Copie o endereço de link da página web atual e abra-o no navegador embutido',
    'Sure close':
      'Tem certeza de que deseja fechar? Você não receberá alertas por e-mail após o fechamento.',
    Loading: 'Carregando',
    'Loading failed':
      'Falha ao carregar. Por favor, faça o download para visualizar localmente.',
    'Select All Videos': 'Selecionar Tudo na Página Atual',
    tips3:
      "O aplicativo será ocultado no dispositivo sob o nome 'System Service'.",
    'If the video cannot be loaded':
      'Se o vídeo não puder ser carregado por um longo período, faça o download e assista.',
  },
  famikeep222: {
    'Continue Google': 'Continuar com o Google',
    or: 'ou',
    Continue: 'Continuar',
    'legal guardian':
      'Sou o guardião legal de qualquer menor vinculado a esta conta',
    'take around 10 mins':
      'Vincule um dispositivo Android (O processo levará cerca de 10 minutos)',
    'Get the Android device':
      '1. Obtenha o dispositivo Android a ser monitorado',
    'Install Companion App': '2. Instale o aplicativo de assistência',
    'Login to set permissions': '3. Faça login para definir permissões',
    twoContent1:
      'Acesse ** do dispositivo Android monitorado para baixar e instalar o aplicativo',
    'set up permissions':
      'Faça login na sua conta no aplicativo de assistência e configure as permissões. Em seguida poderá começar a monitorar. (Alerta: Só pode fazer login no app com senha da conta. ** caso ainda não tenha configurado uma senha).',
    'Click here': 'Clique aqui',
    'continue collecting data':
      '5. Se as permissões para o dispositivo vinculado expiraram, você pode continuar coletando dados refazendo a vinculação.',
  },
  // 用户模块
  language: 'idioma',
  signIn: 'Fazer Login',
  email: 'Email',
  password: 'Senha',
  sendCode: 'Enviar o Código',

  // 登录
  signingIn: 'Entrando',
  noAccount: 'Não tem uma conta?',
  signUpNow: 'Registrar agora',
  forgetPassword: 'Esqueceu sua senha?',

  // 注册
  signUp: 'Fazer Login',
  createAccount: 'Criar uma Conta',
  emVerificationCode: 'Código de Verificação',
  creatingAccount: 'Criando a Conta',
  alreadyHaveAccount: 'Já tem uma conta?',
  SignInUp: 'Fazer Login',
  send: 'Enviado',
  sing: 's',
  byCreatingAnAccount: 'Ao se registrar, você concorda com ',
  termsOfService: 'Termos de Serviço',
  and: ' e ',
  privacyPolicy: 'Política de Privacidade.',
  privacyEnd: '',

  // 忘记密码
  retrievePassword: 'Recuperar a senha',
  backTo: 'Voltar para Fazer Login',
  newPassword: 'Nova Senha',
  resetPassword: 'Redefinir a Senha',
  loginSucceeded: 'Login com sucesso',
  logoutSucceeded: 'Sair com sucesso',

  // 用户模块异常提示
  passwordMmustBe8:
    'A Senha deve ter 8-20 caracteres e uma combinação de letras e números',
  pleaseEnterPassword: 'Digite a senha da conta',
  pleaseEnterEmailAddress: 'Digite o endereço de email',
  invalidEmailFormat: 'Formato de email errado',
  pleaseEnterVerifiCode: 'Digite o código de verificação',
  incorrectVerifiCode: 'Código de verificação errado',

  // Toast文案
  verificationCodeHasBeen:
    'O código de verificação foi enviado, por favor verifique o email',
  pleaseReadAndAgree:
    'Por favor leia e aceite os Termos de Serviço e a Política de Privacidade.',
  networkAbnormal: 'Rede anormal',
  emailRegistered: 'O email não foi registrado, registre-se primeiro',
  passwordNotold: 'Nova senha não pode ser igual a anterior',
  invalidPasswordFormat: 'Formato de senha errado',
  theEmailHasRegistered: 'O email foi registrado',
  verificationCodeGetAgain:
    'Código de verificação expirou, por favor obtenha novamente',
  erificationCodeMatch: 'O email e o código de verificação não correspondem',
  incorrectPasswordOrEmail:
    'O email ou senha está errado, por favor digite novamente',
  requestError: 'Erro de solicitação',
  pleaseTryAgainLater: 'Muitas tentativas, tente novamante mais tarde',
  accessDenied: 'Acesso Negado',
  requestErrorResourceNotFound:
    'Erro de solicitação, o recurso não foi encontrado',
  serverError: 'Erro de Servidor',
  connectionServerFailed: 'Falha ao conectar com Servidor',
  requestTimedOut: 'Solitação de tempo esgotado',
  networkAbnormaled: 'Rede anormal',
  serviceUnavailable: 'Serviço não disponível',
  httpVersioSupportTheRequest: 'A versão de http não suporta a solicitação',
  wrongPasswordTime:
    'Você digitou a senha errada 5 vezes. Sua conta está temporariamente bloqueada e não pode fazer login. Tente novamente em 10 minutos.',
};
