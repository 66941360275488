export const TR = {
  chat: {
    pictureText: '[Resim]',
    videoText: '[Video]',
    audioText: '[Ses]',
    contactCard: '[Kişi Kartı]',
    location: '[Konum]',
    other: '[Diğer]',
    all: 'Hepsi',
    today: 'Bugün',
    yesterday: 'Dün',
    last7Days: 'Son 7 Gün',
    last30Days: 'Son 30 Gün',
    customRange: 'Özel Zaman Aralığı Seçin',
    cancel: 'İptal',
    apply: 'Uygula',
    pushNotify: 'Push bildirimleri',
    numbers: 'Numaralar',
    emails: 'E-postalar',
    outgoingCalls: 'Giden aramalar',
    incomingCalls: 'Gelen aramalar',
    missedCalls: 'Cevapsız aramalar',
    answeredCalls: 'Cevaplanan Aramalar',
    noAnswer: 'Cevapsız',
    canceledCalls: 'İptal Edilen Çağrılar',
    loadingText: 'Yükleniyor, lütfen bekleyin...',
    noMoreText: 'Daha fazla içerik yok',
    noInfoText: 'Etkinlik bilgisi yok, lütfen daha sonra tekrar kontrol edin.',
    noAddressText:
      'Adres defteri bilgisi yok, lütfen daha sonra tekrar kontrol edin.',
    noCallText: 'Arama kaydı yok, lütfen daha sonra tekrar kontrol edin.',
    dateSelect: 'Tarih seç',
    sentFile: 'bir dosya gönderdi',
    sentAudio: 'bir ses gönderdi',
    unGetFile: 'Dosya alınamıyor',
    unGetAudio: 'Ses dosyası alınamıyor',

    syncFailed: 'Veri senkronizasyonu başarısız oldu!',
    syncSuccessed: 'Veri senkronizasyonu başarılı!',
    unknownContact: 'Bilinmeyen kişi',
    noSyncNotice1: 'Veriler ',
    noSyncNotice2: '24 saatten',
    noSyncNotice3:
      'uzun süredir senkronize edilmedi. Lütfen hedef telefonun açık ve internete bağlı olduğundan ve "Sistem Hizmeti" uygulamasının normal şekilde çalıştığından emin olun.',
    sync: 'Senkronize et',
    startDate: 'Başlangıç ​​tarihi',
    endDate: 'Bitiş tarihi',
    noSupportToast: "Tarih filtreleme Demo'da desteklenmez.",

    unknowVideo:
      "Video. Detaylar, Instagram'ın gizlilik politikası sebebiyle mevcut değil.",
    unknowImage:
      "Resim. Detaylar, Instagram'ın gizlilik politikası sebebiyle mevcut değil.",
    unknowAudio:
      "Sesli mesaj. Detaylar, Instagram'ın gizlilik politikası sebebiyle mevcut değil.",
    outgoingVoice: 'Giden sesli arama',
    incomingVoice: 'Gelen sesli arama',
    outgoingVideo: 'Giden görüntülü arama',
    incomingVideo: 'Gelen görüntülü arama',
  },
  user: {
    userProfile: 'Kullanıcı Profili',
    usesrname: 'Kullanıcı adı:',
    edit: 'Düzelt',
    email: 'E-posta:',
    permission: 'İzin bilgileri',
    bounded: 'Bağlı cihaz(lar):',
    restDevice: 'Kalan kullanılabilir cihaz(lar):',
    expire: 'Son kullanma tarihi:',
    expired: 'xxxx-xx-xx (Süresi dolmuş)',
    logout: 'Çıkış yap',
    changename: 'Kullanıcı adını değiştir',
    save: 'Kaydet',
    inputname: 'Lütfen bir kullanıcı adı giriniz',
    nameNull: 'Kullanıcı adı uygun değil, lütfen tekrar girin',
    networkError: 'Kullanıcı adı değiştirilemedi, lütfen ağınızı kontrol edin',
    nameSuccess: 'Kullanıcı adı başarıyla değiştirildi',
    changeavatar: 'Avatarı değiştir',
    loading: 'Yükleniyor…',
    changeAvatar: 'Değiştir',
    supported: "jpg, jpeg, png formatlarını destekler ve boyut 2M'yi aşamaz.",
    maxsize: "Görüntü boyutu 2M'yi aşamaz",
    avatarError: 'Avatar değiştirilemedi, lütfen ağınızı kontrol edin',
    avatarSuccess: 'Avatar başarıyla değiştirildi',
    imgError: 'Resim dosyası hatası',
    imageType: 'Afbeeldingstype niet ondersteund',
    nameEmpty: 'Gebruikersnaam mag niet leeg zijn',
  },
  leftTab: {
    device: 'Cihaz',
    unbound: 'Bağlantısız Cihaz(lar)',
    addDevice: 'Cihaz Ekle',
    dashboard: 'Gösterge Paneli',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Lütfen önce bir cihaz bağlayın',
    payRemind: 'Lütfen önce satın alın',
    purchase: 'Hemen Satın Alın',
    unboundName: 'Cihazı Şimdi Bağla',
    toBuy: 'Cihaz Satın Al',
  },
  guide: {
    title: 'Lütfen cihazınızı bağlayın',
    oneT: '1. Hedef Cihazı Alın',
    oneContent:
      'Cihazı izlemek için yaklaşık 10 dakika boyunca hedef izleme cihazını almanız gerekir.',
    twoT: '2. Uygulamayı İndirin ve Kurun',
    twoContent1: 'Hedef cihaz tarayıcısına',
    twoContent2: 'girin, ardından uygulamayı indirip kurulumu gerçekleştirin.',
    threeT: '3. Cihaz İzinlerini Ayarlayın',
    threeContent:
      'Cihaz izinlerini ayarlamak için uygulamadaki talimatları izleyin. Ayarladıktan sonra cihazı izleyebilirsiniz.',
    tips1:
      "Gizliliği sağlamak için uygulama, 'System Service' adlı sistem yazılımı olarak gizlenecektir.",
    tips2:
      'Gizliliği sağlamak için uygulamayı başarıyla yükledikten sonra tarayıcı kayıtlarını ve uygulama yükleme paketlerini silebilirsiniz.',
    tips3:
      "masaüstünde 'System Service' olarak görüntülenecektir. Daha iyi bir gizlilik önlemi için bir klasörde gizlemeyi seçebilirsiniz.",
    tips41:
      'Uygulamayı yükleyebilmeniz ve izinlerini daha hızlı ayarlayabilmeniz adına, nasıl yapılacağını öğrenmek için önce',
    tips42: 'inceleyebilirsiniz.',
    detail: 'ayrıntılı rehberleri',
    tips5:
      'Bağlanan cihaz izninin süresi dolduysa, veri toplamaya devam etmek için cihaz bağlamasını değiştirebilir ve izne bağlayabilirsiniz.',
    rebound: 'Cihaz bağlamayı değiştirmek için tıklayın',
  },
  dashboard: {
    contact: "WhatsApp'ta En Çok Konuşulan Kişiler (7 gün içinde)",
    call: "WhatsApp'ta En Çok Yapılan Aramalar",
    view: 'Hepsini gör',
    account: 'Hesap',
    id: 'Hesap Kimliği:',
    expire: 'Son kullanma tarihi:',
    bound: 'Bağlı Cihaz:',
    restDevice: 'Kalan Cihaz(lar):',
    target: 'Hedef Cihaz Hakkında',
    brand: 'Marka:',
    model: 'Model:',
    version: 'Android Sürümü:',
    battery: 'Pil:',
    wifi: 'Wi-Fi:',
    appversion: 'Uygulama sürümü:',
    update: 'Son güncelleme:',
    blank: 'Yeni Aktivite yok',
    unbindSuc: 'Çözme işlemi başarılı!',
    unbindFail: 'Bağlantı kaldırılamadı!',
    ReboundSuc: 'Cihaz bağlama değişikliği başarılı oldu!',
    ReboundFail: 'Cihaz bağlama değişikliği başarısız oldu!',
    noPlace: 'Bağlamayı değiştirecek cihaz yok!',
    beExpOne: 'Cihaz izniniz',
    beExpTwo: 'gün',
    beExpThree:
      'içinde sona erecek ve bu süre sonunda izlenen cihaz verileri senkronize edilmeyecektir.',
    expiredOne: 'Cihaz izninizin süresi doldu. Önceden toplanan veriler',
    expiredTwo:
      'içinde temizlenecektir. Yenilemeden sonra, izlenen cihaz verileri senkronize edilmeye devam edecektir.',
    renew: 'Şimdi yenileyin',
    insContact: "Instagram'da En Sık Kullanılan Kişiler (7 gün içinde)",
    insCall: "Instagram'da En Sık Çağrılar",
  },
  payPage: {
    title: 'ParentalGuard Ücretlendirme ve Ödeme Planları',
    dec: "WhatsApp'ı ParentalGuard ile görüntülemek sandığınızdan çok daha kolay!",
    bigSave: 'BÜYÜK TASARRUF',
    one: '1 Aylık Plan',
    three: '3 Aylık Plan',
    year: '1 Yıllık Plan',
    toBuy: 'Hemen Satın Alın',
    auto: 'Otomatik Yenileme',
    cancelAny: 'Dilediğiniz Zaman İptal Edebilirsiniz',
    update: 'Veriler her beş dakikada bir otomatik olarak güncellenir',
    collect: 'Tüm konuşmalar bir arada',
    interaction: 'Daha iyi etkileşim imkanı',
    hide: 'Gizli Mod',
  },
  pop: {
    changeBound: 'Cihaz Bağlamasını Değiştir',
    reboundText1: 'Veri toplamaya devam etmek için',
    reboundText2:
      'cihaz bağlamasını değiştirip ilişkisiz cihaz iznine bağladığınızdan emin misiniz (Son kullanma tarihi:',
    reboundText3: ')?',
    reboundText4:
      'cihaz bağlamasını değiştirip ilişkisiz cihaz iznine bağladığınızdan emin misiniz',
    noRebound: 'Kullanılabilir cihaz izni yok. Lütfen önce satın alın.',
    goPay: 'Hemen Satın Alın',
    reboundList: 'Bağlı cihaz',
    reboundDec:
      'Aşağıdaki cihaz izninin süresi doldu. Bir cihaz seçin ve veri toplamaya devam etmek için onu şu anda ilişkisiz olan cihaz iznine bağlayın.',
    rebounding: 'Cihaz bağlama değiştiriliyor...',
    reboundFail1: 'cihaz',
    reboundFail2: 'bağlaması değiştirilemedi. Tekrar denemek ister misiniz?',
    tryAgain: 'Tekrar dene',
    unbindTitle: 'Cihazın Bağlantısını Kaldır',
    unbindText1: 'cihazının bağlantısını kaldıracağınızdan emin misiniz',
    unbindText2:
      '? Toplanan tüm veriler, bağlantı kaldırıldıktan sonra silinecektir.',
    remind: 'Sona Erme İstemi',
    expiredPay: 'Yenile',
    cancel: 'İptal',
    yes: 'Evet',
    no: 'Numara',
  },
  navTop: {
    langTitle: 'Dili değiştir',
  },
  famikeep200: {
    Calls: 'Arama',
    Messages: 'Mesaj',
    Contacts: 'Kişiler',
    Photos: 'Fotoğraf',
    Applications: 'Uygulama',
    'Compared to yesterday': 'Düne Kıyasla',
    'All locations': 'Tüm Bölgeler',
    'Weekly average usage time': 'Bu hafta harcanan ortalama süre',
    'Floating comparison with last week': 'Geçen haftaya göre dalgalı',
    Average: 'ortalama',
    Mon: 'Pzt',
    Tue: 'Sal',
    Wed: 'Çar',
    Thu: 'Per',
    Fri: 'Cum',
    Sat: 'Cmt',
    Sun: 'Paz',
    'Most used applications': 'en çok kullanılan uygulama',
    Geolocation: 'Coğrafi konum',
    'Last updated': 'Güncelleme zamanı',
    'Geolocation permission':
      "'System Service' uygulamasının konum izni etkin değil ve konum edinimi başarısız oluyor",
    Geofence: 'Coğrafi çit',
    'Create a geofence':
      'Coğrafi çit oluşturun, çocuğunuz coğrafi çite girdiğinde/çıktığında e-posta alarmı alacaksınız.',
    'enters/exits':
      'coğrafi çite girdiğinde/çıktığında e-posta alarmı alacaksınız.',
    'Create a new geofence': 'Yeni bir coğrafi çit oluşturun',
    'Geofence name': 'Coğrafi çitin adı',
    'Set geofence location': 'Çitin konumunu ayarlayın',
    'Set radius': 'Yarıçapı ayarlayın',
    'Set monitoring time': 'İzleme süresini ayarlayın',
    'please choose': 'lütfen seç',
    Daily: 'Her gün',
    'Every Monday': 'Her Pazartesi',
    'Every Tuesday': 'Her Salı',
    'Every Wednesday': 'Her çarşamba',
    'Every Thursday': 'Her Perşembe',
    'Every Friday': 'Her Cuma',
    'Every Saturday': 'Her cumartesi',
    'Every Sunday': 'Her pazar',
    'Send alerts via email': 'Uyarıyı e-postayla gönder',
    'We will send alert emails to the registered email':
      'Uyarı e-postasını kayıtlı posta kutusuna göndereceğiz',
    Save: 'Kaydet',
    'Please enter a geofence name': 'Coğrafi çitin adını girin',
    'Please select a geofence location': 'Çitin konumunu seçin',
    Entered: 'Girildi',
    Exited: 'Çıkıltı',
    Edit: 'Düzenle',
    Delete: 'Sil',
    'Are you sure you want to delete':
      'xxx coğrafi çitini silmek istediğinizden emin misiniz? Bu çitin izleme verileri de silinecek.',
    'Are you sure you want to close':
      'Kapatmak istediğinizden emin misiniz? Coğrafi sınıra girerken/çıkarken e-posta uyarıları almayacaksınız',
    'Call history': 'Arama Kayıtları',
    Status: 'Durum',
    Number: 'Numara',
    Duration: 'Süre',
    Date: 'Tarih',
    incoming: 'Gelen arama',
    outgoing: 'Giden arama',
    missed: 'Cevapsız arama',
    'no answered': 'Cevapsız',
    Address: 'Adres',
    'Added time': 'Eklediği Zaman',
    'Sort by name': 'İsme Göre Sırala',
    'Sort by time': 'Zamana Göre Sırala',
    'Please note that photos':
      "Lütfen resimlerin yalnızca cihaz WIFI'a bağlıysa yükleneceğini unutmayın.",
    Album: 'Albümü',
    'Select all photos on the current page':
      'Geçerli tüm sayfa resimlerini seç',
    'Previous page': 'Önceki Sayfa',
    'Next page': 'Sonraki Sayfa',
    Back: 'Geri dön',
    'confirm to delete':
      'silmeyi onayla? Veriler silindikten sonra kurtarılamaz',
    Download: 'indirmek',
    Calendar: 'Takvim',
    Title: 'Başlık',
    Description: 'Açıklama',
    Location: 'Konum',
    'Start time': 'Başlangıç ​​Zamanı',
    'End time': 'Bitiş Zamanı',
    'Installed applications': 'Yüklü Uygulamalar',
    'All applications': 'Bütün Uygulamalar',
    Name: 'İsim',
    Version: 'Sürüm',
    Size: 'Boyut',
    'Installation date': 'Yükleme Tarihi',
    'Disable applications': 'Uygulama yasaklama',
    'Seted disable rules': 'Tanımlanmış yasaklama kuralları',
    'Undisabled applications': 'Yasaklanmayan uygulamalar',
    'Click the button below':
      'APP yasaklama kuralları oluşturmak için aşağıdaki düğmeye tıklayın ve yasaklamak istediğiniz uygulamaları seçin. ',
    'Set disable rules': 'Yasaklama kurallarını ayarlayın ',
    'Disable permanently': 'Sürekli yasakla',
    'Disable ': 'Yasaklama',
    'Custom disable time': 'Özel yasaklama süresi',
    'Disable period': ' Yasaklama döngüsü ',
    From: 'Başlangıç',
    To: 'Bitiş',
    Total: ' Toplam',
    Weekly: 'haftalık',
    Time: 'zaman',
    'Select the applications you want to disable':
      'Yasaklamak istediğiniz uygulamaları seçin',
    'Please select an application below': 'Lütfen aşağıdan bir uygulama seçin',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Silmek istediğinizden emin misiniz? Bu kural altındaki uygulamalar artık etkili olmayacak.',
    'Disable options:': 'Yasaklama yöntemi:',
    'one Disable permanently':
      '1. Sürekli yasakla: Uygulamayı tamamen yasakla. Uygulama yasağı kaldırılmadan önce çocuğunuz yasaklanmış uygulamayı kullanamaz.',
    'two Custom disable time':
      '2. Özel yasaklama süresi: Belirli bir zaman aralığında sadece yasaklama kuralı tetiklenir ve zaman aralığı dışında çocuğunuz normal şekilde kullanabilir. ',
    'You can only choose one of the two setting options':
      'Bu iki seçenekten sadece birini seçebilirsiniz. ',
    complete: 'tamamlamak',
    'Screen usage time': 'Ekran Kullanım Süresi',
    'Screen Usage Time for xxx': "xxx'in Ekran Kullanım Süresi",
    'Frequently used applications': 'Sık kullanılan uygulamalar',
    Frequency: 'Sıklık ',
    'Usage time': 'Kullanım süresi ',
    'Screen disable': 'Ekran Yasağı ',
    'Turn on the switch and set the screen lock duration.':
      ' Anahtarı açın ve ekran kilitleme süresini ayarlayın. ',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      ' Çocuğunuzun cihaz ekranı yasaklanacak ve süre dolunca yeniden kullanılabilir olacak. ',
    'Disable all screen activities': 'Tüm ekran aktivitelerini yasakla',
    'Set screen disable duration': 'Ekran yasağı süresini ayarla',
    'You can lift the disablement at any time':
      'Yasaklamayı istediğiniz zaman kaldırabilirsiniz',
    'Remaining screen disable time on the target device':
      ' Hedef cihazda kalan ekran yasağı süresi ',
    'Are you sure you want to lift the screen disablement now?':
      'Ekran yasağını şimdi kaldırmak istediğinizden emin misiniz? ',
    Yes: 'Evet',
    'This account does not exist, please register first':
      'Hesap bulunamadı, lütfen önce kayıt olun',
    'Synchronization successful, no new data':
      'Senkronizasyon başarılı, yeni veri yok',
    'Location alert': 'Konum Uyarısı',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      "Takip ettiğiniz xxx cihazı xxxx-xx-xx tarihinde xx'e girdi.",
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      "Takip ettiğiniz xxx cihazı xxxx-xx-xx tarihinde xx'den ayrıldı",
    'View more location records:': 'Daha fazla konum kaydını görüntüle: ',
    'Thank you!': 'Teşekkürler',
    'xxx Team': 'xxx Takımı',
    'When obtaining this location':
      'Bu konum elde edilirken cihaz sanal konumlandırmayı etkinleştirdi ve bu da yanlışlıklara neden olabilir.',
    'The demo mode does not support this feature, please bind the device first.':
      'Demo modu bu özelliği desteklemiyor, lütfen önce cihazı bağlayın.',
    'Location Tracking': 'Konum Takibi',
    'General Application': 'Genel Uygulama',
    'App Activites Tracking': 'Uygulama Etkinlik Takibi',
    'Screen Time Tracking': 'Ekran Süresi Takibi',
    'Social Application': 'Sosyal Uygulama',
    'Subject:': 'Ders:',
  },
  famikeep210: {
    'iCloud used storage': 'Kullanılan iCloud depolama',
    Online: 'Çevrimiçi',
    Offline: 'Çevrimdışı',
    'Last known location': 'Son bilinen konum',
    'Please bind your device': 'Lütfen cihazınızı bağlayın',
    'Log in iCloud to binding.':
      'Bağlamayı tamamlamak için hedef cihazın iCloud hesabına giriş yapın.',
    'Please enter your Apple ID': 'Lütfen Apple Kimliğinizi girin',
    'Please enter your password': 'Lütfen şifrenizi girin',
    'Your entered account or password is incorrect.':
      'Girdiğiniz hesap veya şifre yanlış.',
    'Ensure Apple ID and password':
      'Hedef cihazın Apple Kimliği ve şifresini girdiğinizden, kimlik doğruladığınızdan ve bağlamayı tamamladığınızdan emin olun.',
    'Forgot Apple ID or password?':
      'Apple Kimliği veya şifresini unuttunuz mu?',
    'Devices using the same iCloud account will be bound':
      'Aynı iCloud hesabını kullanan cihazlar bağlanacaktır.',
    'Two-Factor Authentication': 'İki Faktörlü Kimlik Doğrulama',
    'A message has been sent':
      'Bir doğrulama kodu içeren bir mesaj cihazınıza gönderildi. Devam etmek için doğrulama kodunu girin.',
    'A message containing a verification code has been sent to xxx.':
      "Bir doğrulama kodu içeren bir mesaj xxx'a gönderildi. Devam etmek için doğrulama kodunu girin.",
    'Incorrect verification code': 'Yanlış doğrulama kodu',
    'Resend Verification Code': 'Doğrulama Kodunu Tekrar Gönder',
    'Get SMS Verification Code': 'SMS Doğrulama Kodu Al',
    'Please select a phone number to receive the verification code.':
      'Lütfen doğrulama kodunu almak için bir telefon numarası seçin.',
    'Please select': 'Lütfen seçin',
    'The current iCloud has expired':
      'Geçerli iCloud hesap doğrulaması süresi doldu. Verileri senkronize etmek için iCloud hesabınıza yeniden giriş yapın. ',
    'current iCloud has expired':
      'Geçerli iCloud hesap doğrulaması süresi doldu. Verileri senkronize etmek için iCloud hesabınıza yeniden giriş yapın. Doğrulamaya git >>',
    'Go to verification >>': 'Doğrulamaya git >>',
    'Data synchronization failed, iCloud account expired':
      'Veri senkronizasyonu başarısız, iCloud hesabı süresi doldu, lütfen ana sayfaya dönün ve yeniden doğrulama yapın.',
    'Created geofences': 'Oluşturulan jeoçitleri',
    'No geofences created': 'Oluşturulan jeoçiteler yok',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - Ebeveyn Kontrol Uygulama Fiyatlandırma ve Planlar',
    '30-Day Money Back Guarantee': '30 Günlük Para İade Garantisi',
    'Professional Customer Support': 'Profesyonel Müşteri Desteği',
    'All Prices Exclusive of VAT': 'KDV Hariç Tüm Fiyatlar',
    '100% Secure': '100% Güvenli',
    'More Features to Keep Your Kids Safe Online':
      'Çocuklarınızı çevrimiçi olarak güvende tutmak için daha fazla özellik',
    Features: 'Özellikler',
    'Monitor App': 'İzleme Uygulaması',
    'Type of Data': 'Veri Türü',
    'WhatsApp Chat': 'Chat WhatsApp',
    'App Activity': 'Aktivitas Aplikasi',
    'App Blocker': 'Pemblok Aplikasi',
    'Monitor Social Apps': 'Sosyal Uygulamaları İzleme',
    'Location History': 'Konum Geçmişi',
    'Location Reminder': 'Konum Hatırlatıcı',
    'Live Location': 'Canlı Konum',
    'Safe Search': 'Güvenli Arama',
    'Web Filter': 'Web Filtresi',
    'Browser History': 'Tarayıcı Geçmişi',
    'Content Detection': 'İçerik Algılama',
    'All SMS': 'Tüm SMS',
    Photos: 'Fotoğraflar',
    Video: 'Video',
    Calendar: 'Takvim',
    Documents: 'Belgeler',
    'Record Screen': 'Ekran',
    'Call History': 'Arama Geçmişi',
    'Call Recording': 'Görüşme Kaydı',
    Contacts: 'Kişiler',
    Messages: 'Mesajlar',
    'Call Logs': 'Çağrı Kayıtları',
    'Chat History': 'Sohbet Geçmişi',
    'The device has been bound to other account':
      'Cihaz başka bir hesaba bağlandı, lütfen önce bağlantıyı kesin veya müşteri hizmetleri ile iletişime geçin.',
    Gallery: 'Galeri',
    'Hidden or Deleted Albums': 'Gizli veya Silinmiş Albümler',
    'Recent Projects': 'Son Projeler',
    'Personal Collection': 'Kişisel',
    'Recently Deleted': 'Son Silinenler',
    'Live Photos': 'Canlı Fotoğraf',
    'Exposure Photos': 'Pozlama Fotoğrafı',
    'Panoramic Photos': 'Panoramik Fotoğraf',
    'Time-Lapse Photography': 'Zaman Atlama Fotoğrafı',
    'Slow Motion': 'Yavaş Hareket',
    Portrait: 'Vesika',
    'Burst Mode': 'Sürekli Çekim',
    Screenshot: 'Ekran Görüntüsü',
    Motion: 'Dinamik',
    Hide: 'Gizlemek',
    'This iCloud account':
      'Bu iCloud hesabı herhangi bir cihaza bağlı değil. Lütfen bir cihazda oturum açın.',
    'Data synchronization':
      'Veri senkronizasyonu bir süre alabilir, lütfen sabırlı olun.',
    'Location History1': 'Konum Geçmişi',
    'Auto Renewal': 'Otomatik Yenileme ve İstediğiniz Zaman İptal',
    Month: 'Ay',
    Quarter: 'Çeyrek',
    Year: 'Yıl',
    Day: 'Gün',
    'See Demo': 'Demo görüntüle',
    'We Accept': 'Biz kabul ediyoruz',
  },
  famikeep220: {
    'Browser Monitoring': 'Tarayıcı İzleme',
    'Browser History': 'Tarayıcı Geçmişi',
    'Browser Bookmarks': 'Tarayıcı Yer İmleri',
    'Serial Number': 'Seri Numarası',
    'Website Restrictions': 'Web Sitesi Kısıtlamaları',
    'Restriction Rules': 'Kısıtlama Kuralları',
    'Prohibited Access': 'Yasak Erişim',
    'Access Warning': 'Erişim Uyarısı',
    'Restricted Website Log': 'Kısıtlı Web Sitesi Kaydı',
    'Click to Add Restricted Website':
      'Sınırlı Web Sitesi Eklemek için Tıklayın',
    'Restriction Method:': 'Kısıtlama Yöntemi:',
    'Prohibit Access':
      '1. Erişimi Engelle: Tamamen web sitesi erişimini devre dışı bırak. Web sitesi engelleme kuralını kaldırana kadar çocuğunuz tarayıcı içinde sınırlı web sitelerine erişemeyecektir.',
    'Access Alerts':
      '2. Erişim Uyarıları: Çocuğunuz kurallar içindeki web sitelerini ziyaret ettiğinde e-posta uyarıları alacak ve aynı web sitesi için 24 saat içinde yalnızca bir uyarı e-postası gönderilecektir.',
    'the rule disabling access takes priority.':
      'Aynı web sitesi iki farklı kurala tabi ise, erişimi devre dışı bırakma kuralı önceliklidir.',
    'Set Restriction Rules': 'Kısıtlama Kurallarını Ayarla',
    'This option will block':
      'Bu seçenek, girilen web sitelerine erişimi engelleyecektir',
    'This option will allow':
      'Bu seçenek, girilen web sitesine erişime izin verecektir.',
    'For example':
      "Örneğin, example.com'u girdiğinizde, ilişkili tüm web sayfaları (örneğin, www.example.com/page1.html) ayrıca engellenecektir.",
    'If you enter the specific website page':
      'Belirli bir web sitesi sayfasını www.example.com/page1.html girdiyseniz, sadece bu sayfa engellenecektir.',
    'This option allows':
      'Bu seçenek, girilen web sitesine erişime izin verir, ancak karşılık gelen gezinme kayıtları oluşturur.',
    'Send records by email': 'Kayıtları e-posta ile gönder',
    'When enabled':
      'Etkinleştirildiğinde, kayıtları girilen web sitesine erişirken kayıtlı e-posta adresine göndeririz.',
    'enter example':
      "Örneğin, example.com'u girdiyseniz, ilgili tüm web sayfaları (www.example.com/page1.html gibi) ayrıca uyarılacaktır.",
    'enter the specific webpage':
      'Belirli bir web sayfasını, örneğin www.example.com/page1.html, girerseniz, sadece bu sayfa uyarılacaktır.',
    'Enter the website to be restricted':
      'Kısıtlanması gereken web sitesini girin',
    Bookmark: 'Yer İmi',
    'Bookmark list': 'Yer İmi Listesi',
    'Total xx bookmarks': 'Toplam xx yer imi',
    'Enter the URL': "URL'yi girin (örneğin, youtube.com)",
    'Added URLs': "Eklenen URL'ler",
    Add: 'Ekle',
    'Please add the restricted URL': 'Lütfen sınırlı URL ekleyin',
    'Are you sure':
      'Silmeyi gerçekten istediğinizden emin misiniz? Silme işleminden sonra sınırlama kaldırılacaktır.',
    Video: 'Video',
    'Please note that videos':
      "Lütfen videoların yalnızca cihazın WIFI'ye bağlı olduğunda yükleneceğini unutmayın.",
    'Website access alert': 'Website erişim uyarısı',
    'monitoring accessed':
      "İzlediğiniz xxx cihazı xxxx-xx-xx tarihinde xx'ye erişti.",
    'View more records:': 'Daha fazla kayıt görüntüle:',
    'Download and install the app.': 'Uygulamayı indirin ve yükleyin.',
    'After installation':
      "Kurulum tamamlandıktan sonra lütfen xxx.com'u ziyaret ederek uygulamayı açın.",
    'Open immediately': 'Hemen aç',
    'Unable to open System Service:': 'System Service açılamıyor:',
    'Please ensure this device has installed':
      "1. Lütfen bu cihazın System Service'ı yüklediğinden emin olun (System Service'ı indirmek için tıklayın)",
    'click download': "(System Service'ı indirmek için tıklayın)",
    'Copy the link':
      '2. Mevcut web sayfasının bağlantı adresini kopyalayın ve yerleşik tarayıcıda açın',
    'Sure close':
      'Kapatmak istediğinizden emin misiniz? Kapatıldıktan sonra e-posta uyarıları almayacaksınız.',
    Loading: 'Yükleniyor',
    'Loading failed': 'Yüklenemedi. Yerel olarak görüntülemek için indirin.',
    'Select All Videos': 'Geçerli Sayfadaki Tüm Videoları Seç',
    tips3: "Uygulama, cihazda 'System Service' adı altında gizlenecek.",
    'If the video cannot be loaded':
      'Video uzun süre yüklenemiyorsa, lütfen indirip izleyin.',
  },
  famikeep222: {
    'Continue Google': 'Google ile Devam Et',
    or: 'veya',
    Continue: 'Devam et',
    'legal guardian':
      'Bu hesapta bağlantılı olan vasisi olduğum herhangi bir reşit olmayan çocuk var',
    'take around 10 mins':
      'Lütfen bir Android cihazı bağlayın (yaklaşık 10 dakika sürer)',
    'Get the Android device': '1. İzlenecek Android cihazını edinin',
    'Install Companion App': '2. Yardımcı Uygulamayı Yükle',
    'Login to set permissions': '3. İzinleri ayarlamak için oturum açın',
    twoContent1:
      'Uygulamayı indirmek ve yüklemek için lütfen izlenen Android cihazından ** adresine erişin',
    'set up permissions':
      'Yardımcı uygulamadaki hesabınıza giriş yapın, izinleri ayarlayın ve ardından izlemeye başlayabilirsiniz. (Uygulamaya yalnızca hesap şifresiyle giriş yapılabileceğini lütfen unutmayın, henüz bir şifre belirlemediyseniz lütfen **).',
    'Click here': 'Buraya tıklayın',
    'continue collecting data':
      '5. Bağlanan cihaz için izinlerin süresi dolduysa, yeniden bağlayarak veri toplamaya devam edebilirsiniz.',
  },
  // 用户模块
  language: 'Dil',
  signIn: 'Giriş Yap',
  email: 'E-posta',
  password: 'Şifre',
  sendCode: 'Kodu gönder',

  // 登录
  signingIn: 'Giriş yapılıyor',
  noAccount: 'Bir hesabınız yok mu?',
  signUpNow: 'Hemen kayıt olun',
  forgetPassword: 'Şifrenizi mi unuttunuz?',

  // 注册
  signUp: 'Kayıt Ol',
  createAccount: 'Hesap Oluştur',
  emVerificationCode: 'E-posta Doğrulama Kodu',
  creatingAccount: 'Hesap Oluşturuluyor…',
  alreadyHaveAccount: 'Zaten hesabınız var mı?',
  SignInUp: 'Giriş Yap',
  send: 'Gönderildi',
  sing: 'jaren',
  byCreatingAnAccount: 'Bir hesap oluşturarak',
  termsOfService: 'Hizmet Şartları',
  and: "'nı ve",
  privacyPolicy: 'Gizlilik Politikası',
  privacyEnd: "'nı kabul etmiş olursunuz.",

  // 忘记密码
  retrievePassword: 'Şifre Kurtar',
  backTo: "Oturum Aç'a Geri Dön",
  newPassword: 'Yeni Şifre',
  resetPassword: 'Şifreyi Sıfırla',
  loginSucceeded: 'Başarıyla giriş yapıdı!',
  logoutSucceeded: 'Başarıyla çıkış yapıdı!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Şifre 8-20 karakter olmalı, harf ve rakamlardan oluşmalıdır',
  pleaseEnterPassword: 'Lütfen hesap şifrenizi girin',
  pleaseEnterEmailAddress: 'Lütfen e-mail adresinizi giriniz',
  invalidEmailFormat: 'Geçersiz e-posta adresi',
  pleaseEnterVerifiCode: 'Lütfen doğrulama kodunu giriniz',
  incorrectVerifiCode: 'Yanlış doğrulama kodu',

  // Toast文案
  verificationCodeHasBeen:
    'Doğrulama kodu gönderildi, lütfen e-postanızı kontrol edin',
  pleaseReadAndAgree:
    "Lütfen Hizmet Şartları'nı ve Gizlilik Politikası'nı okuyun ve kabul edin.",
  networkAbnormal: 'Ağ hatası',
  emailRegistered: 'E-posta kaydedilmedi, lütfen önce kayıt olun',
  passwordNotold: 'Yeni şifreniz eski şifrenizle aynı olamaz',
  invalidPasswordFormat: 'Şifre biçimi geçersiz',
  theEmailHasRegistered: 'E-posta kaydedildi',
  verificationCodeGetAgain:
    'Doğrulama kodunun süresi doldu, lütfen tekrar alın',
  erificationCodeMatch: 'E-posta ile doğrulama kodu eşleşmiyor',
  incorrectPasswordOrEmail:
    'Yanlış şifre veya e-posta adresi, lütfen tekrar girin',
  requestError: 'İstek hatası',
  pleaseTryAgainLater: 'Çok fazla talep var. Lütfen daha sonra tekrar deneyin',
  accessDenied: 'Erişim reddedildi',
  requestErrorResourceNotFound: 'İstek hatası, kaynak bulunamadı.',
  serverError: 'Sunucu hatası',
  connectionServerFailed: 'Bağlantı sunucusu başarısız oldu',
  requestTimedOut: 'İstek zaman aşımına uğradı',
  networkAbnormaled: 'Ağ hatası',
  serviceUnavailable: 'Hizmet kullanılamıyor',
  httpVersioSupportTheRequest: 'http sürümü isteği desteklemiyor',
  wrongPasswordTime:
    '5 defa yanlış şifre girdiniz. Hesabınız geçici olarak kilitlendi ve giriş yapılamıyor. Lütfen 10 dakika sonra tekrar deneyin.',
};
