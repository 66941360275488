import { computed, ref } from 'vue';
import store from '@/store';
import router from '@/router';
import { ElMessage } from 'element-plus';
import { gaEvent } from '@/utils/format';
import moment from 'moment';
// import { useI18n } from "vue-i18n";

// const { t } = useI18n();

// 检测是否是24h内的第一次访问网站
export const checkIs24HVisit = () => {
  // console.log("checkIs24HVisit");

  const is24HVisit = ref(false);
  const visitTime = {
    year: moment().get('year'),
    month: moment().get('month') + 1,
    day: moment().get('date'),
    timestamp: moment().format('X'),
  };

  const end = moment().endOf('day').format('X'); // 当天的起始时间戳（单位：s）
  const start = moment().startOf('day').format('X'); // // 当天的结束时间戳（单位：s）
  let localVisitTime2 = JSON.parse(
    localStorage.getItem('lastVisitTime2') as string
  );
  // localVisitTime2 = 1869348887;
  if (!localVisitTime2) {
    // 新用户第1次访问
    is24HVisit.value = false;
  } else {
    // 老用户访问
    if (
      !(localVisitTime2.timestamp >= start && localVisitTime2.timestamp <= end)
    ) {
      // 同一天访问网站时，只统计1次访问量
      is24HVisit.value = false;
    } else {
      is24HVisit.value = true;
    }
  }
  localStorage.setItem('lastVisitTime2', JSON.stringify(visitTime));
  // console.log(" is24HVisit.value", is24HVisit.value);

  return is24HVisit.value;
};

interface callBack {
  (): void;
}

// 检测是否出现24h未更新通知提示框
export const useCheckNotification: any = () => {
  const messageToastType = computed(() => store.state.messageToastType);
  const checkNotification = (successFn: callBack, errorFn: callBack) => {
    // console.log("checkNotification");

    const subscripts = computed(() => store.state.subscripts);
    const is_expired = computed(() => store.state.currentDeviceInfo.is_expired);
    const nowTime: number = parseInt((new Date().getTime() / 1000).toFixed(0));
    // if (parseInt(subscripts.value.expires_at) > new Date().getTime() / 1000) {
    if (!is_expired.value) {
      // console.log("权限未过期会员");
      // 当前选中设备的最后更新时间
      const currDeviceLastUpdate = computed(
        () => store.state.currentDeviceInfo.last_update_at
      );
      // console.log("currDeviceLastUpdate", currDeviceLastUpdate.value);

      const isClickUpdateBtn = computed(() => store.state.isClickUpdateBtn);

      if (nowTime - currDeviceLastUpdate.value >= 86400) {
        // console.log("超过24h未更新数据");
        store.commit('setMessageToastType', 1);
        if (!checkIs24HVisit()) {
          // console.log("埋点===");
          // 24h内的第一次访问，则统计一次埋点
          gaEvent('Notice_24h', {
            event_category: 'Dashboard_Function',
            event_label: 'None',
          });
        }
        if (isClickUpdateBtn.value) {
          // ElMessage({
          //   message: t("chat.syncFailed"),
          //   type: "error",
          // });
          errorFn();
        }
      } else {
        store.commit('setMessageToastType', 0);
        if (isClickUpdateBtn.value) {
          // ElMessage({
          //   message: t("chat.syncSuccessed"),
          //   type: "success",
          // });
          successFn();
        }
      }
      // toast弹出后，重置回false
      store.commit('setIsClickUpdateBtn', false);
    } else {
      // 权限过期的
    }
  };

  return [messageToastType, checkNotification];
};

// setTimeout(() => {
//   store.commit("setMessageToastType", 0);
// }, 6000);

// 监听浏览器的后退操作，对不同页面菜单项是否高亮和设备列表选中项做处理
export const useListenBack: any = () => {
  const deviceList = computed(() => store.state.deviceInfo);
  const totalDeviceCount = computed(
    () => (store.state.menuList2[0] as any).children.length
  );

  window.addEventListener(
    'popstate',
    function (e: any) {
      console.log(e, '监听到了浏览器的后退事件', e.state.current);
      console.log('deviceList', deviceList.value, totalDeviceCount.value);
      if (e.state.current == '/guide') {
        // 如果上一级页面是guide，则不可点击概览或者whatsapp功能栏
        // console.log("上一级页面是guide");
        // prePageIsGuide.value = true
        store.commit('setClickGoPay', false);
        // console.log("totalDeviceCount.value", totalDeviceCount.value);
        store.commit('setDefaultMenu', 'guide');
        store.commit('setCurrentDeviceId', `Device ${totalDeviceCount.value}`);
        store.commit('setCurrentDeviceInfo', {
          id: `Device ${totalDeviceCount.value}`,
          model: 'leftTab.unbound',
          name: 'leftTab.unboundName',
        });
        store.commit('setDeviceType', 0);
      } else if (e.state.current == '/iosLogin') {
        // 如果上一级页面是guide，则不可点击概览或者whatsapp功能栏
        // console.log("上一级页面是guide");
        // prePageIsGuide.value = true
        store.commit('setClickGoPay', false);
        // console.log("totalDeviceCount.value", totalDeviceCount.value);
        store.commit('setDefaultMenu', 'iosLogin');
        store.commit('setCurrentDeviceId', `Device ${totalDeviceCount.value}`);
        store.commit('setCurrentDeviceInfo', {
          id: `Device ${totalDeviceCount.value}`,
          model: 'leftTab.unbound',
          name: 'leftTab.unboundName',
        });
        store.commit('setDeviceType', 1);
      } else if (e.state.current == '/whatsapp') {
        // 取消选中 addDevice菜单项
        store.commit('setClickGoPay', false);
        // 重置设备列表当前激活项为第一台已绑定的设备
        // store.commit("setCurrentDeviceId", (deviceList.value[0] as any).id);
        // store.commit("setCurrentDeviceInfo", deviceList.value[0]);
        // 重置左侧菜单激活项为whatsapp
        store.commit('setDefaultMenu', 'whatsapp');
      } else if (e.state.current == '/instagram') {
        // 取消选中 addDevice菜单项
        store.commit('setClickGoPay', false);
        // 重置设备列表当前激活项为第一台已绑定的设备
        // store.commit("setCurrentDeviceId", (deviceList.value[0] as any).id);
        // store.commit("setCurrentDeviceInfo", deviceList.value[0]);
        // 重置左侧菜单激活项为whatsapp
        store.commit('setDefaultMenu', 'instagram');
      } else if (e.state.current == '/login') {
        store.commit('setBoundImg', false);
        store.commit('setDefaultMenu', null);
        // 如果用户退出前选择的是addDevice，在退出前清空，使用户下次进入选中第一个设备
        store.commit('setClickGoPay', false);
        store.commit('setExpired', false);
        localStorage.removeItem('authorization');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('user_id');
        store.commit('setModalStatus', '1');
        store.commit('setLoginStatus', false);
        localStorage.removeItem('token');
        store.commit('setIsDemo', null);
        store.commit('setHashValue', null);
        // 清空vuex的缓存
        store.commit('getUserInfo', {});
        store.commit('getDeviceInfo', []);
        store.commit('setSubscripts', {});
        store.commit('setCurrentDeviceInfo', {});
        store.commit('setCurrentDeviceId', 0);
        router.go(0);
      } else if (e.state.current == '/addDevice') {
        // 取消菜单激活项
        store.commit('setDefaultMenu', null);
        // 选中 addDevice菜单项
        store.commit('setClickGoPay', true);
      } else {
        // console.log(
        //   " 重置设备列表当前激活项为第一台已绑定的设备",
        //   e.state.current
        // );
        let currMenu = e.state.current.split('/')[1];
        store.commit('setDefaultMenu', currMenu);
        // 取消选中 addDevice菜单项
        store.commit('setClickGoPay', false);
        // 重置设备列表当前激活项为第一台已绑定的设备

        if ((deviceList.value[0] as any).apple_id) {
          console.log('iiiiiiiiios');
          store.commit(
            'setCurrentAppleId',
            (deviceList.value[0] as any).apple_id
          );
          store.commit(
            'setCurrentDeviceId',
            (deviceList.value[0] as any).devices[0].id
          );
          store.commit('setCurrentDeviceInfo', {
            ...(deviceList.value[0] as any).devices[0],
            is_expired: (deviceList.value[0] as any).is_expired,
            expire_at: (deviceList.value[0] as any).expired_at,
          });
          store.commit('setDeviceType', 1);
        } else {
          console.log('aaaandroid');
          store.commit('setCurrentDeviceId', (deviceList.value[0] as any).id);
          store.commit('setCurrentDeviceInfo', deviceList.value[0]);
          store.commit('setDeviceType', 0);
        }
      }
    },
    false
  );
};
