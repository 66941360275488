export const JA = {
  chat: {
    pictureText: '[写真]',
    videoText: '[動画]',
    audioText: '[オーディオ]',
    contactCard: '[連絡先カード]',
    location: '[位置]',
    other: '[その他]',
    all: 'すべての時間',
    today: '今日',
    yesterday: '昨日',
    last7Days: '過去7日間',
    last30Days: '過去30日間',
    customRange: 'カスタム範囲を選択',
    cancel: 'キャンセル',
    apply: '適用',
    pushNotify: 'プッシュ通知',
    numbers: '電話',
    emails: 'Eメール',
    outgoingCalls: '送信',
    incomingCalls: '着信',
    missedCalls: '不在着信',
    answeredCalls: '接続済み',
    noAnswer: '応答なし',
    canceledCalls: 'キャンセルした通話',
    loadingText: '読み込み中です。しばらくお待ちください...',
    noMoreText: 'これ以上のコンテンツはありません。',
    noInfoText:
      'アクティビティ情報はありません。後でもう一度確認してください。',
    noAddressText: 'アドレス帳情報がありません。後でもう一度確認してください。',
    noCallText: '通話履歴がありません。後でもう一度確認してください。',

    dateSelect: '日付選択',
    sentFile: 'ファイルを送信しました',
    sentAudio: 'オーディオを送信しました',
    unGetFile: 'ファイルを取得できません',
    unGetAudio: 'オーディオを取得できません',

    syncFailed: 'データの同期に失敗しました。',
    syncSuccessed: 'データの同期が成功しました。',
    unknownContact: '不明な連絡先',
    noSyncNotice1: 'データが ',
    noSyncNotice2: '24 時間',
    noSyncNotice3:
      '以上同期されていません。ターゲットデバイスの電源がオンになっており、インターネットに接続されていること、および「System Service」アプリが正常に実行されていることを確認してください。',
    sync: '同期',
    startDate: '開始日',
    endDate: '終了日',
    noSupportToast: 'デモ モードでは日付をフィルターできません。',

    unknowVideo:
      '動画メッセージ。 Instagram のデータ保護のため、詳細は表示されません。',
    unknowImage:
      '画像メッセージ。Instagram のデータ保護のため、詳細は表示されません。',
    unknowAudio:
      '音声メッセージ。Instagram のデータ保護のため、詳細は表示されません。',
    outgoingVoice: '音声呼び出し',
    incomingVoice: '音声着信',
    outgoingVideo: 'ビデオ通話の発信',
    incomingVideo: 'ビデオ通話の着信',
  },
  user: {
    userProfile: 'ユーザープロフィール',
    usesrname: 'ユーザー名:',
    edit: '編集',
    email: 'メールアドレス:',
    permission: '許可情報',
    bounded: 'バインドされたデバイス：',
    restDevice: '残りの利用可能なデバイス台数：',
    expire: '有効期限：',
    expired: '(期限切れ)',
    logout: 'ログアウト',
    changename: 'ユーザー名の変更',
    save: '保存',
    inputname: 'ユーザー名を入力してください。',
    nameNull: 'ユーザー名が異常です。もう一度入力してください。',
    networkError:
      'ユーザー名を変更できませんでした。ネットワークを確認してください。',
    nameSuccess: 'ユーザー名が正常に変更されました。',
    changeavatar: 'アバターを変更',
    loading: '読み込み中…',
    changeAvatar: '変更',
    supported:
      'JPG、JPEG、PNGをサポートし、アバターのサイズは 2M を超えることはできません。',
    maxsize: '画像サイズは 2M を超えることはできません',
    avatarError:
      'アバターの変更に失敗しました。ネットワークを確認してください。',
    avatarSuccess: 'アバターが正常に変更されました。',
    imgError: '画像エラー',
    imageType: '画像のフォーマットがサポートされていません。',
    nameEmpty: 'ユーザーネームをご入力ください',
  },
  leftTab: {
    device: 'デバイス',
    unbound: 'バインドされていないデバイス',
    addDevice: 'デバイスを追加',
    dashboard: 'ダッシュボード',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: '先にデバイスをバインドしてください。',
    payRemind: 'まずはご購入ください。',
    purchase: '今すぐ購入',
    unboundName: '今すぐデバイスをバインドします',
    toBuy: 'デバイスを購入',
  },
  guide: {
    title: 'デバイスをバインドしてください。',
    oneT: '1. ターゲットデバイスを取得する',
    oneContent:
      'デバイスを監視するには、ターゲット監視デバイスを約 10 分間取得する必要があります。',
    twoT: '2. アプリのダウンロードとインストール',
    twoContent1: 'ターゲットデバイスのブラウザに',
    twoContent2: 'と入力し、アプリをダウンロードしてインストールします。',
    threeT: '3. デバイスの権限を設定する',
    threeContent:
      'アプリの指示に従って、デバイスのアクセス許可を設定します。 設定後、デバイスを監視できます。',
    tips1:
      'ステルス性を確保するために、アプリは「System Service」という名前のシステム ソフトウェアに偽装されます。',
    tips2:
      'アプリを正常にインストールした後、ブラウザの記録とアプリのインストールパッケージを削除して、確実にシークレットモードにできます。',
    tips3:
      'アプリはデスクトップに「System Service」として表示されます。 ステルス効果を高めるために、フォルダーに非表示にすることを選択できます。',
    tips41:
      'アプリをインストールしてそのアクセス許可をより迅速に設定できるようにするために、最初に',
    tips42: 'を表示してその方法を学習できます。',
    detail: '詳細なチュートリアル',
    tips5:
      'バインドされたデバイスのアクセス許可の有効期限が切れている場合は、デバイスのバインドを変更し、それをアクセス許可にバインドして、データの収集を続行できます。',
    rebound: 'クリックしてデバイスのバインドを変更します。',
  },
  dashboard: {
    contact: 'WhatsAppの上位連絡先 (7日以内)',
    call: 'WhatsAppの上位通話',
    view: 'すべて見る',
    account: 'アカウント',
    id: 'アカウントID：',
    expire: '有効期限：',
    bound: 'バインドされたデバイス：',
    restDevice: '残りの利用可能なデバイス台数：',
    target: 'ターゲットデバイス情報',
    brand: 'ブランド：',
    model: 'モデル：',
    version: 'Android バージョン：',
    battery: 'バッテリー：',
    wifi: 'Wi-Fi:',
    appversion: 'アプリバージョン：',
    update: '最終更新時刻：',
    blank: '最近のアクティビティはありません',
    unbindSuc: 'バインド解除に成功しました。',
    unbindFail: 'バインド解除に失敗しました。',
    ReboundSuc: 'バインドの交換に成功しました。',
    ReboundFail: 'バインドの交換に失敗しました。',
    noPlace: '交換可能なバインディング デバイスはありません。',
    beExpOne: 'デバイスの権限は',
    beExpTwo: '日',
    beExpThree:
      'で期限切れになり、期限が切れると監視対象のデバイスのデータは同期されなくなります。',
    expiredOne: 'デバイスの権限が期限切れになりました、',
    expiredTwo:
      '後に, 以前に収集されたデータが消去されます。更新後, 監視対象デバイスのデータは引き続き同期されます。',
    renew: 'すぐに更新',

    insContact: 'Instagramの上位連絡先 (7日以内)',
    insCall: 'Instagramの上位通話',
  },
  payPage: {
    title: 'ParentalGuard の料金とプラン',
    dec: 'ParentalGuard で WhatsApp を監視するのは、思ったより簡単です！',
    bigSave: 'おススメ',
    one: '1月間プラン',
    three: '3月間プラン',
    year: '1年間プラン',
    toBuy: '今すぐ購入',
    auto: '自動更新',
    cancelAny: 'いつでも解約できる ',
    update: '5分ごとにデータを自動更新',
    collect: 'すべての会話を収集する',
    interaction: 'より良いインタラクティブ体験',
    hide: 'ステルスモード',
  },
  pop: {
    changeBound: 'バインドを変更',
    reboundText1: 'データの収集を続行するには、',
    reboundText2:
      'デバイスをバインドされていないデバイスのアクセス許可に変更してもよろしいですか (有効期限:',
    reboundText3: ')？',
    reboundText4:
      'デバイスをバインドされていないデバイスのアクセス許可に変更してもよろしいですか',
    noRebound:
      '交換可能なデバイス権限はありません。最初にデバイス権限を購入してください。',
    goPay: '今すぐ購入',
    reboundList: 'バインドされたデバイス',
    reboundDec:
      '次のデバイスの権限は期限切れです。データの収集を続行するには、現在バインドされていないデバイスに変更します。',
    rebounding: 'バインドを交換中',
    reboundFail1: 'デバイス',
    reboundFail2: 'のバインド交換に失敗しました。再試行しますか？',
    tryAgain: '再試行',
    unbindTitle: 'バインド解除',
    unbindText1: 'デバイス',
    unbindText2:
      'のバインドを解除しますか? バインドを解除すると、収集されたすべてのデータ情報が消去されます。',
    remind: '期限切れのお知らせ',
    expiredPay: '更新する',
    cancel: 'キャンセル',
    yes: 'はい',
    no: 'いいえ',
  },
  navTop: {
    langTitle: '言語を変更',
  },
  famikeep200: {
    Calls: '通話',
    Messages: 'メッセージ',
    Contacts: '連絡先',
    Photos: '写真',
    Applications: 'アプリケーション',
    'Compared to yesterday': '昨日と比べて',
    'All locations': 'すべての場所',
    'Weekly average usage time': '週平均使用時間',
    'Floating comparison with last week': '先週と比べて',
    Average: '平均',
    Mon: '月',
    Tue: '火',
    Wed: '水',
    Thu: '木',
    Fri: '金',
    Sat: '土',
    Sun: '日',
    'Most used applications': 'よく使うアプリケーション',
    Geolocation: '位置情報',
    'Last updated': '更新時間',
    'Geolocation permission':
      '「System Service」の位置情報へのアクセス許可が無効になっているため、位置情報の取得に失敗しました。',
    Geofence: 'ジオフェンス',
    'Create a geofence':
      'ジオフェンスを作成し、お子様が指定したエリアに出入りした際に電子メールでアラートを受け取ることができます。',
    'enters/exits': '指定したエリアに出入りした',
    'Create a new geofence': '新規ジオフェンスの作成',
    'Geofence name': 'ジオフェンス名',
    'Set geofence location': 'ジオフェンスの位置を設定する',
    'Set radius': '半径を設定する',
    'Set monitoring time': '監視時間を設定する',
    'please choose': '選んでください',
    Daily: '毎日',
    'Every Monday': '毎月曜日',
    'Every Tuesday': '毎火曜日',
    'Every Wednesday': '毎水曜日',
    'Every Thursday': '毎木曜日',
    'Every Friday': '毎金曜日',
    'Every Saturday': '毎土曜日',
    'Every Sunday': '毎日曜日',
    'Send alerts via email': '電子メールでアラートを送信',
    'We will send alert emails to the registered email':
      '登録されたメールアドレスにアラートメールを送信します。',
    Save: '保存',
    'Please enter a geofence name': 'ジオフェンス名を入力してください。',
    'Please select a geofence location':
      'ジオフェンスの場所を選択してください。',
    Entered: 'に入った',
    Exited: 'を離れた',
    Edit: '編集',
    Delete: '削除',
    'Are you sure you want to delete':
      'この操作を行うと「xxx」が完全に削除されます。対応するモニタリングデータも削除されます。',
    'Are you sure you want to close':
      '閉じてもよろしいですか? ジオフェンスに出入りするときに電子メール アラートは受信されません',
    'Call history': '通話履歴',
    Status: '状態',
    Number: '携帯電話',
    Duration: '呼び出し時間',
    Date: '日',
    incoming: '着信',
    outgoing: '発信',
    missed: '不在着信',
    'no answered': '応答なし',
    Address: '住所',
    'Added time': '作成時間',
    'Sort by name': '名前順',
    'Sort by time': '時間順',
    'Please note that photos':
      '写真がアップロードされるのは、デバイスがWi-Fiに接続されている場合のみです。',
    Album: 'アルバム',
    'Select all photos on the current page': 'すべてを選択',
    'Previous page': '前のページ',
    'Next page': '次のページ',
    Back: '戻る',
    'confirm to delete': '削除を確認しますか？ 削除後のデータは復元できません',
    Download: 'ダウンロード',
    Calendar: 'カレンダー',
    Title: 'タイトル',
    Description: '説明',
    Location: '現在地',
    'Start time': '開始時間',
    'End time': '終了時間',
    'Installed applications': 'インストールされているアプリケーション',
    'All applications': 'すべてのアプリケーション',
    Name: '名前',
    Version: 'バージョン',
    Size: 'サイズ',
    'Installation date': '設置日',
    'Disable applications': 'アプリケーションを無効にする',
    'Seted disable rules': '無効化ルールを設定する',
    'Undisabled applications': '無効化されていないアプリケーション',
    'Click the button below':
      '下のボタンをクリックしてアプリ無効化ルールを作成し、無効化するアプリを選択します。',
    'Set disable rules': '無効化ルールを設定する',
    'Disable permanently': '永久に無効にする',
    'Disable ': '無効',
    'Custom disable time': 'カスタム無効時間',
    'Disable period': '無効期間',
    From: 'から',
    To: 'まで',
    Total: '合計',
    Weekly: '毎週',
    Time: '時間',
    'Select the applications you want to disable':
      '無効にしたいアプリケーションを選択する',
    'Please select an application below':
      '以下のアプリケーションを選択してください',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'この操作を行うと、この無効化ルールを応用したアプリには、効かなくなります。',
    'Disable options:': '無効モード：',
    'one Disable permanently':
      '1. 永久に無効にする： アプリケーションを完全に無効にします。無効化を解除するまで、お子様は無効化されたアプリを使用できなくなります。',
    'two Custom disable time':
      '2. カスタム無効化時間： 特定の時間帯のみ無効化ルールを発動し、それ以外の時間帯は通常通りアプリを使用できます。',
    'You can only choose one of the two setting options':
      '2 つの無効モードのうち 1 つだけを選択できます',
    complete: '完了',
    'Screen usage time': 'スクリーンタイム',
    'Screen Usage Time for xxx': 'xxxの使用時間:',
    'Frequently used applications': 'よく使われるアプリケーション',
    Frequency: '頻度',
    'Usage time': '使用時間',
    'Screen disable': 'スクリーンタイムをオンにする',
    'Turn on the switch and set the screen lock duration.':
      'スクリーンタイムをオンにして、スクリーンロックの時間を設定します。',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      '子供用のデバイスの画面は無効になり、設定時間が経過すると元に戻ります。',
    'Disable all screen activities': 'すべての画面操作を無効にする',
    'Set screen disable duration': 'スクリーン無効時間の設定',
    'You can lift the disablement at any time': 'いつでも解除できる',
    'Remaining screen disable time on the target device':
      '対象デバイスの画面無効化残り時間',
    'Are you sure you want to lift the screen disablement now?':
      '今すぐスクリーン無効化を解除しますか？',
    Yes: 'はい',
    'This account does not exist, please register first':
      'このアカウントは存在しません。まず登録してください',
    'Synchronization successful, no new data':
      '同期は成功しましたが、新しいデータはありません',
    'Location alert': '位置情報アラート',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      '監視している xxx デバイスは、xxxx-xx-xx に xx に入りました',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      '監視していた xxx デバイスは、xxxx-xx-xx の xx 場所を離れました',
    'View more location records:': '位置情報をもっと見る',
    'Thank you!': '今後ともよろしくお願いします。',
    'xxx Team': 'xxxチーム',
    'When obtaining this location':
      'この位置を取得するときに、デバイスは仮想測位をアクティブ化したため、不正確さが生じる可能性があります。',
    'The demo mode does not support this feature, please bind the device first.':
      'デモ モードはこの機能をサポートしていません。最初にデバイスをバインドしてください。',
    'Location Tracking': '位置追跡',
    'General Application': '一般的なアプリケーション',
    'App Activites Tracking': 'アプリの活動追跡',
    'Screen Time Tracking': '画面時間の追跡',
    'Social Application': 'ソーシャルアプリケーション',
    'Subject:': '主題：',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud使用済みストレージ',
    Online: 'オンライン',
    Offline: 'オフライン',
    'Last known location': '最終所在地',
    'Please bind your device': 'デバイスをバインドしてください',
    'Log in iCloud to binding.':
      '対象デバイスのiCloudアカウントにログインし、バインドを完了する。',
    'Please enter your Apple ID': 'Apple IDを入力してください',
    'Please enter your password': 'パスワードを入力してください',
    'Your entered account or password is incorrect.':
      '入力されたアカウントまたはパスワードが正しくありません。',
    'Ensure Apple ID and password':
      '対象となるデバイスのApple IDとパスワードを入力し、認証を受け、バインドが完了したことを確認します。',
    'Forgot Apple ID or password?': 'Apple IDまたはパスワードをお忘れですか？',
    'Devices using the same iCloud account will be bound':
      '同じiCloudアカウントを使用するデバイスはバインドされます。',
    'Two-Factor Authentication': '二重認証',
    'A message has been sent':
      '認証コードを含むメッセージがお客様のデバイスに送信されました。続行するには認証コードを入力してください。',
    'A message containing a verification code has been sent to xxx.':
      '認証コードを含むメッセージがxxxに送信されました。続行するには認証コードを入力してください。',
    'Incorrect verification code': '認証コードが正しくありません',
    'Resend Verification Code': '認証コードを再送信',
    'Get SMS Verification Code': 'SMS認証コードを取得',
    'Please select a phone number to receive the verification code.':
      '認証コードを受け取る電話番号を選択してください。',
    'Please select': '選択してください',
    'The current iCloud has expired':
      '現在のiCloudアカウント認証の有効期限が切れています。データを同期するには、iCloudアカウントに再度ログインしてください。',
    'current iCloud has expired':
      '現在のiCloudアカウント認証の有効期限が切れています。データを同期するには、iCloudアカウントに再度ログインしてください。認証に進む>> ',
    'Go to verification >>': '認証に進む>> ',
    'Data synchronization failed, iCloud account expired':
      'データの同期に失敗しました。iCloudアカウントの有効期限が切れています。ホームページに戻り再認証してください。',
    'Created geofences': '作成されたジオフェンス',
    'No geofences created': 'ジオフェンスが作成されていない',
    'ParentalGuard - Parental Control App Pricing & Plans':
      'ParentalGuard - 親子コントロールアプリの価格とプラン',
    '30-Day Money Back Guarantee': '30日間の返金保証',
    'Professional Customer Support': 'プロフェッショナル・カスタマー・サポート',
    'All Prices Exclusive of VAT': 'すべての価格は消費税抜きです',
    '100% Secure': '100%安全',
    'More Features to Keep Your Kids Safe Online':
      '子供たちの安全なオンラインを守るための機能がさらに充実',
    Features: '機能',
    'Monitor App': 'モニターアプリ',
    'Type of Data': 'データの種類',
    'WhatsApp Chat': 'WhatsAppチャット',
    'App Activity': 'アプリケーション活動',
    'App Blocker': 'アプリケーションインターセプター',
    'Monitor Social Apps': 'ソーシャルアプリの監視',
    'Location History': '位置履歴',
    'Location Reminder': '位置アラート',
    'Live Location': 'リアルタイムの場所',
    'Safe Search': '安全な検索',
    'Web Filter': 'ウェブフィルタ',
    'Browser History': 'ブラウザ履歴',
    'Content Detection': 'コンテンツ検出',
    'All SMS': 'すべてのメッセージ',
    Photos: '写真',
    Video: 'ビデオ',
    Calendar: 'カレンダー',
    Documents: 'ドキュメント',
    'Record Screen': '録画画面',
    'Call History': '通話履歴',
    'Call Recording': '通話録音',
    Contacts: '連絡先',
    Messages: 'メッセージ',
    'Call Logs': '通話記録',
    'Chat History': 'チャット履歴',
    'The device has been bound to other account':
      "L'appareil a été lié à un autre compte, veuillez d'abord le dissocier ou contacter le service client pour le dissocier.",
    Gallery: 'ギャラリー',
    'Hidden or Deleted Albums': '隠されたまたは削除されたアルバム',
    'Recent Projects': '最近のプロジェクト',
    'Personal Collection': '個人',
    'Recently Deleted': '最近削除',
    'Live Photos': 'ライブフォト',
    'Exposure Photos': '露出写真',
    'Panoramic Photos': 'パノラマ写真',
    'Time-Lapse Photography': 'タイムラプス写真',
    'Slow Motion': 'スローモーション',
    Portrait: '肖像画',
    'Burst Mode': '連続ショット',
    Screenshot: 'スクリーンショット',
    Motion: 'ダイナミック',
    Hide: '隠す',
    'This iCloud account':
      'このiCloudアカウントはデバイスに関連付けられていません。デバイスでログインしてください。',
    'Data synchronization':
      'データ同期には時間がかかる場合がありますので、お待ちください。',
    'Location History1': '位置履歴',
    'Auto Renewal': '自動更新といつでもキャンセル',
    Month: '月',
    Quarter: '四半期',
    Year: '年',
    Day: '日',
    'See Demo': 'デモを見る',
    'We Accept': '受け付けます',
  },
  famikeep220: {
    'Browser Monitoring': 'ブラウザモニタリング',
    'Browser History': 'ブラウザの履歴',
    'Browser Bookmarks': 'ブラウザのブックマーク',
    'Serial Number': 'シリアル番号',
    'Website Restrictions': 'ウェブサイト制限',
    'Restriction Rules': '制限ルール',
    'Prohibited Access': 'アクセス禁止',
    'Access Warning': 'アクセス警告',
    'Restricted Website Log': '制限付きサイトへのアクセス記録',
    'Click to Add Restricted Website': '制限付きサイトの追加をクリック',
    'Restriction Method:': '制限方法：',
    'Prohibit Access':
      '1.アクセス禁止：ウェブサイトへのアクセスを完全に禁止します。 ウェブサイト禁止ルールを解除するまで、お子様はブラウザ内で禁止されたウェブサイトにアクセスできなくなります。',
    'Access Alerts':
      '2.アクセス警告：お子様がルール内のウェブサイトにアクセスすると、警告メールが送信され、警告記録が作成されます（同じウェブサイトに対して24時間以内に送信される警告メールは1回のみです）。',
    'the rule disabling access takes priority.':
      '両方のルールに同じWebサイトが存在する場合は、「アクセス禁止」ルールが優先されます。',
    'Set Restriction Rules': '制限ルールの設定',
    'This option will block':
      'このオプションにより、入力された Web サイトへのアクセスが禁止されます。',
    'This option will allow':
      'このオプションにより、入力された Web サイトへのアクセスが許可されます。',
    'For example':
      'たとえば、example.com を入力した場合、関連するすべてのウェブページ（例：www.example.com/page1.html）もブロックされます。',
    'If you enter the specific website page':
      '特定のウェブサイトページ（例：www.example.com/page1.html）を入力すると、そのページのみがブロックされます。',
    'This option allows':
      'このオプションは、入力されたウェブサイトへのアクセスを許可しますが、対応する閲覧履歴が生成されます。',
    'Send records by email': '閲覧記録を電子メールで送信します。',
    'When enabled':
      '有効にすると、入力されたウェブサイトにアクセスする際に、登録された電子メールに記録を送信します。',
    'enter example':
      '例えば、example.comを入力すると、関連するすべてのウェブページ（www.example.com/page1.htmlなど）も警告されます。',
    'enter the specific webpage':
      '特定のウェブサイトページ（例：www.example.com/page1.html）を入力すると、そのページのみがアラートされます。',
    'Enter the website to be restricted':
      '制限するウェブサイトを入力してください。',
    Bookmark: 'ブックマーク',
    'Bookmark list': 'ブックマークリスト',
    'Total xx bookmarks': '合計xx個のブックマーク',
    'Enter the URL': 'ウェブサイトを入力してください（例：youtube.com）',
    'Added URLs': '追加されたウェブサイト',
    Add: '追加',
    'Please add the restricted URL': '制限するウェブサイトを追加してください。',
    'Are you sure':
      '削除してもよろしいですか？削除後は当ウェブサイトへのアクセス制限が解除されます。',
    Video: 'ビデオ',
    'Please note that videos':
      'ビデオは、デバイスがWIFIに接続されている場合のみアップロードされることに注意してください。',
    'Website access alert': 'ウェブサイトアクセス警告',
    'monitoring accessed':
      '監視対象のxxxデバイスがxxxx-xx-xxにxxにアクセスしました。',
    'View more records:': 'もっと記録を表示：',
    'Download and install the app.':
      'アプリをダウンロードしてインストールしてください。',
    'After installation':
      'インストールが完了したら、xxx.comを訪れてアプリを開いてください。',
    'Open immediately': 'すぐに開く',
    'Unable to open System Service:': 'System Serviceを開けません：',
    'Please ensure this device has installed':
      '1. このデバイスが System Service をインストールしていることを確認してください（System Service をダウンロードするにはクリックしてください）',
    'click download':
      '（System Service をダウンロードするにはクリックしてください）',
    'Copy the link':
      '2. 現在のウェブページのリンクアドレスをコピーし、組み込みブラウザで開く',
    'Sure close': '閉じますか？閉じると電子メールの警告が受信されません。',
    Loading: '読み込み中',
    'Loading failed':
      'ロードに失敗しました。ローカルで表示するにはダウンロードしてください。',
    'Select All Videos': 'すべての動画を選択',
    tips3: 'このアプリはデバイス上で「System Service」という名前で隠れます。',
    'If the video cannot be loaded':
      '動画が長時間読み込まれない場合は、ダウンロードしてご覧ください。',
  },
  famikeep222: {
    'Continue Google': 'Googleで続ける',
    or: 'または',
    Continue: '続ける',
    'legal guardian':
      '合法的な成人であり、未成年の子供のみをこのアカウントに追加します。',
    'take around 10 mins':
      'Androidデバイスをバインドしてください(約10分かかります)',
    'Get the Android device': '1. 監視対象のAndroid端末をご用意ください。',
    'Install Companion App': '2.補助アプリをインストールしてください。',
    'Login to set permissions':
      '3. 権限の設定のためにまずログインしてください。',
    twoContent1:
      '監視対象のAndroidデバイスから**にアクセスして、アプリをダウンロードおよびインストールしてください。',
    'set up permissions':
      '補助アプリ内でアカウントにログインして権限を設定すると、監視を開始できます。 (アプリはアカウントやパスワードでのみログインできます。パスワードがまだ設定されていない場合は、 ** して設定してください)。',
    'Click here': 'ここをクリック',
    'continue collecting data':
      '5. バインドされたデバイスの許可が期限切れになった場合は、再度バインドしてデータの収集を継続できます。',
  },
  // 用户模块
  language: '言語',
  signIn: '登録',
  email: 'メールアドレス',
  password: 'パスワード',
  sendCode: '確認コードを送信',

  // 登录
  signingIn: '登録中',
  noAccount: 'アカウントがない?',
  signUpNow: '登録する',
  forgetPassword: 'パスワードを忘れた',

  // 注册
  signUp: 'サインアップ',
  createAccount: 'アカウントの作成',
  emVerificationCode: '確認コード',
  creatingAccount: 'アカウントを作成中',
  alreadyHaveAccount: 'すでにアカウントをお持ちですか?',
  SignInUp: '登録',
  send: '送信済み ',
  sing: '秒',
  byCreatingAnAccount: 'アカウントを作成すると、',
  termsOfService: '利用規約',
  and: 'と',
  privacyPolicy: 'プライバシー ポリシー',
  privacyEnd: 'に同意したことになります。',

  // 忘记密码
  retrievePassword: 'パスワードを取得する',
  backTo: '登録に戻る',
  newPassword: '新しいパスワード',
  resetPassword: 'パスワードを再設定する',
  loginSucceeded: '正常にログインしました！',
  logoutSucceeded: '正常にログアウトしました！',

  // 用户模块异常提示
  passwordMmustBe8:
    'パスワードは 8 ～ 20 文字で、文字と数字の組み合わせである必要があります。',
  pleaseEnterPassword: 'パスワードを入力してください。',
  pleaseEnterEmailAddress: 'メールアドレスを入力してください。',
  invalidEmailFormat: 'メール形式が無効です',
  pleaseEnterVerifiCode: '確認コードを入力してください。',
  incorrectVerifiCode: '確認コードが正しくありません。',

  // Toast文案
  verificationCodeHasBeen:
    '確認コードが送信されました。メールを確認してください。',
  pleaseReadAndAgree:
    '利用規約とプライバシーポリシーを読んで同意してください。',
  networkAbnormal: 'ネットワーク異常',
  emailRegistered: 'メールが登録されていません。先に登録してください。',
  passwordNotold:
    '新しいパスワードを古いパスワードと同じにすることはできません。',
  invalidPasswordFormat: 'パスワードエラー',
  theEmailHasRegistered: 'メールアドレスが登録されました。',
  verificationCodeGetAgain:
    '確認コードの有効期限が切れています。もう一度取得してください。',
  erificationCodeMatch: 'メールアドレスと確認コードが一致しません。',
  incorrectPasswordOrEmail:
    'メールアドレスまたはパスワードエラー。もう一度入力してください。',
  requestError: 'リクエストエラー',
  pleaseTryAgainLater:
    'リクエストの頻度が高すぎます。しばらくしてからもう一度お試しください。',
  accessDenied: 'アクセス拒否',
  requestErrorResourceNotFound:
    'リクエスト エラー。リソースが見つかりませんでした。',
  serverError: 'サーバーエラー',
  connectionServerFailed: '接続サーバーが失敗しました。',
  requestTimedOut: 'リクエストがタイムアウトしました。',
  networkAbnormaled: 'ネットワーク異常',
  serviceUnavailable: 'サービスは利用できません。',
  httpVersioSupportTheRequest:
    'http バージョンはリクエストをサポートしていません。',
  wrongPasswordTime:
    'パスワードを5回入力してエラーが発生しました。アカウントは一時的にロックされ、ログインできません。10分後に再試行してください。',
};
