//1.引入vue
// import Vue from 'vue'
//2.引入axios库
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import store from '../store/index';
import router from './../router';
import { ElMessage } from 'element-plus';
import i18n from '@/main';

let nodeEnv = process.env;
// const baseURL = nodeEnv.VUE_APP_BASE_URL;
// console.log('window.location:', window.location, nodeEnv);
let baseURL;
let originUrl = window.location.origin;
if (
  originUrl.includes('famikeep-test') ||
  originUrl.includes('famikeep-demo')
) {
  baseURL = 'https://famikeep-test.afirstsoft.cn/api/';
} else if (
  originUrl.includes('my.famikeep') ||
  originUrl.includes('demo.famikeep')
) {
  baseURL = 'https://my.famikeep.com/api/';
} else if (
  originUrl.includes('parentalguard-test') ||
  originUrl.includes('parentalguard-demo')
) {
  baseURL = 'https://parentalguard-test.afirstsoft.cn/api/';
} else if (
  originUrl.includes('my.parentalguard') ||
  originUrl.includes('demo.parentalguard')
) {
  baseURL = 'https://my.parentalguard.com/api/';
} else {
  baseURL = 'https://parentalguard-test.afirstsoft.cn/api/';
  // baseURL = 'https://my.parentalguard.com/api/';
}

export const service = axios.create({
  baseURL,
  timeout: 10 * 1000,
});

// 存储进行中的请求
const pendingRequest = new Map();
function addPendingRequest(config: { url: string; cancelToken: CancelToken }) {
  let url = config.url;
  // console.log('config:', config);

  const requestKey = url;
  // console.log('configconfigconfig:', config);

  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
  // console.log('configconfigconfig1111:', config);

  // 同步正在发送的请求列表

  // 请求开始时将isCancel重置为false，以免报错无提示
  store.commit('setPendingRequest', pendingRequest);
}

// 重复请求取消及删除队列
function removePendingRequest(config: any) {
  // let { url } = config;
  // // console.log('urlurlurlurl:', url, pendingRequest);
  // if (pendingRequest.has(url)) {
  //   const cancelToken = pendingRequest.get(url);
  //   cancelToken(url);
  //   pendingRequest.delete(url);
  //   // console.log('在request中，pendingRequest，url:', pendingRequest, url);
  //   // 每次取消请求同步操作状态，以便发起请求和需要取消请求的操作使用
  //   // let requestInfoStore = requestInfo();
  //   // requestInfoStore.$patch((state) => {
  //   //     state.isCancel = true; //重复请求取消不作为报错处理
  //   //     state.pendingRequest = pendingRequest;
  //   // });
  // }
}
// 2.请求拦截器
service.interceptors.request.use(
  (config: any) => {
    console.log('config:', config);

    if (config.baseURLtype) {
      config.baseURL = nodeEnv[config.baseURLtype];
    }
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // 数据转化,也可以使用qs转换
    config.data = JSON.stringify(config.data);
    // config.data = true
    config.headers = {
      'Content-Type': 'application/json', // 配置请求头
      // "X-Oss-Object-Acl": "public-read",
      authorization: localStorage.getItem('authorization'),
    };
    if (config.url.indexOf('/api/v1/mapbox/getMapBoxToken') >= 0) {
      delete config.headers.authorization;
    }
    //根据isnotCancel判断是否取消检查重复请求
    // if (!config.isCancelRepeat) {
    //   console.log('取消删除请求');
    // removePendingRequest(config); // 检查重复请求
    // }
    // 把当前请求添加到pendingRequest
    addPendingRequest(config);
    return config;
  },
  (error) => {
    store.state.overLoading = false;
    ElMessage.error('You are out of networker');
    Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    console.log('response', response);
    // 如果是登录失效，则清除本地存储中的用户信息，同时保存email用于自动填充登录框的用户名
    if (
      response.data.code == 401 &&
      response.data.data.reason == 'INACTIVE_EXPIRED'
    ) {
      localStorage.removeItem('authorization');
      const userInfo = JSON.parse(localStorage.getItem('userInfo') || '0');
      const email = userInfo.email;
      localStorage.setItem('email', JSON.stringify(email));
      localStorage.removeItem('userInfo');
      localStorage.removeItem('user_id');
      store.dispatch('setLoginStatus', false);
      store.commit('setIsLoginExpired', true);
    }
    store.state.overLoading = false;
    return response;
  },
  (error) => {
    store.state.overLoading = false;
    /** *** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      console.log('error:', error, error.response.data.code == 400);

      // 1.公共错误处理
      // 2.根据响应码具体处理
      // console.log("error.response.status", error.response.status);

      switch (error.response.data.code) {
        case 401001:
          // 当密码发生修改或者token失效，其他地方就退出登录
          // ElMessage.error("Unauthorized, please login first!");
          localStorage.removeItem('authorization');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('user_id');
          store.commit('setModalStatus', '1');
          store.commit('setLoginStatus', false);
          localStorage.removeItem('token');
          store.commit('setHashValue', null);
          // 清空vuex的缓存
          store.commit('getUserInfo', {});
          store.commit('getDeviceInfo', []);
          store.commit('setSubscripts', {});
          store.commit('setCurrentDeviceInfo', {});
          store.commit('setCurrentDeviceId', 0);

          router.go(0);
          break;
        case 401003:
          // 登录过期;
          if (store.state.messageToastType !== 3) {
            store.commit('setMessageToastType', 4);
            store.commit('setIsExpiredLogin', true);
            store.commit('setIsShowBack', true);
          }
          if (
            !window.location.href.includes('home') &&
            !window.location.href.includes('guide')
          ) {
            router.push({ path: '/iosLogin' });
            store.commit('setDefaultMenu', null);
          }
          break;
        case 400:
          console.log('111111111111111111');
          // ElMessage.error(i18n.global.t('requestError'));
          break;
        case 403:
          ElMessage.error(i18n.global.t('accessDenied'));
          break;
        case 404:
          ElMessage.error(i18n.global.t('requestErrorResourceNotFound'));
          break;
        case 408:
          ElMessage.error(i18n.global.t('requestTimedOut'));
          break;
        case 500:
          ElMessage.error(i18n.global.t('serverError'));
          break;
        case 502:
          ElMessage.error(i18n.global.t('connectionServerFailed'));
          break;
        case 503:
          ElMessage.error(i18n.global.t('serviceUnavailable'));
          break;
        case 504:
          ElMessage.error(i18n.global.t('networkAbnormaled'));
          break;
        case 505:
          ElMessage.error(i18n.global.t('httpVersioSupportTheRequest'));
          break;
        //   default:
        //     ElMessage.error(error.response.status);
      }
    } else {
      // 超时处理
      // ElMessage.error(i18n.global.t("networkAbnormal"));
      console.log('超时处理？？？:', error.__CANCEL__);
      if (error.__CANCEL__) return;
      ElMessage.error(i18n.global.t('networkAbnormaled'));
    }
    console.log('error:', error);
    /** *** 处理结束 *****/
    // 如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);

// 4.导入文件
export default service;
