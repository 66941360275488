import mutations from './mutations';
import actions from './actions';

const generalAppStore = {
  state: {
    // isShowMessageLeft: true,
    isShowAppBlockLeft: true,
    isShowMessageLeft: true, //是否展示左侧的信息菜单
    isShowWhatsAppLeft: true, //是否展示whatsapp的左侧菜单栏
    isShowInstagramLeft: true, //是否展示instagram的左侧菜单栏
  },
  mutations,
  actions,
};
export default generalAppStore;
